import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import { getCdnUrl } from '../../../Services/ImageResizer';
import { sameDate } from '../../../Services/SchedulerHelper';
import { hex2rgba } from '../../../Services/DataHelper';

type Props = {
  style: Object,
  data: Object,
  isShaded: Boolean,
  updatedTaskId: Number,
  isUpdating: Boolean,
  currentUser: Object,
  view: String,
  datas: Array,
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0.5),
  },
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(0.5),
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
  iconColor: {
    '&>*': {
      color: '#fff !important',
    },
  },
  media: {
    backgroundColor: 'white',
  },
}));

const TwigAppointmentFullScreen = ({ style, data, view, datas, ...restProps }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isOnMultipleTwigsDay =
    datas?.filter(d => sameDate(new Date(d.startDate), new Date(data.startDate))).length > 1;
  const twigDuration = new Date(data?.endDate - data?.startDate).getHours();
  return (
    <Appointments.Appointment
      {...restProps}
      data={data}
      style={{
        ...style,
        backgroundColor: data.lightBackgroundColor || hex2rgba(data.backgroundColor, 0.2),
        marginTop: view === 'Month' && !isOnMultipleTwigsDay ? '-20px' : 0,
        minHeight: view === 'Month' && !isOnMultipleTwigsDay ? '120px' : 'auto',
        color: 'black',
        borderColor: data.backgroundColor,
        borderWidth: 3,
      }}
    >
      <Grid className={classes.container}>
        {((view === 'Week' && twigDuration > 3) || (view === 'Month' && !isOnMultipleTwigsDay)) && (
          <Grid item>
            <CardMedia
              classes={{
                root: classes.mediaTextArea,
                media: classes.media,
              }}
              component="img"
              src={getCdnUrl(data.twig.task.cover_url, 280, 110)}
              alt={data.twig.excerpt}
              title={data.twig.excerpt}
            />
          </Grid>
        )}
        <Grid item>
          <Grid>{`${data.event_start_time} - ${data.event_end_time}`}</Grid>
          <Grid
            className={classes.title}
            style={{
              WebkitLineClamp: view === 'Month' ? '3' : '5',
            }}
          >
            {data.title || t('APP.ACTIVITY.HEALTH_TITLE')}
          </Grid>
        </Grid>
      </Grid>
    </Appointments.Appointment>
  );
};

export default TwigAppointmentFullScreen;
