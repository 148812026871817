import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import RowTwig from './RowTwig';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  info: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2.5),
    color: 'initial',
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

type Props = {
  twigs: Array,
  currentUser: Object,
  organisation: Object,
  archived: Boolean,
};

const GridTwigs = ({ twigs, currentUser, organisation, archived }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isFamilyTwig = Boolean(!organisation.holding_slug);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t('APP.ACTIVITY.CATEGORY.TITLE')}</TableCell>
              <TableCell>{t('APP.ACTIVITY.ACTIVITY_NAME.TITLE')}</TableCell>
              <TableCell>{t('APP.ACTIVITY.NB_COMMENT')}</TableCell>
              <TableCell>{t('APP.ACTIVITY.NB_PARTICIPANT')}</TableCell>
              {!isFamilyTwig && <TableCell>{t('APP.ACTIVITY.STATUS')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {twigs?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(twig => {
              return (
                <RowTwig
                  key={`${twig.task.id}-${twig.event_date}`}
                  twig={twig}
                  currentUser={currentUser}
                  organisation={organisation}
                />
              );
            })}
          </TableBody>
        </Table>
        {archived && (
          <Typography variant="body2" className={classes.info}>
            <InfoIcon className={classes.infoIcon} />
            {t('APP.ACTIVTY.ARCHIVED')}
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30]}
        component="div"
        count={twigs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => setPage(newPage)}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t('ROWS_PER_PAGE')}
      />
    </>
  );
};

export default GridTwigs;
