import React from 'react';
import { useTranslation } from 'react-i18next';
import ForumIcon from '@material-ui/icons/Forum';
import MenuItems from '../../Block/MenuItems';
import {
  getSubscription,
  getUnreadPostCount,
  SubscribleSubTypes,
  SubscribleTypes,
} from '../../../Services/SubscriptionHelper';
import { isMyFamily } from '../../../Acl/Controls';

type Props = {
  family: Object,
  hideAccordion: Boolean,
};

function MenuItemCarenotebooks({ family, hideAccordion }: Props) {
  const { t } = useTranslation();

  return (
    <MenuItems
      items={[
        {
          id: 'carenotebook',
          link: `/families/${family.slug}/care-notebook`,
          title: t('APP.ORGANISATION.MENU.CARENOTEBOOK_LINK', {
            context: isMyFamily(family) && 'MINE',
          }),
          icon: <ForumIcon />,
          sub_type: SubscribleSubTypes.POSTS,
          unread_posts_count: getUnreadPostCount(
            family,
            SubscribleTypes.FAMILY,
            SubscribleSubTypes.POSTS,
            family.id,
          ),
          showNotifOffIcon: !getSubscription(
            family,
            SubscribleTypes.FAMILY,
            SubscribleSubTypes.POSTS,
            family.id,
          ),
        },
      ]}
      isFetching={false}
      menuTitle={t('APP.ORGANISATION.MENU.CARENOTEBOOKS.LINK')}
      canCreate={false}
      hideAccordion={hideAccordion}
    />
  );
}

export default MenuItemCarenotebooks;
