import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import CommunityPost from './CommunityPost';
import CommunityPostForm from './CommunityPostForm';
import OrganisationSignUp from '../../Pages/Authentication/Organisation/SignUp';
import ContactSuccess from '../Organisations/Shared/ContactSuccess';
type Props = {
  posts: Array,
  onCreatePost: Function,
  isFetching: Boolean,
  noPostCard: Node,
  totalPosts: Number,
  isFetchingNext: Boolean,
  onFetchNext: Function,
  canCreatePost: Function,
  community: Object,
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 0,
  },
  appbar: {
    margin: theme.spacing(0, 1, 1),
    borderRadius: 4,
    flexShrink: 1,
    zIndex: 0,
  },
  toolbar: {
    padding: theme.spacing(3),
    flexWrap: 'wrap',
  },
  progress: {
    marginTop: theme.spacing(-2),
    zIndex: '1000',
  },
  button: {
    padding: theme.spacing(1),
  },
  buttonContainer: {
    textAlign: 'center',
  },
  textField: {
    flexGrow: 1,
    '& .MuiInputBase-root': {
      height: 40,
    },
  },
  btnColor: {
    backgroundColor: theme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  postContainer: { width: '100%' },
}));

const CommunityTimeline = ({
  posts,
  onCreatePost,
  isFetching,
  noPostCard,
  totalPosts,
  isFetchingNext,
  onFetchNext,
  canCreatePost,
  community,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openCommunityPostFormDialog, setOpenCommunityPostFormDialog] = React.useState(false);
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openSignUpPending, setOpenSignUpPending] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const showSeeMoreButton = !isFetching && posts?.length > 0 && totalPosts > posts?.length;

  const handleSubmitClick = userEmail => {
    setEmail(userEmail);
    setOpenSignUp(false);
    setOpenSignUpPending(true);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.container}>
        <AppBar position="static" color="inherit" elevation={1} className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <TextField
              label=""
              variant="outlined"
              className={classes.textField}
              placeholder={t('APP.COMMUNITIES.SEARCH.AVAILABLE_SOON')}
              disabled
            />
            <Fab
              size="small"
              className={classes.btnColor}
              style={{ margin: '4px 16px' }}
              // onClick={handleSubmit}
              // ref={buttonRef}
              disabled
            >
              <SearchIcon fontSize="small" />
            </Fab>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className={classes.btnColor}
              onClick={() =>
                canCreatePost ? setOpenCommunityPostFormDialog(true) : setOpenSignUp(true)
              }
              disabled={!community?.validated}
            >
              {canCreatePost ? t('APP.COMMUNITIES.TIMELINE') : t('APP.COMMUNITIES.CARD.JOIN.LABEL')}
            </Button>
          </Toolbar>
        </AppBar>

        {isFetching && (
          <Grid item xs={12}>
            <LinearProgress className={classes.progress} />
          </Grid>
        )}
        {!isFetching && posts?.length === 0 && (
          <Grid item xs={12}>
            {noPostCard}
          </Grid>
        )}
        {posts?.length > 0 &&
          posts.map(post => (
            <Grid item key={`post-${post.id}`} className={classes.postContainer}>
              <CommunityPost post={post} />
            </Grid>
          ))}
        {showSeeMoreButton && (
          <Grid item key="button" className={classes.buttonContainer}>
            <Button
              className={classes.button}
              onClick={onFetchNext}
              startIcon={isFetchingNext ? <HourglassEmptyIcon /> : <ArrowDownwardIcon />}
              endIcon={isFetchingNext ? <HourglassEmptyIcon /> : <ArrowDownwardIcon />}
              disabled={isFetchingNext}
            >
              <Typography variant="caption">
                {isFetchingNext ? t('LOADING') : t('APP.TIMELINE.SEE_MORE')}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={openCommunityPostFormDialog}
        onClose={() => setOpenCommunityPostFormDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <CommunityPostForm
          dialogTitle="APP.COMMUNITIES.POST_FORM.CREATE"
          titleLabel="APP.COMMUNITIES.POST_FORM.LABEL.TITLE"
          rawLabel="APP.COMMUNITIES.POST_FORM.LABEL.RAW"
          handleSubmit={onCreatePost}
          onClose={() => setOpenCommunityPostFormDialog(false)}
          withImage
        />
      </Dialog>
      <Dialog open={openSignUp} onClose={() => setOpenSignUp(false)}>
        <OrganisationSignUp
          organisation={community}
          isDialog
          onSubmitClick={handleSubmitClick}
          subtitle={t('APP.COMMUNITIES.SIGN_UP.SUBTITLE')}
          title={t('APP.COMMUNITIES.SIGN_UP.TITLE')}
        />
      </Dialog>
      <Dialog open={openSignUpPending} onClose={() => setOpenSignUpPending(false)}>
        <ContactSuccess
          title={t('APP.COMMUNITIES.SIGN_UP.SUCCESS.TITLE')}
          description={!isLoggedIn && t('APP.COMMUNITIES.SIGN_UP.SUCCESS.DESCRIPTION', { email })}
          subtitle={t('APP.COMMUNITIES.SIGN_UP.SUCCESS.SUBTITLE')}
        />
      </Dialog>
    </>
  );
};

export default CommunityTimeline;
