import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CardContent,
  CardHeader,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@mui/icons-material/Edit';

import { getFamilyAvatar } from '../../Services/FamilyHelper';
import { getCapitalizedName } from '../../Services/DataHelper';
import SelectOrganisationDialog from '../Shared/SelectOrganisationDialog';
import LeaguesActions from '../../Redux/LeaguesRedux';
import getLeagueOrganisationsWithoutNoStruct from '../../Services/LeagueHelper';
import UserSettings from '../../Pages/UserSetup/UserSettings';
import HelperContactDetails from '../Shared/HelperContactDetails';
import WelloAvatar from '../Shared/WelloAvatar';
import Editor from '../Posts/EditorWysiwyg';

const useStyle = makeStyles(theme => ({
  cardHeader: {
    '& .MuiTypography-h5': {
      textTransform: 'none',
    },
  },
  list: {
    padding: theme.spacing(0.5, 0),
  },
  listItem: {
    overflowWrap: 'break-word',
  },
  listItemIcon: {
    minWidth: 40,
  },
  avatarHolding: {
    width: 26,
    height: 26,
  },
  notes: {
    margin: 0,
  },
  notesFormControl: {
    margin: theme.spacing(2, 0),
  },
  overline: {
    margin: theme.spacing(1, 0),
  },
}));

type Props = {
  league: Object,
  helper: Object,
  getLeagueTransmissions: Object,
};

const MenuHelper = ({ league, helper, getLeagueTransmissions }: Props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { league_id: leagueId } = match.params;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showEditUserSettings, setShowEditUserSettings] = React.useState(false);
  const [openSelectOrgaDialog, setOpenSelectOrgaDialog] = React.useState(false);
  const [openNotesDialog, setOpenNotesDialog] = React.useState(false);
  const [note, setNote] = React.useState(helper.league_note || '');
  const leagueOrganisationsWithoutNoStruct = getLeagueOrganisationsWithoutNoStruct(league);
  const helperOrganisations = leagueOrganisationsWithoutNoStruct.filter(o =>
    helper.organisations_ids?.includes(o.id),
  );

  const handleClickEditUser = () => {
    setAnchorEl(null);
    setShowEditUserSettings(true);
  };

  const handleEditLeagueHelper = status => {
    const helperAttributes = {
      status: status || helper.league_status,
      note: note,
    };
    dispatch(LeaguesActions.updateLeagueHelperRequest(leagueId, helper.id, helperAttributes));
    setOpenNotesDialog(false);
  };

  const handleClickTransfer = () => {
    setAnchorEl(null);
    setOpenSelectOrgaDialog(true);
  };

  const handleConfirmTransfer = selectedOrga => {
    if (
      !selectedOrga ||
      !helper ||
      helper?.organisations_ids?.includes(selectedOrga.id) ||
      !leagueOrganisationsWithoutNoStruct.find(o => o.id === selectedOrga.id)
    )
      return;

    dispatch(LeaguesActions.transferLeagueHelperRequest(league?.id, helper.id, selectedOrga.id));
    setOpenSelectOrgaDialog(false);
  };

  return (
    <>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={getFamilyAvatar({ admin: helper })}
          title={
            <Typography variant="h4" component="p">
              {getCapitalizedName(helper.first_name, helper.last_name)}
            </Typography>
          }
          subheader={
            helper.league_status && (
              <Typography variant="caption">{helper.league_status}</Typography>
            )
          }
          action={
            <>
              <IconButton
                onClick={event => setAnchorEl(event.currentTarget)}
                aria-label="More"
                aria-owns={anchorEl ? 'actions-menu' : undefined}
                aria-haspopup="true"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="actions-menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={handleClickEditUser}>
                  <ListItemText className={classes.flex}>
                    {t('APP.LEAGUES.SETTINGS.HELPERS.EDIT')}
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClickTransfer}>
                  <ListItemText className={classes.flex}>
                    {t('APP.LEAGUES.SETTINGS.HELPERS.TRANSFER')}
                  </ListItemText>
                </MenuItem>
              </Menu>
            </>
          }
        />
        <Divider />
        {helperOrganisations.length > 0 && (
          <>
            <CardContent>
              <List
                aria-labelledby="orga-list"
                subheader={
                  <Typography variant="overline" component="div" className={classes.overline}>
                    {t('STRUCTURES')}
                  </Typography>
                }
              >
                {helperOrganisations.map(orga => (
                  <ListItem key={orga.id} className={classes.list}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <WelloAvatar
                        avatarUrl={orga.cover_url}
                        avatarClass={classes.avatarHolding}
                        size={26}
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.listItem}>
                      <Typography variant="body2">{`${orga?.holding_name} - ${orga?.name}`}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </CardContent>
            <Divider />
          </>
        )}
        <CardContent>
          <List
            aria-labelledby="orga-list"
            subheader={
              <Typography variant="overline" component="div" className={classes.overline}>
                {t('CONTACT_DETAILS')}
              </Typography>
            }
          >
            <HelperContactDetails helper={helper} showCopyEmail />
          </List>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 8 }}>
        <CardHeader
          title={t('APP.LEAGUES.HELPER.NOTES')}
          className={classes.cardHeader}
          action={
            <IconButton onClick={() => setOpenNotesDialog(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
          }
        />
        <Divider />
        {helper.league_note && (
          <CardContent>
            <Typography
              className={classes.notes}
              dangerouslySetInnerHTML={{
                __html: helper.league_note,
              }}
            />
          </CardContent>
        )}
      </Card>
      <Dialog open={showEditUserSettings} onClose={() => setShowEditUserSettings(false)} fullWidth>
        <UserSettings
          userToEdit={helper}
          onClose={() => setShowEditUserSettings(false)}
          showLeagueStatus
          handleLeagueStatusChange={handleEditLeagueHelper}
          getLeagueTransmissions={getLeagueTransmissions}
        />
      </Dialog>
      <SelectOrganisationDialog
        open={openSelectOrgaDialog}
        dialogTitle={t(
          `APP.${league?.slug?.toUpperCase()}.LEAGUES.SETTINGS.SELECT_ORGANISATION`,
          t(`APP.LEAGUES.SETTINGS.SELECT_ORGANISATION`),
        )}
        dialogButtonLabel={t('APP.LEAGUES.SETTINGS.HELPERS.ASSIGN')}
        onConfirm={handleConfirmTransfer}
        onCancel={() => setOpenSelectOrgaDialog(false)}
        organisations={leagueOrganisationsWithoutNoStruct}
        disabledOrganisationsIds={helper?.organisations_ids}
      />
      <Dialog open={openNotesDialog} onClose={() => setOpenNotesDialog(false)} fullWidth>
        <Card>
          <CardContent>
            <Typography align="center" variant="h4" component="div" gutterBottom>
              {t('APP.LEAGUES.HELPER.NOTES')}
            </Typography>
            <FormControl component="fieldset" fullWidth className={classes.notesFormControl}>
              <Editor
                name="note"
                value={helper.league_note || ''}
                onChange={event => setNote(event.target.value)}
                options={['inline', 'emoji', 'list', 'link']}
                editorStyle={{ height: '300px' }}
              />
            </FormControl>
            <Grid align="center">
              <Button
                variant="contained"
                align="center"
                onClick={() => handleEditLeagueHelper(null)}
                color="primary"
              >
                {t('SEND')}
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
};

export default MenuHelper;
