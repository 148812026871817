import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import CommentIcon from '@mui/icons-material/Comment';
import CardActionArea from '@material-ui/core/CardActionArea';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import CardMedia from '@material-ui/core/CardMedia';
import LinearProgress from '@material-ui/core/LinearProgress';

import CommunityPostForm from './CommunityPostForm';
import CommentPost from '../Posts/CommentPost';
import PostForm from '../Posts/Form';
import { getRoleInCommunity, isCommunityMember } from '../../Acl/Controls';
import { CheckRoleRule } from '../../Acl/Rules';
import { getCdnUrl } from '../../Services/ImageResizer';
import DealsActions from '../../Redux/DealsRedux';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
  },
  postContainer: {
    padding: theme.spacing(3),
  },
  postInfo: {
    marginBottom: theme.spacing(1),
  },
  description: {
    margin: theme.spacing(1, 0),
  },
  shortDescription: {
    margin: theme.spacing(1, 0),
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '5',
  },
  chip: {
    marginRight: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.grey[400],
  },
  img: {
    marginTop: theme.spacing(2),
  },
  commentCountContainer: {
    padding: theme.spacing(2, 3),
  },
  commentContainer: {
    padding: theme.spacing(1),
  },
  postFormContainer: {
    margin: theme.spacing(3, 2, 0),
  },
  icon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[400],
  },
  actionBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  media: {
    maxHeight: '80vh',
    objectFit: 'contain',
  },
}));

type Props = {
  post: Object,
  dealId: Number,
  onCreateComment: Function,
  onDeleteComment: Function,
  onEditPost: Function,
  onDeletePost: Function,
};

const CommunityPost = ({
  post,
  dealId,
  onCreateComment,
  onDeleteComment,
  onEditPost,
  onDeletePost,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug } = match.params;
  const currentUser = useSelector(state => state.auth.currentUser);
  const stateDeal = useSelector(state => state.deals.deal);
  const errors = useSelector(state => state.deals.errors.getDeal);
  const isFetching = useSelector(state => state.deals.isFetching.getDeal);
  const creatingComment = useSelector(state => state.deals.isFetching.createDealPost);
  const [actionMenuAnchor, setActionMenuAnchor] = React.useState(null);
  const [openCommunityPostFormDialog, setOpenCommunityPostFormDialog] = React.useState(false);
  const deal = dealId ? stateDeal : post;
  const dealImage = deal?.attachments?.length > 0 && deal?.attachments[0];
  const role = getRoleInCommunity(
    currentUser,
    deal?.organisation?.holding_slug || holdingSlug,
    deal?.organisation?.slug,
  );
  const isDealReady = deal && (!dealId || (dealId && !isFetching && !errors?.status));

  const canDeleteComment = commentOwnerId => {
    return CheckRoleRule(role, 'deals-comments:delete', { userId: currentUser.id, commentOwnerId });
  };
  const canEditDeletePost = CheckRoleRule(role, 'deals:editdelete', {
    userId: currentUser?.id,
    dealOwnerId: deal?.helper?.id,
  });

  React.useEffect(() => {
    if (dealId) {
      dispatch(DealsActions.getDealRequest(holdingSlug, holdingSlug, dealId));
    }
  }, []);

  return (
    <>
      {isFetching && <LinearProgress />}
      {isDealReady && (
        <Card className={classes.card}>
          <CardActionArea
            disabled={Boolean(dealId || !isCommunityMember(role))}
            onClick={() =>
              history.push(`/communities/${holdingSlug}/timeline/${deal.id}`, { from: 'forum' })
            }
          >
            <Grid className={classes.postContainer}>
              <Typography
                variant="subtitle2"
                component="div"
                className={classes.postInfo}
                dangerouslySetInnerHTML={{
                  __html: t('APP.COMMUNITIES.POST.CREATED_BY', {
                    helper: `${deal?.helper?.first_name}  ${deal?.helper?.last_name}`,
                    date: moment(deal?.created_at)?.format('LL'),
                  }),
                }}
              ></Typography>
              <Typography variant="h3">{deal?.title}</Typography>
              <Typography
                className={dealId ? classes.description : classes.shortDescription}
                align="left"
                dangerouslySetInnerHTML={{ __html: deal?.description }}
              />
              {deal?.related_words &&
                deal?.related_words.map(word => (
                  <Chip className={classes.chip} size="small" label={word} key={word} />
                ))}
              {dealId && dealImage && (
                <Grid className={classes.img}>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    src={getCdnUrl(dealImage.preview_url, 500)}
                    alt={dealImage.filename || dealImage.preview_url}
                    title={dealImage.filename || dealImage.preview_url}
                    key={dealImage.preview_url}
                  />
                </Grid>
              )}
            </Grid>
            <Divider light />
            {deal?.posts?.length > 0 && (
              <Grid className={classes.commentCountContainer}>
                <Grid container>
                  <CommentIcon className={classes.icon} />
                  <Typography variant="body2" component="div">
                    {t('APP.POST.COMMENT_COUNT', {
                      count: deal?.posts?.length,
                      context: `${deal?.posts?.length}`,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </CardActionArea>
          {dealId && (
            <>
              <Grid className={classes.commentContainer}>
                {(deal?.posts?.sort((a, b) => a.id - b.id) || []).map(comment => (
                  <CommentPost
                    post={comment}
                    key={`comment-${comment.id}`}
                    onDelete={
                      canDeleteComment(comment.helper_id)
                        ? () =>
                            onDeleteComment(
                              deal?.organisation?.holding_slug || holdingSlug,
                              deal?.organisation?.slug,
                              deal?.id,
                              comment,
                            )
                        : null
                    }
                  />
                ))}
                <Grid className={classes.postFormContainer}>
                  <PostForm
                    currentUser={currentUser}
                    postableType="Deal"
                    createPost={onCreateComment}
                    creatingPost={creatingComment === deal?.id}
                    postableId={deal?.id}
                    sendOnEnter
                  />
                </Grid>
              </Grid>
              {canEditDeletePost && (
                <>
                  <IconButton
                    onClick={event => setActionMenuAnchor(event.currentTarget)}
                    aria-label="More"
                    aria-owns={actionMenuAnchor ? 'actions-menu' : undefined}
                    aria-haspopup="true"
                    style={{ margin: 0 }}
                    className={classes.actionBtn}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    open={Boolean(actionMenuAnchor)}
                    anchorEl={actionMenuAnchor}
                    onClose={() => setActionMenuAnchor(null)}
                  >
                    <MenuItem
                      onClick={() => {
                        setOpenCommunityPostFormDialog(true);
                        setActionMenuAnchor(null);
                      }}
                    >
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText>{t('EDIT')}</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onDeletePost(
                          deal?.organisation?.holding_slug || holdingSlug,
                          deal?.organisation?.slug,
                          deal?.id,
                        )
                      }
                    >
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText>{t('DELETE')}</ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
              <Dialog
                open={openCommunityPostFormDialog}
                onClose={() => setOpenCommunityPostFormDialog(false)}
                fullWidth
                maxWidth="lg"
              >
                <CommunityPostForm
                  dialogTitle="APP.COMMUNITIES.POST_FORM.EDIT"
                  titleLabel="APP.COMMUNITIES.POST_FORM.LABEL.TITLE"
                  rawLabel="APP.COMMUNITIES.POST_FORM.LABEL.RAW"
                  post={deal}
                  handleSubmit={onEditPost}
                  onClose={() => setOpenCommunityPostFormDialog(false)}
                  withImage
                />
              </Dialog>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default CommunityPost;
