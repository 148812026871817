import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DealsActions from '../../../Redux/DealsRedux';
import Images from '../../../Themes/Images';
import {
  isAssociation,
  isPfr,
  isEstablishment,
  isCommunity,
  getRoleInOrga,
  getRoleInCommunity,
} from '../../../Acl/Controls';
import { convertDealtoPost, urlifyText } from '../../../Services/DataHelper';
import Timeline from '../../Block/Timeline';
import {
  getLastReadDate,
  getSubscription,
  SubscribleSubTypes,
} from '../../../Services/SubscriptionHelper';
import AuthActions from '../../../Redux/AuthRedux';
import { CHANNEL_DEAL, subscribeChannel, unsubscribeChannel } from '../../../Services/WebSocket';
import CommunityTimeline from '../../Communities/CommunityTimeline';
import { CheckRoleRule } from '../../../Acl/Rules';

type Props = {
  organisation: Object,
};

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: '800',
  },
}));

const OrganisationTimeline = ({ organisation }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { t } = useTranslation();

  const { holding_slug: hSlug, organisation_slug: organisationSlug } = match.params;
  const currentUser = useSelector(state => state.auth.currentUser);
  const isFetching = useSelector(state => state.deals.isFetching.getDeals);
  const isFetchingNext = useSelector(state => state.deals.isFetching.getNextDeals);
  const isCreating = useSelector(state => state.deals.isFetching.createDeal);
  const deals = useSelector(state => state.deals.deals);
  const totalDeals = useSelector(state => state.deals.totalDeals);
  const creatingComment = useSelector(state => state.deals.isFetching.createDealPost);
  const orgaSlug = isCommunity(organisation) ? hSlug : organisationSlug;
  const isDealReady = deals && deals[0]?.organisation?.holding_slug === hSlug;

  const [page, setPage] = React.useState(1);

  const updateLastReadDate = React.useCallback(
    debounce(
      (sub, date) =>
        dispatch(
          AuthActions.updateHelperSubscriptionRequest(sub.helper_id, {
            ...sub,
            last_read_date: date.toJSON(),
          }),
        ),
      2000,
    ),
    [dispatch, organisation],
  );

  const onCreateDeal = (post, attachments) => {
    const deal = {
      title: post.title,
      description: urlifyText(post.raw),
      related_words: post.related_words,
    };
    dispatch(
      DealsActions.createDealRequest(
        organisation.holding_slug,
        organisation.slug,
        deal,
        attachments,
      ),
    );
  };

  const onEditDeal = (post, attachments) => {
    const deal = {
      id: post.id,
      description: urlifyText(post.raw),
      related_words: post.related_words,
    };
    dispatch(
      DealsActions.updateDealRequest(post.holding_slug, post.organisation_slug, deal, attachments),
    );
  };

  const onNextPageClick = () => {
    dispatch(
      DealsActions.getDealsRequest(organisation?.holding_slug, organisation?.slug, page + 1),
    );
    setPage(page + 1);
  };

  React.useEffect(() => {
    if (!organisation?.deals) {
      dispatch(DealsActions.getDealsRequest(organisation?.holding_slug, organisation?.slug, page));
    }
  }, []);

  const onCreateComment = (post, attachments, postId) => {
    dispatch(
      DealsActions.createDealPostRequest(
        post.holding_slug || hSlug,
        post.organisation_slug || orgaSlug,
        postId,
        post,
      ),
    );
  };

  const onDeleteDeal = (holdingSlug, organisationSlug, dealId) => {
    dispatch(DealsActions.destroyDealRequest(holdingSlug, organisationSlug, dealId));
  };

  const onDeleteComment = (holdingSlug, organisationSlug, dealId, post) => {
    dispatch(DealsActions.destroyDealPostRequest(holdingSlug, organisationSlug, dealId, post.id));
  };

  React.useEffect(() => {
    const channelSubscription = currentUser?.token
      ? subscribeChannel(
          currentUser?.token,
          { channel: CHANNEL_DEAL, organisation_id: organisation.id },
          {
            received(data) {
              if (data && data.helper_id !== currentUser.id && data.deal) {
                dispatch(DealsActions.createDealSuccess(data.deal));
                const sub = getSubscription(
                  organisation,
                  organisation.type,
                  SubscribleSubTypes.DEALS,
                  organisation.id,
                );
                const date = new Date();
                dispatch(
                  AuthActions.updateHelperSubscriptionRequest(currentUser.id, {
                    ...sub,
                    last_read_date: date.toJSON(),
                  }),
                );
              }
            },
          },
        )
      : null;
    return () => {
      if (channelSubscription) unsubscribeChannel(channelSubscription);
    };
    // eslint-disable-next-line
  }, [dispatch, currentUser?.token, organisation?.id]);

  React.useEffect(() => {
    const sub = getSubscription(
      organisation,
      organisation.type,
      SubscribleSubTypes.DEALS,
      organisation.id,
    );
    const date = new Date();
    if (sub) updateLastReadDate(sub, date);
    // eslint-disable-next-line
  }, [orgaSlug]);

  const lastReadDate = React.useMemo(() => {
    return getLastReadDate(
      organisation,
      organisation.type,
      SubscribleSubTypes.DEALS,
      organisation.id,
    );
  }, [organisation.id]);

  const renderNoPostCard = () => {
    return (
      <Card>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <CardHeader
              title={
                <Typography variant="h4" className={classes.bold}>
                  {t('APP.DEALS.EMPTY.TITLE')}
                </Typography>
              }
            />
            {(isAssociation(organisation) ||
              isPfr(organisation) ||
              isEstablishment(organisation)) && (
              <>
                <CardContent>
                  <Typography variant="h6" className={classes.bold}>
                    {t('APP.DEALS.EMPTY.SUBTITLE')}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Link
                    to={`/signup/on/${organisation.holding_slug}/${organisation.slug}/volunteer`}
                  >
                    {t('JOIN_US')}
                  </Link>
                </CardContent>
              </>
            )}
          </Grid>
          <Hidden only={['xs']}>
            <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
              <img alt="empty" src={Images.wello.no_timeline} />
            </Grid>
          </Hidden>
        </Grid>
      </Card>
    );
  };

  return isCommunity(organisation) ? (
    // CommunityTimeline
    <CommunityTimeline
      posts={isDealReady && deals?.map(deal => convertDealtoPost(deal))}
      canCreatePost={CheckRoleRule(getRoleInCommunity(currentUser, hSlug), 'deals:create')}
      onCreatePost={onCreateDeal}
      isFetching={isFetching || !deals || !organisation || orgaSlug !== organisation.slug}
      noPostCard={renderNoPostCard()}
      totalPosts={totalDeals}
      isFetchingNext={isFetchingNext}
      onFetchNext={onNextPageClick}
      community={organisation}
    />
  ) : (
    // OrgaTimeline
    <Timeline
      posts={isDealReady && deals?.map(deal => convertDealtoPost(deal))}
      lastReadDate={lastReadDate}
      holdingSlug={organisation.holding_slug}
      organisationSlug={organisation.slug}
      currentUser={currentUser}
      header={{
        title: t('APP.DEALS.HOME.CREATE'),
        titleIfCanNotCreatePost: t('APP.DEALS.HOME.READ'),
      }}
      canCreatePost={CheckRoleRule(
        getRoleInOrga(currentUser, organisation.holding_slug, organisation.slug),
        'deals:create',
      )}
      canReadPost={(hSlug, olug) =>
        CheckRoleRule(getRoleInOrga(currentUser, hSlug, olug), 'deals:read')
      }
      canEditDeletePost={(hSlug, olug, postOwnerId) =>
        CheckRoleRule(getRoleInOrga(currentUser, hSlug, olug), 'deals:editdelete', {
          userId: currentUser?.id,
          dealOwnerId: postOwnerId,
        })
      }
      canDeleteComment={(hSlug, olug, commentOwnerId) =>
        CheckRoleRule(getRoleInOrga(currentUser, hSlug, olug), 'deals-comments:delete', {
          userId: currentUser.id,
          commentOwnerId,
        })
      }
      onCreatePost={onCreateDeal}
      isCreatingPost={isCreating}
      commentPostableType="Deal"
      isFetching={isFetching || !deals || !organisation || orgaSlug !== organisation.slug}
      noPostCard={renderNoPostCard()}
      onEditPost={onEditDeal}
      onDeletePost={onDeleteDeal}
      totalPosts={totalDeals}
      isFetchingNext={isFetchingNext}
      onFetchNext={onNextPageClick}
      creatingComment={creatingComment}
      onCreateComment={onCreateComment}
      onDeleteComment={onDeleteComment}
    />
  );
};

export default OrganisationTimeline;
