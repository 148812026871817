import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IconButton, Tooltip } from '@material-ui/core';

import LinearProgress from '@material-ui/core/LinearProgress';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import EmptyList from '../../../Components/Shared/EmptyList';
import GridMembers from '../../../Components/Organisations/Membership/GridMembers';
import InviteMember from './InviteMember';
import { CheckRoleRule } from '../../../Acl/Rules';
import Roles, { getExistingRoles, getRolesForOrganisation } from '../../../Acl/Roles';
import { isCommunity } from '../../../Acl/Controls';
import ConfirmationDialog from '../../../Components/Shared/ConfirmationDialog';
import FilteringCard from '../../../Components/Shared/FilteringCard';
import LayoutContainer from '../../../Components/Layout/LayoutContainer';

type Props = {
  organisation: Object,
};

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '800',
    textTransform: 'none',
  },
  grow: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  appbar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    backgroundColor: 'white',
    flexGrow: 1,
  },
  progress: {
    width: '100%',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  btnColor: {
    backgroundColor: theme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
}));

const MembershipSettings = ({ organisation }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const members = useSelector(state => state.organisationMembership.members);
  const isFetching = useSelector(
    state => state.organisationMembership.isFetching.getOrganisationMembership,
  );
  const match = useRouteMatch();
  const { organisation_slug: orgaSlug, holding_slug: holdingSlug } = match.params;
  const currentUser = useSelector(state => state.auth.currentUser);
  const [openRemoveMemberDialog, setOpenRemoveMemberDialog] = React.useState(false);
  const [showInviteMemberDialog, setShowInviteMemberDialog] = React.useState(false);
  const canAddMembers = CheckRoleRule(organisation?.role, 'organisation:configure', {
    organisation,
  });
  const [filterItems, setFilterItems] = React.useState([]);

  const canQuit = CheckRoleRule(organisation?.role, 'organisation:quit', { organisation });
  const isCommu = Boolean(organisation && isCommunity(organisation));

  const membersFound =
    members &&
    members?.length !== 0 &&
    members?.every(m => m?.role !== Roles.PRIMARY) &&
    members?.every(m => m?.organisation_slug === organisation?.slug);
  const thisMemberCount =
    organisation?.helper_count - (organisation?.helper_count - members?.length);
  const otherMemberCount = organisation?.helper_count - members?.length;

  const rolesToShow = Object.keys(filterItems)
    .map(item => (filterItems[item].isRefined ? item : null))
    .filter(role => role !== null);
  const membersToShow = members?.filter(m =>
    rolesToShow.includes(t(`APP.ROLE.GROUPED.ORGA.${m.role.toUpperCase()}`)),
  );

  React.useEffect(() => {
    if (membersFound) {
      const roleGrouped = {};

      // default roles
      getRolesForOrganisation(organisation, t, [Roles.PRIMARY]).forEach(role => {
        roleGrouped[t(`APP.ROLE.GROUPED.ORGA.${role.value.toUpperCase()}`)] = {
          count: 0,
          isRefined: role.value !== Roles.EXTERIOR,
        };
      });

      // fill roles count
      members?.forEach(member => {
        const group = roleGrouped[t(`APP.ROLE.GROUPED.ORGA.${member.role.toUpperCase()}`)];
        if (group) {
          roleGrouped[t(`APP.ROLE.GROUPED.ORGA.${member.role.toUpperCase()}`)].count =
            group.count + 1;
        } else {
          roleGrouped[t(`APP.ROLE.GROUPED.ORGA.${member.role.toUpperCase()}`)].count = 1;
        }
        if (!member.validated)
          roleGrouped[t(`APP.ROLE.GROUPED.ORGA.${member.role.toUpperCase()}`)].tooltip = true;
      });
      setFilterItems(roleGrouped);
    }
  }, [organisation, members]);

  const onClickFilterItem = item => {
    const newFilterItems = { ...filterItems };
    newFilterItems[item.label].isRefined = !newFilterItems[item.label].isRefined;
    setFilterItems(newFilterItems);
  };

  React.useEffect(() => {
    if (organisation)
      dispatch(
        OrganisationMembershipActions.getOrganisationMembershipRequest(
          organisation.holding_slug,
          organisation.slug,
          getExistingRoles(organisation, t, [Roles.PRIMARY]).map(r => r?.value),
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgaSlug, holdingSlug, dispatch]);

  const handleClickInvite = (event, selectedHelper, role) => {
    if (selectedHelper) {
      dispatch(
        OrganisationMembershipActions.createOrganisationMembershipRequest(
          organisation.holding_slug,
          organisation.slug,
          { role, id: selectedHelper.id },
          null,
        ),
      );
    }
    setShowInviteMemberDialog(false);
    event.preventDefault();
  };

  const handleCreateOrgaMembership = (helper, role, email) => {
    dispatch(
      OrganisationMembershipActions.createOrganisationMembershipRequest(
        organisation.holding_slug,
        organisation.slug,
        {
          role,
          email: email ? email?.toLowerCase()?.trim() : null,
        },
        helper,
      ),
    );
  };

  const onRemoveMember = () => {
    dispatch(
      OrganisationMembershipActions.destroyOrganisationMembershipRequest(
        organisation.holding_slug,
        organisation.slug,
        currentUser.id,
        null,
      ),
    );
    setOpenRemoveMemberDialog(false);
  };

  return (
    <>
      {!organisation && (
        <Typography
          variant="body1"
          align="left"
          style={{ fontStyle: 'italic' }}
          dangerouslySetInnerHTML={{ __html: t('APP.ORGANISATION.SETTINGS.NO_FAMILY') }}
        />
      )}
      {organisation && (
        <>
          <AppBar position="static" color="inherit" elevation={1} className={classes.appbar}>
            <Toolbar className={classes.grow} style={{ flexWrap: 'wrap' }}>
              <Grid className={classes.grow}>
                <Typography className={classes.title} variant="h4" component="h3" align="left">
                  {t('APP.ORGANISATION.SETTINGS.MEMBERS.TITLE', {
                    value: organisation?.name || '',
                  })}
                  {membersFound && !isCommu ? ` (${thisMemberCount})` : ''}
                </Typography>
                {canAddMembers && (
                  <Typography variant="subtitle2" component="p" align="left" gutterBottom>
                    {t('APP.ORGANISATION.SETTINGS.MEMBERS.SUBTITLE')}
                  </Typography>
                )}
              </Grid>
              {canAddMembers && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  className={classes.btnColor}
                  onClick={() => setShowInviteMemberDialog(true)}
                >
                  {t('APP.ORGANISATION.SETTINGS.MEMBERS.ADD')}
                </Button>
              )}
              {isCommu && canQuit && (
                <Tooltip title={t('APP.COMMUNITIES.QUIT.LABEL')}>
                  <IconButton
                    onClick={() => setOpenRemoveMemberDialog(true)}
                    className={classes.button}
                    color="inherit"
                  >
                    <ExitToAppIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Toolbar>
          </AppBar>

          {isFetching && <LinearProgress className={classes.progress} />}
          {!isCommu && !isFetching && membersFound && (
            <LayoutContainer
              menu={
                <FilteringCard
                  items={Object.keys(filterItems).map(item => {
                    return {
                      label: item,
                      count: filterItems[item].count,
                      isRefined: filterItems[item].isRefined,
                      tooltip: filterItems[item].tooltip
                        ? t('APP.ORGANISATION.SETTINGS.MEMBERS.TO_VALIDATE')
                        : null,
                    };
                  })}
                  title="Filtrer par rôle"
                  onClickItem={onClickFilterItem}
                />
              }
              hidePadding
            >
              <Paper>
                <GridMembers members={membersToShow} organisation={organisation} />
              </Paper>
            </LayoutContainer>
          )}
          {isCommu && !isFetching && membersFound && (
            <>
              <Paper>
                <GridMembers members={membersToShow} organisation={organisation} />
              </Paper>
              {otherMemberCount > 0 && !canAddMembers && (
                <Paper>
                  <Typography variant="subtitle2" style={{ margin: '8px 24px' }}>
                    {'+ '}
                    {t('APP.ORGANISATION.HOME.LABEL.Organisations.PRO', {
                      context: otherMemberCount > 1 && 'MANY',
                      pro_count: otherMemberCount,
                    })}
                  </Typography>
                </Paper>
              )}
            </>
          )}

          {!isFetching && members && members?.length === 0 && (
            <EmptyList id="APP.ORGANISATION.SETTINGS.MEMBERS.EMPTY" level="info" />
          )}
        </>
      )}
      <Dialog open={showInviteMemberDialog} onClose={() => setShowInviteMemberDialog(false)}>
        <InviteMember
          organisation={organisation}
          onClose={() => setShowInviteMemberDialog(false)}
          handleClickInvite={handleClickInvite}
          handleCreateOrgaMembership={handleCreateOrgaMembership}
          titleUserAndRole="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.TITLE"
          subtitle="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.SUBTITLE"
          createMemberLabel="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.TITLE"
          titleNewUser="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.TITLE"
          subtitleNewUser="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.SUBTITLE"
          hasRoleToSelect
          tooltipLabel={
            isCommu
              ? 'APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.TOOLTIP_2'
              : 'APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.TOOLTIP'
          }
          emailRequired={isCommu}
        />
      </Dialog>
      <ConfirmationDialog
        openDialog={openRemoveMemberDialog}
        onCancel={() => setOpenRemoveMemberDialog(false)}
        onClickConfirm={() => onRemoveMember()}
        dialogTitle={`${t('COMMUNITY')} ${organisation?.name}`}
        dialogContent={t('APP.COMMUNITIES.QUIT.CONTENT')}
      />
    </>
  );
};

export default MembershipSettings;
