import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Roles, { getFormattedRole, getRolesForOrganisation } from '../../../Acl/Roles';

type Props = {
  task: Object,
  onBackClick: Function,
  onSubmitClick: Function,
  organisation: Object,
};

const useStyles = makeStyles(theme => ({
  formTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  words: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[100],
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}));

const TaskFormStep3 = ({ task, onBackClick, onSubmitClick, organisation }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [stepTask, setStepTask] = React.useState(task);
  const [primaryHelped, setPrimaryHelped] = React.useState(
    stepTask?.registrableRoles.includes(Roles.PRIMARY_HELPED),
  );
  const roles = getRolesForOrganisation(organisation, t, [Roles.ADMIN]);

  const isCreatingTask = useSelector(state => state.tasks.isFetching.createTask);
  const hasRegistration = stepTask.registration;

  const isValidFormStep = () => {
    if (hasRegistration) {
      return stepTask?.registrableRoles?.length > 1;
    }
    return true;
  };

  React.useEffect(() => {
    const updatedRoles = stepTask?.registrableRoles;
    if (!updatedRoles.includes(Roles.PRIMARY)) {
      setPrimaryHelped(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepTask?.registrableRoles]);

  React.useEffect(() => {
    const updatedRoles = stepTask?.registrableRoles;
    if (
      primaryHelped &&
      !updatedRoles.includes(Roles.PRIMARY_HELPED) &&
      updatedRoles.includes(Roles.PRIMARY)
    ) {
      updatedRoles.push(Roles.PRIMARY_HELPED);
      setStepTask({
        ...stepTask,
        registrableRoles: updatedRoles,
      });
    }
    if (!primaryHelped && updatedRoles.includes(Roles.PRIMARY_HELPED)) {
      const primaryHelpedIndex = updatedRoles.indexOf(Roles.PRIMARY_HELPED);
      updatedRoles.splice(primaryHelpedIndex, 1);
      setStepTask({
        ...stepTask,
        registrableRoles: updatedRoles,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryHelped]);

  return (
    <>
      <Typography variant="h5" align="center" className={classes.formTitle}>
        {t(`APP.ACTIVITY.STEP3.TITLE`)}
      </Typography>
      {/* recurrence */}
      <FormControlLabel
        control={
          <Switch
            checked={stepTask.registration}
            onClick={() =>
              setStepTask({
                ...stepTask,
                registration: !stepTask.registration,
              })
            }
            color="primary"
          />
        }
        label={t('APP.ACTIVITY.REGISTERATION')}
      />
      {hasRegistration && (
        <>
          {/* registrable_roles */}
          <FormControl margin="normal" variant="outlined" component="fieldset" fullWidth>
            <InputLabel
              variant="outlined"
              shrink
              className={classes.selectLabel}
              htmlFor="select-roles"
              required={hasRegistration}
            >
              {t('APP.ACTIVITY.REGISTERATION.ROLES')}
            </InputLabel>
            <Select
              multiple
              value={stepTask.registrableRoles}
              onChange={event => setStepTask({ ...stepTask, registrableRoles: event.target.value })}
              input={<OutlinedInput labelWidth={0} name="role" id="select-roles" />}
              renderValue={selected =>
                selected
                  .filter(role => role !== Roles.ADMIN)
                  .map(role => getFormattedRole(role, t))
                  .join(', ')
              }
              fullWidth
              variant="outlined"
            >
              {roles.map(role => (
                <MenuItem key={role.value} value={role.value}>
                  <Checkbox
                    checked={
                      stepTask.registrableRoles &&
                      stepTask.registrableRoles.indexOf(role.value) !== -1
                    }
                    color="primary"
                  />
                  <ListItemText primary={role.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {stepTask.registrableRoles.includes(Roles.PRIMARY) && (
            <FormControlLabel
              control={
                <Switch
                  checked={primaryHelped}
                  onClick={() => setPrimaryHelped(!primaryHelped)}
                  color="primary"
                />
              }
              label={t('APP.ACTIVITY.PRIMARY_HELPED.SWITCH')}
            />
          )}
          {/* participants */}
          <FormControl
            margin="normal"
            component="fieldset"
            className={classes.formControl}
            fullWidth
          >
            <TextField
              id="outlined-number"
              label={t('APP.ACTIVITY.MAX_PARTICIPANTS.LABEL')}
              value={stepTask.maxParticipants}
              onChange={event => setStepTask({ ...stepTask, maxParticipants: event.target.value })}
              type="number"
              inputProps={{ min: '1' }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              required
            />
          </FormControl>
        </>
      )}
      <div className={classes.buttonContainer}>
        <Button onClick={() => onBackClick(stepTask)} className={classes.backButton}>
          {t('BACK')}
        </Button>
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={isCreatingTask || !isValidFormStep()}
            onClick={event => onSubmitClick(event, stepTask)}
          >
            {!isCreatingTask && t('SEND')}
            {isCreatingTask && <CircularProgress size={24} />}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TaskFormStep3;
