import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Link } from 'react-router-dom';

import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { getExpertiseStyle } from '../../Themes/Expertises';
import ListTaskInfo from '../Organisations/Schedule/ListTaskInfo';
import TasksActions from '../../Redux/TasksRedux';
import { CheckRoleRule } from '../../Acl/Rules';
import SectionTaskComments from '../Organisations/Schedule/SectionTaskComments';
import { getOrgaKey } from '../../Services/DataHelper';
import ReccurrenceActionDialog from '../Organisations/Schedule/ReccurrenceActionDialog';
import { getTwigEndDate } from '../../Services/DataHelper';

type Props = {
  appointmentMeta: Object,
  visible: Boolean,
  onHide: Function,
  data: Array,
  twig: Object,
  role: String,
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    maxWidth: 600,
    margin: 'auto',
    marginTop: 100,
    marginBottom: 100,
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'scroll',
  },
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    marginBottom: theme.spacing(4),
  },
  rightHeader: {
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
  },
  avatar: {
    backgroundColor: 'white',
    height: 45,
    width: 45,
    marginRight: theme.spacing(2),
    boxShadow: `0 0 0 2px white`,
    '&>*': {
      height: 45,
      width: 45,
    },
  },
  topRightBtn: {
    flexShrink: 0,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  sectionComments: {
    marginTop: theme.spacing(4),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ShowFamilyTwigDialog = ({ appointmentMeta, visible, onHide, data, twig, role }: Props) => {
  const appointment = data?.find(t => t.id === appointmentMeta?.data.id) || appointmentMeta?.data;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { family_slug: familySlug } = match.params;
  const [anchorEl, setAnchorEl] = React.useState();
  const [action, setAction] = React.useState(null);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const currentUser = useSelector(state => state.auth.currentUser);
  const task = appointment
    ? {
        ...appointment.twig?.task,
        start_date: appointment.twig?.event_date,
        end_date: getTwigEndDate(appointment.twig),
        twig_for_date: {
          ...appointment.twig,
          helpers: appointment.twig?.helpers || [],
          helpers_count: appointment.twig?.helpers_count,
        },
      }
    : {
        ...twig?.task,
        start_date: twig?.event_date,
        end_date: getTwigEndDate(twig),
        twig_for_date: {
          ...twig,
          helpers: twig?.helpers || [],
          helpers_count: twig?.helpers_count,
        },
      };
  const eventStartDate = new Date(task?.start_date);
  const eventLastDate = task?.until_date ? new Date(task?.until_date) : task?.end_date;
  const registrants = task?.twig_for_date?.helpers || [];
  const userRegistered = registrants.find(h => h.id === currentUser.id);
  const taskRegistrable =
    registrants.length < task?.max_participants &&
    (!currentUser || !userRegistered) &&
    eventStartDate > Date.now();
  const isFamilyTwig = Boolean(!task?.holding_slug);

  const canRegister = React.useMemo(() => {
    return isFamilyTwig && CheckRoleRule(role, 'tasks:register');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, role]);

  const canEdit = CheckRoleRule(role, 'tasks:editdelete', {
    userId: currentUser?.id,
    taskOwnerId: task?.helper_id,
    taskOrgaKey: getOrgaKey(task?.holding_slug, task?.organisation_slug),
    pageOrgaKey: getOrgaKey(null, familySlug),
  });

  const handleAddCurrentHelper = () => {
    dispatch(
      TasksActions.registerHelperOnTaskRequest(
        null,
        null,
        task?.organisation_slug,
        task?.slug,
        currentUser.id,
        task?.start_date,
        null,
        null,
      ),
    );
  };

  const handleRemoveCurrentHelper = event => {
    dispatch(
      TasksActions.unregisterHelperOnTaskRequest(
        null,
        null,
        task?.organisation_slug,
        task?.slug,
        currentUser.id,
        task?.start_date,
        familySlug,
      ),
    );
    event.preventDefault();
    onHide();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAction(null);
    if (onHide) onHide();
  };

  const handleSkipOccurrence = () => {
    dispatch(TasksActions.skipOccurenceRequest(null, null, familySlug, task.slug, eventStartDate));
    handleClose();
  };

  const handleDestroySerie = () => {
    dispatch(TasksActions.destroyTaskRequest(null, null, familySlug, task.slug));
    handleClose();
  };
  const handleRecurrenceDialogConfirm = actionChoice => {
    if (action === 'delete') {
      if (actionChoice === 'occurrence') handleSkipOccurrence();
      else if (actionChoice === 'all') handleDestroySerie();
    }
  };

  return (
    <>
      <Modal open={visible || false} onClose={onHide} disableAutoFocus disableEnforceFocus>
        <Paper className={classes.paper}>
          <Grid container className={classes.header}>
            <Grid container className={classes.rightHeader}>
              <Avatar className={classes.avatar} alt={task?.expertise?.label}>
                {getExpertiseStyle(task?.expertise).icon}
              </Avatar>
              <Grid>
                <Typography variant="h3" component="h2" className={classes.title}>
                  {task?.description}
                </Typography>
                {task?.holding_slug ? (
                  <>
                    {userRegistered && !userRegistered.validated_on_twig && (
                      <Typography variant="subtitle1" style={{ paddingBottom: 4, fontWeight: 700 }}>
                        {t('APP.ACTIVITY.PARTICIPANTS.REGISTERED.PENDING')}
                      </Typography>
                    )}
                    <Typography variant="subtitle1">
                      {t('APP.ACTIVITY.ORGA')}{' '}
                      <a
                        href={`/${task?.holding_slug}/${task?.organisation_slug}/activites`}
                        className={classes.link}
                      >
                        {task?.holding?.name}
                      </a>
                    </Typography>
                  </>
                ) : (
                  <Typography variant="subtitle1">
                    {task?.helper_name === task?.organisation_name
                      ? t(`CREATED_BY_USER`, { user: task?.helper_name })
                      : t(`CREATED_BY_USER_FOR`, {
                          user: task?.helper_name,
                          helped: task?.organisation_name,
                        })}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid className={classes.topRightBtn}>
              {task?.registration && taskRegistrable && canRegister && (
                <Button color="primary" onClick={handleAddCurrentHelper}>
                  {t('APP.ACTIVITY.ACTION.REGISTER')}
                </Button>
              )}
              {registrants.length === task?.max_participants &&
                !registrants.find(h => h.id === currentUser?.id) && (
                  <Typography variant="subtitle1">{t('APP.ACTIVITY.PARTICIPANTS.FULL')}</Typography>
                )}
              {userRegistered && eventStartDate >= Date.now() && isFamilyTwig && (
                <Button color="primary" onClick={handleRemoveCurrentHelper}>
                  {t('APP.ACTIVITY.PARTICIPANTS.UNREGISTERED')}
                </Button>
              )}
              {eventStartDate < Date.now() && (
                <Typography variant="subtitle1">{t('APP.ACTIVITY.DATE.OVER')}</Typography>
              )}
              {canEdit && isFamilyTwig && (
                <>
                  <IconButton
                    onClick={event => setAnchorEl(event.target)}
                    aria-label="More"
                    aria-owns={anchorEl ? 'actions-menu' : undefined}
                    aria-haspopup="true"
                    className={classes.iconButton}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem
                      component={Link}
                      to={`/families/${familySlug}/schedule/${task?.slug}/edit`}
                      disabled={eventStartDate < Date.now()}
                    >
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText>{t('EDIT')}</ListItemText>
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to={`/families/${familySlug}/schedule/${task?.slug}/copy`}
                    >
                      <ListItemIcon>
                        <ContentCopyIcon />
                      </ListItemIcon>
                      <ListItemText>{t('DUPLICATE')}</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={
                        task?.recurrence_type !== 'NONE'
                          ? () => setAction('delete')
                          : handleSkipOccurrence
                      }
                      disabled={eventStartDate < Date.now()}
                    >
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText>{t('DELETE')}</ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          </Grid>
          {task?.excerpt && (
            <Typography paragraph variant="body1" gutterBottom>
              {task?.excerpt}
            </Typography>
          )}
          <ListTaskInfo
            task={task}
            registrantsCount={registrants?.length}
            registrants={registrants}
            eventStartDate={eventStartDate}
            eventLastDate={eventLastDate}
            isLargeCard
            hideAddress
          />
          {isLoggedIn && (task?.posts?.length > 0 || isFamilyTwig) && (
            <Grid className={classes.sectionComments}>
              <SectionTaskComments task={task} showPostForm={isFamilyTwig} />
            </Grid>
          )}
        </Paper>
      </Modal>
      <ReccurrenceActionDialog
        open={action}
        title={action === 'delete' ? t('APP.ACTIVITY.MENU.WHAT_TO_DELETE') : ''}
        onClose={handleClose}
        onConfirm={handleRecurrenceDialogConfirm}
      />
    </>
  );
};

export default ShowFamilyTwigDialog;
