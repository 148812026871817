import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import 'moment/locale/fr';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import ClubsActions from '../../../Redux/ClubsRedux';

type Props = {
  club: Object,
  onClose: Function,
};

const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(4) },
}));

function ClubForm({ club, onClose }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const organisation = useSelector(state => state.organisation.organisation);
  const [name, setName] = React.useState(club?.name);
  const dispatch = useDispatch();

  const handleSubmit = event => {
    event.preventDefault();

    const clubData = Object.assign({
      name,
    });

    if (club) {
      clubData.id = club.id;
      dispatch(
        ClubsActions.updateClubRequest(organisation.holding_slug, organisation.slug, clubData),
      );
    } else {
      dispatch(
        ClubsActions.createClubRequest(
          organisation.holding_slug,
          organisation.slug,
          clubData,
          organisation.type,
        ),
      );
    }

    onClose();
  };

  return (
    <Grid container>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h5">{club ? t('APP.CLUBS.EDIT') : t('APP.CLUBS.CREATE')}</Typography>
          {/* title */}
          <FormControl margin="normal" component="fieldset" fullWidth>
            <TextField
              required
              name="name"
              value={name || ''}
              type="text"
              onChange={event => setName(event.target.value)}
              label={t('APP.CLUBS.TEXT_FIELD.LABEL.TITLE')}
              margin="normal"
              variant="outlined"
              autoFocus
              fullWidth
            />
          </FormControl>
          <Grid style={{ textAlign: 'center' }}>
            <Button color="primary" type="submit">
              {t('SEND')}
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}

export default withRouter(ClubForm);
