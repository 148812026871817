import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import ArticlesActions from '../../../Redux/ArticlesRedux';
import EmptyList from '../../../Components/Shared/EmptyList';
import CardArticle from '../../../Components/Shared/Articles/CardArticle';
import { isHoldingAdmin } from '../../../Acl/Controls';
import { CheckRoleRule } from '../../../Acl/Rules';
import Routes from '../../../Routing/Routes';
import LayoutContainer from '../../../Components/Layout/LayoutContainer';
import CardOrganisationHome from '../../../Components/Organisations/Home/CardOrganisationHome';
import RefinedFilters from '../../../Components/Organisations/Shared/RefinedFilters';
import { Hidden } from '@material-ui/core';

type Props = {
  organisation: Object,
};

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '800',
    textTransform: 'none',
  },
  appbar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    backgroundColor: 'white',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  progress: {
    width: '100%',
  },
  leagueArticles: {
    fontStyle: 'italic',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  btnColor: {
    backgroundColor: theme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  sticky: {
    width: '100%',
    position: 'sticky',
    top: '8rem',
  },
}));

const Articles = ({ organisation }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const history = useHistory();
  const { holding_slug: holdingSlug } = match.params;
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    relatedWords: queryParam.get('related_words'),
  };
  const filters = urlParams.relatedWords
    ?.split('|')
    ?.map(rw => rw.normalize('NFD').replace(/\p{Diacritic}/gu, ''));
  const articles = useSelector(state => state.articles.holdingArticles);
  const currentUser = useSelector(state => state.auth.currentUser);
  const isFetching = useSelector(state => state.articles.isFetching.getHoldingArticles);
  const canCreateArticle = CheckRoleRule(organisation?.role, 'articles:create');
  const articlesReady =
    articles && articles.length > 0 && holdingSlug === organisation?.holding?.slug;

  const filteredArticles = filters
    ? articles?.filter(a => {
        const refinedRelatedWord = a.related_words?.filter(rw =>
          filters.includes(
            rw
              .toLowerCase()
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, ''),
          ),
        );
        return refinedRelatedWord?.length > 0;
      })
    : articles;

  const holdingArticles = filteredArticles?.filter(a => a.postable_type === 'Holding');
  const leagueArticles = filteredArticles?.filter(a => a.postable_type === 'League');

  React.useEffect(() => {
    if (holdingSlug) {
      dispatch(ArticlesActions.getHoldingArticlesRequest(holdingSlug));
    }
  }, [holdingSlug, dispatch]);

  const onDeleteArticle = article => {
    dispatch(ArticlesActions.destroyHoldingArticleRequest(holdingSlug, article.slug));
  };

  const getRelatedWords = () => {
    const articlesRelatedWords =
      articles?.length > 0 &&
      articles
        ?.map(a => a.related_words)
        ?.reduce((accumulator, currentValue) => accumulator.concat(currentValue))
        ?.map(word => word.toLowerCase());
    let relatedWords = [];

    if (!isFetching && articlesRelatedWords) {
      articlesRelatedWords?.forEach(word => {
        if (relatedWords.every(rw => rw.label !== word)) {
          relatedWords.push({
            count: 1,
            isRefined: false,
            label: word,
            labelToShow: word.charAt(0).toUpperCase() + word.substring(1),
          });
        } else {
          relatedWords.find(rw => rw.label === word).count += 1;
        }
      });
    }
    return relatedWords;
  };

  const renderArticlesList = list =>
    list.map(article => (
      <Grid item xs={12} key={article.slug}>
        <CardArticle
          canEdit={
            article.postable_type === 'Holding' &&
            (isHoldingAdmin(organisation?.holding_id, currentUser) || canCreateArticle)
          }
          article={article}
          linkUrl={`/articles/${article.slug}`}
          onEditUrl={`${Routes.organisationHomePath(organisation)}/articles/${article.slug}`}
          onDelete={onDeleteArticle}
          showVisibility
          showPostable
          disabled={article.visibility === 'private_premium' && !organisation?.holding?.premium}
        />
      </Grid>
    ));

  return (
    <LayoutContainer
      menu={
        <Grid item className={classes.sticky}>
          <Hidden only={['xs']}>
            <CardOrganisationHome organisation={organisation} currentUser={currentUser} />
          </Hidden>
          <RefinedFilters attribute="related_words" items={getRelatedWords()} />
        </Grid>
      }
      hidePadding
    >
      <AppBar position="static" color="inherit" elevation={1} className={classes.appbar}>
        <Toolbar className={classes.grow} style={{ flexWrap: 'wrap' }}>
          <Grid className={classes.grow}>
            <Typography className={classes.title} variant="h4" component="h3" align="left">
              {t('APP.ARTICLES.APP_BAR.TITLE')}
            </Typography>
          </Grid>
          {canCreateArticle && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className={classes.btnColor}
              onClick={() =>
                history.push(`${Routes.organisationHomePath(organisation)}/articles/new`)
              }
            >
              {t('APP.ARTICLES.CREATE')}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {isFetching && <LinearProgress className={classes.progress} />}
      {!isFetching && articlesReady && (
        <>
          {renderArticlesList(holdingArticles)}
          {leagueArticles?.length > 0 && (
            <>
              <Typography className={classes.leagueArticles} variant="body1" align="left">
                {t(
                  holdingArticles?.length > 0
                    ? 'APP.ARTICLES.LEAGUE.SECTION'
                    : 'APP.ARTICLES.LEAGUE.SECTION_WITHOUT_HOLDING',
                  { value: leagueArticles[0].postable_name },
                )}
              </Typography>
              {renderArticlesList(leagueArticles)}
            </>
          )}
        </>
      )}
      {!isFetching &&
        organisation?.holding?.slug === holdingSlug &&
        articles &&
        articles.length === 0 && <EmptyList id="APP.ARTICLES.EMPTY" level="info" />}
    </LayoutContainer>
  );
};

export default Articles;
