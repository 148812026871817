const getLeagueOrganisationsWithoutNoStruct = league => {
  const leagueOrganisationsWithoutNoStruct = [];
  league?.holdings?.forEach(h =>
    h.organisations?.forEach(o =>
      leagueOrganisationsWithoutNoStruct.push({
        ...o,
        holding_name: h.name,
        holding_id: h.id,
        cover_url: h.cover_url,
      }),
    ),
  );
  return leagueOrganisationsWithoutNoStruct;
};

export const getHelpersStatus = leagueHelpers => {
  return leagueHelpers
    ?.map(helper => helper.league_status)
    ?.reduce(function (accumulator, currentValue) {
      if (accumulator.indexOf(currentValue) === -1) {
        accumulator?.push(currentValue);
      }
      return accumulator;
    }, [])
    ?.filter(s => s);
};

export const getExistingLeagueLeadStatus = leagueHelpers =>
  leagueHelpers
    ?.map(helper => helper.last_lead?.league_status)
    .reduce(function (accumulator, currentValue) {
      if (currentValue && accumulator.indexOf(currentValue) === -1) {
        accumulator?.push(currentValue);
      }
      return accumulator;
    }, []);

export default getLeagueOrganisationsWithoutNoStruct;
