import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Chip, IconButton, InputLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import parse from 'autosuggest-highlight/parse';
import automatch from 'autosuggest-highlight/match';

import Editor from '../Posts/EditorWysiwyg';
import HoldingActions from '../../Redux/HoldingRedux';
import getExpertiseLevelStyle from '../../Themes/Expertises';
import { hex2rgba } from '../../Services/DataHelper';
import { orange } from '@material-ui/core/colors';

type Props = {
  rubric: Object,
  organisation: Object,
  onClose: Function,
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  headingContainer: {
    width: '100%',
  },
  formLabel: {
    paddingLeft: '3px',
    paddingRight: '3px',
    zIndex: '1002',
    backgroundColor: 'white',
  },
  label: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  button: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  selected: {
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderRadius: '5px',
    border: `solid 1px ${theme.palette.grey[400]}`,
  },
  crossIcon: {
    flexGrow: 1,
    textAlign: 'end',
  },
}));

const RubricForm = ({ rubric, organisation, onClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const levels = useSelector(state => state.holding.levels);
  const [level, setLevel] = React.useState(
    rubric ? levels.find(level => level.id === rubric?.level_id) : null,
  );
  const [title, setTitle] = React.useState(rubric?.title || '');
  const [description, setDescription] = React.useState(rubric?.description || '');

  const handleChangeLevel = value => {
    setLevel(value);
    setTitle(value?.title);
    setDescription(value?.description);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const rubricAttributes = {
      title,
      description,
      background_color: rubric?.background_color || 'rgb(255, 255, 255)',
      level_id: level?.id,
      holding_id: organisation.holding_id,
    };
    if (rubric) {
      rubricAttributes.id = rubric.id;
      dispatch(
        HoldingActions.updateHoldingRubricRequest(organisation.holding_slug, rubricAttributes),
      );
    } else {
      dispatch(HoldingActions.addHoldingRubricRequest(organisation.holding_slug, rubricAttributes));
    }

    if (onClose) onClose();
  };

  return (
    <Grid container>
      <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.headingContainer}>
          <Grid item xs={12}>
            <Typography variant="h4" align="left" gutterBottom>
              {rubric
                ? t('APP.ORGANISATION.HOME.RUBRIC.UPDATE')
                : t('APP.ORGANISATION.HOME.RUBRIC.ADD')}
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          {level && (
            <FormControl component="fieldset" fullWidth margin="normal">
              {!rubric?.id && (
                <InputLabel variant="outlined" shrink className={classes.selectLabel}>
                  {t('APP.ORGANISATION.HOME.RUBRIC.SETUP.AUTOCOMPLETE')}
                </InputLabel>
              )}
              <Grid container flexdirection="row" className={!rubric?.id && classes.selected}>
                <Chip
                  variant="outlined"
                  label={level?.title}
                  style={{
                    backgroundColor: hex2rgba(getExpertiseLevelStyle(level.slug).color, 0.2),
                    border: `solid ${getExpertiseLevelStyle(level.slug).color}`,
                  }}
                />
                {!rubric?.id && (
                  <Grid item className={classes.crossIcon}>
                    <IconButton size="small" onClick={() => setLevel(null)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </FormControl>
          )}
          {!level && !rubric?.id && (
            <FormControl margin="normal" variant="outlined" component="fieldset" fullWidth>
              <Autocomplete
                fullWidth
                id="expertise-select"
                options={levels}
                getOptionDisabled={level =>
                  Boolean(
                    organisation?.holding_rubrics?.find(rubric => rubric?.level_id === level?.id),
                  )
                }
                autoHighlight
                defaultValue={{
                  label: level?.title,
                }}
                getOptionLabel={level => level?.title}
                renderOption={(level, { inputValue }) => {
                  const matches = automatch(level?.title, inputValue);
                  const parts = parse(level?.title, matches);
                  return (
                    <Grid item className={classes.chip}>
                      <Chip
                        variant="outlined"
                        label={parts.map((part, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <span key={`${level?.title}-${index}`}>{part.text}</span>
                        ))}
                        style={{
                          backgroundColor: hex2rgba(getExpertiseLevelStyle(level.slug).color, 0.2),
                          border: `solid ${getExpertiseLevelStyle(level.slug).color}`,
                        }}
                      />
                    </Grid>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('APP.ORGANISATION.HOME.RUBRIC.SETUP.AUTOCOMPLETE')}
                    variant="outlined"
                    helperText={
                      <Grid container alignItems="center">
                        <InfoIcon fontSize="small" style={{ color: orange[500], marginRight: 8 }} />
                        <Typography variant="caption">
                          {t('APP.ORGANISATION.HOME.RUBRIC.SETUP.AUTOCOMPLETE.HELPER')}
                        </Typography>
                      </Grid>
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(event, value) => handleChangeLevel(value)}
                disabled={rubric?.id}
              />
            </FormControl>
          )}
          <FormControl component="fieldset" fullWidth>
            <TextField
              name="title"
              value={title}
              onChange={event => setTitle(event.target.value)}
              label={t('APP.ORGANISATION.HOME.RUBRIC.SETUP.TEXT_FIELD.TITLE')}
              margin="normal"
              variant="outlined"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl component="fieldset" fullWidth margin="normal">
            <InputLabel variant="outlined" shrink className={classes.selectLabel}>
              {t('APP.ORGANISATION.HOME.RUBRIC.SETUP.TEXT_FIELD.DESCRIPTION')}
            </InputLabel>
            <Editor
              name="content"
              value={rubric?.id ? rubric.description : level?.description || ''}
              onChange={event => setDescription(event.target.value)}
              options={['inline', 'list', 'link']}
              editorStyle={{ height: '200px' }}
            />
          </FormControl>
          <Grid className={classes.button}>
            <Button type="submit" variant="contained" color="primary" disabled={!title}>
              {t('SEND')}
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default RubricForm;
