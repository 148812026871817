import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import HoldingActions from '../../Redux/HoldingRedux';
import LayoutStyles from '../Layout/Styles/LayoutStyles';

type Props = {
  organisation: Object,
  onClose: Function,
};

const useStyles = makeStyles(theme => ({
  paper: {
    ...LayoutStyles.centered800Layout,
    padding: theme.spacing(4),
  },
  headingContainer: {
    width: '100%',
  },
  formLabel: {
    paddingLeft: '3px',
    paddingRight: '3px',
    zIndex: '1002',
    backgroundColor: 'white',
  },
  label: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  button: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
}));

const RubricOrderForm = ({ organisation, onClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);

  const [orderedRubrics, setOrderedRubrics] = React.useState(
    organisation?.holding_rubrics.map(r => ({
      title: r.title,
      id: r.id,
    })),
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleSubmit = event => {
    event.preventDefault();

    const oldIdsOrder = organisation?.holding_rubrics.map(r => r.id);
    const newIdsOrder = orderedRubrics.map(r => r.id);

    const logo = organisation?.holding?.cover_url
      ? {
          source: organisation?.holding?.cover_url,
          options: {
            type: 'local',
            file: { name: organisation?.holding?.cover_url, size: 0 },
            metadata: { poster: organisation?.holding?.cover_url },
          },
        }
      : null;

    const charters =
      organisation?.holding?.charters?.length > 0
        ? [
            {
              source: organisation.holding.charters[0].download_url,
              options: {
                type: 'local',
                file: {
                  name: organisation.holding.charters[0].filename,
                  size: 0,
                  url: organisation.holding.charters[0].download_url,
                },
                metadata: { poster: organisation.holding.charters[0] },
              },
            },
          ]
        : null;

    if (JSON.stringify(oldIdsOrder) !== JSON.stringify(newIdsOrder)) {
      dispatch(
        HoldingActions.updateHoldingRequest(
          organisation.holding,
          { rubrics_order: newIdsOrder },
          logo,
          charters ? charters[0] : null,
          currentUser.id,
          null,
        ),
      );
    }

    if (onClose) onClose();
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const ids = orderedRubrics.map(r => r.id);
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      setOrderedRubrics(arrayMove(orderedRubrics, oldIndex, newIndex));
    }
  };

  return (
    <Grid container>
      <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.headingContainer}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" gutterBottom style={{ paddingBottom: 8 }}>
              {t('APP.ORGANISATION.HOME.RUBRIC.ORDER')}
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={orderedRubrics} strategy={verticalListSortingStrategy}>
              {orderedRubrics.map(item => (
                <SortableItem key={item.id} item={item} />
              ))}
            </SortableContext>
          </DndContext>
          <Grid className={classes.button}>
            <Button type="submit" variant="contained" color="primary">
              {t('SEND')}
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default RubricOrderForm;

type ItemProps = {
  item: Object,
};

const SortableItem = ({ item }: ItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: transform ? 'grabbing' : 'grab',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Paper elevation={2} style={{ padding: 16, margin: 16, marginLeft: 32, marginRight: 32 }}>
        {item.title}
      </Paper>
    </div>
  );
};
