import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _uniq from 'lodash/uniq';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { formatMonthAndYear } from '../../../Services/DataHelper';
import { useTranslation } from 'react-i18next';

type Props = {
  data: Array,
  currentDate: String,
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    paddingLeft: 0,
  },
  eventTitle: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  date: { fontWeight: 300, paddingLeft: theme.spacing(1) },
}));

const EventsList = ({ data, currentDate }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const levels = _uniq(data.map(d => d.twig.task.expertise.level?.title));

  return (
    <Grid className={classes.container}>
      <Typography style={{ paddingTop: 40, paddingBottom: 8, textAlign: 'center' }} variant="h3">
        {t('APP.ACTIVITY.FULLSCREEN.TITLE', { value: formatMonthAndYear(currentDate) })}
      </Typography>
      {levels.map(level => (
        <>
          <Typography variant="h5" style={{ paddingTop: 16 }}>
            {level}
          </Typography>
          {data
            .filter(d => d.twig.task.expertise.level?.title === level)
            .map(d => (
              <>
                <Grid container className={classes.eventTitle}>
                  <Typography variant="h6">{d.title}</Typography>
                  <Typography variant="h6" className={classes.date}>
                    {`${moment(d.twig.event_date).format('dddd LL')} ${d.twig.event_start_time}`}
                  </Typography>
                </Grid>
                <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                  {d.location}
                </Typography>
                <Typography variant="body2" paragraph style={{ whiteSpace: 'pre-line' }}>
                  {d.twig.task.excerpt}
                </Typography>
              </>
            ))}
        </>
      ))}
    </Grid>
  );
};

export default EventsList;
