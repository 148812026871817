import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InfoIcon from '@material-ui/icons/Info';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GroupIcon from '@material-ui/icons/Group';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';

import ConfirmationDialog from '../../Shared/ConfirmationDialog';
import ClubsActions from '../../../Redux/ClubsRedux';
import { RoleCheck } from '../../Shared/Can';
import WelloAvatar from '../../Shared/WelloAvatar';
import { isCommunity } from '../../../Acl/Controls';
import Routes from '../../../Routing/Routes';

type Props = {
  organisation: Object,
  club: Object,
};

const useStyles = makeStyles(theme => ({
  accordionTitle: {
    padding: 0,
    width: '100%',
    fontWeight: '700',
    fontSize: '0.95rem',
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    alignSelf: 'center',
  },
  menuIcon: {
    textAlignLast: 'center',
  },
  accordion: {
    width: '100%',
    borderRadius: 0,
    margin: 0,
    padding: 0,
    '&:first-child': {
      width: '100%',
      borderRadius: 0,
      margin: 0,
    },
  },
  adminMenu: {
    marginBottom: '20px',
  },
  member: {
    alignSelf: 'center',
  },
  memberSettingsIcon: {
    right: '3px',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  icon: {
    marginRight: '-15px',
    color: 'black',
  },
  accordionCreateMenuItem: {
    paddingLeft: theme.spacing(2),
    color: 'grey',
    width: '100%',
  },
  accordionMenuItem: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  colorPrimary: {
    backgroundColor: '#DDDDDE',
  },
  barColorPrimary: {
    backgroundColor: '#707070',
  },
}));

function MenuItemClubMembers({ organisation, club }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState();
  const [openRemoveMemberDiag, setOpenRemoveMemberDiag] = React.useState(false);
  const [openMemberInfoDiag, setOpenMemberInfoDiag] = React.useState(false);
  const [selectedMemberId, setSelectedMemberId] = React.useState();
  const handleCloseMenu = () => {
    setSelectedMemberId(null);
    setAnchorEl(null);
  };

  const handleClickConfirmRemoveMemberDiag = () => {
    dispatch(
      ClubsActions.destroyClubMembershipRequest(
        organisation.holding_slug,
        organisation.slug,
        club.id,
        selectedMemberId,
      ),
    );
    setOpenRemoveMemberDiag(false);
    handleCloseMenu();
  };

  const handleClickCancelDiag = () => {
    setSelectedMemberId(null);
    setOpenRemoveMemberDiag(false);
    setOpenMemberInfoDiag(false);
    handleCloseMenu();
  };

  const handleOpenMenuClick = helperId => event => {
    setSelectedMemberId(helperId);
    setAnchorEl(event.currentTarget);
  };

  const renderShowMemberInfo = () => {
    const member = club.helpers?.find(helper => helper.id === selectedMemberId);
    if (!member) {
      return (
        <div>
          <h2 id="modal-title" style={{ textAlign: 'center' }}>
            -
          </h2>
        </div>
      );
    }

    const {
      avatar_url: avatarUrl,
      first_name: firstName,
      last_name: lastName,
      description,
      birthday,
    } = member;
    return (
      <>
        <WelloAvatar
          avatarUrl={avatarUrl}
          firstName={firstName}
          lastName={lastName}
          backgroundColor={member?.avatar_background_color}
          size={36}
        />
        <div>
          <h2 className={classes.profilInfo}>{`${firstName} ${lastName}`}</h2>
          {birthday && moment(birthday).format('L')}
          <h4 className={classes.profilInfo}>{description}</h4>
        </div>
      </>
    );
  };

  return (
    <Card className={classes.adminMenu}>
      <Accordion
        defaultExpanded={!isCommunity(organisation)}
        classes={{
          root: classes.accordion,
          rounded: classes.accordion,
          expanded: classes.accordion,
        }}
      >
        <AccordionSummary style={{ paddingLeft: '0' }} expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={3} className={classes.menuIcon}>
              <GroupIcon />
            </Grid>
            <Grid item xs={9} className={classes.accordionTitle}>
              {t('APP.CLUBS.SHOW_CLUB.MEMBERS')}
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider light />
        <AccordionDetails className={classes.accordion}>
          <Grid container>
            {club &&
              club.helpers &&
              club.helpers.map(helper => (
                <Grid style={{ width: '100%' }} key={helper.id}>
                  <ListItem
                    className={classes.accordionMenuItem}
                    alignItems="flex-start"
                    ContainerComponent={Grid}
                    key={`id${helper.id}`}
                  >
                    <ListItemAvatar key={`avatar${helper.id}`}>
                      <WelloAvatar
                        avatarUrl={helper.avatar_url}
                        firstName={helper.first_name}
                        lastName={helper.last_name}
                        backgroundColor={helper.avatar_background_color}
                        size={36}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${helper.first_name} ${helper.last_name}`}
                      className={classes.member}
                      key={`item${helper.id}`}
                    />

                    <ListItemSecondaryAction className={classes.memberSettingsIcon}>
                      <IconButton
                        onClick={handleOpenMenuClick(helper.id)}
                        aria-label="More"
                        aria-owns={anchorEl ? 'actions-menu' : undefined}
                        aria-haspopup="true"
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="actions-menu"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem
                          onClick={() => setOpenMemberInfoDiag(true)}
                          style={{ padding: 0 }}
                        >
                          <Grid container>
                            <Grid item xs={3} style={{ padding: '5px' }}>
                              <ListItemIcon>
                                <InfoIcon />
                              </ListItemIcon>
                            </Grid>
                            <Grid item xs={9} style={{ alignSelf: 'center', paddingLeft: '3px' }}>
                              <ListItemText>
                                {t('APP.CLUBS.SHOW_CLUB.SEE_MEMBER_INFO')}
                              </ListItemText>
                            </Grid>
                          </Grid>
                        </MenuItem>
                        <RoleCheck
                          role={organisation.role}
                          action="clubs:create"
                          yes={() => (
                            <MenuItem
                              onClick={() => setOpenRemoveMemberDiag(true)}
                              style={{ padding: 0 }}
                            >
                              <Grid container>
                                <Grid item xs={3} style={{ padding: '5px' }}>
                                  <ListItemIcon>
                                    <DeleteForeverIcon />
                                  </ListItemIcon>
                                </Grid>
                                <Grid
                                  item
                                  xs={9}
                                  style={{ alignSelf: 'center', paddingLeft: '3px' }}
                                >
                                  <ListItemText>{t('REMOVE')}</ListItemText>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          )}
                        />
                      </Menu>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Grid>
              ))}
            <RoleCheck
              role={organisation.role}
              action="clubs:create"
              yes={() => (
                <>
                  {club && club.helpers?.length > 0 && (
                    <Divider light style={{ width: 'inherit', marginTop: '5px' }} />
                  )}
                  <MenuItem
                    component={Link}
                    to={`${Routes.organisationHomePath(organisation)}/clubs/${club.id}/members`}
                    className={classes.accordionCreateMenuItem}
                  >
                    <ListItemIcon className={classes.icon}>
                      <AddIcon />
                    </ListItemIcon>
                    {t('APP.CLUBS.SHOW_CLUB.ADD_MEMBERS')}
                  </MenuItem>
                </>
              )}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      <ConfirmationDialog
        openDialog={Boolean(openRemoveMemberDiag)}
        onCancel={handleClickCancelDiag}
        onClickConfirm={handleClickConfirmRemoveMemberDiag}
        dialogContent={t('APP.CLUBS.SHOW_CLUB.DELETE_MEMBER')}
      />
      <Dialog className={classes.modal} open={openMemberInfoDiag} onClose={handleClickCancelDiag}>
        <Grid align="center" className={classes.paper}>
          {renderShowMemberInfo()}
        </Grid>
      </Dialog>
    </Card>
  );
}

export default MenuItemClubMembers;
