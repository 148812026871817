import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import RepeatIcon from '@material-ui/icons/Repeat';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { green, red, grey } from '@material-ui/core/colors';

import { useHistory, useRouteMatch } from 'react-router';
import { CheckRoleRule } from '../../../Acl/Rules';
import { getRole } from '../../../Acl/Controls';
import { getOrgaKey, hex2rgba } from '../../../Services/DataHelper';

type Props = {
  style: Object,
  data: Object,
  isShaded: Boolean,
  updatedTaskId: Number,
  isUpdating: Boolean,
  currentUser: Object,
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0.5),
  },
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(0.5),
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    lineHeight: 1,
  },
  repeatIcon: {
    fontSize: '1.1rem',
    float: 'right',
  },
  iconColor: {
    '&>*': {
      backgroundColor: '#fff !important',
      borderRadius: '50%',
    },
  },
  iconColorGrey: {
    '&>*': {
      backgroundColor: '#fff !important',
      borderRadius: '50%',
      color: 'grey !important',
    },
  },
  time: {
    justifyContent: 'space-between',
  },
  green: {
    backgroundColor: green[600],
    color: 'white',
    fontSize: '1rem !important',
    borderRadius: '50%',
    padding: 1,
  },
  red: {
    backgroundColor: red[600],
    color: 'white',
    fontSize: '1rem !important',
    borderRadius: '50%',
    padding: 1,
  },
  grey: {
    backgroundColor: grey[500],
    color: 'white',
    fontSize: '1rem !important',
    borderRadius: '50%',
    padding: 1,
  },
}));

const TwigAppointment = ({
  style,
  data,
  isShaded,
  updatedTaskId,
  isUpdating,
  currentUser,
  ...restProps
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    family_slug: familySlug,
  } = match.params;
  const userRegistered = currentUser && data.twig?.helpers?.find(h => h.id === currentUser.id);
  const userNotInterested =
    currentUser && data.twig?.helpers_not_interested?.find(h => h.id === currentUser.id);
  const userPending = userRegistered && !userRegistered.validated_on_twig;
  const userValidated = userRegistered && userRegistered.validated_on_twig;

  const shade = isShaded || (isUpdating && data.twig?.task?.id === updatedTaskId);

  const taskLink = React.useMemo(() => {
    if (!data.twig?.task) return '';

    if (familySlug) {
      return `/families/${familySlug}/schedule/${data.twig?.task.slug}`;
    }

    return `/${holdingSlug}/${organisationSlug}/activites/${data.twig?.task.slug}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingSlug, organisationSlug, familySlug, data.twig?.task]);

  const handleDoubleClick = event => {
    if (
      CheckRoleRule(
        getRole(currentUser, holdingSlug, organisationSlug, familySlug),
        'tasks:editdelete',
        {
          userId: currentUser?.id,
          taskOwnerId: data.twig?.task?.helper_id,
          taskOrgaKey: getOrgaKey(
            data.twig?.task?.holding_slug,
            data.twig?.task?.organisation_slug,
          ),
          pageOrgaKey: getOrgaKey(familySlug ? null : holdingSlug, familySlug || organisationSlug),
        },
      ) &&
      data.startDate >= Date.now()
    ) {
      event.preventDefault();
      history.push(`${taskLink}/edit`);
    }
  };

  return (
    <Appointments.Appointment
      {...restProps}
      data={data}
      style={{
        ...style,
        backgroundColor: data.lightBackgroundColor || hex2rgba(data.backgroundColor, 0.2),
        color: 'black',
        opacity: shade ? 0.4 : 1,
        borderColor: data.backgroundColor,
        borderWidth: 2,
      }}
      onDoubleClick={handleDoubleClick}
    >
      <Grid container className={classes.container}>
        <Hidden smDown>
          <Grid item xs={4} className={data.colorIcon ? classes.iconColor : classes.iconColorGrey}>
            {data.icon}
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8}>
          <Grid container className={classes.time}>
            <Grid item xs={data?.hasRecurrence ? 9 : 12} md={data?.hasRecurrence ? 5 : 8}>
              {data.event_start_time}
            </Grid>
            {userValidated && (
              <Hidden smDown>
                <Grid>
                  <CheckIcon className={classes.green} />
                </Grid>
              </Hidden>
            )}
            {userPending && (
              <Hidden smDown>
                <Grid>
                  <ScheduleIcon className={classes.grey} />
                </Grid>
              </Hidden>
            )}
            {userNotInterested && (
              <Hidden smDown>
                <Grid>
                  <CloseIcon className={classes.red} />
                </Grid>
              </Hidden>
            )}
            {!userRegistered && !userNotInterested && data?.hasRecurrence && (
              <Hidden smDown>
                <Grid>
                  <RepeatIcon className={classes.repeatIcon} />
                </Grid>
              </Hidden>
            )}
          </Grid>
          <Grid className={classes.title}>{data.title || t('APP.ACTIVITY.HEALTH_TITLE')}</Grid>
        </Grid>
      </Grid>
    </Appointments.Appointment>
  );
};

export default TwigAppointment;
