import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';

import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';

import TasksActions from '../../../Redux/TasksRedux';
import EmptyList from '../../Shared/EmptyList';
import GridTwigs from './GridTwigs';
import { getPastDate, getCurrentDate, getFutureDate } from '../../../Services/DataHelper';
import OrganisationScheduler from './OrganisationScheduler';
import { formatTwigData } from '../../../Services/SchedulerHelper';
import { getSubscription, SubscribleSubTypes } from '../../../Services/SubscriptionHelper';
import AuthActions from '../../../Redux/AuthRedux';
import { CHANNEL_TASK, subscribeChannel, unsubscribeChannel } from '../../../Services/WebSocket';
import { getRole } from '../../../Acl/Controls';
import FullScreenScheduler from './FullScreenScheduler';
import LayoutContainer from '../../Layout/LayoutContainer';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import RefinedFilters from '../Shared/RefinedFilters';
import CardOrganisationHome from '../Home/CardOrganisationHome';
import AppBarSchedule from './AppBarSchedule';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: '800',
    textTransform: 'none',
    flexGrow: 1,
  },
  progress: {
    transform: 'translateY(-4px)',
    marginTop: '-4px',
  },
  sticky: {
    width: '100%',
    position: 'sticky',
    top: '8rem',
  },
}));

type Props = {
  organisation: Object,
};

const Twigs = ({ organisation }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug, organisation_slug: organisationSlug } = match.params;
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    expertiseSlug: queryParam.get('expertise_slug'),
  };

  const filters = urlParams.expertiseSlug?.split('|');
  const [filterArchived, setFilterArchived] = React.useState('toCome');
  const [viewMode, setViewMode] = React.useState('schedule');
  const [showScheduleDialog, setShowScheduleDialog] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState(Date.now());

  const twigs = useSelector(state => state.tasks.twigs);
  const errors = useSelector(state => state.tasks.errors.getTwigs);
  const isFetching = useSelector(state => state.tasks.isFetching.getTwigs);
  const currentUser = useSelector(state => state.auth.currentUser);

  const twigsFound =
    twigs &&
    twigs.length !== 0 &&
    twigs.every(
      tw => tw.task.organisation_slug === organisationSlug && tw.task.holding_slug === holdingSlug,
    );
  const noTwigs = !isFetching && twigs && twigs.length === 0;

  React.useEffect(() => {
    dispatch(
      TasksActions.getTwigsRequest(
        holdingSlug,
        organisationSlug,
        null,
        getFutureDate(),
        getPastDate(),
        true,
      ),
    );
    // eslint-disable-next-line
  }, [dispatch, holdingSlug, organisationSlug]);

  React.useEffect(() => {
    if (organisation?.id)
      window.dataLayer.push({
        event: 'gtm_view_structure_planning',
        orga_id: organisation?.id,
        role: organisation?.role,
      });
  }, [organisation?.id]);

  const updateLastReadDate = React.useCallback(
    debounce(
      (sub, date) =>
        dispatch(
          AuthActions.updateHelperSubscriptionRequest(sub.helper_id, {
            ...sub,
            last_read_date: date.toJSON(),
          }),
        ),
      2000,
    ),
    [dispatch, organisation],
  );

  React.useEffect(() => {
    const channelSubscription = currentUser?.token
      ? subscribeChannel(
          currentUser?.token,
          { channel: CHANNEL_TASK, organisation_id: organisation.id },
          {
            received(data) {
              if (data && data.helper_id !== currentUser.id && data.task) {
                dispatch(TasksActions.createTaskSuccess(data.task));
                const sub = getSubscription(
                  organisation,
                  organisation.type,
                  SubscribleSubTypes.TASKS,
                  organisation.id,
                );
                const date = new Date();
                dispatch(
                  AuthActions.updateHelperSubscriptionRequest(currentUser.id, {
                    ...sub,
                    last_read_date: date.toJSON(),
                  }),
                );
              }
            },
          },
        )
      : null;
    return () => {
      if (channelSubscription) unsubscribeChannel(channelSubscription);
    };
    // eslint-disable-next-line
  }, [dispatch, currentUser?.token, organisation?.id]);

  React.useEffect(() => {
    const sub = getSubscription(
      organisation,
      organisation.type,
      SubscribleSubTypes.TASKS,
      organisation.id,
    );
    const date = new Date();
    if (sub) updateLastReadDate(sub, date);
    // eslint-disable-next-line
  }, [organisationSlug]);

  const gridData = React.useMemo(() => {
    const filteredTwigs = twigs.filter(
      twig =>
        (filterArchived === 'over' && twig.event_date < getCurrentDate()) ||
        (filterArchived === 'toCome' && twig.event_date >= getCurrentDate()),
    );
    return orderBy(filteredTwigs, 'event_date', filterArchived === 'over' ? 'desc' : 'asc');
  }, [twigs, filterArchived]);

  const scheduleData = React.useMemo(() => {
    if (twigsFound)
      return filters
        ? twigs
            .filter(twig => filters.includes(twig.task?.expertise?.slug))
            .map(twig => formatTwigData(twig))
        : twigs.map(twig => formatTwigData(twig));
    return [];
  }, [twigs, twigsFound, filters]);

  const getExpertisesTwigs = () => {
    const expertisesTwigs = twigs?.map(twig => twig?.task?.expertise);
    let expertises = [];
    if (!isFetching) {
      expertisesTwigs?.forEach(exp => {
        if (expertises.every(e => e?.slug !== exp.slug)) {
          expertises.push({
            ...exp,
            count: 1,
            isRefined: false,
            label: exp.slug,
            labelToShow: exp.label,
            icon: getExpertiseStyle(exp).icon,
          });
        } else {
          expertises.find(e => e.slug === exp.slug).count += 1;
        }
      });
    }
    return expertises.sort((a, b) => a?.level?.id - b?.level?.id);
  };

  return (
    <LayoutContainer
      menu={
        <Grid item className={classes.sticky}>
          <Hidden only={['xs']}>
            <CardOrganisationHome organisation={organisation} currentUser={currentUser} />
          </Hidden>
          <RefinedFilters attribute="expertise_slug" items={getExpertisesTwigs()} limit={16} />
        </Grid>
      }
      hidePadding
    >
      <div className={classes.appBarContainer}>
        <AppBarSchedule
          typography={
            <Typography className={classes.title} variant="h4">
              {t('APP.ACTIVITY.APP_BAR.TITLE')}
            </Typography>
          }
          viewMode={viewMode}
          setViewMode={setViewMode}
          filterArchived={filterArchived}
          setFilterArchived={setFilterArchived}
          role={organisation.role}
          setShowScheduleDialog={setShowScheduleDialog}
          canCreateTask
          tooltipTitle={t('APP.ACTIVITY.CREATE')}
          link={`/${holdingSlug}/${organisationSlug}/activites/new`}
        />
      </div>
      {isFetching && <LinearProgress className={classes.progress} />}

      <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-start">
        {viewMode === 'grid' && twigsFound && (
          <Grid item xs={12}>
            <GridTwigs
              twigs={gridData}
              currentUser={currentUser}
              organisation={organisation}
              archived={filterArchived === 'over'}
            />
          </Grid>
        )}
        {viewMode === 'grid' && noTwigs && (
          <EmptyList id="APP.ACTIVITY.EMPTY" level={errors ? 'error' : 'info'} />
        )}
        {viewMode === 'schedule' && (
          <Grid item xs={12}>
            <OrganisationScheduler
              data={scheduleData}
              currentUser={currentUser}
              role={getRole(currentUser, holdingSlug, organisationSlug)}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
          </Grid>
        )}
      </Grid>
      <Dialog fullScreen open={showScheduleDialog} onClose={() => setShowScheduleDialog(false)}>
        <FullScreenScheduler
          currentUser={currentUser}
          onClose={() => setShowScheduleDialog(false)}
          scheduleData={scheduleData}
          currentDate={currentDate}
        />
      </Dialog>
    </LayoutContainer>
  );
};

export default Twigs;
