import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import UserForm from './UserForm';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import UserActions from '../../Redux/UsersRedux';
import { serializeDateFromDatePicker } from '../../Services/FormHelpers';
import { standardizeWordCase } from '../../Services/DataHelper';
import Routes from '../../Routing/Routes';

const useStyles = makeStyles(theme => ({
  layout: LayoutStyles.fullWidthLayout,
  paper: {
    padding: theme.spacing(2, 3, 3),
    maxWidth: '800px',
  },
  margin: {
    margin: theme.spacing(1, 0, 3),
  },
}));

type Props = {
  userToEdit: Object,
  onClose: Function,
  dialogTitle: String,
  onCancel: Function,
  helpedMainHelperId: Number,
  showLeagueStatus: Boolean,
  handleLeagueStatusChange: Function,
  getLeagueTransmissions: Function,
};

const UserSettings = ({
  userToEdit,
  onClose,
  dialogTitle,
  onCancel,
  helpedMainHelperId,
  showLeagueStatus,
  handleLeagueStatusChange,
  getLeagueTransmissions,
}: Props) => {
  const classes = useStyles();
  const isUpdating = useSelector(state => state.users.isFetching.updateUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [user, setUser] = React.useState({
    gender: userToEdit?.gender || 'female',
    firstName: userToEdit?.first_name || '',
    lastName: userToEdit?.last_name || '',
    phone: userToEdit?.phone || '',
    country_code: userToEdit?.country_code || 'fr',
    locality: userToEdit?.locality || '',
    birthday: new Date(userToEdit?.birthday || (1970, 1, 1)),
    formattedAddress: userToEdit?.formatted_address || '',
    addressSupplement: userToEdit?.address_supplement || '',
    description:
      userToEdit?.description ||
      t('APP.USER.TEXT_FIELD.DEFAULT_VALUE.DESCRIPTION', {
        league: t(`${Routes.currentNamespace()}.SEO.SITENAME`),
      }),
    pathology: userToEdit?.pathology || '',
    latitude: userToEdit?.coordinates?.latitude || '',
    longitude: userToEdit?.coordinates?.longitude || '',
    avatarPreview: userToEdit?.avatar_url,
    avatar: null,
    leagueStatus: userToEdit?.league_status || '',
    email: userToEdit?.email,
    sendEmail: true,
  });

  React.useEffect(() => {
    const avatar = userToEdit?.avatar_url;
    if (avatar) {
      setUser({
        ...user,
        avatar: {
          source: avatar,
          options: {
            type: 'local',
            file: { name: avatar, size: 0 },
            metadata: { poster: avatar },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToEdit]);

  const handleSubmit = event => {
    event.preventDefault();
    const attributes = {
      first_name: standardizeWordCase(user.firstName),
      last_name: standardizeWordCase(user.lastName),
      phone: user.phone,
      country_code: user.country_code,
      description: user.description,
      pathology: user.pathology,
      gender: user.gender,
      birthday: serializeDateFromDatePicker(user.birthday),
      locality: user.locality,
      formatted_address: user.formattedAddress,
      latitude: user.latitude,
      longitude: user.longitude,
      address_supplement: user.addressSupplement,
      helper_id: helpedMainHelperId,
      email: user.email,
      send_email: userToEdit?.email ? false : user.sendEmail,
    };
    if (userToEdit?.id) {
      dispatch(
        UserActions.updateUserRequest(
          userToEdit.id,
          attributes,
          user.avatar && user.avatar.size > 0 ? user.avatar : null,
          () => getLeagueTransmissions(),
        ),
        null,
      );
    }
    if (!userToEdit?.id && helpedMainHelperId) {
      dispatch(
        UserActions.createHelpedRequest(
          attributes,
          user.avatar && user.avatar.size > 0 ? user.avatar : null,
        ),
      );
    }
    if (user.leagueStatus && user.leagueStatus !== userToEdit.leagueStatus) {
      handleLeagueStatusChange(user.leagueStatus);
    }

    onClose();
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography align="center" component="h2" variant="h5" className={classes.margin}>
          {dialogTitle || t('INFO')}
        </Typography>
        {userToEdit?.pseudo && (
          <Grid className={classes.margin}>
            <Grid container alignItems="center" flexdirection="row" display="flex">
              <Typography variant="h6" align="center" gutterBottom>
                {t('APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.PSEUDO')}
              </Typography>
              <Typography variant="body1" align="center" gutterBottom style={{ marginLeft: 10 }}>
                {userToEdit?.pseudo}
              </Typography>
            </Grid>
            {userToEdit?.email && (
              <Grid container alignItems="center" flexdirection="row" display="flex">
                <Typography variant="h6" align="center" gutterBottom>
                  {`${t('APP.QUOTATION.EMAIL')} :`}
                </Typography>
                <Typography variant="body1" align="center" gutterBottom style={{ marginLeft: 10 }}>
                  {userToEdit?.email}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        <UserForm
          user={user}
          setUser={setUser}
          onSubmit={handleSubmit}
          isUpdating={isUpdating}
          buttonBack
          onCancel={onCancel || onClose}
          showLeagueStatus={showLeagueStatus}
        />
      </Paper>
    </div>
  );
};

export default UserSettings;
