import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FilterListIcon from '@material-ui/icons/FilterList';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';

import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import Roles from '../../../Acl/Roles';
import { CheckRoleRule } from '../../../Acl/Rules';
import InviteMember from './InviteMember';
import RowPrimary from './RowPrimary';

const useStyles = makeStyles(theme => ({
  appbar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    backgroundColor: 'white',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  progress: {
    width: '100%',
  },
  title: {
    fontWeight: '800',
    textTransform: 'none',
  },
  button: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  filterCell: {
    padding: 0,
  },
  newMemberDialog: {
    padding: theme.spacing(1, 1, 2),
  },
  dialogBtn: {
    margin: theme.spacing(3, 1, 1),
  },
  btnColor: {
    backgroundColor: theme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
}));

type Props = {
  organisation: Object,
};

const GridPrimaries = ({ organisation }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFetching = useSelector(
    state => state.organisationMembership.isFetching.getOrganisationMembership,
  );
  const primaries = useSelector(state => state.organisationMembership.members);
  const newMembership = useSelector(state => state.organisationMembership.newMembership);
  const [filterName, setFilterName] = React.useState(null);
  const [filterPhone, setFilterPhone] = React.useState(null);
  const [filterEmail, setFilterEmail] = React.useState(null);
  const [filterLocality, setFilterLocality] = React.useState(null);
  const [showInviteMemberDialog, setShowInviteMemberDialog] = React.useState(false);
  const [showNewMembershipDialog, setShowNewMembershipDialog] = React.useState(false);
  const hasHoldingAdmin = Boolean(organisation?.holding?.admin_id);
  const canAddMembers =
    hasHoldingAdmin &&
    CheckRoleRule(organisation?.role, 'organisation:configure', {
      organisation,
    });

  const primariesFound =
    primaries &&
    primaries.length !== 0 &&
    primaries.every(p => p?.role === Roles.PRIMARY) &&
    primaries.every(p => p?.organisation_slug === organisation?.slug);

  React.useEffect(() => {
    if (organisation) {
      dispatch(
        OrganisationMembershipActions.getOrganisationMembershipRequest(
          organisation.holding_slug,
          organisation.slug,
          Roles.PRIMARY,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation?.slug, dispatch]);

  React.useEffect(() => {
    if (
      newMembership &&
      newMembership.role === Roles.PRIMARY &&
      newMembership.organisation_id === organisation?.id
    ) {
      setShowNewMembershipDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMembership]);

  React.useEffect(() => {
    dispatch(OrganisationMembershipActions.resetNewMembership());
  }, []);

  const filteredPrimaries = () => {
    if (filterName?.value) {
      return primaries?.filter(p =>
        `${p?.helper?.last_name} ${p?.helper?.first_name}`
          .toLowerCase()
          .includes(filterName?.value.toLowerCase()),
      );
    }
    if (filterPhone?.value) {
      return primaries?.filter(p => p?.helper?.phone?.includes(filterPhone?.value));
    }
    if (filterEmail?.value) {
      return primaries?.filter(p =>
        p?.helper?.email?.toLowerCase().includes(filterEmail?.value.toLowerCase()),
      );
    }
    if (filterLocality?.value) {
      return primaries?.filter(p =>
        p?.helper?.locality?.toLowerCase().includes(filterLocality?.value.toLowerCase()),
      );
    }
    return primaries;
  };

  const handleClickInvite = (event, selectedHelper, role) => {
    if (selectedHelper) {
      dispatch(
        OrganisationMembershipActions.createOrganisationMembershipRequest(
          organisation.holding_slug,
          organisation.slug,
          { role, id: selectedHelper.id },
          null,
        ),
      );
    }
    setShowInviteMemberDialog(false);
    event.preventDefault();
  };

  const handleCreateOrgaMembership = (helper, role, email) => {
    dispatch(
      OrganisationMembershipActions.createOrganisationMembershipRequest(
        organisation.holding_slug,
        organisation.slug,
        {
          role,
          email: email ? email?.toLowerCase()?.trim() : null,
        },
        helper,
      ),
    );
  };

  const handleCreateNewMember = () => {
    setShowInviteMemberDialog(true);
    setShowNewMembershipDialog(false);
  };

  const onCloseNewMembershipDialog = () => {
    setShowNewMembershipDialog(false);
    dispatch(OrganisationMembershipActions.resetNewMembership());
  };

  const handleClickFamilyHome = () => {
    dispatch(OrganisationMembershipActions.resetNewMembership());
    history.push(`/families/${newMembership?.helper?.family_slug}`);
  };

  return (
    <>
      <AppBar position="static" color="inherit" elevation={1} className={classes.appbar}>
        <Toolbar className={classes.grow} style={{ flexWrap: 'wrap' }}>
          <Grid className={classes.grow}>
            <Typography className={classes.title} variant="h4" component="h3" align="left">
              {t('APP.ORGANISATION.SETTINGS.PRIMARIES.TITLE', {
                value: organisation?.name || '',
              })}
              {primariesFound && ` (${primaries?.length})`}
            </Typography>
            {canAddMembers && (
              <Typography variant="subtitle2" component="p" align="left" gutterBottom>
                {t('APP.ORGANISATION.SETTINGS.PRIMARIES.SUBTITLE')}
              </Typography>
            )}
          </Grid>
          {canAddMembers && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              className={classes.btnColor}
              onClick={() => setShowInviteMemberDialog(true)}
            >
              {t('APP.ORGANISATION.SETTINGS.PRIMARIES.ADD')}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {isFetching && <LinearProgress className={classes.progress} />}

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" gutterBottom component="p">
                  {t('APP.ORGANISATION.SETTINGS.PRIMARIES.COL1')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" gutterBottom component="p">
                  {t('APP.ORGANISATION.SETTINGS.PRIMARIES.COL2')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" gutterBottom component="p">
                  {t('APP.ORGANISATION.SETTINGS.PRIMARIES.COL3')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" gutterBottom component="p">
                  {t('APP.ORGANISATION.SETTINGS.PRIMARIES.COL4')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" gutterBottom component="p">
                  {t('APP.ORGANISATION.SETTINGS.PRIMARIES.COL5')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.filterCell} />
              <TableCell className={classes.filterCell}>
                <Grid container style={{ alignItems: 'center' }}>
                  <FilterListIcon style={{ color: 'grey', paddingRight: '8' }} />
                  <Input
                    placeholder={t('FILTER_BY_NAME')}
                    value={filterName ? filterName.value : ''}
                    onChange={e => setFilterName(e.target.value ? { value: e.target.value } : null)}
                    disableUnderline
                  />
                </Grid>
              </TableCell>
              <TableCell className={classes.filterCell}>
                <Grid container style={{ alignItems: 'center' }}>
                  <FilterListIcon style={{ color: 'grey', paddingRight: '8' }} />
                  <Input
                    placeholder={t('FILTER_BY_PHONE')}
                    value={filterPhone ? filterPhone.value : ''}
                    onChange={e =>
                      setFilterPhone(e.target.value ? { value: e.target.value } : null)
                    }
                    disableUnderline
                  />
                </Grid>
              </TableCell>
              <TableCell className={classes.filterCell}>
                <Grid container style={{ alignItems: 'center' }}>
                  <FilterListIcon style={{ color: 'grey', paddingRight: '8' }} />
                  <Input
                    placeholder={t('FILTER_BY_EMAIL')}
                    value={filterEmail ? filterEmail.value : ''}
                    onChange={e =>
                      setFilterEmail(e.target.value ? { value: e.target.value } : null)
                    }
                    disableUnderline
                  />
                </Grid>
              </TableCell>
              <TableCell className={classes.filterCell}>
                <Grid container style={{ alignItems: 'center' }}>
                  <FilterListIcon style={{ color: 'grey', paddingRight: '8' }} />
                  <Input
                    placeholder={t('FILTER_BY_LOCALITY')}
                    value={filterLocality ? filterLocality.value : ''}
                    onChange={e =>
                      setFilterLocality(e.target.value ? { value: e.target.value } : null)
                    }
                    disableUnderline
                  />
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {primariesFound &&
              filteredPrimaries().map(primary => {
                return (
                  <RowPrimary key={primary.id} primary={primary} organisation={organisation} />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={showInviteMemberDialog} onClose={() => setShowInviteMemberDialog(false)}>
        <InviteMember
          organisation={organisation}
          onClose={() => setShowInviteMemberDialog(false)}
          handleClickInvite={handleClickInvite}
          handleCreateOrgaMembership={handleCreateOrgaMembership}
          titleUserAndRole="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.PRIMARY.TITLE"
          createMemberLabel="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.PRIMARY.NEW.TITLE"
          titleNewUser="APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.PRIMARY.NEW.TITLE"
        />
      </Dialog>
      <Dialog open={showNewMembershipDialog} onClose={onCloseNewMembershipDialog}>
        <Paper className={classes.newMemberDialog} align="center">
          <DialogTitle>
            {t('APP.ORGANISATION.SETTINGS.PRIMARIES.NEW_MEMBER.TITLE', {
              member: `${newMembership?.helper?.last_name} ${newMembership?.helper?.first_name}`,
            })}
          </DialogTitle>
          <DialogContent>
            {t('APP.ORGANISATION.SETTINGS.PRIMARIES.NEW_MEMBER.CONTENT')}
          </DialogContent>
          <Grid className={classes.dialogBtn}>
            <Button variant="contained" color="primary" onClick={handleClickFamilyHome}>
              {t('FAMILY_HOME')}
            </Button>
          </Grid>
          {canAddMembers && (
            <Button className={classes.button} onClick={handleCreateNewMember}>
              {t('APP.ORGANISATION.SETTINGS.PRIMARIES.ADD')}
            </Button>
          )}
        </Paper>
      </Dialog>
    </>
  );
};

export default GridPrimaries;
