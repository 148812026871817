import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import WelloAvatar from '../Shared/WelloAvatar';

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(3),
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(-1),
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  header: {
    padding: theme.spacing(3, 0, 0),
  },
  avatar: {
    fontSize: '1rem',
  },
  chipContainer: {
    marginTop: theme.spacing(3),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.grey[400],
  },
}));

type Props = {
  holdingSlug: String,
  dealId: Number,
};

const DealDetail = ({ holdingSlug, dealId }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const errors = useSelector(state => state.deals.errors.getDeal);
  const isFetching = useSelector(state => state.deals.isFetching.getDeal);
  const deal = useSelector(state => state.deals.deal);
  const isDealReady = deal && dealId && !isFetching && !errors?.status;

  return (
    <>
      {isDealReady && (
        <Card className={classes.card}>
          <Link
            onClick={() =>
              history.location?.state?.from
                ? history.goBack()
                : history.push(`/communities/${holdingSlug}`)
            }
            className={classes.link}
          >
            <ArrowLeftIcon />
            {t('APP.COMMUNITIES.DEAL_DETAIL.BACK')}
          </Link>
          <Typography variant="h4">{deal?.title}</Typography>
          <CardHeader
            classes={{ root: classes.header }}
            title={
              <Grid>
                <Typography
                  variant="h6"
                  component="div"
                >{`${deal?.helper?.first_name}  ${deal?.helper?.last_name}`}</Typography>
                <Typography variant="caption" component="div" color="textSecondary">
                  {moment(deal?.created_at).format('LL')}
                </Typography>
              </Grid>
            }
            avatar={
              <WelloAvatar
                avatarUrl={deal?.helper?.avatar_url}
                firstName={deal?.helper?.first_name}
                lastName={deal?.helper?.last_name}
                backgroundColor={deal?.helper?.avatar_background_color}
                avatarClass={classes.avatar}
                size={36}
                helper={deal?.helper}
              />
            }
          />
          {deal?.related_words?.length > 0 && (
            <Grid className={classes.chipContainer}>
              {deal?.related_words.map(word => (
                <Chip className={classes.chip} size="small" label={word} key={word} />
              ))}
            </Grid>
          )}
        </Card>
      )}
    </>
  );
};

export default DealDetail;
