import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';

import Divider from '@material-ui/core/Divider';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PostForm from '../Posts/Form';

type Props = {
  currentUser: Object,
  header: Object,
  onCreatePost: Function,
  isCreatingPost: Boolean,
  canCreatePost: Boolean,
  postableType: String,
  notifs: Object,
  showPostType: Boolean,
  withExpertises: Boolean,
  family: Object,
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: '800',
    textTransform: 'none',
  },
  subtitle: {
    textTransform: 'none',
    padding: theme.spacing(1, 0),
  },
  post: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

const TimelinePostForm = ({
  currentUser,
  header,
  onCreatePost,
  isCreatingPost,
  postableType,
  canCreatePost,
  notifs,
  showPostType,
  withExpertises,
  family,
}: Props) => {
  const classes = useStyles();

  return (
    <Card style={{ width: '100%', overflow: 'visible' }}>
      {canCreatePost && (
        <>
          <CardHeader
            title={
              <Grid>
                <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
                  <Grid container justifyContent="flex-start" wrap="nowrap">
                    {header?.avatar}
                    <Typography
                      variant="h4"
                      component="h3"
                      className={classes.bold}
                      style={header?.avatar && { marginLeft: 16 }}
                    >
                      {header?.title}
                    </Typography>
                  </Grid>
                  {notifs && (
                    <Tooltip
                      title={
                        notifs.notifsOn
                          ? notifs.notifsDeletionTooltip
                          : notifs.notifsCreationTooltip
                      }
                      placement="bottom"
                    >
                      <IconButton
                        size="small"
                        onClick={
                          notifs.notifsOn
                            ? () => notifs.onDeleteSubscription()
                            : () => notifs.onCreateSubscription()
                        }
                      >
                        {notifs.notifsOn ? <NotificationsActiveIcon /> : <NotificationsOffIcon />}
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>

                <Typography variant="body2" className={classes.subtitle}>
                  {header?.subtitle}
                </Typography>
              </Grid>
            }
          />
          <Divider light />
          <CardContent className={classes.post}>
            <PostForm
              currentUser={currentUser}
              postableType={postableType}
              createPost={onCreatePost}
              creatingPost={isCreatingPost}
              iconBar
              isMessage
              showPostType={showPostType}
              withExpertises={withExpertises}
              family={family}
            />
          </CardContent>
        </>
      )}
      {!canCreatePost && (
        <CardHeader
          title={
            <Typography variant="h4" className={classes.bold}>
              {header?.titleIfCanNotCreatePost}
            </Typography>
          }
        />
      )}
    </Card>
  );
};

export default TimelinePostForm;
