import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Typography } from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';

import { getRestrictionsArray } from '../../Services/FamilyHelper';

type Props = {
  restrictions: Object,
  circles: Object,
};

const useStyles = makeStyles(theme => ({
  circleIcon: {
    width: '8px !important',
    marginRight: theme.spacing(1),
  },
  listItem: {
    paddingLeft: theme.spacing(10),
    '&.MuiListItem-dense': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

const RestrictionsPost = ({ restrictions, circles }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const array = getRestrictionsArray(restrictions, circles);

  return (
    <List dense>
      {array.map(orga => {
        return (
          <>
            <ListItem key={orga.name}>
              <ListItemAvatar>{orga.avatar}</ListItemAvatar>
              <ListItemText primary={<Typography variant="h6">{t(orga.name)}</Typography>} />
            </ListItem>
            <List dense>
              {orga.roles
                ?.filter((role, i) => orga.roles?.indexOf(role) === i) // for old posts to hide "ami & voisin" written twice
                ?.map(role => (
                  <ListItem key={role} className={classes.listItem}>
                    <CircleIcon className={classes.circleIcon} />
                    <ListItemText primary={role} />
                  </ListItem>
                ))}
            </List>
          </>
        );
      })}
    </List>
  );
};

export default RestrictionsPost;
