import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import i18n from 'i18next';
import 'moment/locale/fr';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker, DatePicker, TimePicker } from '@mui/x-date-pickers';
import isDate from 'lodash/isDate';
import AutoCompletePlaces from '../../../Components/Shared/AutoCompletePlaces';
import { serializeDateFromDatePicker, isLongerThan } from '../../../Services/FormHelpers';
import { getFutureDate } from '../../../Services/DataHelper';

moment.locale(i18n?.language);

type Props = {
  task: Object,
  onBackClick: Function,
  onNextClick: Function,
  isFamily: Boolean,
  onSubmitClick: Function,
};

const useStyles = makeStyles(theme => ({
  formTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  recurrenceLabel: {
    marginRight: theme.spacing(1),
  },
  repeatCount: {
    width: '10%',
    margin: theme.spacing(0, 1),
  },
  repeatPeriod: {
    margin: theme.spacing(0, 1),
  },
  datePicker: {
    width: '30%',
    margin: theme.spacing(0, 4),
  },
  pickerPadding: {
    paddingRight: 16,
  },
}));

const RECURRENCE_TYPES = [
  {
    value: 'DAILY',
    label: 'DAY',
  },
  {
    value: 'WEEKLY',
    label: 'WEEK',
  },
  {
    value: 'MONTHLY',
    label: 'MONTH',
  },
];

const TaskFormStep2 = ({ task, onBackClick, onNextClick, isFamily, onSubmitClick }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [stepTask, setStepTask] = React.useState(task);
  const hasRecurrence = stepTask.recurrence;
  const isCreatingTask = useSelector(state => state.tasks.isFetching.createTask);
  const sixMonthsDate = getFutureDate();
  const twoYearsDate = moment().add(2, 'years').toISOString();
  const today = moment().toISOString();
  const startDate = moment(stepTask?.startDate).format();
  const untilDate = moment(stepTask?.untilDate).format();
  const [addressQuery, setAddressQuery] = React.useState(task.formattedAddress);
  const isValidTime = stepTask?.startDate < stepTask?.endDate;
  const isValidDate = (task.id || startDate > today) && startDate < sixMonthsDate;
  const isValidUntilDate =
    !hasRecurrence || (stepTask?.untilDate && untilDate > today && untilDate < twoYearsDate);

  const [address, setAddress] = React.useState({
    formattedAddress: task.formattedAddress,
    latitude: task.latitude,
    longitude: task.longitude,
    locality: task.locality,
  });

  React.useEffect(() => {
    if (address) {
      setStepTask({
        ...stepTask,
        formattedAddress: address.formattedAddress,
        latitude: address.latitude,
        longitude: address.longitude,
        locality: address.locality,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const isValidFormStep = () => {
    if (isFamily) {
      return hasRecurrence
        ? isValidDate && isValidTime && isDate(stepTask?.untilDate) && isValidUntilDate
        : isValidDate && isValidTime;
    }
    return hasRecurrence
      ? isLongerThan(0)(stepTask?.formattedAddress) &&
          isValidDate &&
          isValidTime &&
          isDate(stepTask?.untilDate) &&
          isValidUntilDate &&
          (!addressQuery || stepTask?.formattedAddress === addressQuery)
      : isLongerThan(0)(stepTask?.formattedAddress) &&
          isValidDate &&
          isValidTime &&
          (!addressQuery || stepTask?.formattedAddress === addressQuery);
  };

  return (
    <>
      <Typography variant="h5" align="center" className={classes.formTitle}>
        {t(`APP.ACTIVITY.STEP2.TITLE`)}
      </Typography>
      {/* address */}
      {!isFamily && (
        <FormControl margin="normal" component="fieldset" className={classes.formControl} fullWidth>
          <InputLabel
            variant="outlined"
            shrink
            className={classes.selectLabel}
            style={{
              zIndex: '1011',
            }}
          >
            {t('APP.ACTIVITY.ADDRESS')}
          </InputLabel>
          <AutoCompletePlaces
            onSelect={value => {
              setAddress({
                formattedAddress: value.formatted_address,
                latitude: value.coordinates?.lat,
                longitude: value.coordinates?.lng,
                locality: value.locality,
              });
            }}
            onClear={() => {
              setAddress({
                formattedAddress: '',
                latitude: null,
                longitude: null,
                locality: '',
              });
            }}
            placeHolder={t('APP.ACTIVITY.ADDRESS')}
            defaultValue={stepTask.formattedAddress}
            id="taskAddress"
            setQuery={setAddressQuery}
          />
        </FormControl>
      )}
      {/* date and time */}
      <Grid container style={{ alignItems: 'flex-start' }}>
        <Grid item xs={12} sm={8} className={classes.pickerPadding}>
          <FormControl
            margin="normal"
            component="fieldset"
            className={classes.formControl}
            fullWidth
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                // ampm={false}
                value={stepTask.startDate}
                onChange={date =>
                  setStepTask({
                    ...stepTask,
                    startDate: serializeDateFromDatePicker(date),
                    endDate: serializeDateFromDatePicker(moment(date).add(60, 'm').format()),
                  })
                }
                label={t('APP.ACTIVITY.DATE.LABEL')}
                disablePast
                cancelText={t('CANCEL')}
                minutesStep={5}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    helperText={
                      (startDate > sixMonthsDate && t('APP.ACTIVITY.DATE_FUTUR.ERROR')) ||
                      (!task.id && startDate < today && t('APP.ACTIVITY.DATE_OVER.ERROR'))
                    }
                    error={!isValidDate}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            margin="normal"
            component="fieldset"
            className={classes.formControl}
            fullWidth
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                // ampm={false}
                value={stepTask.endDate}
                onChange={date =>
                  setStepTask({ ...stepTask, endDate: serializeDateFromDatePicker(date) })
                }
                label={t('END_TIME.LABEL')}
                cancelText={t('CANCEL')}
                minutesStep={5}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    helperText={!isValidTime && t('APP.ACTIVITY.DATE.END_DATE.ERROR')}
                    error={!isValidTime}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      {/* recurrence */}
      <FormControlLabel
        control={
          <Switch
            checked={stepTask.recurrence}
            onClick={() =>
              setStepTask({
                ...stepTask,
                recurrenceType: stepTask.recurrence ? 'NONE' : 'WEEKLY',
                recurrence: !stepTask.recurrence,
                untilDate: null,
              })
            }
            color="primary"
          />
        }
        label={t('APP.ACTIVITY.RECURRENCE')}
      />
      <FormGroup row style={{ alignItems: 'center' }}>
        <Typography variant="body1" align="center" className={classes.recurrenceLabel}>
          {t(`APP.ACTIVITY.RECURRENCE_INTERVAL.LABEL`)}
        </Typography>
        {/* recurrence repeat count */}
        <TextField
          value={stepTask.recurrenceInterval}
          onChange={event => setStepTask({ ...stepTask, recurrenceInterval: event.target.value })}
          type="number"
          margin="normal"
          variant="outlined"
          className={classes.repeatCount}
          inputProps={{ min: '1' }}
          disabled={!hasRecurrence}
        />
        {/* recurrence repeat period */}
        <FormControl variant="outlined" component="fieldset" className={classes.repeatPeriod}>
          <Select
            value={stepTask.recurrenceType}
            onChange={event => setStepTask({ ...stepTask, recurrenceType: event.target.value })}
            input={
              <OutlinedInput labelWidth={0} name="recurrence-type" id="recurrence-type-simple" />
            }
            variant="outlined"
            disabled={!hasRecurrence}
          >
            {RECURRENCE_TYPES.map(rec => (
              <MenuItem key={rec.value} value={rec.value}>
                {t(rec.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* recurrence end date */}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label={t('APP.ACTIVITY.UNTIL_DATE.LABEL')}
            onChange={date =>
              setStepTask({
                ...stepTask,
                untilDate: serializeDateFromDatePicker(date),
              })
            }
            value={stepTask.untilDate}
            disablePast
            className={classes.datePicker}
            disabled={!hasRecurrence}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                required={hasRecurrence}
                error={!isValidUntilDate}
                helperText={
                  (!isValidUntilDate &&
                    stepTask?.untilDate &&
                    untilDate > twoYearsDate &&
                    t('APP.ACTIVITY.UNTIL_DATE_FUTUR.ERROR')) ||
                  (!isValidUntilDate &&
                    stepTask?.untilDate &&
                    untilDate < today &&
                    t('APP.ACTIVITY.DATE_OVER.ERROR'))
                }
              />
            )}
          />
        </LocalizationProvider>
      </FormGroup>
      <div className={classes.buttonContainer}>
        <Button onClick={() => onBackClick(stepTask)} className={classes.backButton}>
          {t('BACK')}
        </Button>
        {isFamily ? (
          <Button
            variant="contained"
            color="primary"
            type="button"
            disabled={isCreatingTask || !isValidFormStep()}
            onClick={event => onSubmitClick(event, stepTask)}
          >
            {!isCreatingTask && t('SEND')}
            {isCreatingTask && <CircularProgress size={24} />}
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={!isValidFormStep()}
            color="primary"
            onClick={() => onNextClick(stepTask)}
          >
            {t('NEXT')}
          </Button>
        )}
      </div>
    </>
  );
};

export default TaskFormStep2;
