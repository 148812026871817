import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CardTask from '../Shared/CardTask';
import CardSimplifiedTask from '../Shared/CardSimplifiedTask';
import { getTwigEndDate } from '../../../Services/DataHelper';

type Props = {
  appointmentMeta: Object,
  visible: Boolean,
  onHide: Function,
};

// const useStyles = makeStyles(() => ({
// }));

const TwigAppointmentTooltip = ({ appointmentMeta, visible, onHide }: Props) => {
  // const classes = useStyles();
  const target = appointmentMeta && appointmentMeta.target;
  const task = appointmentMeta && {
    ...appointmentMeta.data?.twig?.task,
    start_date: appointmentMeta.data?.twig?.event_date,
    end_date: getTwigEndDate(appointmentMeta.data?.twig),
    twig_for_date: {
      helpers: appointmentMeta.data?.twig?.helpers || [],
      helpers_not_interested: appointmentMeta.data?.twig?.helpers_not_interested || [],
      helpers_count: appointmentMeta.data?.twig?.helpers_count,
      event_start_time: appointmentMeta.data?.twig?.event_start_time,
      event_end_time: appointmentMeta.data?.twig?.event_end_time,
    },
  };

  return (
    <ClickAwayListener onClickAway={onHide}>
      <Popper
        open={visible || false}
        anchorEl={target}
        onClose={onHide}
        style={{ zIndex: '1000', width: '270px' }}
        placement="left"
      >
        {task && task.slug && (
          <CardTask task={task} organisationPreview connectedMode onClose={onHide} />
        )}
        {task && !task.slug && <CardSimplifiedTask task={task} />}
        {!task && <>error</>}
      </Popper>
    </ClickAwayListener>
  );
};

export default TwigAppointmentTooltip;
