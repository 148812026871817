import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import WelloAvatar from '../Shared/WelloAvatar';

type Props = {
  post: Object,
  onDelete: Function,
};

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 30,
    height: 30,
    fontSize: '1rem',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  cardHeader: {
    padding: theme.spacing(1.5, 2),
  },
}));

const CommentPost = ({ post, onDelete }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [actionMenuAnchor, setActionMenuAnchor] = React.useState(null);

  return (
    <Grid item key={post.id}>
      <Card
        elevation={0}
        style={{ backgroundColor: 'transparent' }}
        itemScope
        itemProp="review"
        itemType="http://schema.org/Review"
      >
        <CardHeader
          avatar={
            <WelloAvatar
              avatarUrl={post.helper?.avatar_url}
              firstName={post.helper?.first_name}
              lastName={post.helper?.last_name}
              backgroundColor={post.helper?.avatar_background_color}
              avatarClass={classes.avatar}
              size={30}
              helper={post.helper}
            />
          }
          title={
            <>
              <b>{post.helper_first_name} - </b>
              {t('DATE', {
                date: moment(post.created_at).format('LL'),
                time: moment(post.created_at).format('LT'),
              })}
            </>
          }
          action={
            onDelete && (
              <>
                <IconButton
                  onClick={event => setActionMenuAnchor(event.currentTarget)}
                  aria-label="More"
                  aria-owns={actionMenuAnchor ? 'actions-menu' : undefined}
                  aria-haspopup="true"
                  size="small"
                  className={classes.icon}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  id="actions-menu"
                  open={Boolean(actionMenuAnchor)}
                  anchorEl={actionMenuAnchor}
                  onClose={() => setActionMenuAnchor(null)}
                  MenuListProps={{
                    style: {
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  }}
                >
                  <MenuItem onClick={() => onDelete(post)}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>{t('DELETE')}</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )
          }
          subheader={post.raw}
          className={classes.cardHeader}
          titleTypographyProps={{ style: { textTransform: 'capitalize' } }}
        />
        <meta itemProp="author" content={post.helper_first_name} />
        <meta itemProp="description" content={post.raw} />
        <meta itemProp="datePublished" content={post.created_at} />
      </Card>
    </Grid>
  );
};

export default CommentPost;
