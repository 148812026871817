import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Avatar from '@material-ui/core/Avatar';
import WarningIcon from '@mui/icons-material/Warning';
import PostForm from '../../Posts/Form';
import MessageCardPost from '../../Posts/MessageCardPost';
import { CheckRoleRule } from '../../../Acl/Rules';
import { getRole } from '../../../Acl/Controls';
import CardPost from './CardPost';

const useStyles = makeStyles(() => ({
  card: { width: '100%', overflow: 'visible', marginBottom: 16 },
  avatar: { backgroundColor: 'transparent', marginRight: -8 },
  icon: { color: 'black' },
  postsContainer: { paddingBottom: 16 },
}));

type Props = {
  family: Object,
  organisation: Object,
  onEditPost: Function,
  onDeletePost: Function,
  onCreateComment: Function,
  onDeleteComment: Function,
};

const MenuCarenotebook = ({
  family,
  organisation,
  onEditPost,
  onDeletePost,
  onCreateComment,
  onDeleteComment,
}: Props) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const classes = useStyles();
  const { family_slug: familySlug } = match.params;

  const importantPosts = useSelector(state =>
    state.careNotebooks.postsByFamily[familySlug]
      ? state.careNotebooks.postsByFamily[familySlug]?.importantPosts
      : [],
  );
  const isCreatingComment = useSelector(
    state => state.careNotebooks.isFetching.createCareNotebookPostComment,
  );
  const currentUser = useSelector(state => state.auth.currentUser);
  const [editedPostId, setEditedPostId] = React.useState(null);
  const [selectedPostId, setSelectedPostId] = React.useState(null);
  const selectedPost = importantPosts?.find(p => p.id === selectedPostId);

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography variant="h5" component="h3" style={{ textTransform: 'none' }}>
              {t('APP.ORGANISATION.MENU.CARENOTEBOOKS.NEWS')}
            </Typography>
          }
          avatar={
            <Avatar className={classes.avatar}>
              <WarningIcon className={classes.icon} />
            </Avatar>
          }
        />
      </Card>
      {importantPosts?.map(post => (
        <Grid key={post.id} className={classes.postsContainer}>
          <CardActionArea onClick={() => setSelectedPostId(post?.id)}>
            <MessageCardPost
              post={post}
              canReadPost={() => true}
              canEditDeletePost={() => false}
              simplifiedVersion
            />
          </CardActionArea>
        </Grid>
      ))}
      <Dialog
        open={selectedPost}
        onClose={() => setSelectedPostId(null)}
        PaperProps={{ style: { flexGrow: 1 } }}
      >
        {selectedPost && (
          <CardPost
            holdingSlug={organisation?.holding_slug}
            organisationSlug={organisation?.slug}
            post={selectedPost}
            onEditPost={() => setEditedPostId(selectedPost?.id)}
            onDeletePost={() =>
              onDeletePost(
                selectedPost?.organisation?.holding_slug || organisation?.holding_slug,
                selectedPost?.organisation?.slug || organisation?.slug,
                selectedPost?.id,
              )
            }
            currentUser={currentUser}
            creatingComment={isCreatingComment}
            onCreateComment={onCreateComment}
            onDeleteComment={onDeleteComment}
            postableType="Post"
            canReadPost={() => true}
            canEditDeletePost={(hSlug, olug, postOwnerId) =>
              CheckRoleRule(
                getRole(currentUser, organisation?.holding_slug, organisation?.slug, family?.slug),
                'carenotebooks-posts:editdelete',
                {
                  userId: currentUser?.id,
                  postOwnerId,
                },
              )
            }
            canDeleteComment={(hSlug, olug, commentOwnerId) =>
              CheckRoleRule(
                getRole(currentUser, organisation?.holding_slug, organisation?.slug, family?.slug),
                'carenotebooks-posts-comments:delete',
                {
                  userId: currentUser?.id,
                  commentOwnerId,
                },
              )
            }
            family={family}
            isDialog
          />
        )}
      </Dialog>
      <Dialog
        open={editedPostId === selectedPost?.id}
        onClose={() => setEditedPostId(null)}
        fullWidth
      >
        <DialogTitle>{t('APP.TIMELINE.EDIT')}</DialogTitle>
        <DialogContent>
          <PostForm
            post={selectedPost}
            currentUser={currentUser}
            postableType="Organisation"
            iconBar
            isMessage
            onClose={() => setEditedPostId(null)}
            updatePost={onEditPost}
            isDialog
            withExpertises
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MenuCarenotebook;
