import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Chip from '@material-ui/core/Chip';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { getExpertiseStyle } from '../../../Themes/Expertises';

registerPlugin(FilePondPluginFileValidateType);

type Props = {
  task: Object,
  onNextClick: Function,
  coverUrl: String,
  expertises: Array,
  family: Object,
};

const useStyles = makeStyles(theme => ({
  formTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  facet: {
    paddingLeft: theme.spacing(2),
  },
  formLegend: {
    color: 'black',
    margin: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  buttonWrapper: {
    marginRight: theme.spacing(2),
    position: 'relative',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectedChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#fff',
    '&>*': {
      '&>*': {
        color: '#fff',
      },
    },
  },
}));

const TaskFormStep0 = ({ task, onNextClick, coverUrl, expertises, family }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [stepTask, setStepTask] = React.useState(task);
  const [files, setFiles] = React.useState(stepTask.files);
  const [isImageLoaded, setIsImageLoaded] = React.useState(true);
  const [imagesCompressed, setImagesCompressed] = React.useState([]);

  React.useEffect(() => {
    if (files) {
      const images = files.map(file => imagesCompressed.find(ic => ic.name === file.name) || file);
      setStepTask({ ...stepTask, files: images });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  React.useEffect(() => {
    if (coverUrl && (!files || files.length === 0)) {
      const fileObject = [
        {
          source: coverUrl,
          options: {
            type: 'local',
            file: { name: coverUrl, size: 0 },
            metadata: { poster: coverUrl },
          },
        },
      ];
      setStepTask({
        ...stepTask,
        files: fileObject,
      });
      setFiles(fileObject);
      setIsImageLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverUrl, setStepTask, setFiles]);

  const isValidFormStep = () => {
    return family
      ? stepTask.expertiseId
      : stepTask.expertiseId && stepTask.files.length > 0 && isImageLoaded;
  };
  return (
    <>
      <Typography variant="h5" align="center" className={classes.formTitle}>
        {family
          ? t('APP.ACTIVITY.STEP0.TITLE.FAMILY', { user: family.name })
          : t('APP.ACTIVITY.STEP0.TITLE')}
      </Typography>
      {/* expertises */}
      <Grid container>
        {expertises
          ?.filter(expertise =>
            family
              ? expertise.expertise_type === 'family'
              : expertise.expertise_type === 'activity',
          )
          .map(expertise => (
            <Chip
              key={expertise.slug}
              variant={expertise.id === stepTask.expertiseId ? 'default' : 'outlined'}
              style={
                expertise.id === stepTask.expertiseId
                  ? {
                      backgroundColor: getExpertiseStyle(expertise).color,
                    }
                  : {
                      backgroundColor: '#fff',
                    }
              }
              className={
                expertise.id === stepTask.expertiseId ? classes.selectedChip : classes.chip
              }
              label={t(getExpertiseStyle(expertise).label)}
              clickable
              onClick={() =>
                setStepTask({
                  ...stepTask,
                  expertiseId: expertise?.id,
                })
              }
              icon={getExpertiseStyle(expertise).icon}
            />
          ))}
      </Grid>
      {/* cover */}
      {!family && (
        <FormControl
          margin="normal"
          component="fieldset"
          className={classes.formControl}
          fullWidth
          required
        >
          <FormLabel component="legend" className={classes.formLegend}>
            {t('APP.ACTIVITY.COVER.LABEL')}
          </FormLabel>
          <FilePond
            files={files}
            allowMultiple={false}
            maxFiles={1}
            credits={('', '')}
            instantUpload={false}
            acceptedFileTypes={['image/*']}
            labelIdle={t('APP.USER.IMAGE_FIELD.PLACEHOLDER')}
            onaddfilestart={() => setIsImageLoaded(false)}
            onupdatefiles={fileItems => {
              const hasNewFiles =
                fileItems?.filter(fileItem => fileItem?.file?.size > 0).length > 0;
              if (fileItems?.length === 0 || hasNewFiles) {
                setFiles(fileItems.map(fileItem => fileItem.file));
              }
            }}
            imageResizeTargetWidth="1200"
            allowImageResize
            imageResizeMode="contain"
            onpreparefile={(file, output) => {
              const newIm = imagesCompressed.slice();
              newIm.push(output);
              setImagesCompressed(newIm);
              setIsImageLoaded(true);
            }}
            imageResizeUpscale={false}
          />
        </FormControl>
      )}
      <div className={classes.buttonContainer}>
        <div className={classes.buttonWrapper}>
          <Button onClick={() => history.goBack()} className={classes.backButton}>
            {t('CANCEL')}
          </Button>
        </div>
        <Button
          variant="contained"
          disabled={!isValidFormStep()}
          color="primary"
          onClick={() => onNextClick(stepTask)}
        >
          {t('NEXT')}
        </Button>
      </div>
    </>
  );
};

export default TaskFormStep0;
