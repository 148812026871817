import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import parse from 'autosuggest-highlight/parse';
import automatch from 'autosuggest-highlight/match';

import CardPrimaryResume from '../Transmission/CardPrimaryResume';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import Roles from '../../../Acl/Roles';

const useStyle = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(2),
  },
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    textTransform: 'initial',
  },
  buttonLink: {
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

type Props = {
  organisation: Object,
};

const MenuPrimary = ({ organisation }: Props) => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    item_id: primaryOrgaId,
  } = match.params;
  const isFetching = useSelector(
    state => state.organisationMembership.isFetching.getOrganisationMembership,
  );
  const primaries = useSelector(state => state.organisationMembership.members);
  const primary = primaries?.find(p => p.id === parseInt(primaryOrgaId, 10));

  React.useEffect(() => {
    if (organisation && !primaries) {
      dispatch(
        OrganisationMembershipActions.getOrganisationMembershipRequest(
          organisation.holding_slug,
          organisation.slug,
          Roles.PRIMARY,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation?.slug, dispatch]);

  const handleSelectedMembershipChanged = selectedPrimary => {
    if (selectedPrimary?.id) {
      history.push(`/${holdingSlug}/${organisationSlug}/primaries/${selectedPrimary?.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Card>
      <Grid className={classes.mainContainer}>
        <Grid container className={classes.container}>
          <Typography variant="h5" component="p" className={classes.title}>
            {t('PRIMARIES')}
          </Typography>
          <Button
            className={classes.buttonLink}
            onClick={() => history.push(`/${holdingSlug}/${organisationSlug}/primaries`)}
          >
            {t('SEE_ALL')}
          </Button>
        </Grid>
        <FormControl margin="normal" variant="outlined" component="fieldset" fullWidth>
          <Autocomplete
            fullWidth
            id="primary-select"
            options={primaries}
            autoHighlight
            getOptionLabel={p => `${p.helper.last_name} ${p.helper.first_name}`}
            renderOption={(p, { inputValue }) => {
              const matches = automatch(`${p.helper.last_name} ${p.helper.first_name}`, inputValue);
              const parts = parse(`${p.helper.last_name} ${p.helper.first_name}`, matches);
              return (
                <Typography variant="body1" component="p">
                  {parts.map(part => (
                    <span
                      key={JSON.stringify(part)}
                      style={{ fontWeight: part.highlight ? 500 : 300 }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
              );
            }}
            renderInput={params => <TextField {...params} label={t('SELECT')} variant="outlined" />}
            onChange={(event, value) => handleSelectedMembershipChanged(value)}
          />
        </FormControl>
      </Grid>
      <Divider light />
      {!isFetching && primary && (
        <CardPrimaryResume primary={primary} organisation={organisation} />
      )}
    </Card>
  );
};

export default MenuPrimary;
