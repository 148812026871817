import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import IconButton from '@material-ui/core/IconButton';
import parse from 'autosuggest-highlight/parse';
import automatch from 'autosuggest-highlight/match';
import WelloAvatar from './WelloAvatar';
import { getOrgaName } from '../../Services/OrganisationHelper';

type Props = {
  organisations: Array,
  title: String,
  onClickOrga: Function,
  width: String,
  disabledOrganisationsIds: Array,
  selectedOrga: Object,
};

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '700',
    paddingBottom: theme.spacing(2),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  names: {
    alignSelf: 'center',
    paddingLeft: theme.spacing(2),
  },
  namesText: {
    fontWeight: '700',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selected: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    borderRadius: '4px',
    backgroundColor: theme.palette.grey[300],
    marginTop: 0,
    padding: theme.spacing(1.5),
  },
  orgaName: { flexGrow: 1, marginLeft: 16, textAlign: 'left' },
}));

const OrganisationsSearchBar = ({
  organisations,
  title,
  onClickOrga,
  width,
  disabledOrganisationsIds,
  selectedOrga,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {title && <Typography className={classes.title}>{title}</Typography>}
      <Grid style={{ width }}>
        {selectedOrga && (
          <Grid container className={classes.selected}>
            <Grid item>
              {selectedOrga.id === 0 && (
                <PriorityHighIcon style={{ marginLeft: 2, color: 'grey', marginTop: 4 }} />
              )}
              {selectedOrga.id !== 0 && (
                <WelloAvatar
                  avatarUrl={selectedOrga.holding_cover_url}
                  size={30}
                  avatarStyle={{ width: 30, height: 30 }}
                />
              )}
            </Grid>
            <Grid item className={classes.orgaName}>
              <Typography variant="body1">{getOrgaName(selectedOrga)}</Typography>
            </Grid>
            <IconButton size="small" onClick={() => onClickOrga(null)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
        {!selectedOrga && (
          <Autocomplete
            fullWidth
            id="orga-select"
            options={organisations}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={orga => getOrgaName(orga)}
            renderOption={(orga, { inputValue }) => {
              const matches = automatch(getOrgaName(orga), inputValue);
              const parts = parse(getOrgaName(orga), matches);
              return (
                <Grid container className={classes.text}>
                  <Grid item xs={12} sm={1} style={{ alignSelf: 'center' }}>
                    {orga.id === 0 && <PriorityHighIcon style={{ color: 'grey', marginTop: 4 }} />}
                    {orga.id !== 0 && (
                      <WelloAvatar
                        avatarUrl={orga.holding_cover_url}
                        size={30}
                        avatarStyle={{ width: 30, height: 30 }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={11} className={classes.names}>
                    <Typography className={classes.namesText}>
                      {parts.map((part, index) => (
                        // eslint-disable-next-line
                        <span
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${orga.id}-${index}`}
                          style={{ fontWeight: part.highlight ? 700 : orga.id === 0 ? 600 : 400 }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
            renderInput={params => <TextField {...params} label={t('SEARCH')} variant="outlined" />}
            noOptionsText={t('APP.LEAGUES.SETTINGS.NO_HOLDING')}
            onChange={(event, value) => onClickOrga(value)}
            getOptionDisabled={option =>
              disabledOrganisationsIds && disabledOrganisationsIds.includes(option.id)
            }
            getOptionSelected={(option, value) => option.id === value.id}
            disabled={!organisations || organisations.length === 0}
          />
        )}
      </Grid>
    </>
  );
};

export default OrganisationsSearchBar;
