import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
// import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@mui/icons-material/Help';
// import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { orange } from '@material-ui/core/colors';
import { ListItemText } from '@material-ui/core';

import UserForm from '../../UserSetup/UserForm';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import Role, { getRolesForOrganisation } from '../../../Acl/Roles';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
// import AutosuggestHelper from '../../../Components/Shared/AutosuggestHelper';
import { serializeDateFromDatePicker } from '../../../Services/FormHelpers';
// import WelloAvatar from '../../../Components/Shared/WelloAvatar';
import { checkEmailValidity, checkPhoneValidity } from '../../../Services/DataHelper';
// import { isFamily } from '../../../Acl/Controls';
import Routes from '../../../Routing/Routes';

type Props = {
  organisation: Object,
  onClose: Function,
  handleClickInvite: Function,
  handleCreateOrgaMembership: Function,
  titleUserAndRole: String,
  subtitle: String,
  createMemberLabel: String,
  titleNewUser: String,
  subtitleNewUser: String,
  hasRoleToSelect: Boolean,
  tooltipLabel: String,
  emailRequired: Boolean,
  handleCreateHelped: Function,
  showLeagueStatus: Boolean,
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    ...LayoutStyles.centered600Layout,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  headingContainer: {
    width: '100%',
    margin: '0 0 1rem',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  submit: {
    margin: theme.spacing(1),
  },
  buttonLink: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  selected: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '5px',
    backgroundColor: theme.palette.grey[300],
  },
  crossIcon: {
    flexGrow: 1,
    textAlign: 'end',
  },
  subtitle: {
    color: 'inherit',
  },
  helpIcon: {
    color: orange[600],
    marginLeft: theme.spacing(1),
  },
  checkBox: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(-1),
  },
  flex: {
    '&>*': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));

const InviteMember = ({
  organisation,
  onClose,
  // handleClickInvite,
  handleCreateOrgaMembership,
  titleUserAndRole,
  // subtitle,
  // createMemberLabel,
  titleNewUser,
  subtitleNewUser,
  hasRoleToSelect,
  tooltipLabel,
  emailRequired,
  handleCreateHelped,
  showLeagueStatus,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isCreatingMembership = useSelector(
    state => state.organisationMembership.isFetching.createOrganisationMembership,
  );
  // const newMembership = useSelector(state => state.organisationMembership.newMembership);

  // const [selectedHelper, setSelectedHelper] = React.useState(null);

  const [role, setRole] = React.useState(!hasRoleToSelect ? Role.PRIMARY : '');
  const [helperAttributes, setHelperAttributes] = React.useState({
    gender: 'female',
    firstName: '',
    lastName: '',
    phone: '',
    country_code: 'fr',
    birthday: new Date(1970, 1, 1),
    formattedAddress: '',
    addressSupplement: '',
    latitude: '',
    longitude: '',
    locality: '',
    description: t('APP.USER.TEXT_FIELD.DEFAULT_VALUE.DESCRIPTION', {
      league: t(`${Routes.currentNamespace()}.SEO.SITENAME`),
    }),
    pathology: '',
    avatar: null,
    email: null,
    sendEmail: true,
  });
  // const noOrga = !organisation || isFamily(organisation);
  // const [showNewHelperSetup, setShowNewHelperSetup] = React.useState(noOrga);
  const rolesExceptions = organisation?.existing_roles?.find(role => role === Role.MAIN_HELPER)
    ? [Role.ADMIN, Role.PRIMARY, Role.PRIMARY_HELPED]
    : [Role.ADMIN, Role.PRIMARY];

  const canSubmitNewHelper =
    role &&
    helperAttributes.firstName &&
    helperAttributes.lastName &&
    ((!emailRequired && helperAttributes.email && checkEmailValidity(helperAttributes.email)) ||
      (!emailRequired && !helperAttributes.email) ||
      (emailRequired && checkEmailValidity(helperAttributes.email))) &&
    (!helperAttributes.phone ||
      checkPhoneValidity(helperAttributes.phone, helperAttributes.country));

  React.useEffect(() => {
    dispatch(OrganisationMembershipActions.resetNewMembership());
    return () => dispatch(OrganisationMembershipActions.resetNewMembership());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = event => {
    if (!role || (helperAttributes.email && !checkEmailValidity(helperAttributes.email))) return;

    const helper = {
      first_name: helperAttributes.firstName,
      last_name: helperAttributes.lastName,
      phone: helperAttributes.phone,
      country_code: helperAttributes.country_code,
      description: helperAttributes.description,
      pathology: helperAttributes.pathology,
      gender: helperAttributes.gender,
      birthday: serializeDateFromDatePicker(helperAttributes.birthday),
      locality: helperAttributes.locality,
      formatted_address: helperAttributes.formattedAddress,
      latitude: helperAttributes.latitude,
      longitude: helperAttributes.longitude,
      address_supplement: helperAttributes.addressSupplement,
      send_email: helperAttributes.sendEmail,
    };

    if (role === Role.PRIMARY_HELPED) {
      const helped = {
        ...helper,
        helper_id: organisation?.admin?.id,
        avatar: helperAttributes.avatar,
      };
      handleCreateHelped(helped, role);
    } else {
      handleCreateOrgaMembership(helper, role, helperAttributes.email);
    }

    onClose();
    event.preventDefault();
  };

  const renderNewUserSetup = () => (
    <Paper className={classes.paper}>
      <Typography align="left" variant="h4" gutterBottom>
        {t(titleUserAndRole || titleNewUser)}
      </Typography>
      <Typography variant="subtitle2" align="left" gutterBottom>
        {t(subtitleNewUser)}
      </Typography>
      {hasRoleToSelect && (
        <FormControl
          variant="outlined"
          component="fieldset"
          margin="normal"
          fullWidth
          required
          className={classes.formControl}
          style={{ marginTop: '24px' }}
        >
          <InputLabel
            variant="outlined"
            className={classes.selectLabel}
            htmlFor="role-simple"
            shrink
          >
            {t('APP.ORGANISATION.SETTINGS.MEMBERS.ROLE.LABEL')}
          </InputLabel>
          <Select
            value={role}
            onChange={event => setRole(event.target.value)}
            input={<OutlinedInput labelWidth={0} name="role" id="role-simple" />}
            variant="outlined"
          >
            {getRolesForOrganisation(organisation, t, rolesExceptions).map(organisationRole => (
              <MenuItem
                key={`select-item-${organisationRole.value}`}
                value={organisationRole.value}
              >
                <ListItemText className={classes.flex}>
                  {organisationRole.label}
                  {(organisationRole.value === Role.MAIN_HELPER ||
                    organisationRole.value === Role.PRIMARY_HELPED) && (
                    <Tooltip
                      title={t(
                        `APP.ORGANISATION.SETTINGS.MEMBERS.ROLE.${organisationRole.value.toUpperCase()}`,
                      )}
                      placement="bottom"
                    >
                      <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                  )}
                </ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <UserForm
        hideAdvancedParams={role !== Role.PRIMARY_HELPED}
        hideProfilParams={role !== 'primary' && role !== Role.PRIMARY_HELPED}
        user={helperAttributes}
        setUser={setHelperAttributes}
        // onSubmit={handleSubmit}
        isUpdating={isCreatingMembership}
        showLeagueStatus={showLeagueStatus}
        emailRequired={emailRequired}
        tooltipLabel={tooltipLabel}
      />
      {/* {role !== Role.PRIMARY_HELPED && (
        <>
          <Grid container style={{ alignItems: 'center' }}>
            <Grid style={{ flexGrow: 1 }}>
              <TextField
                id="email"
                name="email"
                value={email}
                label={t('APP.ORGANISATION.SETTINGS.MEMBERS.EMAIL.LABEL')}
                onChange={event => setEmail(event.target.value?.trim())}
                className={classes.formControl}
                fullWidth
                margin="none"
                variant="outlined"
                onBlur={() => setEmailError(email && !checkEmailValidity(email))}
                InputLabelProps={{
                  shrink: true,
                }}
                error={emailError}
                helperText={emailError && t('INCORRECT_EMAIL')}
                required={emailRequired}
              />
            </Grid>
            {tooltipLabel && (
              <Tooltip title={t(tooltipLabel)} placement="bottom">
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            )}
          </Grid>
          <FormControl component="div" className={classes.checkBox}>
            <FormControlLabel
              key="inviteLabel"
              label={t('APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.SEND_EMAIL')}
              control={
                <Checkbox
                  name="invite"
                  onChange={() => setSendEmail(!sendEmail)}
                  checked={organisation && sendEmail}
                  color="primary"
                  disabled={!email || !organisation}
                />
              }
            />
          </FormControl>
        </>
      )} */}

      <Grid
        item
        xs
        align="center"
        className={classes.buttonContainer}
        flexDirection="row"
        spacing={2}
      >
        {/* {!isFamily(organisation) && (
          <Button
            disabled={isCreatingMembership}
            color="primary"
            variant="outlined"
            className={classes.submit}
            onClick={() => (noOrga ? onClose() : setShowNewHelperSetup(false))}
          >
            {t('BACK')}
          </Button>
        )} */}
        <Button
          disabled={isCreatingMembership || !canSubmitNewHelper}
          color="primary"
          type="submit"
          variant="contained"
          className={classes.submit}
          onClick={handleSubmit}
        >
          {t('CREATE')}
        </Button>
      </Grid>
    </Paper>
  );

  // const renderUserAndRole = () => (
  //   <Paper className={classes.paper}>
  //     <Grid item xs={12} className={classes.headingContainer}>
  //       <Typography variant="h4" align="left" gutterBottom>
  //         {t(titleUserAndRole)}
  //       </Typography>
  //     </Grid>

  //     <form onSubmit={event => handleClickInvite(event, selectedHelper, role)}>
  //       {selectedHelper && (
  //         <Grid
  //           container
  //           flexDirection="row"
  //           display="flex"
  //           spacing={2}
  //           className={classes.selected}
  //         >
  //           <Grid item>
  //             <WelloAvatar
  //               avatarUrl={selectedHelper.avatar_url}
  //               firstName={selectedHelper.first_name}
  //               lastName={selectedHelper.last_name}
  //               backgroundColor={selectedHelper.avatar_background_color}
  //               size={36}
  //             />
  //           </Grid>
  //           <Grid item>
  //             <Typography variant="body1">{selectedHelper.fullname}</Typography>
  //             <Typography variant="subtitle1">{selectedHelper.pseudo}</Typography>
  //           </Grid>
  //           <Grid item className={classes.crossIcon}>
  //             <IconButton onClick={() => setSelectedHelper(null)}>
  //               <CloseIcon />
  //             </IconButton>
  //           </Grid>
  //         </Grid>
  //       )}
  //       {!selectedHelper && (
  //         <FormControl component="fieldset" fullWidth className={classes.formControl}>
  //           <AutosuggestHelper
  //             onSuggestionSelected={value => {
  //               setSelectedHelper(value);
  //             }}
  //           />
  //         </FormControl>
  //       )}
  //       {hasRoleToSelect && (
  //         <>
  //           <Grid item xs={12}>
  //             <Typography
  //               variant="subtitle2"
  //               align="left"
  //               className={classes.subtitle}
  //               gutterBottom
  //             >
  //               {t(subtitle)}
  //             </Typography>
  //           </Grid>
  //           <FormControl
  //             variant="outlined"
  //             component="fieldset"
  //             margin="none"
  //             fullWidth
  //             required={selectedHelper}
  //             className={classes.formControl}
  //           >
  //             <InputLabel variant="outlined" className={classes.selectLabel} htmlFor="role-simple">
  //               {t('APP.ORGANISATION.SETTINGS.MEMBERS.ROLE.LABEL')}
  //             </InputLabel>
  //             <Select
  //               value={role}
  //               onChange={event => setRole(event.target.value)}
  //               input={<OutlinedInput labelWidth={0} name="role" id="role-simple" />}
  //               variant="outlined"
  //             >
  //               {getRolesForOrganisation(organisation, t, [Role.ADMIN, Role.PRIMARY]).map(
  //                 organisationRole => (
  //                   <MenuItem
  //                     key={`select-item-${organisationRole.value}`}
  //                     value={organisationRole.value}
  //                   >
  //                     {organisationRole.label}
  //                   </MenuItem>
  //                 ),
  //               )}
  //             </Select>
  //           </FormControl>
  //         </>
  //       )}

  //       <Grid className={classes.buttonContainer}>
  //         <Button
  //           variant="contained"
  //           color="primary"
  //           type="submit"
  //           disabled={isCreatingMembership || !selectedHelper || !role}
  //         >
  //           {t('ADD')}
  //         </Button>
  //       </Grid>
  //       <Grid className={classes.buttonContainer} flexdirection="row">
  //         <Typography variant="body1" align="center" gutterBottom>
  //           {t('APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.NEW.NOT_FOUND')}
  //         </Typography>
  //         <Button
  //           className={classes.buttonLink}
  //           color="primary"
  //           disabled={isCreatingMembership}
  //           onClick={() => setShowNewHelperSetup(true)}
  //         >
  //           {t(createMemberLabel)}
  //         </Button>
  //       </Grid>
  //     </form>
  //   </Paper>
  // );

  return (
    <>
      {/* {!noOrga && !showNewHelperSetup && renderUserAndRole()} */}
      {/* {showNewHelperSetup && !newMembership && renderNewUserSetup()} */}
      {renderNewUserSetup()}
    </>
  );
};

export default InviteMember;
