import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
  connectionCard: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(-0.5, 0),
  },
}));

type Props = {
  post: Object,
};

const HomeVisitCardPost = ({ post }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fullName = post.helper_first_name
    ? `${post.helper_first_name}  ${post.helper_last_name}`
    : `${post.helper?.first_name}  ${post.helper?.last_name}`;

  return (
    <Card
      key={`cardpost-${post.id}`}
      style={{ overflow: 'visible' }}
      className={classes.connectionCard}
    >
      <Typography
        variant="body2"
        align="center"
        dangerouslySetInnerHTML={{
          __html: t('APP.POST.CONNECTION', {
            name: `${fullName}${!post.helper ? t('APP.POST.VISITOR') : ''}`,
            date: moment(post.created_at).format('dddd Do MMMM LT'),
          }),
        }}
      />
    </Card>
  );
};

export default HomeVisitCardPost;
