import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { Images, muiTheme } from '../../../Themes';
import ButtonCallMeBackInModal from '../../Shared/ButtonCallMeBackInModal';
import Routes from '../../../Routing/Routes';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: theme.spacing(0, 0, 2),
    alignItems: 'stretch',
  },
  card: {
    padding: theme.spacing(3, 3, 1),
    height: '100%',
  },
  paddingSubtitle: {
    padding: theme.spacing(1, 0),
  },
  paddingBody: {
    padding: theme.spacing(2, 0),
  },
  btn: {
    color: muiTheme.current.primary.main,
    // '&:hover': {
    //   backgroundColor: theme.current.primary.dark,
    // },
  },
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardMedia: {
    height: '100px',
    width: 'auto',
  },
}));

const CardCtaProOrHelper = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item sm={7}>
        <Card
          className={classes.card}
          style={{ backgroundColor: muiTheme.palette.grey[300], borderRadius: '4px 0 0 4px' }}
        >
          <Typography variant="h4">{t('APP.ORGANISATION.HOME.CTA.PRO.TITLE')}</Typography>
          <Typography variant="h6" className={classes.paddingSubtitle}>
            {t('APP.ORGANISATION.HOME.CTA.PRO.SUBTITLE')}
          </Typography>
          <Typography variant="body1" className={classes.paddingBody}>
            {t('APP.ORGANISATION.HOME.CTA.PRO.BODY')}
          </Typography>
          <Grid container className={classes.container}>
            <Button
              className={classes.btn}
              href={
                Routes.currentLeague().namespace === 'SLA'
                  ? 'mailto:federation@soutenirlesaidants.fr?cc=hello@wello.fr'
                  : 'mailto:hello@wello.fr'
              }
            >
              {t('APP.ORGANISATION.HOME.CTA.PRO.LABEL')}
            </Button>
            <CardMedia
              component="img"
              className={classes.cardMedia}
              image={Images.illu.carenotebook_pro}
              alt={t('APP.ORGANISATION.HOME.CTA.PRO.LABEL')}
            />
          </Grid>
        </Card>
      </Grid>
      <Grid item sm={5}>
        <Card className={classes.card} style={{ borderRadius: '0 4px 4px 0' }}>
          <Typography variant="h4">{t('APP.ORGANISATION.HOME.CTA.HELPER.TITLE')}</Typography>
          <Typography variant="body1" className={classes.paddingBody}>
            {t('APP.ORGANISATION.HOME.CTA.HELPER.BODY')}
          </Typography>
          <Grid container className={classes.container}>
            <ButtonCallMeBackInModal
              withContainedButton
              // organisation={organisation} // send to league admin
              withPostCode
              withEmail
              withPhone
              buttonLabel={t('CONTACT_US')}
              descriptionPlaceHolder={t('APP.SLA.ASK_CONTACT.TEXT_FIELD.LABEL.OTHER.PLACEHOLDER')}
              gtmOrigin="no_admin_card"
            />
            <CardMedia
              component="img"
              className={classes.cardMedia}
              style={{ transform: 'scaleX(-1)' }}
              image={Images.illu.carenotebook_for_helped}
              alt={t('CONTACT_US')}
            />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
export default CardCtaProOrHelper;
