import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import OrganisationActions from '../../../Redux/OrganisationRedux';
import OrganisationForm from '../../../Components/Organisations/OrganisationForm';

type Props = {
  organisation: Object,
  onClose: Function,
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    width: '100%',
  },
}));

const OrganisationEdit = ({ organisation, onClose }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);

  const handleSubmit = (orgaAttributes, banner) => {
    const updateAttributes = { ...orgaAttributes };
    if (organisation) {
      updateAttributes.id = organisation.id;
      dispatch(
        OrganisationActions.updateOrganisationRequest(
          organisation.holding_slug,
          organisation.slug,
          updateAttributes,
          banner,
          currentUser.id,
        ),
      );
    }
    if (onClose) onClose();
  };

  return (
    <Paper className={classes.paper}>
      {organisation && <OrganisationForm organisation={organisation} onSubmit={handleSubmit} />}
    </Paper>
  );
};

export default OrganisationEdit;
