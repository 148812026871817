import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Dialog from '@material-ui/core/Dialog';
import { getCdnUrl } from '../../Services/ImageResizer';

type Props = {
  images: Array,
  forcedGridWidth: Number,
};

const useStyles = makeStyles(() => ({
  media: {
    objectFit: 'cover',
  },
}));

const ImagesMosaic = ({ images, forcedGridWidth }: Props) => {
  const classes = useStyles();
  const [openImagePreview, setOpenImagePreview] = React.useState(null);

  if (!images) return null;

  let gridWidth = 12;
  let height = 'auto';

  if (images.length === 2 || images.length === 4) {
    gridWidth = 6;
    height = 25;
  }
  if (images.length === 3 || images.length === 5 || images.length === 6) {
    gridWidth = 4;
    height = 15;
  }

  if (forcedGridWidth) gridWidth = forcedGridWidth;

  return (
    <Grid container spacing={1}>
      {images.map(im => (
        <Grid item xs={gridWidth} key={im.preview_url}>
          <CardActionArea onClick={() => setOpenImagePreview(im.preview_url)}>
            <CardMedia
              className={classes.media}
              component="img"
              src={getCdnUrl(im.preview_url, 500)}
              alt={im.filename || im.preview_url}
              title={im.filename || im.preview_url}
              key={im.preview_url}
              style={{ height: `${height}vw` }}
            />
          </CardActionArea>
        </Grid>
      ))}
      <Dialog open={!!openImagePreview} onClose={() => setOpenImagePreview(null)}>
        <img src={openImagePreview} alt="preview" />
      </Dialog>
    </Grid>
  );
};

export default ImagesMosaic;
