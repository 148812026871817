import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  MonthView,
  ViewSwitcher,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  TodayButton,
  Toolbar,
  DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';
import moment from 'moment';
import { useLocation, useRouteMatch } from 'react-router';
import AppBar from '@material-ui/core/AppBar';
import Bar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import TodayIcon from '@mui/icons-material/Today';
import ConfirmationDialog from '../../Shared/ConfirmationDialog';
import TwigAppointment from './TwigAppointment';
import TwigAppointmentTooltip from './TwigAppointmentTooltip';
import TwigTimeTableCell from './TwigTimeTableCell';
import ShowFamilyTwigDialog from '../../Families/ShowFamilyTwigDialog';
import TasksActions from '../../../Redux/TasksRedux';
import { CheckRoleRule } from '../../../Acl/Rules';
import { getOffsetDates } from '../../../Services/SchedulerHelper';
import { getOrgaKey, getFutureDate, getTwigEndDate } from '../../../Services/DataHelper';
import ReccurrenceActionDialog from './ReccurrenceActionDialog';
import TwigAppointmentFullScreen from './TwigAppointmentFullScreen';
import EventsList from './EventsList';
import history from '../../../Routing/CurrentHistory';
import { isMobile } from '../../Layout/Styles/LayoutStyles';

type Props = {
  data: Array,
  currentUser: Object,
  isFullScreen: Boolean,
  role: String,
  currentDate: String,
  setCurrentDate: Function,
};

type TimeTableProps = {
  startDate: Object,
};

const useStyles = makeStyles(theme => ({
  pastCell: {
    backgroundColor: theme.palette.grey[100],
  },
  dayCell: {
    paddingBottom: theme.spacing(1),
    '&>*': { fontSize: '0.8rem', fontWeight: '600' },
  },
  todayIcon: {
    color: theme.palette.text.secondary,
  },
}));

const OrganisationScheduler = ({
  data,
  currentUser,
  isFullScreen,
  role,
  currentDate,
  setCurrentDate,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    family_slug: familySlug,
  } = match.params;
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    eventDate: queryParam.get('eventDate') || '',
    taskSlug: queryParam.get('taskSlug') || '',
  };
  const classes = useStyles();
  const isUpdating = useSelector(state => state.tasks.isFetching.updateTask);
  const timer = React.useRef();
  const [showScheduler, setShowScheduler] = React.useState(false);
  const [schedulerLoading, setSchedulerLoading] = React.useState(true);
  const [view, setView] = React.useState(familySlug ? 'Week' : 'Month');
  const [updatedTaskId, setUpdatedTaskId] = React.useState();
  const [editTaskConfirmDialog, setEditTaskConfirmDialog] = React.useState(false);
  const [movedReccurrenceAction, setMovedReccurrenceAction] = React.useState(null);
  const [oldMonthsLoaded, setOldMonthsLoaded] = React.useState(-2);
  const isFetchingTwigs = useSelector(state => state.tasks.isFetching.getTwigs);
  const task = useSelector(state => state.tasks.task);
  const [openFamilyTwigDialog, setOpenFamilyTwigDialog] = React.useState(null);
  const familyTaskFetch =
    urlParams.eventDate &&
    urlParams.taskSlug &&
    task &&
    task.slug === urlParams.taskSlug &&
    new Date(task?.twig_for_date?.event_date)?.toISOString() ===
      new Date(urlParams.eventDate)?.toISOString();

  React.useEffect(() => {
    if (urlParams.eventDate && urlParams.taskSlug) {
      dispatch(
        TasksActions.getTaskRequest(
          null,
          null,
          familySlug,
          urlParams.taskSlug,
          urlParams.eventDate,
        ),
      );
    }
  }, []);

  React.useEffect(() => {
    if (familyTaskFetch) {
      const dialogInfo = {
        twig: { ...task.twig_for_date, task: task },
      };
      setOpenFamilyTwigDialog(dialogInfo);
    }
  }, [urlParams.eventDate, urlParams.taskSlug, task]);

  React.useEffect(() => {
    timer.current = setTimeout(() => {
      setShowScheduler(true);
    }, 100);
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  React.useEffect(() => {
    // load older twigs if needed
    if (isFetchingTwigs) return;

    const now = new Date();
    const current = new Date(currentDate);

    const diff =
      current.getMonth() - now.getMonth() + 12 * (current.getFullYear() - now.getFullYear());

    if (diff <= oldMonthsLoaded) {
      setOldMonthsLoaded(diff - 3);
      dispatch(
        TasksActions.getTwigsRequest(
          holdingSlug,
          organisationSlug,
          familySlug,
          moment(now).subtract(-oldMonthsLoaded, 'months').toISOString(),
          moment(current).subtract(3, 'months').toISOString(),
          false,
        ),
      );
    }
  }, [currentDate]);

  const handleCloseFamilyTwigDialog = () => {
    setOpenFamilyTwigDialog(null);
    history.push(match.url);
  };

  const checkAllowDrag = ({ twig }) => {
    if (isFullScreen) return false;
    if (new Date(twig.event_date) < Date.now()) return false;

    const checkRoleCanEdit = CheckRoleRule(role, 'tasks:editdelete', {
      userId: currentUser?.id,
      taskOwnerId: twig?.task?.helper_id,
      taskOrgaKey: getOrgaKey(twig.task?.holding_slug, twig.task?.organisation_slug),
      pageOrgaKey: getOrgaKey(familySlug ? null : holdingSlug, familySlug || organisationSlug),
    });
    return checkRoleCanEdit;
  };

  const handleEditTask = () => {
    if (editTaskConfirmDialog) {
      if (editTaskConfirmDialog.updateAllTask) {
        dispatch(
          TasksActions.updateTaskRequest(
            holdingSlug,
            organisationSlug,
            familySlug,
            editTaskConfirmDialog.taskData,
            null,
          ),
        );
      } else {
        dispatch(
          TasksActions.detachTaskOccurrenceRequest(
            holdingSlug,
            organisationSlug,
            familySlug,
            editTaskConfirmDialog.oldTask.slug,
            editTaskConfirmDialog.elementToUpdate?.startDate,
            editTaskConfirmDialog.taskData,
            null,
          ),
        );
      }
      setEditTaskConfirmDialog(null);
    }
  };

  const handleMoveTask = (changed, updateAllTask) => {
    const elementToUpdate = data.find(element => changed[element.id]);
    const dropDate = changed[elementToUpdate.id].startDate;

    const { task: oldTask } = elementToUpdate.twig;
    const task = updateAllTask
      ? oldTask
      : {
          ...oldTask,
          slug: null,
          id: null,
          start_date: elementToUpdate?.startDate,
          end_date: elementToUpdate?.endDate,
          recurrence_type: 'NONE',
        };

    const [newStartDate, newUntilDate] = getOffsetDates(
      elementToUpdate?.startDate,
      new Date(dropDate),
      task,
    );

    if (view === 'Week') {
      // keep drop date hour
      newStartDate.setHours(dropDate.getHours());
      newStartDate.setMinutes(dropDate.getMinutes());
    }

    const taskData = Object.assign(
      {
        slug: task.slug,
        id: task.id,
        excerpt: task.excerpt,
        description: task.description,
        expertise_id: task.expertise.id,
        related_words: task.related_words,
        start_date: newStartDate,
        end_date: getTwigEndDate({
          task: { start_date: elementToUpdate?.startDate, end_date: elementToUpdate?.endDate },
          event_date: newStartDate,
        }),
        start_date_fixed: true,
        formatted_address: task.formatted_address,
        latitude: task.coordinates?.latitude,
        longitude: task.coordinates?.longitude,
        recurrence_type: task.recurrence_type,
        max_participants: task.max_participants,
        external_link: task.external_link,
        holding_slug: task.holding_slug,
        organisation_slug: task.organisation_slug,
        registrable_roles: task.registrable_roles,
        registration: task.registration,
        locality: task.locality,
      },
      task.recurrence_type !== 'NONE'
        ? { recurrence_interval: task.recurrence_interval, until_date: newUntilDate }
        : {},
    );
    if (task.id) setUpdatedTaskId(task.id);

    // Check if task has helpers registered
    if (
      (updateAllTask &&
        (elementToUpdate.twig.task?.has_twigs_registered ||
          elementToUpdate.twig.helpers?.length > 0)) ||
      (!updateAllTask && elementToUpdate.twig.helpers?.length > 0)
    ) {
      setEditTaskConfirmDialog({ taskData, oldTask, updateAllTask, elementToUpdate });
      return;
    }
    if (updateAllTask) {
      dispatch(
        TasksActions.updateTaskRequest(holdingSlug, organisationSlug, familySlug, taskData, null),
      );
    } else {
      dispatch(
        TasksActions.detachTaskOccurrenceRequest(
          holdingSlug,
          organisationSlug,
          familySlug,
          oldTask.slug,
          elementToUpdate?.startDate,
          taskData,
          null,
        ),
      );
    }
  };

  const handleDragDrop = ({ changed }) => {
    const elementToUpdate = data.find(element => changed[element.id]);
    const dropDate = changed[elementToUpdate.id].startDate;

    const { task } = elementToUpdate.twig;

    const isSameDate =
      (view === 'Month' &&
        moment(elementToUpdate?.startDate).format('L') === moment(dropDate).format('L')) ||
      (view === 'Week' &&
        moment(elementToUpdate?.startDate).format('L') === moment(dropDate).format('L') &&
        moment(elementToUpdate?.startDate).format('LT') === moment(dropDate).format('LT'));

    const isOlderThanSixMonths = moment(dropDate).toISOString() > getFutureDate();

    const isInPast = moment(dropDate).toISOString() < moment(Date.now()).toISOString();

    if (isSameDate || !elementToUpdate || !elementToUpdate.twig || isOlderThanSixMonths || isInPast)
      return;

    if (task.recurrence_type !== 'NONE') {
      setMovedReccurrenceAction(changed);
      return;
    }

    handleMoveTask(changed, true);
  };

  const handleRecurrenceDialogConfirm = actionChoice => {
    if (actionChoice === 'occurrence') handleMoveTask(movedReccurrenceAction, false);
    else if (actionChoice === 'all') handleMoveTask(movedReccurrenceAction, true);
    setMovedReccurrenceAction(null);
  };

  const TimeTableCell = (props: TimeTableProps) => {
    const { startDate } = props;
    const date = new Date(startDate);
    const today = new Date();
    const sixMonthsDate = new Date(getFutureDate());
    const checkRoleCanCreate = CheckRoleRule(role, 'tasks:create');

    if (date < today || date > sixMonthsDate) {
      return (
        <TwigTimeTableCell
          {...props}
          view={view}
          className={isFullScreen ? classes.cell : classes.pastCell}
          style={isFullScreen && view === 'Month' ? { height: 150 } : {}}
          isFullScreen={isFullScreen}
          canCreateTask={checkRoleCanCreate}
        />
      );
    }
    return (
      <TwigTimeTableCell
        {...props}
        className={classes.cell}
        view={view}
        isFullScreen={isFullScreen}
        style={isFullScreen && view === 'Month' ? { height: 150 } : {}}
        canCreateTask={checkRoleCanCreate}
      />
    );
  };

  const DayScaleCell = props => <MonthView.DayScaleCell {...props} className={classes.dayCell} />;
  return (
    <Paper elevation={isFullScreen ? 0 : 1}>
      {schedulerLoading && (
        <>
          <AppBar position="static" color="default" style={{ borderRadius: '4px' }}>
            <Bar>
              <Button variant="outlined" style={{ padding: '6.4px 16px' }}>
                {t('TODAY')}
              </Button>
            </Bar>
          </AppBar>
          <Card elevation={2} style={{ borderRadius: '0' }}>
            <Box height="500px" style={{ backgroundColor: '#f5f5f5' }} />
          </Card>
        </>
      )}
      {showScheduler && (
        <div ref={() => setSchedulerLoading(false)}>
          <Scheduler data={data} locale="fr-FR" firstDayOfWeek={1}>
            <ViewState
              defaultCurrentViewName={view}
              ViewState={view}
              onCurrentViewNameChange={value => setView(value)}
              currentDate={currentDate}
              onCurrentDateChange={setCurrentDate}
            />
            <EditingState onCommitChanges={handleDragDrop} />
            <IntegratedEditing />
            <WeekView
              startDayHour={6}
              endDayHour={24}
              cellDuration={60}
              displayName="Semaine"
              timeTableCellComponent={TimeTableCell}
            />
            <MonthView
              timeTableCellComponent={TimeTableCell}
              dayScaleCellComponent={DayScaleCell}
              displayName="Mois"
            />
            {!isFullScreen && <Toolbar />}
            {!isFullScreen && <DateNavigator />}
            {!isFullScreen && (
              <TodayButton
                messages={{
                  today: isMobile ? <TodayIcon className={classes.todayIcon} /> : t('TODAY'),
                }}
              />
            )}
            {!isFullScreen && <ViewSwitcher />}
            <Appointments
              appointmentComponent={props =>
                isFullScreen
                  ? TwigAppointmentFullScreen({ ...props, view, datas: data })
                  : TwigAppointment({ ...props, updatedTaskId, isUpdating, currentUser })
              }
            />
            <AppointmentTooltip
              layoutComponent={props =>
                familySlug
                  ? ShowFamilyTwigDialog({ ...props, data, role })
                  : TwigAppointmentTooltip(props)
              }
            />
            <DragDropProvider
              sourceAppointmentComponent={props => TwigAppointment({ ...props, isShaded: true })}
              draftAppointmentComponent={props => TwigAppointment({ ...props, isShaded: true })}
              finishCommitAppointment={() => null}
              allowDrag={checkAllowDrag}
              allowResize={() => false}
            />
          </Scheduler>
        </div>
      )}
      <ConfirmationDialog
        openDialog={Boolean(editTaskConfirmDialog)}
        onCancel={() => setEditTaskConfirmDialog(null)}
        onClickConfirm={handleEditTask}
        dialogTitle={t('APP.ACTIVITY.CONFIRM.TITLE_DATE')}
        dialogContent={t('APP.ACTIVITY.CONFIRM.CONTENT')}
      />
      <ReccurrenceActionDialog
        open={movedReccurrenceAction}
        title={t('APP.ACTIVITY.MENU.WHAT_TO_MOVE')}
        onClose={() => setMovedReccurrenceAction(null)}
        onConfirm={handleRecurrenceDialogConfirm}
      />
      {isFullScreen && <EventsList data={data} currentDate={currentDate} />}
      <Dialog open={openFamilyTwigDialog} onClose={handleCloseFamilyTwigDialog}>
        <ShowFamilyTwigDialog
          visible={openFamilyTwigDialog}
          onHide={handleCloseFamilyTwigDialog}
          role={role}
          twig={openFamilyTwigDialog?.twig}
        />
      </Dialog>
    </Paper>
  );
};

export default OrganisationScheduler;
