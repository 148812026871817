import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ForumIcon from '@material-ui/icons/Forum';

import CareNotebookActions from '../../../Redux/CareNotebooksRedux';
import AuthActions from '../../../Redux/AuthRedux';
import { urlifyText } from '../../../Services/DataHelper';
import Timeline from '../../Block/Timeline';
import { getFamilyName } from '../../../Services/FamilyHelper';
import { CheckRoleRule } from '../../../Acl/Rules';
import { getRole } from '../../../Acl/Controls';
import {
  getLastReadDate,
  getSubscription,
  SubscribleTypes,
  SubscribleSubTypes,
} from '../../../Services/SubscriptionHelper';
import { useLocation } from 'react-router';

type Props = {
  organisation: Object,
  family: Object,
  onEditPost: Function,
  onDeletePost: Function,
  onCreateComment: Function,
  onDeleteComment: Function,
};

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: '800',
  },
}));

const CarenoteBookTimeline = ({
  organisation,
  family,
  onEditPost,
  onDeletePost,
  onCreateComment,
  onDeleteComment,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    postId: queryParam.get('postId') || '',
  };

  const currentUser = useSelector(state => state.auth.currentUser);
  const existingSub = getSubscription(
    family,
    SubscribleTypes.FAMILY,
    SubscribleSubTypes.POSTS,
    family.id,
  );

  const posts = useSelector(state =>
    state.careNotebooks.postsByFamily[family.slug]
      ? state.careNotebooks.postsByFamily[family.slug]?.posts
      : null,
  );
  const totalposts = useSelector(state =>
    state.careNotebooks.postsByFamily[family.slug]
      ? state.careNotebooks.postsByFamily[family.slug]?.totalposts
      : 0,
  );
  const isFetchingPosts = useSelector(state => state.careNotebooks.isFetching.getCareNotebookPosts);
  const isFetchingNextPosts = useSelector(
    state => state.careNotebooks.isFetching.getCareNotebookNextPosts,
  );

  const isCreatingPost = useSelector(
    state => state.careNotebooks.isFetching.createCareNotebookPost,
  );
  const isCreatingComment = useSelector(
    state => state.careNotebooks.isFetching.createCareNotebookPostComment,
  );

  const carenotebookPost = useSelector(state => state.careNotebooks.careNotebookPost);
  const postFetch =
    urlParams.postId && carenotebookPost && `${carenotebookPost.id}` === `${urlParams.postId}`;
  const [openCardPostDialog, setOpenCardPostDialog] = React.useState(null);

  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    if (urlParams.postId) {
      dispatch(CareNotebookActions.getCareNotebookPostRequest(family.slug, urlParams.postId));
    }
  }, []);

  React.useEffect(() => {
    if (postFetch) {
      setOpenCardPostDialog(carenotebookPost);
    } else if (!urlParams.postId || !carenotebookPost) setOpenCardPostDialog(null);
  }, [urlParams.postId, carenotebookPost]);

  React.useEffect(() => {
    dispatch(CareNotebookActions.getCareNotebookPostsRequest(family.slug, 1));
    setPage(1);
    // eslint-disable-next-line
  }, [dispatch, organisation?.id, family?.id]);

  const onNextPageClick = () => {
    dispatch(CareNotebookActions.getCareNotebookPostsRequest(family.slug, page + 1));
    setPage(page + 1);
  };

  const lastReadDate = React.useMemo(() => {
    return getLastReadDate(
      organisation || family,
      SubscribleTypes.FAMILY,
      SubscribleSubTypes.POSTS,
      family.id,
    );
  }, [family?.slug]);

  const onCreateSubscription = () => {
    const date = new Date();
    dispatch(
      AuthActions.createHelperSubscriptionRequest(currentUser.id, {
        subscrible_type: SubscribleTypes.FAMILY,
        subscrible_id: family.id,
        organisation_id: (organisation || family).id,
        helper_id: currentUser.id,
        importance: 0,
        last_read_date: date.toJSON(),
        sub_type: SubscribleSubTypes.POSTS,
        subscrible: family,
      }),
    );
  };

  const onDeleteSubscription = () => {
    if (existingSub)
      dispatch(AuthActions.deleteHelperSubscriptionRequest(currentUser.id, existingSub.id));
  };

  const onCreatePost = (post, attachments) => {
    const p = {
      ...post,
      raw: urlifyText(post.raw),
      restrictions: post.restrictions,
    };
    let newSub = null;
    if (!existingSub) {
      const date = new Date();
      newSub = {
        subscrible_type: SubscribleTypes.FAMILY,
        subscrible_id: family.id,
        organisation_id: (organisation || family).id,
        helper_id: currentUser.id,
        importance: 0,
        last_read_date: date.toJSON(),
        sub_type: SubscribleSubTypes.POSTS,
        subscrible: family,
      };
    }
    dispatch(
      CareNotebookActions.createCareNotebookPostRequest(
        family.slug,
        family.id,
        p,
        attachments,
        newSub,
      ),
    );
  };

  return (
    <>
      <Timeline
        posts={posts}
        notifs={
          currentUser?.family_id === family?.id
            ? null
            : {
                notifsOn: existingSub,
                onDeleteSubscription,
                onCreateSubscription,
                notifsCreationTooltip: t('APP.SUBSCRIPTIONS.CARENOTEBOOK.CREATE'),
                notifsDeletionTooltip: t('APP.SUBSCRIPTIONS.CARENOTEBOOK.DELETE'),
              }
        }
        lastReadDate={lastReadDate}
        holdingSlug={organisation?.holding_slug}
        organisationSlug={organisation?.slug}
        currentUser={currentUser}
        header={{
          title: t(`APP.CARENOTEBOOKS.SHOW.CREATE_POST`),
          subtitle: t(`APP.CARENOTEBOOKS.SHOW.CREATE_POST.SUBTITLE`, {
            value: getFamilyName(family),
          }),
          avatar: <ForumIcon />,
        }}
        canCreatePost
        canReadPost={() => true}
        canEditDeletePost={(hSlug, olug, postOwnerId) =>
          CheckRoleRule(
            getRole(currentUser, organisation?.holding_slug, organisation?.slug, family?.slug),
            'carenotebooks-posts:editdelete',
            {
              userId: currentUser?.id,
              postOwnerId,
            },
          )
        }
        canDeleteComment={(hSlug, olug, commentOwnerId) =>
          CheckRoleRule(
            getRole(currentUser, organisation?.holding_slug, organisation?.slug, family?.slug),
            'carenotebooks-posts-comments:delete',
            {
              userId: currentUser?.id,
              commentOwnerId,
            },
          )
        }
        noPostCard={
          <Card>
            <CardHeader
              title={
                <Typography variant="h6" className={classes.bold}>
                  {t('APP.CARENOTEBOOKS.SHOW.EMPTY.TITLE')}
                </Typography>
              }
            />
          </Card>
        }
        onCreatePost={onCreatePost}
        isCreatingPost={isCreatingPost}
        postPostableType="Organisation"
        commentPostableType="Post"
        isFetching={isFetchingPosts}
        onEditPost={onEditPost}
        onDeletePost={onDeletePost}
        totalPosts={totalposts}
        isFetchingNext={isFetchingNextPosts}
        onFetchNext={onNextPageClick}
        creatingComment={isCreatingComment}
        onCreateComment={onCreateComment}
        onDeleteComment={onDeleteComment}
        showPostType
        withExpertises
        family={family}
        openCardPostDialog={openCardPostDialog}
        setOpenCardPostDialog={setOpenCardPostDialog}
      />
    </>
  );
};

export default CarenoteBookTimeline;
