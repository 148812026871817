import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HelpIcon from '@mui/icons-material/Help';
import { red, orange } from '@material-ui/core/colors';

import { getFamilyAvatar } from '../../../Services/FamilyHelper';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import ConfirmationDialog from '../../Shared/ConfirmationDialog';
import InviteMemberByEmail from '../Membership/InviteMemberByEmail';
// import MembershipLogin from '../Membership/MembershipLogin';
import { CheckRoleRule } from '../../../Acl/Rules';
import ResetMemberPin from '../Membership/ResetMemberPin';
import SelectOrganisationDialog from '../../Shared/SelectOrganisationDialog';
import LeadsActions from '../../../Redux/LeadsRedux';
import UserActions from '../../../Redux/UsersRedux';
import LeaguesActions from '../../../Redux/LeaguesRedux';
import getLeagueOrganisationsWithoutNoStruct from '../../../Services/LeagueHelper';
import Routes from '../../../Routing/Routes';
import { getCapitalizedName } from '../../../Services/DataHelper';
import HelperContactDetails from '../../Shared/HelperContactDetails';

type Props = {
  primary: Object,
  organisation: Object,
};

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2),
  },
  avatarHeader: {
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  list: {
    paddingTop: '0',
    paddingBottom: '0',
    paddingLeft: '0',
  },
  listItem: {
    marginRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    minWidth: '40px',
    overflowWrap: 'break-word',
  },
  buttonContainer: {
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
  },
  redColor: {
    color: red[600],
  },
  orangeColor: {
    color: orange[600],
    marginLeft: 8,
  },
  flex: {
    '&>*': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}));

function CardPrimaryResume({ primary, organisation }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug, organisation_slug: organisationSlug } = match.params;
  const league = useSelector(state => state.leagues.league);
  const usersOrganisations = useSelector(state => state.users.usersOrganisations);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showInviteByEmail, setShowInviteByEmail] = React.useState(false);
  const [openRemoveMemberDialog, setOpenRemoveMemberDialog] = React.useState(false);
  const [showResetPin, setShowResetPin] = React.useState(false);
  const [openSelectOrgaDialog, setOpenSelectOrgaDialog] = React.useState(false);
  // const password = React.useRef();
  // const updatedMembership = useSelector(state => state.organisationMembership.updateMembership);
  // const showMembershipLogin = Boolean(
  //   updatedMembership && !updatedMembership?.helper?.email && password.current,
  // );
  const userOrganisationsIds = usersOrganisations
    ? usersOrganisations[primary?.helper?.id]?.map(orga => orga.id)
    : null;

  const leagueOrganisations = getLeagueOrganisationsWithoutNoStruct(league);

  const onClickValidateMember = () => {
    const updateAttributes = {
      id: primary.id,
      role: primary.role,
      validated: true,
    };
    dispatch(
      OrganisationMembershipActions.updateOrganisationMembershipRequest(
        holdingSlug,
        organisationSlug,
        primary.helper.id,
        updateAttributes,
      ),
    );
    setAnchorEl(null);
  };

  // const onClickResetPassword = () => {
  //   password.current = Math.floor(1000 + Math.random() * 9000).toString();
  //   const updateAttributes = {
  //     id: primary.id,
  //     password: password.current,
  //   };
  //   dispatch(
  //     OrganisationMembershipActions.updateOrganisationMembershipRequest(
  //       holdingSlug,
  //       organisationSlug,
  //       primary.helper.id,
  //       updateAttributes,
  //     ),
  //   );
  //   setAnchorEl(null);
  // };

  // const onClose = () => {
  //   dispatch(OrganisationMembershipActions.resetNewMembership());
  // };

  const onResetPin = () => {
    setShowResetPin(true);
    setAnchorEl(null);
  };

  const onClickInviteByEmail = () => {
    if (!primary?.helper?.email) {
      setShowInviteByEmail(true);
    } else {
      dispatch(
        OrganisationMembershipActions.resendInvitationRequest(
          primary.holding_slug,
          primary.organisation_slug,
          primary.helper_id,
        ),
      );
    }
    setAnchorEl(null);
  };

  const onCancelRemoveMember = () => {
    setOpenRemoveMemberDialog(false);
    setAnchorEl(null);
  };

  const onConfirmRemoveMember = () => {
    dispatch(
      OrganisationMembershipActions.destroyOrganisationMembershipRequest(
        holdingSlug,
        organisationSlug,
        primary.helper.id,
        primary.id,
      ),
    );
    setOpenRemoveMemberDialog(false);
    setAnchorEl(null);
    history.push(`/${holdingSlug}/${organisationSlug}/primaries`);
  };

  const handleClickShareLead = () => {
    setAnchorEl(null);
    setOpenSelectOrgaDialog(true);
    if (!leagueOrganisations || leagueOrganisations?.length <= 1) {
      dispatch(LeaguesActions.getLeagueRequest(Routes.currentLeague().id, true, false));
    }
    if (primary) {
      dispatch(UserActions.getUserOrganisationsRequest(primary?.helper.id));
    }
  };

  const handleConfirmShareLead = (selectedOrga, description) => {
    if (
      !selectedOrga ||
      !primary?.helper ||
      userOrganisationsIds.includes(selectedOrga.id) ||
      !leagueOrganisations.find(o => o.id === selectedOrga.id)
    )
      return;

    const leadToCreate = {
      organisation_id: organisation.id,
      ask_for: 'me',
      description,
      phone: primary?.helper?.phone,
      country_code: primary?.helper?.countryCode,
      email: primary?.helper?.email,
      gender: primary?.helper?.gender,
      helper_id: primary?.helper?.id,
      helper_first_name: primary?.helper?.first_name,
      helper_last_name: primary?.helper?.last_name,
      helper_pathology: primary?.helper?.pathology,
      sharer_id: organisation.id,
    };

    dispatch(
      LeadsActions.createSharedLeadRequest(
        selectedOrga.holding_slug,
        selectedOrga.slug,
        leadToCreate,
        primary?.id,
      ),
    );
    setOpenSelectOrgaDialog(false);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          className={classes.avatarHeader}
          avatar={getFamilyAvatar({ admin: primary?.helper })}
          title={
            <Typography variant="h4" component="p" style={{ textTransform: 'capitalize' }}>
              {getCapitalizedName(primary?.helper.first_name, primary?.helper.last_name)}
            </Typography>
          }
          subheader={
            !primary?.validated && (
              <Typography variant="subtitle1" component="p" className={classes.redColor}>
                {t('APP.ORGANISATION.SETTINGS.MEMBERS.MEMBER_TO_VALIDATE')}
              </Typography>
            )
          }
          action={
            <>
              {CheckRoleRule(organisation?.role, 'organisation:configure', { organisation }) && (
                <>
                  <IconButton
                    onClick={event => setAnchorEl(event.currentTarget)}
                    aria-label="More"
                    aria-owns={open ? 'actions-menu' : undefined}
                    aria-haspopup="true"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                  >
                    <MenuItem disabled={primary?.validated} onClick={() => onClickValidateMember()}>
                      <ListItemText className={classes.flex}>
                        {t('APP.ORGANISATION.SETTINGS.MEMBERS.VALIDATE')}
                        {!primary?.validated && (
                          <Tooltip
                            title={t('APP.ORGANISATION.SETTINGS.MEMBERS.VALIDATE.TOOLTIP')}
                            placement="top"
                          >
                            <HelpIcon className={classes.orangeColor} />
                          </Tooltip>
                        )}
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClickShareLead}>
                      <ListItemText>
                        {t('APP.ORGANISATION.SETTINGS.MEMBERS.SHARE_PRIMARY')}
                      </ListItemText>
                    </MenuItem>
                    {/* <MenuItem
                      disabled={primary?.helper?.email}
                      onClick={() => onClickResetPassword()}
                    >
                      <ListItemText>
                        {t('APP.ORGANISATION.SETTINGS.MEMBERS.RESET_PASSWORD')}
                      </ListItemText>
                    </MenuItem> */}
                    {CheckRoleRule(organisation.role, 'pin:update') && (
                      <MenuItem onClick={onResetPin}>
                        <ListItemText>
                          {t('APP.ORGANISATION.SETTINGS.MEMBERS.RESET_PIN')}
                        </ListItemText>
                      </MenuItem>
                    )}
                    <MenuItem
                      disabled={Boolean(
                        primary?.helper?.email &&
                          primary?.helper?.validation_status === 'email_validated',
                      )}
                      onClick={onClickInviteByEmail}
                    >
                      <ListItemText>
                        {t(
                          `APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.${
                            primary?.helper?.validation_status === 'not_invited'
                              ? 'EMAIL'
                              : 'EMAIL_AGAIN'
                          }`,
                        )}
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => setOpenRemoveMemberDialog(true)}>
                      <ListItemText>
                        {t('APP.ORGANISATION.SETTINGS.MEMBERS.REMOVE.TITLE')}
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </>
          }
        />
        <HelperContactDetails helper={primary?.helper} showCopyEmail />
        {primary?.validated && (
          <CardActions className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => history.push(`/families/${primary?.helper?.family_slug}`)}
            >
              {t('FAMILY_HOME')}
            </Button>
          </CardActions>
        )}
      </Card>
      {/* <Dialog open={showMembershipLogin}>
        <MembershipLogin
          password={password.current}
          updatedMembership={updatedMembership}
          onClose={() => onClose()}
        />
      </Dialog> */}
      <Dialog open={showResetPin} onClose={() => setShowResetPin(false)}>
        <ResetMemberPin memberToResetPin={primary} onCloseDialog={() => setShowResetPin(false)} />
      </Dialog>
      <Dialog open={showInviteByEmail} onClose={() => setShowInviteByEmail(false)}>
        <InviteMemberByEmail
          memberToAddEmail={primary}
          onCloseDialog={() => setShowInviteByEmail(false)}
        />
      </Dialog>
      <ConfirmationDialog
        openDialog={openRemoveMemberDialog}
        onCancel={() => onCancelRemoveMember()}
        onClickConfirm={onConfirmRemoveMember}
        dialogTitle={t('APP.ORGANISATION.SETTINGS.MEMBERS.REMOVE.TITLE')}
        dialogContent={t('APP.ORGANISATION.SETTINGS.MEMBERS.REMOVE.CONTENT')}
      />
      <SelectOrganisationDialog
        open={openSelectOrgaDialog}
        dialogTitle={t(
          `APP.${league?.slug?.toUpperCase()}.LEAGUES.SETTINGS.SELECT_ORGANISATION`,
          t(`APP.LEAGUES.SETTINGS.SELECT_ORGANISATION`),
        )}
        dialogButtonLabel={t('APP.ORGANISATION.SETTINGS.MEMBERS.SHARE_PRIMARY.LABEL')}
        onConfirm={handleConfirmShareLead}
        onCancel={() => setOpenSelectOrgaDialog(false)}
        organisations={leagueOrganisations
          ?.sort((a, b) => a.holding_name.localeCompare(b.holding_name))
          ?.slice()}
        disabledOrganisationsIds={userOrganisationsIds}
        textFieldLabel={t('APP.ORGANISATION.SETTINGS.MEMBERS.SHARE_PRIMARY.TEXT_FIELD')}
        needConsent
      />
    </>
  );
}

export default CardPrimaryResume;
