import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconPhone from '@material-ui/icons/Phone';
import IconCake from '@material-ui/icons/Cake';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import PinDropRounded from '@material-ui/icons/PinDropRounded';
import EmailIcon from '@material-ui/icons/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { getFormattedPhone } from '../../Services/DataHelper';
import { IconButton, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  list: {
    padding: 0,
  },
  listItem: {
    overflowWrap: 'break-word',
  },
  listItemIcon: {
    minWidth: 40,
  },
}));

type Props = {
  helper: Object,
  showCopyEmail: Boolean,
};

const HelperContactDetails = ({ helper, showCopyEmail }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {helper?.phone && (
        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItemIcon}>
            <IconPhone fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.listItem}>
            <Typography variant="body2" component="p">
              {getFormattedPhone(helper?.phone)}
            </Typography>
          </ListItemText>
        </ListItem>
      )}
      {helper?.email && (
        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItemIcon}>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.listItem}>
            <Typography variant="body2" component="p">
              {helper?.email}
            </Typography>
          </ListItemText>
          {showCopyEmail && (
            <Tooltip title={t('COPY')}>
              <IconButton
                onClick={() => navigator.clipboard.writeText(helper?.email)}
                aria-label="copy-email"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </ListItem>
      )}
      {helper?.formatted_address && helper?.formatted_address !== 'NA' && (
        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItemIcon}>
            <PinDropRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.listItem}>
            <Typography variant="body2" component="p">
              {helper?.formatted_address}
            </Typography>
            {helper?.address_supplement && (
              <Typography variant="body2" component="p">
                {helper?.address_supplement}
              </Typography>
            )}
          </ListItemText>
        </ListItem>
      )}
      {helper?.birthday && (
        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItemIcon}>
            <IconCake fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.listItem}>
            <Typography variant="body2" component="p">
              {moment().diff(helper?.birthday, 'years')} {t('YEARS_OLD')} (
              {moment(helper?.birthday).format('L')})
            </Typography>
          </ListItemText>
        </ListItem>
      )}
      {helper?.pathology && (
        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItemIcon}>
            <FilterVintageIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.listItem}>
            <Typography variant="body2" component="p">
              {helper?.pathology}
            </Typography>
          </ListItemText>
        </ListItem>
      )}
    </>
  );
};

export default HelperContactDetails;
