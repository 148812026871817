import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PeopleIcon from '@mui/icons-material/People';
import Chip from '@material-ui/core/Chip';

import WelloAvatar from '../../Shared/WelloAvatar';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';
import { canEditHolding, isCommunity, isCommunityMember } from '../../../Acl/Controls';
import HoldingEdit from '../../../Pages/Holding/Settings/HoldingEdit';

type Props = {
  organisation: Object,
  currentUser: Object,
};

const useStyles = makeStyles(theme => ({
  holdingCard: {
    backgroundColor: theme.palette.grey[700],
    borderRadius: 0,
  },
  header: {
    ...LayoutStyles.fullWidthLayout,
    paddingLeft: 0,
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      margin: 0,
    },
  },
  bold: {
    fontWeight: '700',
    color: 'white',
  },
  headerTitle: {
    textTransform: 'none',
    marginRight: theme.spacing(2),
  },
  subHeader: {
    color: 'white',
  },
  avatar: {
    margin: theme.spacing(0, 1),
    width: 60,
    height: 60,
    [theme.breakpoints.only('xs')]: {
      width: 40,
      height: 40,
      margin: 0,
    },
  },
  info: {
    fontSize: '0.8rem',
    paddingLeft: theme.spacing(1),
    color: 'white',
  },
  infoContainer: {
    color: 'white',
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  flex: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: 'white',
  },
  communityIcon: {
    marginRight: theme.spacing(1),
    color: 'white',
  },
  iconContainer: {
    alignItems: 'center',
  },
  communityText: {
    color: 'white',
    marginRight: theme.spacing(2),
  },
  chip: {
    backgroundColor: 'white',
    color: theme.current.primary.main,
    textTransform: 'none',
    height: 20,
    margin: theme.spacing(0.5, 0),
  },
}));

const HoldingBanner = ({ organisation, currentUser }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showEditHolding, setShowEditHolding] = React.useState(false);
  const isCommu = isCommunity(organisation);

  return (
    <>
      <Card className={classes.holdingCard}>
        <Grid className={classes.header}>
          <CardHeader
            style={isCommu ? { padding: '24px 16px' } : { alignItems: 'flex-start' }}
            title={
              <Grid className={classes.flex} style={isCommu ? { flexWrap: 'wrap' } : {}}>
                <Grid className={classes.headerTitle}>
                  <Typography variant="h3" component="h1" className={classes.bold}>
                    {organisation?.holding?.name}
                  </Typography>
                  {organisation?.holding?.establishment && (
                    <Typography variant="overline" component="h2" className={classes.subHeader}>
                      {organisation?.holding?.establishment.short_name}
                    </Typography>
                  )}
                </Grid>
                {canEditHolding(organisation, currentUser) && !isCommu && (
                  <IconButton
                    size="small"
                    className={classes.icon}
                    title={t('APP.ORGANISATION.HOME.EDIT_HOLDING')}
                    onClick={() => setShowEditHolding(true)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {isCommu && isCommunityMember(organisation.role) && (
                  <Chip
                    className={classes.chip}
                    label={
                      organisation?.validated
                        ? t('APP.COMMUNITIES.IS_MEMBER')
                        : t('APP.USER.ORGANISATIONS.WAITING')
                    }
                    size="small"
                  />
                )}
              </Grid>
            }
            subheader={
              <>
                <Typography variant="body2" component="h2" className={classes.subHeader}>
                  {organisation?.holding?.short_description?.replace(/<[^>]*>/g, '')}
                </Typography>
                {isCommu && (
                  <Grid container className={classes.iconContainer}>
                    {organisation?.helper_count > 0 && (
                      <>
                        <PeopleIcon className={classes.communityIcon} />
                        <Typography variant="body2" className={classes.communityText}>
                          {t('APP.ORGANISATION.HOME.LABEL.Organisations.PRO', {
                            context: organisation?.helper_count > 1 && 'MANY',
                            pro_count: organisation?.helper_count,
                          })}
                        </Typography>
                      </>
                    )}
                    <VisibilityIcon className={classes.communityIcon} />
                    <Typography variant="body2" className={classes.communityText}>
                      {t(`APP.COMMUNITIES.VISIBILITY.${organisation?.visibility}`)}
                    </Typography>
                  </Grid>
                )}
              </>
            }
            avatar={
              organisation?.admin_id &&
              !isCommu && (
                <WelloAvatar
                  avatarUrl={organisation?.holding?.cover_url}
                  avatarClass={classes.avatar}
                  size={60}
                />
              )
            }
          />
        </Grid>
      </Card>
      <Dialog open={showEditHolding} onClose={() => setShowEditHolding(false)}>
        <HoldingEdit holding={organisation.holding} onClose={() => setShowEditHolding(false)} />
      </Dialog>
    </>
  );
};

export default HoldingBanner;
