import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import IconButton from '@material-ui/core/IconButton';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import { green } from '@material-ui/core/colors';
import EmptyList from '../../../Components/Shared/EmptyList';
import LeadsActions from '../../../Redux/LeadsRedux';
import GridLeads from '../../../Components/Organisations/Shared/GridLeads';
import { RoleCheck } from '../../../Components/Shared/Can';
import { CheckRoleRule } from '../../../Acl/Rules';
import NotificationsSettings from '../../../Components/Organisations/Settings/NotificationsSettings';
import { getSubscription, SubscribleSubTypes } from '../../../Services/SubscriptionHelper';
import AuthActions from '../../../Redux/AuthRedux';
import { CHANNEL_LEAD, subscribeChannel, unsubscribeChannel } from '../../../Services/WebSocket';

type Props = {
  organisation: Object,
};

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  grow: {
    fontWeight: '800',
    textTransform: 'none',
    flexGrow: 1,
  },
  cell: {
    padding: '14px 4px 14px 6px',
  },
  progress: {
    marginTop: theme.spacing(-1),
  },
  chip: {
    marginLeft: '0px',
    marginRight: '3px',
    marginBottom: '3px',
    height: '20px',
    color: 'white',
  },
  checkIcon: {
    color: green[600],
  },
  crossIcon: {
    color: 'red',
  },
  notifs: {
    marginRight: theme.spacing(2),
  },
}));

const Leads = ({ organisation }: Props) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { t } = useTranslation();

  const currentUser = useSelector(state => state.auth.currentUser);
  const errors = useSelector(state => state.leads.errors.getOrganisationLeads);
  const leads = useSelector(state => state.leads.leads);
  const isFetching = useSelector(state => state.leads.isFetching.getOrganisationLeads);
  const totalLeads = useSelector(state => state.leads.totalleads);

  const dispatch = useDispatch();
  const updateLead = (orga, lead) =>
    dispatch(LeadsActions.updateOrganisationLeadRequest(orga.holding_slug, orga.slug, lead));

  const [pageFilters, setPageFilters] = React.useState({
    currentPage: 0,
    filterStatus: 'notArchived',
  });
  const [openNotifDialog, setOpenNotifDialog] = React.useState(false);

  const leadsFetch = leads && leads.length !== 0 && leads[0].organisation?.id === organisation.id;

  React.useEffect(() => {
    dispatch(
      LeadsActions.getOrganisationLeadsRequest(
        organisation.holding_slug,
        organisation.slug,
        pageFilters.filterStatus === 'archived' && pageFilters.filterStatus,
        pageFilters.currentPage,
        null,
      ),
    );
  }, [organisation.holding_slug, organisation.slug, pageFilters, dispatch]);

  React.useEffect(() => {
    const channelSubscription = currentUser?.token
      ? subscribeChannel(
          currentUser?.token,
          { channel: CHANNEL_LEAD, organisation_id: organisation.id },
          {
            received(data) {
              if (data && data.helper_id !== currentUser.id && data.lead) {
                dispatch(LeadsActions.addLead(data.lead));
                const sub = getSubscription(
                  organisation,
                  organisation.type,
                  SubscribleSubTypes.LEADS,
                  organisation.id,
                );
                const date = new Date();
                dispatch(
                  AuthActions.updateHelperSubscriptionRequest(currentUser.id, {
                    ...sub,
                    last_read_date: date.toJSON(),
                  }),
                );
              }
            },
          },
        )
      : null;
    return () => {
      if (channelSubscription) unsubscribeChannel(channelSubscription);
    };
    // eslint-disable-next-line
  }, [dispatch, currentUser?.token, organisation?.id]);

  const updateLastReadDate = React.useCallback(
    debounce(
      (sub, date) =>
        dispatch(
          AuthActions.updateHelperSubscriptionRequest(sub.helper_id, {
            ...sub,
            last_read_date: date.toJSON(),
          }),
        ),
      2000,
    ),
    [dispatch, organisation],
  );

  React.useEffect(() => {
    const sub = getSubscription(
      organisation,
      organisation.type,
      SubscribleSubTypes.LEADS,
      organisation.id,
    );
    const date = new Date();
    if (sub) updateLastReadDate(sub, date);
    // eslint-disable-next-line
  }, [organisation.slug]);

  const renderNoLeads = () => {
    const { params: kind } = match;
    let id;
    switch (kind) {
      case 'qualified':
        id = 'APP.LEADS.EMPTY.QUALIFIED';
        break;
      case 'non_qualified':
        id = 'APP.LEADS.EMPTY.NON_QUALIFIED';
        break;
      default:
        id = 'APP.LEADS.EMPTY.UNKOWN';
    }
    return <EmptyList id={id} level={errors ? 'error' : 'info'} />;
  };

  return (
    <>
      <div className={classes.appBarContainer}>
        <AppBar position="static" color="inherit" elevation={1}>
          <Toolbar>
            <Typography className={classes.grow} variant="h4">
              {t(`APP.ORGANISATION.MENU.${organisation.type}.LEADS`)}
            </Typography>
            {organisation?.services?.filter(s =>
              s.service?.notifiable_roles?.includes(organisation?.role),
            )?.length > 0 && (
              <Tooltip title={t('APP.LEADS.NOTIFICATIONS.BUTTON')} placement="bottom">
                <IconButton
                  onClick={() => setOpenNotifDialog(true)}
                  aria-label="Notifs"
                  className={classes.notifs}
                >
                  <NotificationsActiveIcon />
                </IconButton>
              </Tooltip>
            )}
            <RoleCheck
              role={organisation.role}
              action="leads:archive"
              yes={() => (
                <Select
                  value={pageFilters.filterStatus}
                  onChange={event =>
                    setPageFilters({
                      currentPage: 0,
                      filterStatus: event.target.value,
                    })
                  }
                  input={<OutlinedInput style={{ height: 48 }} name="events" id="select-events" />}
                  variant="outlined"
                >
                  <MenuItem value="notArchived">
                    <ListItemText primary={t('APP.LEADS.FILTER.NOT_ARCHIVED')} />
                  </MenuItem>
                  <MenuItem value="archived">
                    <ListItemText primary={t('APP.LEADS.FILTER.ARCHIVED')} />
                  </MenuItem>
                </Select>
              )}
            />
          </Toolbar>
        </AppBar>
      </div>
      {isFetching && <LinearProgress className={classes.progress} />}
      {leadsFetch && (
        <Paper>
          <GridLeads
            leads={leads}
            gridMode="Organisation"
            totalLeads={totalLeads}
            currentPage={pageFilters.currentPage}
            currentUser={currentUser}
            onUpdatePage={page => setPageFilters({ ...pageFilters, currentPage: page })}
            onUpdateLead={updateLead}
            canArchive={() => CheckRoleRule(organisation.role, 'leads:archive')}
            generateShowLeadLink={row =>
              `/${row.organisation.holding_slug}/${row.organisation.slug}/leads/details/${row.id}`
            }
          />
        </Paper>
      )}
      {!isFetching && leads && leads.length === 0 && renderNoLeads()}
      <Dialog open={openNotifDialog} onClose={() => setOpenNotifDialog(false)}>
        <NotificationsSettings
          organisation={organisation}
          onClose={() => setOpenNotifDialog(false)}
        />
      </Dialog>
    </>
  );
};

export default Leads;
