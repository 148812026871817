import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import FilteringCard from '../../Shared/FilteringCard';

const useStyles = makeStyles(theme => ({
  marginTop: { marginTop: theme.spacing(1) },
}));

type Props = {
  attribute: String,
  items: Array,
  limit: Number,
};

const RefinedFilters = ({ attribute, items, limit }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const filters = queryParam.get(attribute)?.split('|') || '';
  const itemsWithRefined = items?.map(it => {
    return { ...it, isRefined: filters?.includes(it?.label) };
  });
  const [filterItems, setFilterItems] = React.useState(itemsWithRefined);

  React.useEffect(() => {
    setFilterItems(itemsWithRefined);
  }, [items]);

  const getRefinedItems = item => {
    const newFilterItems = filterItems.slice();
    const isItemOnFilters = newFilterItems?.find(it => it.label === item.label);
    if (isItemOnFilters) {
      newFilterItems.find(it => it.label === item.label).isRefined = !newFilterItems.find(
        it => it.label === item.label,
      ).isRefined;
    }
    setFilterItems(newFilterItems);

    if (newFilterItems.find(it => it.isRefined)) {
      const refinedItemsString = newFilterItems
        .filter(it => it.isRefined)
        .map(it => it.label)
        .join('|');
      history.push(`?${attribute}=${refinedItemsString}`);
    } else history.push();
  };

  return (
    <Grid className={classes.marginTop}>
      <FilteringCard
        items={filterItems}
        title={t('FILTER')}
        onClickItem={getRefinedItems}
        limit={limit}
      />
    </Grid>
  );
};
export default RefinedFilters;
