import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ReorderIcon from '@material-ui/icons/Reorder';
import Dialog from '@material-ui/core/Dialog';
import { Divider, Fab, Paper } from '@material-ui/core';

import CardRubric from './CardRubric';
import HoldingActions from '../../../Redux/HoldingRedux';
import RubricForm from '../../Holdings/RubricForm';
import RubricOrderForm from '../../Holdings/RubricOrderForm';
import CardCtaProOrHelper from './CardCtaProOrHelper';
import HoldingEdit from '../../../Pages/Holding/Settings/HoldingEdit';
import Routes from '../../../Routing/Routes';

const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(4) },
  rubricCard: { marginBottom: theme.spacing(2) },
  divider: { margin: theme.spacing(7) },
  card: { padding: theme.spacing(2) },
  orderCard: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlignLast: 'end',
  },
  title: {
    fontWeight: '800',
    textTransform: 'none',
    paddingBottom: theme.spacing(2),
  },
  button: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'none',
    padding: 0,
  },
  chip: {
    marginRight: 4,
  },
  fab: {
    margin: theme.spacing(2),
  },
  add: { marginRight: theme.spacing(1) },
  dialogWidth: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 800,
    },
  },
}));

type Props = {
  organisation: Object,
  userCanEditHolding: Boolean,
};

const About = ({ organisation, userCanEditHolding }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const levels = useSelector(state => state.holding.levels);
  const expertises = useSelector(state => state.holding.allExpertises);
  const [showAddRubric, setShowAddRubric] = React.useState(false);
  const [showEditOrder, setShowEditOrder] = React.useState(false);
  const [showEditHolding, setShowEditHolding] = React.useState(false);

  const lastRubricId =
    organisation?.holding_rubrics?.length > 0
      ? organisation?.holding_rubrics?.slice(-1).pop()?.id
      : null;

  React.useEffect(() => {
    if (
      (!levels || !expertises || (expertises && !expertises[0]?.level?.id)) &&
      userCanEditHolding
    ) {
      dispatch(HoldingActions.getLevelsRequest());
      dispatch(HoldingActions.getAllExpertisesRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!organisation?.admin_id &&
        Routes.currentNamespace() !== 'CD85' &&
        Routes.currentNamespace() !== 'HAPICOOP' && <CardCtaProOrHelper />}
      {userCanEditHolding && organisation.holding_rubrics?.length > 1 && (
        <Grid className={classes.orderCard}>
          <Button
            className={classes.button}
            style={{ backgroundColor: 'transparent' }}
            onClick={() => setShowEditOrder(true)}
          >
            <ReorderIcon style={{ marginRight: 8 }} /> {t('APP.ORGANISATION.HOME.RUBRIC.ORDER')}
          </Button>
        </Grid>
      )}

      {organisation.holding_rubrics?.length > 0 && (
        <Paper className={classes.paper}>
          {organisation.holding_rubrics?.map(rubric => (
            <Grid key={rubric?.id} className={classes.rubricCard}>
              <CardRubric
                rubric={rubric}
                organisation={organisation}
                userCanEditHolding={userCanEditHolding}
              />
              {lastRubricId !== rubric?.id && <Divider className={classes.divider} />}
            </Grid>
          ))}
        </Paper>
      )}

      {userCanEditHolding && (
        <Grid align="center">
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            className={classes.fab}
            onClick={() => setShowAddRubric(true)}
          >
            <AddIcon className={classes.add} />
            {t('APP.ORGANISATION.HOME.RUBRIC.ADD')}
          </Fab>
        </Grid>
      )}
      <Dialog
        open={showAddRubric}
        onClose={() => setShowAddRubric(false)}
        className={classes.dialogWidth}
      >
        <RubricForm organisation={organisation} onClose={() => setShowAddRubric(false)} />
      </Dialog>
      <Dialog open={showEditOrder} onClose={() => setShowEditOrder(false)}>
        <RubricOrderForm organisation={organisation} onClose={() => setShowEditOrder(false)} />
      </Dialog>
      <Dialog open={showEditHolding} onClose={() => setShowEditHolding(false)}>
        <HoldingEdit holding={organisation.holding} onClose={() => setShowEditHolding(false)} />
      </Dialog>
    </>
  );
};
export default About;
