import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import { getExpertiseStyle } from '../../Themes/Expertises';
import PostComments from '../Organisations/Shared/PostComments';

const useStyles = makeStyles(theme => ({
  expertiseAvatar: {
    backgroundColor: 'white',
    height: 24,
    width: 24,
    marginRight: theme.spacing(1),
    boxShadow: `0 0 0 2px white`,
    '&>*': {
      height: 24,
      width: 24,
    },
  },
  menuContainer: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  wishCard: {
    overflow: 'visible',
    position: 'relative',
    paddingTop: theme.spacing(2),
  },
}));

type Props = {
  post: Object,
  currentUser: Object,
  holdingSlug: String,
  organisationSlug: String,
  creatingComment: Boolean,
  onCreateComment: Function,
  onDeleteComment: Function,
  canReadPost: Function,
  canEditDeletePost: Function,
  canDeleteComment: Function,
  postableType: String,
  family: Object,
  renderMenuAction: Function,
  isDialog: Boolean,
};

const WishActivityCardPost = ({
  holdingSlug,
  organisationSlug,
  post,
  currentUser,
  creatingComment,
  onCreateComment,
  onDeleteComment,
  canReadPost,
  canEditDeletePost,
  canDeleteComment,
  postableType,
  renderMenuAction,
  isDialog,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card
      key={`cardpost-${post.id}`}
      className={classes.wishCard}
      style={{ padding: isDialog ? '24px' : 'auto' }}
    >
      {canEditDeletePost() && <Grid className={classes.menuContainer}>{renderMenuAction()}</Grid>}
      <CardContent align="center">
        <Grid style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
          <Avatar
            className={classes.expertiseAvatar}
            alt={getExpertiseStyle({ slug: post?.meta?.expertise_slug }).label}
            style={{ color: getExpertiseStyle({ slug: post?.meta?.expertise_slug }).color }}
          >
            {getExpertiseStyle({ slug: post?.meta?.expertise_slug }).icon}
          </Avatar>
          <Typography variant="h5" component="div" style={{ textTransform: 'none' }}>
            {t(`${post.helper_first_name || post.helper?.first_name} a une nouvelle envie`)}
          </Typography>
        </Grid>
        <Typography variant="caption" component="div" color="textSecondary">
          {moment(post.created_at).format('dddd LL - LT')}
        </Typography>
        <Typography variant="h6" component="div" style={{ marginTop: 8 }}>
          {getExpertiseStyle({ slug: post?.meta?.expertise_slug }).label}
        </Typography>
      </CardContent>
      {canReadPost() && (
        <PostComments
          holdingSlug={holdingSlug}
          organisationSlug={organisationSlug}
          post={post}
          currentUser={currentUser}
          creatingComment={creatingComment}
          onCreateComment={onCreateComment}
          onDeleteComment={onDeleteComment}
          canDeleteComment={canDeleteComment}
          postableType={postableType}
        />
      )}
    </Card>
  );
};

export default WishActivityCardPost;
