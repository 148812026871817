import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import GroupIcon from '@material-ui/icons/Group';
import Dialog from '@material-ui/core/Dialog';
import { getFirstNames } from '../../../Services/ClubHelpers';
import MenuItems from '../../Block/MenuItems';
import { CheckRoleRule } from '../../../Acl/Rules';
import ClubForm from '../../../Pages/Organisations/Clubs/ClubForm';
import { getUnreadPostCount, SubscribleTypes } from '../../../Services/SubscriptionHelper';
import ClubsActions from '../../../Redux/ClubsRedux';
import Routes from '../../../Routing/Routes';

type Props = {
  organisation: Object,
};

function MenuItemClubs({ organisation }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clubs = useSelector(state => state.clubs.clubs);
  const [openCreateClub, setOpenCreateClub] = React.useState(false);
  const areClubsReady = clubs && clubs[0]?.organisation_slug === organisation.slug;

  React.useEffect(() => {
    if (!areClubsReady) {
      dispatch(
        ClubsActions.getOrganisationClubsRequest(organisation?.holding_slug, organisation?.slug),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MenuItems
        items={
          areClubsReady &&
          clubs.map(club => ({
            id: club.id,
            link: `${Routes.organisationHomePath(organisation)}/clubs/${club.id}`,
            icon: <GroupIcon />,
            title: club.name,
            subTitle: getFirstNames(club, t),
            unread_posts_count: getUnreadPostCount(
              organisation,
              SubscribleTypes.CLUB,
              null,
              club.id,
            ),
          }))
        }
        isFetching={false}
        menuTitle={t('APP.ORGANISATION.MENU.CLUBS_LINK')}
        canCreate={CheckRoleRule(organisation.role, 'clubs:create')}
        onCreateClick={() => setOpenCreateClub(true)}
        createTitle={t('APP.ORGANISATION.MENU.CLUBS.CREATE_CLUB')}
        noItemTitle={t('APP.ORGANISATION.MENU.CLUBS.NO_CLUB')}
      />
      <Dialog open={openCreateClub} onClose={() => setOpenCreateClub(false)}>
        <ClubForm onClose={() => setOpenCreateClub(false)} />
      </Dialog>
    </>
  );
}

export default MenuItemClubs;
