import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleIcon from '@material-ui/icons/People';

import moment from 'moment';
import Images from '../../../Themes/Images';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import WelloAvatar from '../../Shared/WelloAvatar';

const useStyles = makeStyles(theme => ({
  media: {
    objectFit: 'cover',
    borderRadius: '0px',
  },
  mediaTextArea: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '110px',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 50%)',
  },
  avatarContainer: {
    margin: '-45px 0 0 10px',
    marginBottom: '-5px',
  },
  avatar: {
    backgroundColor: 'white',
    height: 45,
    width: 45,
    margin: 8,
    boxShadow: `0 0 0 2px white`,
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    maxHeight: '5.8em',
    whiteSpace: 'normal',
  },
  list: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: 0,
    paddingLeft: 0,
  },
  firstlist: {
    padding: 0,
  },
  listItemIcon: {
    marginRight: theme.spacing(-2),
  },
  itemsContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  excerptContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
    height: '70px',
  },
  excerptTypo: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    maxHeight: '5.8em',
    whiteSpace: 'normal',
  },
  titleContent: {
    alignItems: 'start',
    display: 'inline-flex',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  chip: {
    margin: '0px 10px 0px -3px',
    height: '25px',
  },
  registrantAvatar: {
    height: '25px',
    width: '25px',
    fontSize: '0.8rem',
    marginRight: '-8px',
  },
}));

type Props = {
  task: Object,
};

const CardSimplifiedTask = ({ task }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fallbackCoverUrl = getExpertiseStyle(task.expertise)?.cover || Images.badge.ERROR;
  const eventStartDate = new Date(task.start_date);
  const registrants = task?.twig_for_date?.helpers || [];

  return (
    <Card>
      <CardMedia
        classes={{
          root: classes.mediaTextArea,
          media: classes.media,
        }}
        image={fallbackCoverUrl}
      />
      <div className={classes.avatarContainer}>
        <Avatar className={classes.avatar}>{getExpertiseStyle(task.expertise).icon}</Avatar>
      </div>
      <CardHeader
        disableTypography
        classes={{ root: classes.titleContent }}
        title={
          <Typography variant="h4" component="h3" className={classes.description}>
            {t('APP.ACTIVITY.HEALTH_TITLE')}
          </Typography>
        }
      />
      <CardContent className={classes.itemsContent}>
        {registrants?.length > 0 && (
          <ListItem className={classes.firstlist}>
            <ListItemIcon className={classes.listItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            {registrants.slice(0, 2).map(helper => (
              <Chip
                icon={
                  <WelloAvatar
                    avatarUrl={helper.avatar_url}
                    firstName={helper.first_name}
                    lastName={helper.last_name}
                    backgroundColor={helper.avatar_background_color}
                    avatarClass={classes.registrantAvatar}
                    size={36}
                  />
                }
                key={helper.id}
                label={helper.first_name}
                className={classes.chip}
              />
            ))}
            {registrants?.length > 2 && (
              <Avatar className={classes.registrantAvatar}>{`+${registrants?.length - 2}`}</Avatar>
            )}
          </ListItem>
        )}

        <ListItem className={classes.list}>
          <ListItemIcon className={classes.listItemIcon}>
            <ScheduleIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="subtitle2">{`${moment(eventStartDate).format('LL')} ${
              task?.twig_for_date?.event_start_time
            }`}</Typography>
          </ListItemText>
        </ListItem>
      </CardContent>
    </Card>
  );
};

export default CardSimplifiedTask;
