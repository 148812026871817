import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import ChipInput from 'material-ui-chip-input';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import { standardizeWordCase } from '../../../Services/DataHelper';
import { isLongerThan } from '../../../Services/FormHelpers';

type Props = {
  task: Object,
  expertises: Array,
  onBackClick: Function,
  onNextClick: Function,
  isFamily: Boolean,
};

const useStyles = makeStyles(theme => ({
  formTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  words: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[100],
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  selectedChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#fff',
    fontWeight: 600,
    '&>*': {
      '&>*': {
        color: '#fff',
      },
    },
  },
}));

const TaskFormStep1 = ({ task, expertises, onBackClick, onNextClick, isFamily }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [stepTask, setStepTask] = React.useState(task);

  const selectedExpertise = React.useMemo(() => {
    const expertise = expertises?.find(exp => exp.id === stepTask.expertiseId);
    if (!stepTask.expertiseId) return null;

    return getExpertiseStyle(expertise);
  }, [stepTask.expertiseId, expertises]);

  const handleAddChip = chip => {
    if (stepTask.relatedWords.includes(chip)) return;
    const newKeyWords = stepTask.relatedWords.slice();
    newKeyWords.push(standardizeWordCase(chip));
    setStepTask({ ...stepTask, relatedWords: newKeyWords });
  };

  const handleRemoveChip = chip => {
    const newKeyWords = stepTask.relatedWords.filter(keyWord => keyWord !== chip);
    setStepTask({ ...stepTask, relatedWords: newKeyWords });
  };

  const isValidFormStep = () => {
    return isFamily
      ? isLongerThan(0)(stepTask?.description)
      : isLongerThan(0)(stepTask?.description) && isLongerThan(0)(stepTask?.excerpt);
  };

  return (
    <>
      <Typography variant="h5" align="center" className={classes.formTitle}>
        {t(`APP.ACTIVITY.STEP1.TITLE`)}
      </Typography>
      <Grid align="center">
        <Chip
          variant="default"
          style={{ backgroundColor: selectedExpertise.color }}
          className={classes.selectedChip}
          label={t(selectedExpertise.label)}
          icon={selectedExpertise.icon}
        />
      </Grid>
      {/* title */}
      <FormControl margin="normal" component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          required
          name="description"
          value={stepTask.description}
          type="text"
          onChange={event => setStepTask({ ...stepTask, description: event.target.value })}
          label={t('APP.ACTIVITY.DESCRIPTION.LABEL')}
          variant="outlined"
          autoFocus
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 50 }}
          placeholder={t(
            isFamily
              ? 'APP.ACTIVITY.DESCRIPTION.FAMILY.PLACEHOLDER'
              : 'APP.ACTIVITY.DESCRIPTION.PLACEHOLDER',
          )}
        />
      </FormControl>
      {/* description */}
      <FormControl margin="normal" component="fieldset" className={classes.formControl} fullWidth>
        <TextField
          required={!isFamily}
          name="excerpt"
          value={stepTask.excerpt}
          type="text"
          multiline
          rows={4}
          onChange={event => setStepTask({ ...stepTask, excerpt: event.target.value })}
          label={t('APP.ACTIVITY.EXCERPT.LABEL')}
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t(
            isFamily
              ? 'APP.ACTIVITY.EXCERPT.FAMILY.PLACEHOLDER'
              : 'APP.ACTIVITY.EXCERPT.PLACEHOLDER',
          )}
        />
      </FormControl>
      {!isFamily && (
        <>
          {/* key words */}
          <FormControl margin="normal" className={classes.formControl} fullWidth>
            <InputLabel variant="outlined" shrink className={classes.selectLabel}>
              {t('APP.ACTIVITY.ACTIVITY_KEYWORDS.TITLE')}
            </InputLabel>

            <ChipInput
              fullWidth
              autoFocus
              variant="outlined"
              rows={4}
              value={stepTask.relatedWords}
              onDelete={chip => handleRemoveChip(chip)}
              onAdd={chip => handleAddChip(chip)}
              placeholder={t('APP.ACTIVITY.ACTIVITY_KEYWORDS.PLACEHOLDER')}
            />
            {selectedExpertise.keywords && (
              <Card elevation={1}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} align="left">
                      <Typography component="subtitle2" variant="subtitle2">
                        {t('APP.ACTIVITY.ACTIVITY_KEYWORDS.SUGGESTIONS')}
                      </Typography>
                    </Grid>
                    {selectedExpertise.keywords.map(word => (
                      <Chip
                        key={word}
                        className={classes.words}
                        label={word}
                        clickable
                        onClick={() => handleAddChip(word)}
                        icon={<AddIcon />}
                      />
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            )}
          </FormControl>
          {/* external url */}
          <FormControl
            margin="normal"
            component="fieldset"
            className={classes.formControl}
            fullWidth
          >
            <TextField
              name="externalLink"
              value={stepTask.externalLink}
              onChange={event => setStepTask({ ...stepTask, externalLink: event.target.value })}
              label={t('APP.ACTIVITY.EXTERNAL_LINK.LABEL')}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={t('APP.ACTIVITY.EXTERNAL_LINK.PLACEHOLDER')}
            />
          </FormControl>
        </>
      )}
      <div className={classes.buttonContainer}>
        <Button onClick={() => onBackClick(stepTask)} className={classes.backButton}>
          {t('BACK')}
        </Button>
        <Button
          variant="contained"
          disabled={!isValidFormStep()}
          color="primary"
          onClick={() => onNextClick(stepTask)}
        >
          {t('NEXT')}
        </Button>
      </div>
    </>
  );
};

export default TaskFormStep1;
