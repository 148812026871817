import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// import Button from '@material-ui/core/Button';
import CoursesActions from '../../../Redux/CoursesRedux';
import EmptyList from '../../../Components/Shared/EmptyList';
import CardCourse from '../../../Components/Shared/Courses/CardCourse';
import { CheckRoleRule } from '../../../Acl/Rules';
import CourseForm from '../../../Components/Shared/Courses/CourseForm';

type Props = {
  organisation: Object,
};

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '800',
    textTransform: 'none',
  },
  appbar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    backgroundColor: 'white',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  progress: {
    width: '100%',
  },
  button: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  leagueCourses: {
    fontStyle: 'italic',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  cardCourse: {
    marginBottom: theme.spacing(1),
  },
}));

const Courses = ({ organisation }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug, organisation_slug: orgaSlug } = match.params;
  const dispatch = useDispatch();
  const courses = useSelector(state => state.courses.courses);
  const isFetching = useSelector(state => state.courses.isFetching.getHoldingCourses);
  const canCreateCourse = CheckRoleRule(organisation?.role, 'courses:create');
  const coursesReady = courses && courses.length > 0 && holdingSlug === organisation?.holding?.slug;
  const visibilities = [
    { label: 'APP.COURSES.VISIBILITY.PUBLIC', value: 'public' },
    { label: 'APP.COURSES.VISIBILITY.PRIVATE', value: 'private' },
  ];
  const [openCourseFormDialog, setOpenCourseFormDialog] = React.useState(false);

  const holdingCourses = courses?.filter(a => a.postable_type === 'Holding');
  const leagueCourses = courses?.filter(a => a.postable_type === 'League');

  React.useEffect(() => {
    if (holdingSlug) {
      dispatch(CoursesActions.getHoldingCoursesRequest(holdingSlug));
    }
  }, [holdingSlug, dispatch]);

  const onDeleteCourse = course => {
    dispatch(CoursesActions.destroyHoldingCourseRequest(holdingSlug, course.slug));
  };

  const onSubmit = (event, course, cover, attachments) => {
    event.preventDefault();

    const courseAttributes = { ...course, postable_type: 'Holding' };
    dispatch(
      CoursesActions.createHoldingCourseRequest(
        holdingSlug,
        orgaSlug,
        courseAttributes,
        cover,
        attachments,
      ),
    );
  };

  const renderCoursesList = list =>
    list.map(course => (
      <Grid item xs={12} key={course.slug} className={classes.cardCourse}>
        <CardCourse
          canEdit={course.postable_type === 'Holding' && canCreateCourse}
          course={course}
          linkUrl={`/formations/${course.slug}`}
          editUrl={
            course.postable_type === 'League'
              ? `/leagues/${course.postable_id}/formations/${course.slug}`
              : `/${holdingSlug}/${orgaSlug}/formations/${course.slug}`
          }
          onDelete={onDeleteCourse}
        />
      </Grid>
    ));

  return (
    <>
      <AppBar position="static" color="inherit" elevation={1} className={classes.appbar}>
        <Toolbar className={classes.grow}>
          <Grid className={classes.grow}>
            <Typography className={classes.title} variant="h4" align="left">
              {t('APP.COURSES.APP_BAR.TITLE')}
            </Typography>
          </Grid>
          {/* {canCreateCourse && (
            <Button
              className={classes.button}
              color="primary"
              onClick={() => setOpenCourseFormDialog(true)}
            >
              {t('APP.COURSES.CREATE')}
            </Button>
          )} */}
        </Toolbar>
      </AppBar>

      {isFetching && <LinearProgress className={classes.progress} />}
      {!isFetching && coursesReady && (
        <>
          {renderCoursesList(holdingCourses)}
          {leagueCourses?.length > 0 && (
            <Typography className={classes.leagueCourses} variant="body1" align="left">
              {t(
                holdingCourses?.length > 0
                  ? 'APP.COURSES.LEAGUE.SECTION'
                  : 'APP.COURSES.LEAGUE.SECTION_WITHOUT_HOLDING',
                { value: leagueCourses[0].postable_name },
              )}
            </Typography>
          )}
          {renderCoursesList(leagueCourses)}
        </>
      )}
      {!isFetching &&
        organisation?.holding?.slug === holdingSlug &&
        courses &&
        courses.length === 0 && <EmptyList id="APP.COURSES.EMPTY" level="info" />}
      <Dialog
        open={openCourseFormDialog}
        onClose={() => setOpenCourseFormDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <Paper className={classes.paper} elevation={0}>
          <CourseForm
            onSubmit={onSubmit}
            onClose={() => setOpenCourseFormDialog(false)}
            visibilities={visibilities}
          />
        </Paper>
      </Dialog>
    </>
  );
};

export default Courses;
