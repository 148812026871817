import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import WelloAvatar from '../../Shared/WelloAvatar';
import { RoleCheck } from '../../Shared/Can';

type Props = {
  organisation: Object,
  title: String,
  buttonCreateRule: String,
  buttonCreateTitle: String,
  createLink: String,
  elements: Array,
  emptylistMessage: String,
  isFetchingElements: Boolean,
  seeMoreAction: Function,
  seeMoreRule: String,
};

const useStyles = makeStyles(theme => ({
  headerTypo: {
    fontWeight: '800',
    marginBottom: theme.spacing(0.5),
  },
  cardContent: {
    textAlign: 'center',
    '&:last-child': {
      padding: 0,
      paddingBottom: theme.spacing(1),
    },
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'left',
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  avatar: {
    borderRadius: '5%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '50px',
    width: '60px',
  },
  description: {
    fontSize: '0.77rem',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
  },
  button: {
    alignSelf: 'center',
    color: theme.current.primary.main,
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  elementTitle: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    wordBreak: 'break-word',
  },
  link: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  groupIcon: {},
}));

const OrganisationElementsCard = ({
  organisation,
  title,
  buttonCreateRule,
  buttonCreateTitle,
  createLink,
  emptylistMessage,
  elements,
  isFetchingElements,
  seeMoreAction,
  seeMoreRule,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card>
      <>
        {!isFetchingElements && elements?.length > 0 && (
          <>
            <CardHeader
              title={
                <Typography variant="h4" className={classes.headerTypo}>
                  {t(title)}
                </Typography>
              }
              // subheader={
              //   <Typography variant="subtitle2">
              //     <FormattedMessage id={subtitle} />
              //   </Typography>
              // }
            />
            <CardContent className={classes.cardContent}>
              {elements.map((element, i) => (
                <div key={`el-${element.id}`}>
                  {i < 3 && (
                    <CardActionArea
                      component={Link}
                      to={element.link}
                      className={classes.cardAction}
                    >
                      <>
                        {element.cover_url && (
                          <WelloAvatar
                            avatarUrl={element.cover_url}
                            avatarClass={classes.avatar}
                            size={60}
                          />
                        )}
                        {!element.cover_url && element.startIcon && (
                          <Grid
                            item
                            className={classes.groupIcon}
                            style={{ alignSelf: 'center', marginRight: '16px', marginLeft: '8px' }}
                          >
                            {element.startIcon}
                          </Grid>
                        )}
                        <Grid>
                          <Typography
                            className={classes.elementTitle}
                            variant="h6"
                            component="div"
                            align="left"
                          >
                            {element.title}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="div"
                            align="left"
                            className={classes.description}
                          >
                            {element.description}
                          </Typography>
                        </Grid>
                      </>
                    </CardActionArea>
                  )}
                </div>
              ))}
              {seeMoreAction && (
                <RoleCheck
                  role={organisation.role}
                  action={seeMoreRule || true}
                  yes={() => (
                    <Button className={classes.button} onClick={seeMoreAction}>
                      {t('SEE_MORE')}
                    </Button>
                  )}
                />
              )}
            </CardContent>
          </>
        )}
        {!isFetchingElements && elements?.length === 0 && (
          <>
            <CardHeader
              title={
                <Typography variant="h4" className={classes.headerTypo}>
                  {t(emptylistMessage)}
                </Typography>
              }
            />
            {buttonCreateRule && buttonCreateTitle && (
              <RoleCheck
                role={organisation.role}
                action={buttonCreateRule}
                yes={() => (
                  <CardContent className={classes.link}>
                    <Link to={createLink}>{t(buttonCreateTitle)}</Link>
                  </CardContent>
                )}
              />
            )}
          </>
        )}
      </>
    </Card>
  );
};

export default OrganisationElementsCard;
