import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Link from '@material-ui/core/Link';

import LeagueLayoutContainer from '../../../Components/Layout/LayoutContainer';
import MenuHelper from '../../../Components/Leagues/MenuHelper';
import LeaguesActions from '../../../Redux/LeaguesRedux';

import findAndReplace from '../../../Redux/ReduxHelpers';
import TransmissionsActions from '../../../Redux/TransmissionsRedux';
import CardTransmission from '../../../Components/Organisations/Transmission/CardTransmission';

import NoMatch404 from '../../NoMatch404';
import NoMatch401 from '../../NoMatch401';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    '& .MuiTypography-h5': {
      textTransform: 'none',
    },
  },
  textFiled: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  buttonLink: {
    textTransform: 'none',
    padding: theme.spacing(0, 0, 2),
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

const ShowHelper = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const { league_id: leagueId, helper_id: helperId } = match.params;
  const league = useSelector(state => state.leagues.league);
  let leagueHelpers = useSelector(state => state.leagues.helpers) || [];
  const leagueHelper = useSelector(state => state.leagues.helper);
  const isFetchingHelper = useSelector(state => state.leagues.isFetching.getLeagueHelper);
  const errors = useSelector(state => state.leagues.errors.getLeagueHelper);
  const transmissions = useSelector(state => state.transmissions.transmissions);
  const isFetchingTransmissions = useSelector(
    state => state.transmissions.isFetching.getLeagueTransmissions,
  );
  const creatingTransmission = useSelector(
    state => state.transmissions.isFetching.createLeagueTransmissions,
  );
  const updatingTransmission = useSelector(
    state => state.transmissions.isFetching.updateLeagueTransmissions,
  );
  if (leagueHelpers.length > 0 && leagueHelper)
    leagueHelpers = findAndReplace(h => h.id === leagueHelper.id, leagueHelper)(leagueHelpers);
  if (leagueHelpers.length === 0 && leagueHelper) leagueHelpers.push(leagueHelper);
  const helper = leagueHelpers?.find(h => `${h?.id}` === helperId);
  const [showLeagueTransmissionPostForm, setShowLeagueTransmissionPostForm] = React.useState(false);
  const [transmission, setTransmission] = React.useState(null);
  const [message, setMessage] = React.useState('');

  const transmissionsFound =
    transmissions &&
    transmissions.length !== 0 &&
    transmissions[0].helper_id === parseInt(helperId, 10);

  const getLeagueTransmissions = () => {
    dispatch(TransmissionsActions.getLeagueTransmissionsRequest(leagueId, helperId));
  };

  React.useEffect(() => {
    dispatch(LeaguesActions.getLeagueHelperRequest(leagueId, helperId));
  }, [dispatch, leagueId, helperId]);

  React.useEffect(() => {
    getLeagueTransmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, leagueId, helperId, errors?.status]);

  const handleClickSubmit = event => {
    event.preventDefault();
    if (message.trim() === '') return;

    const transmissionAttributes = {
      message,
      event_start_date: transmission?.event_start_date || new Date(Date.now()),
      event_end_date: transmission?.event_end_date || new Date(Date.now()),
      is_important: false,
    };

    if (transmission?.id && transmission?.type === 'Transmissions::Message') {
      dispatch(
        TransmissionsActions.updateLeagueTransmissionRequest(
          leagueId,
          helper?.id,
          transmission.id,
          transmissionAttributes,
        ),
      );
    }

    if (!transmission?.id) {
      dispatch(
        TransmissionsActions.createLeagueTransmissionRequest(
          leagueId,
          helper?.id,
          transmissionAttributes,
        ),
      );
    }
    setShowLeagueTransmissionPostForm(false);
    setMessage('');
  };

  const handleClickEditLeagueTransmission = transmission => {
    setShowLeagueTransmissionPostForm(true);
    setTransmission(transmission);
    setMessage(transmission?.message);
  };

  const renderLeagueTransmissionPostForm = () => (
    <Card>
      <TextField
        id="message"
        name="message"
        value={message}
        placeholder={t('APP.LEAGUES.TRANSMISSIONS.TEXT_FIELD.PLACEHOLDER')}
        onChange={event => setMessage(event.target.value)}
        multiline
        fullWidth
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" alignself="center">
              <IconButton
                onClick={handleClickSubmit}
                color="primary"
                disabled={creatingTransmission || updatingTransmission || !message.trim()}
              >
                {creatingTransmission || updatingTransmission ? <CircularProgress /> : <SendIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        className={classes.textFiled}
      />
    </Card>
  );

  return (
    <>
      {!isFetchingHelper && errors?.status === 404 && <NoMatch404 />}
      {!isFetchingHelper && errors?.status === 401 && <NoMatch401 />}
      {(isFetchingHelper || isFetchingTransmissions) && <LinearProgress />}
      {!isFetchingHelper && !errors?.status && helper && (
        <LeagueLayoutContainer
          menu={
            helper && (
              <MenuHelper
                league={league}
                helper={helper}
                getLeagueTransmissions={getLeagueTransmissions}
              />
            )
          }
          rightMenu={
            <Card>
              <CardHeader
                title={t('FILES')}
                subheader={t('COMMING_SOON')}
                className={classes.cardHeader}
              />
            </Card>
          }
          backBtn={
            <Button
              color="primary"
              component={Link}
              onClick={() =>
                history.location?.state?.from
                  ? history.goBack()
                  : history.push(`/leagues/${league.id}/helpers/`)
              }
              className={classes.buttonLink}
              disableTouchRipple
            >
              <ArrowLeftIcon />
              {t('APP.LEAGUES.HELPER.BACK')}
            </Button>
          }
        >
          {renderLeagueTransmissionPostForm()}
          {transmissionsFound &&
            transmissions.map(transmission => (
              <Card key={transmission.id} style={{ marginTop: 16 }}>
                <CardTransmission
                  transmission={transmission}
                  handleClickEditLeagueTransmission={handleClickEditLeagueTransmission}
                  league={league}
                />
              </Card>
            ))}
        </LeagueLayoutContainer>
      )}
      <Dialog
        open={showLeagueTransmissionPostForm}
        onClose={() => setShowLeagueTransmissionPostForm(false)}
        fullWidth
      >
        {renderLeagueTransmissionPostForm()}
      </Dialog>
    </>
  );
};

export default ShowHelper;
