import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import GroupIcon from '@material-ui/icons/Group';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';

import { getFirstNames } from '../../../Services/ClubHelpers';
import ClubsActions from '../../../Redux/ClubsRedux';
import ClubChat from '../../../Components/Posts/ClubChat';
import EmptyList from '../../../Components/Shared/EmptyList';
import { RoleCheck } from '../../../Components/Shared/Can';
import ClubForm from './ClubForm';
import FirstClubCard from './FirstClubCard';
import { Button, Paper } from '@material-ui/core';

type Props = {
  organisation: Object,
  clubId: string,
};

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    flexGrow: 1,
    height: '100%',
  },
  grow: {
    flexGrow: 1,
    textTransform: 'none',
    fontSize: '1.2rem',
    marginLeft: '8px',
  },
  description: {
    textTransform: 'none',
    fontSize: '0.8rem',
    color: '#9e9e9e',
    marginLeft: '8px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
  },
  avatarIcon: {
    alignSelf: 'center',
  },
  editIcon: {
    alignSelf: 'center',
    textAlignLast: 'right',
  },
  avatar: {
    textAlign: 'center',
    padding: '15px',
  },
  icon: {
    marginRight: '-15px',
    color: 'black',
  },
  paper: {
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
  emptylist: {
    marginTop: theme.spacing(1),
  },
  deleteTitle: {
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
}));

const ShowClub = ({ organisation, clubId }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug } = match.params;
  const club = useSelector(state => state.clubs.club);
  const isFetching = useSelector(state => state.clubs.isFetching.getOrganisationClub);
  const clubFetch = `${clubId}` === `${club?.id}`;
  const currentUser = useSelector(state => state.auth.currentUser);
  const [actionMenuAnchor, setActionMenuAnchor] = React.useState(null);
  const [openDeleteClubDiag, setOpenDeleteClubDiag] = React.useState(false);
  const isClubMember = Boolean(club?.helpers?.find(helper => helper.id === currentUser.id));

  const [openEditClub, setOpenEditClub] = React.useState(false);

  React.useEffect(() => {
    if (clubId) {
      dispatch(
        ClubsActions.getOrganisationClubRequest(
          organisation?.holding_slug || holdingSlug,
          organisation?.slug || holdingSlug,
          clubId,
        ),
      );
    }
    // eslint-disable-next-line
  }, [dispatch, organisation, clubId]);

  const handleClickConfirmDeleteClub = () => {
    dispatch(
      ClubsActions.destroyClubRequest(
        organisation.holding_slug,
        organisation.slug,
        club.id,
        organisation.type,
      ),
    );
    setOpenDeleteClubDiag(false);
  };

  return (
    <>
      {!clubId && <FirstClubCard />}

      {clubId && (
        <Grid className={classes.appBarContainer}>
          <AppBar position="static" color="inherit" elevation={1}>
            <Toolbar style={{ paddingRight: '5px' }}>
              <Grid container>
                <Grid item xs={1} className={classes.avatarIcon}>
                  <GroupIcon />
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.grow} variant="h5" component="h3">
                    {club && clubFetch && club?.name}
                  </Typography>
                  <Typography className={classes.description}>
                    {club && clubFetch ? getFirstNames(club, t) : ''}
                  </Typography>
                </Grid>
                <RoleCheck
                  role={organisation?.role}
                  action="clubs:editdelete"
                  yes={() => (
                    <Grid item xs={2} className={classes.editIcon}>
                      <IconButton
                        onClick={event => setActionMenuAnchor(event.currentTarget)}
                        aria-label="More"
                        aria-owns={actionMenuAnchor ? 'actions-menu' : undefined}
                        aria-haspopup="true"
                        style={{ margin: 0 }}
                        className={classes.actionBtn}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="actions-menu"
                        open={Boolean(actionMenuAnchor)}
                        anchorEl={actionMenuAnchor}
                        onClose={() => setActionMenuAnchor(null)}
                      >
                        <MenuItem
                          onClick={() => {
                            setOpenEditClub(true);
                            setActionMenuAnchor(null);
                          }}
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText>{t('EDIT')}</ListItemText>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenDeleteClubDiag(true);
                            setActionMenuAnchor(null);
                          }}
                        >
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText>{t('ENCLOSE')}</ListItemText>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  )}
                />
              </Grid>
            </Toolbar>
          </AppBar>
          {(!club || isFetching || !clubFetch) && <LinearProgress />}
          {isClubMember && (
            <ClubChat
              organisation={organisation}
              club={club}
              currentUser={currentUser}
              clubId={clubId}
            />
          )}
          {!isClubMember && !isFetching && clubFetch && club && (
            <Grid className={classes.emptylist}>
              <EmptyList id="APP.CLUBS.SHOW_CLUB.NOT_MEMBER" level="info" />
            </Grid>
          )}
        </Grid>
      )}
      <Dialog open={openEditClub} onClose={() => setOpenEditClub(false)}>
        <ClubForm club={clubId ? club : null} onClose={() => setOpenEditClub(false)} />
      </Dialog>
      <Dialog open={openDeleteClubDiag} onClose={() => setOpenDeleteClubDiag(false)}>
        <Paper className={classes.paper}>
          <Typography variant="h4" component="div" className={classes.deleteTitle}>
            {t('APP.CLUBS.SHOW_CLUB.DELETE_CLUB')}
          </Typography>
          <Button onClick={() => setOpenDeleteClubDiag(false)} color="primary">
            {t('CANCEL')}
          </Button>
          <Button onClick={handleClickConfirmDeleteClub}>{t('ENCLOSE')}</Button>
        </Paper>
      </Dialog>
    </>
  );
};

export default ShowClub;
