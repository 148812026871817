import React from 'react';
import { useSelector } from 'react-redux';
import MenuItemClubMembers from './MenuItemClubMembers';
// import MenuItemClubInfo from './MenuItemClubInfo';

type Props = {
  organisation: Object,
  clubId: String,
};

const MenuClub = ({ organisation, clubId }: Props) => {
  const club = useSelector(state => state.clubs.club);
  const clubFetch = `${clubId}` === `${club?.id}`;

  return (
    <>
      {organisation && club && clubFetch && (
        <MenuItemClubMembers organisation={organisation} club={club} />
      )}
    </>
  );
};

export default MenuClub;
