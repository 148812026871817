import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CommunityPostForm from './CommunityPostForm';
import ArticlesActions from '../../Redux/ArticlesRedux';
import EmptyList from '../Shared/EmptyList';
import { COMMUNITIY_VISIBILITIES } from '../../Services/ArticleHelper';
import RowCommunityArticle from './RowCommunityArticle';
import { CheckRoleRule } from '../../Acl/Rules';

type Props = {
  community: Object,
};

const useStyles = makeStyles(theme => ({
  appbar: {
    marginBottom: theme.spacing(1),
    borderRadius: 4,
    flexShrink: 1,
    zIndex: 0,
  },
  toolbar: {
    padding: theme.spacing(3),
    flexWrap: 'wrap',
  },
  textField: {
    flexGrow: 1,
    '& .MuiInputBase-root': {
      height: 40,
    },
  },
  btnColor: {
    backgroundColor: theme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
  progress: {
    width: '100%',
  },
}));

const CommunityArticles = ({ community }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug } = match.params;
  const dispatch = useDispatch();
  const articles = useSelector(state => state.articles.holdingArticles);
  const isFetching = useSelector(state => state.articles.isFetching.getHoldingArticles);
  const currentUser = useSelector(state => state.auth.currentUser);
  const [openCommunityPostFormDialog, setOpenCommunityPostFormDialog] = React.useState(false);
  const [articleToEdit, setArticleToEdit] = React.useState(null);
  const articlesReady = articles && articles.length > 0 && holdingSlug === community?.holding?.slug;
  const holdingArticles = articles?.filter(a => a.postable_type === 'Holding');

  const headCells = [
    { id: 'name', label: 'APP.COMMUNITIES.ARTICLES.COLUMN_NAME' },
    { id: 'created_at', label: 'APP.COMMUNITIES.ARTICLES.COLUMN_CREATED_AT' },
    { id: 'download', label: 'APP.COMMUNITIES.ARTICLES.COLUMN_DOWNLOAD' },
  ];

  React.useEffect(() => {
    if (holdingSlug) {
      dispatch(ArticlesActions.getHoldingArticlesRequest(holdingSlug));
    }
  }, [holdingSlug, dispatch]);

  const onDeleteArticle = articleSlug => {
    dispatch(ArticlesActions.destroyHoldingArticleRequest(holdingSlug, articleSlug));
  };

  const handleEditArticle = article => {
    setOpenCommunityPostFormDialog(true);
    setArticleToEdit(article);
  };

  const onClose = () => {
    setOpenCommunityPostFormDialog(false);
    setArticleToEdit(null);
  };

  const handleSubmit = (articleData, attachments) => {
    const data = {
      title: articleData.title,
      content: articleData.raw,
      related_words: articleData?.related_words,
      visibility: COMMUNITIY_VISIBILITIES[0].value,
    };
    if (articleData?.id) {
      dispatch(
        ArticlesActions.updateHoldingArticleRequest(
          holdingSlug,
          { ...data, slug: articleData?.slug },
          null,
          attachments,
        ),
      );
    } else {
      dispatch(ArticlesActions.createHoldingArticleRequest(holdingSlug, data, null, attachments));
    }
  };

  return (
    <>
      <AppBar position="static" color="inherit" elevation={1} className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <TextField
            label=""
            variant="outlined"
            className={classes.textField}
            placeholder={t('APP.COMMUNITIES.SEARCH.AVAILABLE_SOON')}
            disabled
          />
          <Fab
            size="small"
            className={classes.btnColor}
            style={{ margin: '4px 16px' }}
            // onClick={handleSubmit}
            // ref={buttonRef}
            disabled
          >
            <SearchIcon fontSize="small" />
          </Fab>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className={classes.btnColor}
            onClick={() => setOpenCommunityPostFormDialog(true)}
          >
            {t('APP.COMMUNITIES.ARTICLES.CREATE')}
          </Button>
        </Toolbar>
      </AppBar>

      {isFetching && <LinearProgress className={classes.progress} />}
      {!isFetching && articlesReady && (
        <Paper>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {headCells.map(headCell => (
                    <TableCell
                      align={headCell.id === 'download' ? 'center' : 'left'}
                      key={JSON.stringify(headCell)}
                    >
                      <Typography variant="h6" gutterBottom component="p">
                        {t(headCell.label)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {holdingArticles.map(article => {
                  return (
                    <RowCommunityArticle
                      key={article.id}
                      article={article}
                      onDeleteArticle={onDeleteArticle}
                      handleEditArticle={handleEditArticle}
                      canEditDelete={CheckRoleRule(community.role, 'articles:editdelete', {
                        userId: currentUser?.id,
                        articleOwnerId: article.helper?.id,
                      })}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {!isFetching &&
        community?.holding?.slug === holdingSlug &&
        articles &&
        articles.length === 0 && <EmptyList id="APP.COMMUNITIES.ARTICLES.EMPTY" level="info" />}
      <Dialog open={openCommunityPostFormDialog} onClose={onClose} fullWidth maxWidth="lg">
        <CommunityPostForm
          dialogTitle={
            articleToEdit
              ? 'APP.COMMUNITIES.ARTICLES_FORM.EDIT'
              : 'APP.COMMUNITIES.ARTICLES_FORM.CREATE'
          }
          titleLabel="APP.COMMUNITIES.ARTICLES_FORM.LABEL.TITLE"
          rawLabel="APP.COMMUNITIES.ARTICLES_FORM.LABEL.RAW"
          post={articleToEdit}
          handleSubmit={handleSubmit}
          onClose={onClose}
          withFiles
        />
      </Dialog>
    </>
  );
};

export default CommunityArticles;
