import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from '@material-ui/core/Dialog';

import moment from 'moment';
import { green, orange } from '@material-ui/core/colors';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import TasksActions from '../../../Redux/TasksRedux';
import RowTwigMembers from './RowTwigMembers';
import { getCurrentDate, getOrgaKey } from '../../../Services/DataHelper';
import ReccurrenceActionDialog from './ReccurrenceActionDialog';
import { CheckRoleRule } from '../../../Acl/Rules';
import { getRole } from '../../../Acl/Controls';
import ShowFamilyTwigDialog from '../../Families/ShowFamilyTwigDialog';
import RegisterMember from './RegisterMember';

const useStyles = makeStyles(theme => ({
  greyBackground: {
    backgroundColor: theme.palette.background.default,
  },
  green: {
    color: green[600],
    fontSize: 'medium',
  },
  orange: {
    color: orange[500],
    fontSize: 'medium',
  },
  margin: {
    margin: theme.spacing(2),
  },
  iconSize: {
    '&>*': {
      fontSize: 30,
    },
  },
}));

type Props = {
  twig: Object,
  currentUser: Object,
  organisation: Object,
};

const RowTwig = ({ twig, currentUser, organisation }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    family_slug: familySlug,
  } = match.params;

  const [open, setOpen] = React.useState(false);
  const [openDiag, setOpenDiag] = React.useState(false);
  const [action, setAction] = React.useState(null);
  const [showFamilyTwig, setShowFamilyTwig] = React.useState(false);
  const isFetchingTwig = useSelector(state => state.tasks.isFetching.getTwig);
  const allHelpersValidated = twig?.helpers_not_validated_count === 0;
  const twigOver = twig.event_date < getCurrentDate();
  const isFamilyTwig = Boolean(!twig?.task?.holding_slug);
  const canEdit = CheckRoleRule(
    getRole(currentUser, holdingSlug, organisationSlug, familySlug),
    'tasks:editdelete',
    {
      userId: currentUser?.id,
      taskOwnerId: twig?.task?.helper_id,
      taskOrgaKey: getOrgaKey(twig?.task?.holding_slug, twig?.task?.organisation_slug),
      pageOrgaKey: getOrgaKey(familySlug ? null : holdingSlug, familySlug || organisationSlug),
    },
  );

  const taskLink = React.useMemo(() => {
    if (!twig) return '';

    if (familySlug) {
      return `/families/${familySlug}/schedule/${twig.task.slug}`;
    }

    return `/${twig.task.holding_slug}/${twig.task.organisation_slug}/activites/${twig.task.slug}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingSlug, organisationSlug, familySlug, twig]);

  const handleSkipOccurrence = () => {
    dispatch(
      TasksActions.skipOccurenceRequest(
        holdingSlug,
        organisationSlug,
        isFamilyTwig && organisation.slug,
        twig.task.slug,
        new Date(twig.event_date),
      ),
    );
    setAction(null);
  };

  const handleDestroySerie = () => {
    dispatch(
      TasksActions.destroyTaskRequest(holdingSlug, organisationSlug, familySlug, twig.task.slug),
    );
    setAction(null);
  };

  const handleAddHelper = (event, selectedHelper, helpedId) => {
    dispatch(
      TasksActions.registerHelperOnTaskRequest(
        holdingSlug,
        organisationSlug,
        familySlug,
        twig.task.slug,
        selectedHelper.id,
        twig.event_date,
        helpedId,
        null,
      ),
    );
    setOpenDiag(false);
    event.preventDefault();
  };

  const handleRecurrenceDialogConfirm = actionChoice => {
    if (action === 'delete') {
      if (actionChoice === 'occurrence') handleSkipOccurrence();
      else if (actionChoice === 'all') handleDestroySerie();
    }
  };

  const handleClickOnRow = () => {
    if (!open && twig.id) {
      dispatch(TasksActions.getTwigRequest(holdingSlug, organisationSlug, familySlug, twig.id));
    }
    setOpen(!open);
  };

  return (
    <>
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={handleClickOnRow}>
          <TableCell>
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <Grid className={classes.iconSize}>
              {getExpertiseStyle(twig?.task?.expertise).icon}
            </Grid>
          </TableCell>
          <TableCell>
            {`${moment(twig?.event_date).format('dddd LL')} ${twig?.event_start_time} - ${
              twig?.task?.locality
            }`}
            <Typography variant="h6" gutterBottom component="h5">
              {twig?.task?.description}
            </Typography>
          </TableCell>
          <TableCell>{twig?.task?.posts_count}</TableCell>
          <TableCell>
            {twig?.task?.registration
              ? `${twig?.helpers_count}/${twig?.task?.max_participants}`
              : t('NOT_OPENED')}
          </TableCell>
          {!isFamilyTwig && (
            <TableCell>
              {twig?.task?.registration && (
                <FiberManualRecordIcon
                  className={allHelpersValidated ? classes.green : classes.orange}
                />
              )}
            </TableCell>
          )}
        </TableRow>
        <TableRow className={classes.greyBackground}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={1} />
                      <TableCell colSpan={7}>
                        <Typography variant="h6" gutterBottom className={classes.margin}>
                          {(!twig?.helpers || twig?.helpers?.length === 0) &&
                            isFetchingTwig &&
                            t('APP.ACTIVITY.PARTICIPANTS.SEARCHING')}
                        </Typography>
                        <Typography variant="h6" gutterBottom className={classes.margin}>
                          {twig?.helpers?.length > 0 && t('APP.ACTIVITY.PARTICIPANTS')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {twig?.helpers?.map(helper => (
                      <RowTwigMembers
                        key={helper.id}
                        helper={helper}
                        twig={twig}
                        currentUser={currentUser}
                      />
                    ))}
                    {twig?.helpers_not_interested?.map(helper => (
                      <RowTwigMembers
                        key={helper.id}
                        helper={helper}
                        twig={twig}
                        currentUser={currentUser}
                      />
                    ))}
                  </TableBody>
                </Table>
                <Grid align="right">
                  <IconButton
                    onClick={() =>
                      isFamilyTwig
                        ? setShowFamilyTwig(true)
                        : history.push(
                            `/${twig.task.holding_slug}/${twig.task.organisation_slug}/activites/${twig.task.slug}/date/${twig.event_date}`,
                          )
                    }
                  >
                    <Tooltip title={t('CONSULT')} placement="bottom">
                      <VisibilityIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    component={Link}
                    to={`${taskLink}/edit`}
                    disabled={twigOver || !canEdit}
                  >
                    <Tooltip title={t('EDIT')} placement="bottom">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton component={Link} to={`${taskLink}/copy`}>
                    <Tooltip title={t('DUPLICATE')} placement="bottom">
                      <ContentCopyIcon />
                    </Tooltip>
                  </IconButton>
                  {twig?.task?.registration && (
                    <IconButton onClick={() => setOpenDiag(true)} disabled={twigOver}>
                      <Tooltip
                        title={t('APP.ACTIVITY.PARTICIPANTS.REGISTER_SOMEONE')}
                        placement="bottom"
                      >
                        <AddCircleIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                  <IconButton
                    onClick={
                      twig?.task?.recurrence_type !== 'NONE'
                        ? () => setAction('delete')
                        : handleSkipOccurrence
                    }
                    aria-label="Delete"
                    aria-owns="Delete"
                    aria-haspopup="true"
                    disabled={twigOver || !canEdit}
                  >
                    <Tooltip title={t('DELETE')} placement="bottom">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>

                  <ReccurrenceActionDialog
                    open={action}
                    title={action === 'delete' ? t('APP.ACTIVITY.MENU.WHAT_TO_DELETE') : ''}
                    onClose={() => setAction(null)}
                    onConfirm={handleRecurrenceDialogConfirm}
                  />
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
      <Dialog open={openDiag} onClose={() => setOpenDiag(false)}>
        <RegisterMember
          organisation={organisation}
          handleClickInvite={handleAddHelper}
          allowedRoles={twig?.task?.registrable_roles}
          seatsAvailable={twig?.task?.max_participants - twig?.helpers?.length}
          onCancel={() => setOpenDiag(false)}
          registeredMembersIds={twig?.helpers?.map(h => h.id)}
        />
      </Dialog>
      <ShowFamilyTwigDialog
        visible={showFamilyTwig}
        onHide={() => setShowFamilyTwig(false)}
        twig={twig}
      />
    </>
  );
};

export default RowTwig;
