import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import ClubForm from './ClubForm';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
    textTransform: 'none',
    fontSize: '1.2rem',
    marginLeft: '8px',
  },
}));

const FirstClubCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openEditClub, setOpenEditClub] = React.useState(false);

  return (
    <>
      <Card style={{ padding: 24 }}>
        <Typography className={classes.grow} variant="h6">
          {t('APP.ORGANISATION.HOME.CLUBS.TITLE')}
        </Typography>
        <Grid style={{ textAlign: 'center' }}>
          <Button
            style={{ textTransform: 'none', marginTop: 24 }}
            onClick={() => setOpenEditClub(true)}
            variant="contained"
            color="primary"
          >
            {t('APP.ORGANISATION.HOME.CLUBS.CREATE')}
          </Button>
        </Grid>
      </Card>
      <Dialog open={openEditClub} onClose={() => setOpenEditClub(false)}>
        <ClubForm onClose={() => setOpenEditClub(false)} />
      </Dialog>
    </>
  );
};

export default FirstClubCard;
