import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { red } from '@material-ui/core/colors';

import { getPrimaryStatus } from '../../../Services/UserHelper';
import HelperDetails from '../../../Components/Shared/HelperDetails';
import { getCapitalizedName } from '../../../Services/DataHelper';

const useStyles = makeStyles(() => ({
  namesText: {
    fontWeight: '700',
    textTransform: 'capitalize',
  },
  namesTextRed: {
    fontWeight: '700',
    color: red[600],
    textTransform: 'capitalize',
  },
  cursor: {
    cursor: 'pointer',
  },
  tooltip: {
    backgroundColor: 'transparent !important',
    padding: 0,
  },
}));

type Props = {
  primary: Object,
};

const RowPrimary = ({ primary }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug, organisation_slug: orgaSlug } = match.params;
  const status = getPrimaryStatus(primary);

  return (
    <>
      <React.Fragment>
        <Tooltip
          title={<HelperDetails helper={primary.helper} elevation={2} />}
          placement="top"
          enterDelay={1000}
          enterNextDelay={1000}
          classes={{ tooltip: classes.tooltip }}
        >
          <TableRow
            key={primary?.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            hover
            onClick={() => history.push(`/${holdingSlug}/${orgaSlug}/primaries/${primary?.id}`)}
            className={classes.cursor}
          >
            <TableCell component="th" scope="row">
              <Tooltip title={t(status.title)} placement="left">
                <FiberManualRecordIcon style={{ color: status.color, fontSize: 'medium' }} />
              </Tooltip>
            </TableCell>
            <TableCell>
              <Typography className={primary.validated ? classes.namesText : classes.namesTextRed}>
                {getCapitalizedName(primary?.helper?.first_name, primary?.helper?.last_name)}
              </Typography>
              {!primary.validated && (
                <Typography variant="subtitle1" component="p">
                  {t('APP.ORGANISATION.SETTINGS.MEMBERS.MEMBER_TO_VALIDATE')}
                </Typography>
              )}
            </TableCell>
            <TableCell>{primary?.helper?.phone}</TableCell>
            <TableCell>{primary?.helper?.email}</TableCell>
            <TableCell>{primary?.helper?.locality}</TableCell>
          </TableRow>
        </Tooltip>
      </React.Fragment>
    </>
  );
};

export default RowPrimary;
