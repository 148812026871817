import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MUIGrid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';
import WelloAvatar from '../../Shared/WelloAvatar';
import AuthActions from '../../../Redux/AuthRedux';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    ...LayoutStyles.centered600Layout,
    marginTop: 0,
  },
  buttonContainer: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  form: {
    padding: theme.spacing(0, 2),
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    fontSize: '1.8rem',
  },
}));

type Props = {
  memberToResetPin: Object,
  onCloseDialog: Function,
};

const ResetMemberPin = ({ memberToResetPin, onCloseDialog }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [newPin, setNewPin] = React.useState('');
  const [pinError, setPinError] = React.useState(null);

  const checkPinValidity = pin => {
    // we removed restrictions to improve platform use (see later for rgpd)

    // const birthYear = currentUser.birthday.slice(0, 4);
    // const birthDayAndMonth = currentUser.birthday.slice(8, 10) + currentUser.birthday.slice(5, 7);
    // const birthMonthAndYear = currentUser.birthday.slice(5, 7) + currentUser.birthday.slice(2, 4);

    // if (
    //   pin?.match(birthYear) ||
    //   pin?.match(birthDayAndMonth) ||
    //   pin?.match(birthMonthAndYear) ||
    //   pin?.match(1234) ||
    //   pin?.match(/([0-9])\1{3}/)
    // ) {
    //   return false;
    // }

    if (pin?.match(/\d{4}/)) {
      return true;
    }
    return false;
  };
  const handleSubmitPin = event => {
    event.preventDefault();
    if (!checkPinValidity(newPin)) return;
    dispatch(AuthActions.changePinRequest(newPin, memberToResetPin.helper.id));
    onCloseDialog();
  };

  return (
    <Card itemScope className={classes.paper}>
      <CardHeader
        avatar={
          <WelloAvatar
            avatarUrl={memberToResetPin?.helper?.avatar_url}
            firstName={memberToResetPin?.helper?.first_name}
            lastName={memberToResetPin?.helper?.last_name}
            backgroundColor={memberToResetPin?.helper?.avatar_background_color}
            avatarClass={classes.bigAvatar}
            size={60}
          />
        }
        title={
          <>
            <Typography variant="h5">
              {memberToResetPin?.helper?.first_name} {memberToResetPin?.helper?.last_name}
            </Typography>
            <Typography variant="subtitle1">{memberToResetPin?.helper?.pseudo}</Typography>
          </>
        }
      />
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {t('APP.ORGANISATION.SETTINGS.MEMBERS.PIN.TITLE')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t('APP.ORGANISATION.SETTINGS.MEMBERS.PIN.SUBTITLE')}
        </Typography>
      </CardContent>
      <form onSubmit={handleSubmitPin} className={classes.form}>
        <TextField
          name="newPin"
          value={newPin}
          onChange={event => setNewPin(event.target.value)}
          placeholder={t('APP.USER.SECURITY.NEW_PIN')}
          variant="outlined"
          fullWidth
          required
          inputProps={{ type: 'password', maxLength: 4 }}
          onBlur={() => setPinError(!checkPinValidity(newPin))}
          onFocus={() => setPinError(false)}
          error={pinError}
          helperText={pinError && t('INCORRECT_PIN')}
        />
        <MUIGrid className={classes.buttonContainer}>
          <Button
            disabled={newPin === '' || pinError}
            color="primary"
            type="submit"
            variant="contained"
          >
            {t('SEND')}
          </Button>
        </MUIGrid>
      </form>
    </Card>
  );
};

export default ResetMemberPin;
