import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import { checkEmailValidity } from '../../Services/DataHelper';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

const MapWithPolygon = loadable(() => import('../Shared/MapWithPolygon'), { ssr: false });

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFilePoster,
  FilePondPluginImageTransform,
  FilePondPluginImageResize,
  FilePondPluginFileValidateType,
);

type Props = {
  onSubmit: Function,
  organisation: Object,
  currentUser: Object,
  holding: Object,
};

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textFieldRoot: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  headingContainer: {
    width: '100%',
    paddingBottom: theme.spacing(1),
  },
  titles: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: '1.2em',
  },
  formControl: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}));

const OrganisationForm = ({ holding, onSubmit, organisation, currentUser }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = React.useState(
    // eslint-disable-next-line camelcase
    organisation?.email || holding?.admin_email || currentUser?.email || '',
  );
  const [emailError, setEmailError] = React.useState(null);

  const [name, setName] = React.useState(organisation?.name || '');
  const [bannerFiles, setBannerFiles] = React.useState([]);
  const [bannerFilesCompressed, setBannerFilesCompressed] = React.useState([]);
  const [points, setPoints] = React.useState(organisation?.intervention_polygon || []);

  React.useEffect(() => {
    if (organisation && organisation.banner_url) {
      setBannerFiles([
        {
          source: organisation.banner_url,
          options: {
            type: 'local',
            file: { name: organisation.banner_url, size: 0 },
            metadata: { poster: organisation.banner_url },
          },
        },
      ]);
    }
    // eslint-disable-next-line
  }, []);

  const handleBannerSelected = fileItems => {
    const im = fileItems.map(fileItem => fileItem.file);
    setBannerFiles(im);
  };

  const handleClickSubmit = event => {
    event.preventDefault();
    if (!organisation && !checkEmailValidity(email)) return;

    const updateAttributes = {
      name,
      intervention_polygon: points,
    };

    onSubmit(
      updateAttributes,
      bannerFilesCompressed[0] || bannerFiles[0],
      email?.toLowerCase()?.trim() || null,
    );
  };

  return (
    <form onSubmit={handleClickSubmit}>
      <Grid item xs={12} className={classes.headingContainer}>
        <Grid item xs={12}>
          <Typography variant="h4" align="left" gutterBottom>
            {organisation &&
              t('APP.ORGANISATION.SETTINGS.GENERAL.TITLE', {
                value: organisation?.name,
              })}
            {!organisation && t('APP.ORGANISATION.SETUP.TITLE')}
          </Typography>
        </Grid>
      </Grid>
      <FormControl component="fieldset" fullWidth className={classes.formControl}>
        <TextField
          name="name"
          value={name}
          onChange={event => setName(event.target.value)}
          label={t('APP.ORGANISATION.SETUP.TEXT_FIELD.LABEL.NAME')}
          variant="outlined"
          fullWidth
          required
          placeholder={t('APP.ORGANISATION.SETUP.TEXT_FIELD.LABEL.PLACEHOLDER.NAME')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      {!organisation && (
        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          <TextField
            name="email"
            value={email}
            type="text"
            onChange={event => setEmail(event.target.value?.trim())}
            label={t('APP.ORGANISATION.SETTINGS.GENERAL.EMAIL')}
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
            onBlur={() => setEmailError(!checkEmailValidity(email))}
            error={emailError}
            helperText={emailError && t('INCORRECT_EMAIL')}
          />
        </FormControl>
      )}
      <Typography variant="h4" align="left" gutterBottom className={classes.titles}>
        {t('APP.ORGANISATION.SETTINGS.GENERAL.GEOGRAPHIC')}
      </Typography>
      <MapWithPolygon
        coordinates={organisation?.coordinates || holding?.coordinates}
        points={points}
        setPoints={setPoints}
        cities={organisation?.cities}
      />
      <br />
      <Typography variant="h4" align="left" gutterBottom className={classes.titles}>
        {t('APP.ORGANISATION.SETTINGS.GENERAL.BANNER')}
      </Typography>
      <FormControl component="fieldset" className={classes.formControl} fullWidth>
        <FilePond
          allowMultiple={false}
          maxFiles={1}
          files={bannerFiles}
          // imageValidateSizeMaxWidth={4000}
          // imageValidateSizeMaxHeight={3000}
          onupdatefiles={fileItems => handleBannerSelected(fileItems)}
          instantUpload={false}
          acceptedFileTypes={['image/png', 'image/jpeg', 'image/webp']}
          labelIdle={t('APP.USER.IMAGE_FIELD.PLACEHOLDER')}
          imageResizeTargetWidth="500"
          allowImageResize
          imageResizeMode="contain"
          onpreparefile={(file, output) => setBannerFilesCompressed([output])}
          imageResizeUpscale={false}
        />
      </FormControl>
      <Grid style={{ textAlign: 'center' }}>
        <Button type="submit" color="primary">
          {t('SEND')}
        </Button>
      </Grid>
    </form>
  );
};

export default OrganisationForm;
