import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import HoldingActions from '../../../Redux/HoldingRedux';
import HoldingForm from '../../../Components/Holdings/HoldingForm';

type Props = {
  holding: Object,
  onClose: Function,
};
const useStyles = makeStyles(theme => ({
  layout: LayoutStyles.centered600Layout,
  container: {
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(4),
  },
  headingContainer: {
    width: '100%',
  },
}));

const HoldingEdit = ({ holding, onClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (currentUser, holdingAttributes, image, charter) => {
    const updateAttributes = { ...holdingAttributes };
    if (holding) {
      updateAttributes.id = holding.id;
      updateAttributes.slug = holding.slug;
      updateAttributes.league_id = holding.league_id;
      dispatch(
        HoldingActions.updateHoldingRequest(
          holding,
          updateAttributes,
          image,
          charter,
          currentUser.id,
          null,
        ),
      );
    }
    if (onClose) onClose();
  };

  return (
    <Grid container>
      <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.headingContainer}>
          <Grid item xs={12}>
            <Typography variant="h4" align="left" gutterBottom>
              {t('APP.HOLDING.SETTINGS.GENERAL.TITLE', { value: holding?.name })}
            </Typography>
          </Grid>
        </Grid>
        <HoldingForm onSubmit={handleSubmit} holding={holding} />
      </Paper>
    </Grid>
  );
};

export default HoldingEdit;
