import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Dialog from '@material-ui/core/Dialog';
import SendIcon from '@material-ui/icons/Send';
import ImageIcon from '@material-ui/icons/Image';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinearProgress from '@material-ui/core/LinearProgress';

import TransmissionsActions from '../../Redux/TransmissionsRedux';
import TransmissionPostForm from '../../Components/Posts/TransmissionPostForm';
import CardTransmission from '../../Components/Organisations/Transmission/CardTransmission';
import OrganisationMembershipActions from '../../Redux/OrganisationMembershipRedux';
import Roles from '../../Acl/Roles';
import Routes from '../../Routing/Routes';
import { getCapitalizedName } from '../../Services/DataHelper';

const useStyles = makeStyles(theme => ({
  appbar: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  title: {
    fontWeight: '800',
    textTransform: 'none',
  },
  postContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  postContainerDisabled: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    '&>*': {
      '&>*': {
        color: 'grey',
      },
    },
  },
  postLabel: {
    height: 80,
  },
}));

type Props = {
  organisation: Object,
};

const ShowTransmissions = ({ organisation }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    item_id: primaryMembershipId,
  } = match.params;
  const primariesMemberships = useSelector(state => state.organisationMembership.members);
  const primaryMembership = primariesMemberships?.find(
    p => p.id === parseInt(primaryMembershipId, 10),
  );
  const transmissions = useSelector(state => state.transmissions.transmissions);
  const isFetching = useSelector(
    state => state.transmissions.isFetching.getOrganisationTransmissions,
  );
  const isFetchingMembership = useSelector(
    state => state.organisationMembership.isFetching.getOrganisationMembership,
  );
  const [showNewTransmission, setShowNewTransmission] = React.useState(false);

  const transmissionsFound =
    transmissions &&
    transmissions.length !== 0 &&
    transmissions[0].helpers_organisation_id === parseInt(primaryMembershipId, 10) &&
    transmissions[0].organisation_slug === organisationSlug &&
    transmissions[0].holding_slug === holdingSlug;

  React.useEffect(() => {
    dispatch(
      TransmissionsActions.getOrganisationTransmissionsRequest(
        holdingSlug,
        organisationSlug,
        primaryMembershipId,
      ),
    );
  }, [dispatch, holdingSlug, organisationSlug, primaryMembershipId]);

  React.useEffect(() => {
    if (organisation && !primaryMembership) {
      dispatch(
        OrganisationMembershipActions.getOrganisationMembershipRequest(
          organisation.holding_slug,
          organisation.slug,
          Roles.PRIMARY,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation?.slug, dispatch]);

  return (
    <>
      {primaryMembership && (
        <Card>
          <AppBar position="static" color="inherit" elevation={1} className={classes.appbar}>
            <Toolbar className={classes.grow}>
              <Grid className={classes.grow}>
                <Typography className={classes.title} variant="h4" component="h3" align="left">
                  {isFetchingMembership
                    ? t('APP.ORGANISATION.SETTINGS.PRIMARY.TITLE')
                    : t('APP.ORGANISATION.SETTINGS.PRIMARY.USER.TITLE', {
                        user: getCapitalizedName(
                          primaryMembership?.helper?.first_name,
                          primaryMembership?.helper?.last_name,
                          true,
                        ),
                      })}
                </Typography>
              </Grid>
            </Toolbar>
          </AppBar>
          <Divider light />
          <CardActionArea
            onClick={() => setShowNewTransmission(true)}
            disabled={Routes.currentNamespace() === 'SLA'}
          >
            <Grid
              container
              className={
                Routes.currentNamespace() === 'SLA'
                  ? classes.postContainerDisabled
                  : classes.postContainer
              }
            >
              <Grid>
                <Typography component="h4" className={classes.postLabel}>
                  {t('APP.POST.FIELD.MESSAGE')}
                </Typography>
                <Grid container>
                  <ImageIcon />
                  <AttachFileIcon />
                </Grid>
              </Grid>
              <Grid>
                <SendIcon color="primary" />
              </Grid>
            </Grid>
          </CardActionArea>
          <Divider light />
          {isFetching && <LinearProgress />}
          {transmissionsFound &&
            transmissions.map(transmission => (
              <CardTransmission
                key={transmission.id}
                transmission={transmission}
                primaryMembership={primaryMembership}
              />
            ))}
        </Card>
      )}
      <Dialog open={showNewTransmission} onClose={() => setShowNewTransmission(false)} fullWidth>
        <TransmissionPostForm
          primaryMembership={primaryMembership}
          onclose={() => setShowNewTransmission(false)}
        />
      </Dialog>
    </>
  );
};

export default ShowTransmissions;
