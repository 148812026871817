import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import Grid from '@material-ui/core/Grid';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@mui/material/Drawer';
import OrganisationScheduler from './OrganisationScheduler';
import { sameMonth } from '../../../Services/SchedulerHelper';
import { useTranslation } from 'react-i18next';
import { formatMonthAndYear } from '../../../Services/DataHelper';

type Props = {
  scheduleData: Array,
  currentUser: Object,
  onClose: Function,
  currentDate: String,
};

const FullScreenScheduler = ({ scheduleData, currentUser, onClose, currentDate }: Props) => {
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Drawer
        sx={{
          width: 50,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 50,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <IconButton color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handlePrint} aria-label="print">
          <PrintIcon />
        </IconButton>
      </Drawer>
      <Grid style={{ paddingLeft: 50 }}>
        <Grid style={{ paddingLeft: 24, paddingRight: 24 }} ref={componentRef}>
          <Typography style={{ padding: 16, paddingBottom: 32, textAlign: 'center' }} variant="h3">
            {t('APP.ACTIVITY.FULLSCREEN.TITLE', { value: formatMonthAndYear(currentDate) })}
          </Typography>
          <OrganisationScheduler
            isFullScreen
            data={scheduleData.filter(d =>
              sameMonth(new Date(d.twig.event_date), new Date(currentDate)),
            )}
            currentUser={currentUser}
            currentDate={currentDate}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FullScreenScheduler;
