import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';

type Props = {
  organisation: Object,
  onClose: Function,
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    maxWidth: '400px',
  },
  title: {
    padding: theme.spacing(2),
  },
  services: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  buttonContainer: {
    textAlign: 'center',
  },
  switch: {
    paddingBottom: theme.spacing(2),
  },
  subtitle: { paddingBottom: theme.spacing(1) },
}));

const NotificationsSettings = ({ organisation, onClose }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.auth.currentUser);

  // const [emailAlerts, setEmailAlerts] = React.useState(
  //   organisation?.notifications?.email_alerts === false
  //     ? organisation?.notifications?.email_alerts
  //     : true,
  // );
  const [excludedServicesIds, setExcludedServicesIds] = React.useState(
    organisation?.notifications?.email_excluded_services || [],
  );

  const myServices = organisation?.services?.filter(s =>
    s.service?.notifiable_roles?.includes(organisation?.role),
  );

  const handleServiceChange = (id, checked) => {
    let ids = excludedServicesIds.slice();
    if (checked) ids = excludedServicesIds.filter(serviceId => serviceId !== id);
    if (!checked && !excludedServicesIds.includes(id)) ids.push(id);
    setExcludedServicesIds(ids);
  };

  const handleConfirm = () => {
    const updateAttributes = {
      email_alerts: true, // emailAlerts,
      email_excluded_services: excludedServicesIds,
    };
    dispatch(
      OrganisationMembershipActions.updateOrganisationMembershipRequest(
        organisation.holding_slug,
        organisation.slug,
        currentUser.id,
        updateAttributes,
        currentUser.id,
        'notif',
      ),
    );
    onClose();
  };

  return (
    <Paper className={classes.paper}>
      {/* <Typography variant="h4" gutterBottom className={classes.title}>
        {t('APP.ORGANISATION.SETTINGS.NOTIFICATIONS.TITLE')}
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={emailAlerts}
            onChange={event => setEmailAlerts(event.target.checked)}
            name="emailAlerts"
            color="primary"
          />
        }
        label={t('APP.ORGANISATION.SETTINGS.NOTIFICATIONS.EMAIL_ALERTS')}
        className={classes.switch}
      /> */}

      {myServices?.length > 0 && (
        <Grid className={classes.services}>
          <Typography variant="h6" gutterBottom className={classes.subtitle}>
            {t('APP.ORGANISATION.SETTINGS.NOTIFICATIONS.SERVICES')}
          </Typography>

          <FormGroup>
            {myServices.map(s => (
              <FormControlLabel
                key={s.service_id}
                disabled={false} // {!emailAlerts}
                control={
                  <Switch
                    checked={!excludedServicesIds.includes(s.service_id)}
                    onChange={event => handleServiceChange(s.service_id, event.target.checked)}
                    name={s.service?.label}
                    color="primary"
                  />
                }
                label={s.service?.label}
              />
            ))}
          </FormGroup>
        </Grid>
      )}

      <Grid className={classes.buttonContainer}>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <Button onClick={handleConfirm} color="primary">
          {t('SEND')}
        </Button>
      </Grid>
    </Paper>
  );
};

export default NotificationsSettings;
