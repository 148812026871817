import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import OrganisationSignUp from '../../../Pages/Authentication/Organisation/SignUp';
import ContactSuccess from './ContactSuccess';
import ConnexionDialog from '../../Shared/ConnexionDialog';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(1),
  },
  btn: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 1.5, 1),
  },
}));

type Props = {
  organisation: Object,
  role: string,
};

const CardJoinUs = ({ organisation, role }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openSignUpPending, setOpenSignUpPending] = React.useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const handleSubmitClick = userEmail => {
    setEmail(userEmail);
    setOpenSignUp(false);
    setOpenSignUpPending(true);
  };

  const handleJoinClick = () => {
    if (isLoggedIn) {
      setOpenSignUp(true);
    } else {
      setOpenRegisterDialog(true);
    }
  };

  const handleOpenSignUp = () => {
    setOpenSignUp(true);
    setOpenRegisterDialog(false);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h4" align="left">
            {t(`APP.ORGANISATION.HOME.${role.toUpperCase()}`)}
          </Typography>
        </CardContent>
        <Button className={classes.btn} size="small" onClick={handleJoinClick}>
          {t('JOIN_US')}
        </Button>
      </Card>
      <Dialog open={openSignUp} onClose={() => setOpenSignUp(false)}>
        <OrganisationSignUp
          organisation={organisation}
          isDialog
          onSubmitClick={handleSubmitClick}
          signUpRole={role}
        />
      </Dialog>
      <Dialog open={openRegisterDialog} onClose={() => setOpenRegisterDialog(false)}>
        <ConnexionDialog
          dialogTitle={t('JOIN_US')}
          dialogContent={t('APP.ORGANISATION.HOME.SIGN_IN_TO_JOIN_US')}
          handleOpenSignUp={handleOpenSignUp}
        />
      </Dialog>
      <Dialog open={openSignUpPending} onClose={() => setOpenSignUpPending(false)}>
        <ContactSuccess
          organisation={organisation}
          title={t('WELLO.PENDING_SIGN_UP.TITLE.TASK')}
          description={!isLoggedIn && t('WELLO.PENDING_SIGN_UP.DESCRIPTION', { email })}
          subtitle={t('WELLO.PENDING_SIGN_UP.SUB_TITLE')}
        />
      </Dialog>
    </>
  );
};

export default CardJoinUs;
