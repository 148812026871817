import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import { Images, muiTheme } from '../../Themes';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2),
    maxWidth: '800px',
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  buttonLeft: {
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2),
  },
  actionArea: {
    width: 'fit-content',
    margin: 'auto',
  },
  cardTitle: {
    minHeight: 34,
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    margin: theme.spacing(2, 0, 3),
    '&>*': {
      margin: 0,
    },
  },
  cardMedia: {
    margin: theme.spacing(4, 'auto'),
    height: 200,
    objectFit: 'contain',
    width: 'auto',
  },
}));

type Props = {
  handleSubmit: Function,
  onClose: Function,
  isFirstConnexion: String,
  currentUserIsProOrMainHelper: Boolean,
};

const OpenCareNotebook = ({ handleSubmit, onClose, currentUserIsProOrMainHelper }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderCard = (cardSubtitle, cardSubtitle2, img) => {
    return (
      <Grid>
        <Typography align="center" variant="body2" component="h3">
          {t(cardSubtitle)}
        </Typography>
        <Typography
          variant="body2"
          component="h4"
          dangerouslySetInnerHTML={{
            __html: t(cardSubtitle2),
          }}
        />
        <CardMedia
          align="center"
          component="img"
          className={classes.cardMedia}
          image={img}
          alt={t('OPEN_CARENOTEBOOK')}
        />
      </Grid>
    );
  };

  return (
    <Paper className={classes.paper} style={{ backgroundColor: muiTheme.palette.grey[50] }}>
      <Grid container style={{ justifyContent: 'center' }}>
        <Grid item>
          <Typography align="center" component="h2" variant="h5" className={classes.marginBottom}>
            {t(
              currentUserIsProOrMainHelper
                ? 'APP.CARENOTEBOOK.DIALOG.TITLE.PRO'
                : 'APP.CARENOTEBOOK.DIALOG.TITLE',
            )}
          </Typography>
          <Grid container style={{ justifyContent: 'center' }}>
            {currentUserIsProOrMainHelper
              ? renderCard(
                  'APP.CARENOTEBOOK.DIALOG.SUBTITLE.FOR_BENEFICIARY',
                  'APP.CARENOTEBOOK.DIALOG.SUBTITLE_2.FOR_BENEFICIARY',
                  Images.illu.carenotebook_pro,
                )
              : renderCard(
                  'APP.CARENOTEBOOK.DIALOG.SUBTITLE.FOR_ME',
                  'APP.CARENOTEBOOK.DIALOG.SUBTITLE_2.FOR_ME',
                  Images.illu.carenotebook_for_me,
                )}
          </Grid>
        </Grid>
        <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Button
            className={classes.buttonLeft}
            variant="outlined"
            color="primary"
            onClick={onClose}
            size="small"
          >
            {t('NOT_NOW')}
          </Button>
          <Button
            color="primary"
            onClick={() => handleSubmit('forMe')}
            variant="contained"
            size="small"
          >
            {t(
              currentUserIsProOrMainHelper
                ? `APP.CARENOTEBOOK.DIALOG.LABEL.PRO`
                : `APP.CARENOTEBOOK.DIALOG.LABEL`,
            )}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OpenCareNotebook;
