import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MUIGrid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Input from '@material-ui/core/Input';
import FilterListIcon from '@material-ui/icons/FilterList';
import Hidden from '@material-ui/core/Hidden';
import Checkbox from '@material-ui/core/Checkbox';

import {
  Grid,
  Table,
  TableRowDetail,
  TableHeaderRow,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedSorting,
  SortingState,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import _get from 'lodash/get';
import { green, orange, red } from '@material-ui/core/colors';
import MembershipDetail from './MembershipDetail';
import WelloAvatar from '../../Shared/WelloAvatar';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
// import MembershipLogin from './MembershipLogin';
import InviteMemberByEmail from './InviteMemberByEmail';
import ResetMemberPin from './ResetMemberPin';
import UpdateMemberRole from './UpdateMemberRole';
import { getStatus } from '../../../Services/UserHelper';
import { CheckRoleRule } from '../../../Acl/Rules';

type Props = {
  members: Array,
  organisation: Object,
  onCheckBoxChange: Function,
  alreadySelected: Object,
};

const useStyles = makeStyles(() => ({
  names: {
    alignSelf: 'center',
  },
  namesText: {
    fontWeight: '700',
  },
  namesTextRed: {
    fontWeight: '700',
    color: red[600],
  },
  namesTextBlack: {
    fontWeight: '700',
  },
  subtitle: {
    fontSize: '0.9em',
    fontWeight: '350',
  },
  green: {
    color: green[600],
    fontSize: 'medium',
  },
  orange: {
    color: orange[600],
    fontSize: 'medium',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cell: {
    padding: 0,
  },
  largeCell: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  badge: {
    color: 'red',
    right: -8,
    top: 12,
  },
}));

const GridMembers = ({ members, organisation, onCheckBoxChange, alreadySelected }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUserId = useSelector(state => state.auth.currentUser?.id);
  const [selectedMembers, setSelectedMembers] = React.useState([]);
  const [showInviteByEmail, setShowInviteByEmail] = React.useState(false);
  const [showResetPin, setShowResetPin] = React.useState(false);
  const [showUpdateRole, setShowUpdateRole] = React.useState(false);
  const [memberToUpdate, setMemberToUpdate] = React.useState(null);

  // const updatedMembership = useSelector(state => state.organisationMembership.updateMembership);
  // const password = React.useRef();
  // const showMembershipLogin =
  //   updatedMembership && !updatedMembership.helper.email && password.current;

  const handleCheckBoxChange = memberId => () => {
    const newMembers = selectedMembers.slice();
    if (!alreadySelected.includes(memberId)) {
      if (newMembers.includes(memberId)) newMembers.splice(newMembers.indexOf(memberId), 1);
      else newMembers.push(memberId);
    }
    onCheckBoxChange(memberId);
    setSelectedMembers(newMembers);
  };

  const onValidateMember = membership => {
    const updateAttributes = {
      id: membership?.id,
      role: membership?.role,
      validated: true,
    };
    dispatch(
      OrganisationMembershipActions.updateOrganisationMembershipRequest(
        organisation.holding_slug,
        organisation.slug,
        membership?.helper_id,
        updateAttributes,
      ),
    );
  };

  const onRemoveMember = membership => {
    dispatch(
      OrganisationMembershipActions.destroyOrganisationMembershipRequest(
        organisation.holding_slug,
        organisation.slug,
        membership.helper_id,
        membership.id,
      ),
    );
  };

  // const onResetPassword = membership => {
  //   password.current = Math.floor(1000 + Math.random() * 9000).toString();
  //   const updateAttributes = {
  //     id: membership.id,
  //     password: password.current,
  //   };
  //   dispatch(
  //     OrganisationMembershipActions.updateOrganisationMembershipRequest(
  //       organisation.holding_slug,
  //       organisation.slug,
  //       membership.helper_id,
  //       updateAttributes,
  //     ),
  //   );
  // };

  const onResetPin = membership => {
    setShowResetPin(true);
    setMemberToUpdate(membership);
  };

  const onUpdateRole = membership => {
    setShowUpdateRole(true);
    setMemberToUpdate(membership);
  };

  const onClickInviteWithEmail = membership => {
    if (!membership?.helper?.email) {
      setShowInviteByEmail(true);
      setMemberToUpdate(membership);
    } else {
      dispatch(
        OrganisationMembershipActions.resendInvitationRequest(
          membership.holding_slug,
          membership.organisation_slug,
          membership.helper_id,
        ),
      );
    }
  };

  const onCloseDialog = () => {
    setShowInviteByEmail(false);
    setMemberToUpdate(null);
  };

  // const onClose = () => {
  //   dispatch(OrganisationMembershipActions.resetNewMembership());
  // };

  const onClosePinDialog = () => {
    setShowResetPin(false);
    setMemberToUpdate(null);
  };

  const onCloseRoleDialog = () => {
    setShowUpdateRole(false);
    setMemberToUpdate(null);
  };

  const getName = row => {
    if (row?.helper && row.helper.first_name && row.helper.last_name)
      return `${row.helper.first_name} ${row.helper.last_name?.toUpperCase()}`;

    if (row?.helper && row.helper.email) return row.helper.email;

    return '';
  };

  const columns = [
    {
      name: 'name',
      title: (
        <Typography className={classes.namesText} style={{ fontWeight: '700' }}>
          {t('APP.ORGANISATION.SETTINGS.MEMBERS.COLUMN_NAME')}
        </Typography>
      ),
      getCellValue: row => (row.helper ? getName(row) : undefined),
    },
    {
      name: 'role',
      title: (
        <Typography className={classes.namesText}>
          {t('APP.ORGANISATION.SETTINGS.MEMBERS.COLUMN_ROLE')}
        </Typography>
      ),
      getCellValue: row => {
        if (row.role && row.validated) {
          return t(`APP.ROLE.VALIDATED.${row.role.toUpperCase()}`);
        }
        if (row.role && !row.validated) {
          return t(`APP.ROLE.PENDING.${row.role.toUpperCase()}`);
        }
        return undefined;
      },
    },
  ];

  if (onCheckBoxChange) {
    columns.unshift({
      name: 'selected',
      title: '',
      getCellValue: row =>
        selectedMembers.includes(row.helper_id) ? t('SELECTED') : t('NON_SELECTED'),
    });
  } else {
    columns.unshift({
      name: 'accountState',
      title: ' ',
      getCellValue: row =>
        row.helper.validation_status === 'waiting_for_email_validation' &&
        (row.helper?.email || !row.helper?.last_login_date)
          ? t('APP.ORGANISATION.SETTINGS.MEMBERS.ACCOUNT_NOT_CREATED')
          : t('APP.ORGANISATION.SETTINGS.MEMBERS.ACCOUNT_CREATED'),
    });
  }

  const MemberCell = state => {
    const { row } = state;
    const avatarUrl = _get(row, 'helper.avatar_url');
    // TODO: show if email validated or not
    return (
      <Table.Cell {...state} className={classes.largeCell}>
        <MUIGrid container className={classes.text}>
          {!onCheckBoxChange && (
            <Hidden only={['xs']}>
              <MUIGrid item xs={12} sm={2} className={classes.names}>
                <WelloAvatar
                  avatarUrl={avatarUrl}
                  firstName={row.helper?.first_name}
                  lastName={row.helper?.last_name}
                  backgroundColor={row.helper?.avatar_background_color}
                  size={36}
                />
              </MUIGrid>
            </Hidden>
          )}
          <MUIGrid item xs={12} sm={10} className={classes.names}>
            <Typography className={row.validated ? classes.namesText : classes.namesTextRed}>
              {getName(row)}
            </Typography>
            {!row.validated && (
              <Typography className={classes.subtitle}>
                {t('APP.ORGANISATION.SETTINGS.MEMBERS.MEMBER_TO_VALIDATE')}
              </Typography>
            )}
          </MUIGrid>
        </MUIGrid>
      </Table.Cell>
    );
  };

  const RoleCell = state => {
    const { row } = state;
    return (
      <Table.Cell {...state} className={classes.largeCell}>
        <Typography variant="subtitle2" component="p" className={classes.text}>
          {t(
            row.validated
              ? `APP.ROLE.VALIDATED.LONG.${row.role.toUpperCase()}`
              : `APP.ROLE.PENDING.${row.role.toUpperCase()}`,

            t(
              row.validated
                ? `APP.ROLE.VALIDATED.${row.role.toUpperCase()}`
                : `APP.ROLE.PENDING.${row.role.toUpperCase()}`,
            ),
          )}
        </Typography>
      </Table.Cell>
    );
  };

  const AccountCell = state => {
    const { row } = state;
    const status = getStatus(row);
    return (
      <Table.Cell {...state} className={classes.cell} style={{ paddingLeft: '0' }}>
        <MUIGrid container style={{ justifyContent: 'center' }}>
          <Tooltip title={t(status.title)} placement="left">
            <FiberManualRecordIcon style={{ color: status.color, fontSize: 'medium' }} />
          </Tooltip>
        </MUIGrid>
      </Table.Cell>
    );
  };

  const CheckBoxCell = state => {
    const { row } = state;
    return (
      <Table.Cell
        {...state}
        className={classes.cell}
        style={{ paddingLeft: '0', cursor: 'pointer' }}
      >
        <MUIGrid container style={{ justifyContent: 'center', paddingLeft: '0' }}>
          <Checkbox
            name={row.helper_id}
            onChange={() => handleCheckBoxChange(row.helper_id)}
            checked={
              selectedMembers.includes(row.helper_id) || alreadySelected.includes(row.helper_id)
            }
            color="primary"
            disabled={alreadySelected.includes(row.helper_id)}
          />
        </MUIGrid>
      </Table.Cell>
    );
  };

  const Cell = state => {
    const { column } = state;
    switch (column.name) {
      case 'name':
        return <MemberCell {...state} />;
      case 'role':
        return <RoleCell {...state} />;
      case 'accountState':
        return <AccountCell {...state} />;
      case 'selected':
        return <CheckBoxCell {...state} />;
      default:
        return <Table.Cell {...state} />;
    }
  };

  const Row = state => {
    const { row } = state;
    const [openUserDiag, setOpenUserDiag] = React.useState(false);
    const canOpenUserDiag = CheckRoleRule(organisation?.role, 'members:read');
    const handleClickOpenUserDiag = () => {
      if (canOpenUserDiag) {
        setOpenUserDiag(true);
      }
    };
    return (
      <>
        <TableRowDetail.Row
          {...state}
          hover={canOpenUserDiag}
          style={{ cursor: canOpenUserDiag ? 'pointer' : 'default' }}
          onClick={onCheckBoxChange ? handleCheckBoxChange(row.helper_id) : handleClickOpenUserDiag}
        />
        <Dialog open={openUserDiag} onClose={() => setOpenUserDiag(false)}>
          <MembershipDetail
            membership={row}
            organisation={organisation}
            onRemoveMember={onRemoveMember}
            onValidateMember={onValidateMember}
            // onResetPassword={onResetPassword}
            onResetPin={onResetPin}
            onClickInviteWithEmail={onClickInviteWithEmail}
            onUpdateRole={onUpdateRole}
          />
        </Dialog>
      </>
    );
  };

  const FilterCellBase = state => {
    const { filter, onFilter, column } = state;
    return (
      <TableFilterRow.Cell style={{ fontWeight: 'normal' }}>
        <FilterListIcon style={{ color: 'grey', paddingRight: '8' }} />
        <Input
          placeholder={column.name === 'name' ? t('FILTER_BY_NAME') : t('FILTER_BY_ROLE')}
          value={filter ? filter.value : ''}
          onChange={e => onFilter(e.target.value ? { value: e.target.value } : null)}
          disableUnderline
        />
      </TableFilterRow.Cell>
    );
  };

  const FilterCell = state => {
    const { column } = state;
    if (column.name !== 'accountState' && column.name !== 'selected') {
      return <FilterCellBase {...state} />;
    }
    return <TableFilterRow.Cell {...state}>&nbsp;</TableFilterRow.Cell>;
  };

  return (
    <>
      <Grid rows={members} columns={columns} getRowId={member => member.id}>
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <SortingState />
        <IntegratedSorting />
        <Table
          columnExtensions={[
            {
              columnName: 'name',
              width: '50%',
              wordWrapEnabled: true,
            },
            {
              columnName: 'role',
              width: '40%',
              wordWrapEnabled: true,
            },
            {
              columnName: 'accountState',
              width: '10%',
              wordWrapEnabled: true,
            },
            {
              columnName: 'selected',
              width: '10%',
              wordWrapEnabled: true,
            },
          ]}
          cellComponent={Cell}
          rowComponent={Row}
          noDataCellComponent={() => <td colSpan={6} />}
        />
        <TableHeaderRow showSortingControls align />
        <TableFilterRow cellComponent={FilterCell} messages={{ filterPlaceholder: t('FILTER') }} />
      </Grid>
      {/* <Dialog open={showMembershipLogin}>
        <MembershipLogin
          password={password.current}
          updatedMembership={updatedMembership}
          onClose={() => onClose()}
        />
      </Dialog> */}
      <Dialog open={showResetPin} onClose={onClosePinDialog}>
        <ResetMemberPin memberToResetPin={memberToUpdate} onCloseDialog={onClosePinDialog} />
      </Dialog>
      <Dialog open={showInviteByEmail} onClose={() => setShowInviteByEmail(false)}>
        <InviteMemberByEmail
          memberToAddEmail={memberToUpdate}
          onCloseDialog={() => onCloseDialog()}
        />
      </Dialog>
      <Dialog open={showUpdateRole} onClose={onCloseRoleDialog}>
        <UpdateMemberRole
          memberToUpdateRole={memberToUpdate}
          organisation={organisation}
          currentUserId={currentUserId}
          onCloseDialog={onCloseRoleDialog}
        />
      </Dialog>
    </>
  );
};

export default GridMembers;
