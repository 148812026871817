import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import Grid from '@material-ui/core/Grid';
import OrganisationLayoutContainer from '../../Components/Layout/LayoutContainer';
import AuthMenu from '../../Components/Organisations/Shared/Menu';
import CarenoteBookTimeline from '../../Components/Organisations/Shared/CarenotebookTimeline';
import MenuCarenotebook from '../../Components/Organisations/Shared/MenuCarenotebook';
import {
  getSubscription,
  SubscribleTypes,
  SubscribleSubTypes,
} from '../../Services/SubscriptionHelper';
import AuthActions from '../../Redux/AuthRedux';
import CareNotebooksActions from '../../Redux/CareNotebooksRedux';
import {
  CHANNEL_CARENOTEBOOK,
  subscribeChannel,
  unsubscribeChannel,
} from '../../Services/WebSocket';
import { urlifyText } from '../../Services/DataHelper';

type Props = {
  family: Object,
  organisation: Object,
};

const CareNotebook = ({ family, organisation }: Props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);

  React.useEffect(() => {
    const channelSubscription = currentUser?.token
      ? subscribeChannel(
          currentUser?.token,
          { channel: CHANNEL_CARENOTEBOOK, organisation_id: family?.id },
          {
            received(data) {
              console.log(data);
              if (
                data &&
                data.helper_id !== currentUser.id &&
                data.post &&
                data.carenotebook_slug === SubscribleSubTypes.POSTS
              ) {
                dispatch(
                  CareNotebooksActions.createCareNotebookPostSuccess(family?.slug, data.post),
                );
                const sub = getSubscription(
                  organisation || family,
                  SubscribleTypes.FAMILY,
                  SubscribleSubTypes.POSTS,
                  family.id,
                );
                const date = new Date();
                dispatch(
                  AuthActions.updateHelperSubscriptionRequest(currentUser.id, {
                    ...sub,
                    last_read_date: date.toJSON(),
                  }),
                );
              }
            },
          },
        )
      : null;
    return () => {
      if (channelSubscription) unsubscribeChannel(channelSubscription);
    };
    // eslint-disable-next-line
  }, [dispatch, currentUser?.token, family]);

  const updateLastReadDate = React.useCallback(
    debounce(
      (sub, date) =>
        dispatch(
          AuthActions.updateHelperSubscriptionRequest(sub.helper_id, {
            ...sub,
            last_read_date: date.toJSON(),
          }),
        ),
      2000,
    ),
    [dispatch, organisation],
  );

  React.useEffect(() => {
    const sub = getSubscription(
      organisation || family,
      SubscribleTypes.FAMILY,
      SubscribleSubTypes.POSTS,
      family.id,
    );
    const date = new Date();
    if (family && sub) updateLastReadDate(sub, date);
    // eslint-disable-next-line
  }, [organisation?.slug, family?.slug]);

  const onEditPost = (post, attachments) => {
    const p = {
      ...post,
      raw: urlifyText(post.raw),
    };
    dispatch(
      CareNotebooksActions.updateCareNotebookPostRequest(family.slug, family.id, p, attachments),
    );
  };

  const onDeletePost = (hSlug, oSlug, postId) => {
    dispatch(CareNotebooksActions.deleteCareNotebookPostRequest(family.slug, family.id, postId));
  };

  const onCreateComment = (comment, attachments, postId) => {
    dispatch(
      CareNotebooksActions.createCareNotebookPostCommentRequest(family.slug, postId, comment),
    );
  };

  const onDeleteComment = (hSlug, oSlug, postId, comment) => {
    dispatch(
      CareNotebooksActions.deleteCareNotebookPostCommentRequest(family.slug, postId, comment.id),
    );
  };

  return (
    <>
      <OrganisationLayoutContainer
        menu={<AuthMenu organisation={organisation} family={family} />}
        rightMenu={
          <MenuCarenotebook
            family={family}
            organisation={organisation}
            onEditPost={onEditPost}
            onDeletePost={onDeletePost}
            onCreateComment={onCreateComment}
            onDeleteComment={onDeleteComment}
          />
        }
      >
        <Grid>
          <CarenoteBookTimeline
            organisation={organisation}
            family={family}
            onEditPost={onEditPost}
            onDeletePost={onDeletePost}
            onCreateComment={onCreateComment}
            onDeleteComment={onDeleteComment}
          />
        </Grid>
      </OrganisationLayoutContainer>
    </>
  );
};

export default CareNotebook;
