import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CalendarIcon from '@material-ui/icons/DateRange';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Hidden } from '@material-ui/core';

import { RoleCheck } from '../../Shared/Can';

const useStyles = makeStyles(theme => ({
  toggleGroup: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 0,
    },
  },
  toggleBtn: {
    padding: 4,
    width: 120,
    height: 48,
    [theme.breakpoints.only('xs')]: {
      width: 48,
      height: 'auto',
    },
  },
  toggleLabel: {
    fontSize: '10px',
    color: 'inherit',
    marginLeft: 4,
  },
}));

type Props = {
  typography: React.Component,
  viewMode: String,
  setViewMode: Function,
  filterArchived: String,
  setFilterArchived: Function,
  role: String,
  setShowScheduleDialog: Function,
  canCreateTask: Boolean,
  tooltipTitle: String,
  link: String,
};

const AppBarSchedule = ({
  typography,
  viewMode,
  setViewMode,
  filterArchived,
  setFilterArchived,
  role,
  setShowScheduleDialog,
  canCreateTask,
  tooltipTitle,
  link,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar position="static" color="inherit" elevation={1}>
      <Toolbar style={{ flexWrap: 'wrap' }}>
        {typography}
        {viewMode === 'grid' && (
          <Hidden only={['xs']}>
            <Select
              value={filterArchived}
              onChange={event => setFilterArchived(event.target.value)}
              input={<OutlinedInput style={{ height: 48 }} name="events" id="select-events" />}
              variant="outlined"
            >
              <MenuItem value="toCome">
                <ListItemText
                  primary={t('APP.ACTIVITY.TO_COME')}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
              <MenuItem value="over">
                <ListItemText
                  primary={t('APP.ACTIVITY.OVER')}
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </MenuItem>
            </Select>
          </Hidden>
        )}
        <RoleCheck
          role={role}
          action="tasks:manage"
          yes={() => (
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(event, newMode) => {
                if (newMode !== null) {
                  setViewMode(newMode);
                }
              }}
              aria-label="view-mode"
              className={classes.toggleGroup}
            >
              <ToggleButton value="grid" aria-label="grid" className={classes.toggleBtn}>
                <GroupAddIcon />
                <Hidden only={['xs']}>
                  <Typography className={classes.toggleLabel}>
                    {t('APP.ACTIVITY.REGISTRATION')}
                  </Typography>
                </Hidden>
              </ToggleButton>
              <ToggleButton value="schedule" aria-label="schedule" className={classes.toggleBtn}>
                <CalendarIcon fontSize="small" />
                <Hidden only={['xs']}>
                  <Typography className={classes.toggleLabel}>
                    {t('APP.ACTIVITY.CALENDAR')}
                  </Typography>
                </Hidden>
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        />
        {setShowScheduleDialog && (
          <Tooltip title={t('APP.ACTIVITY.PRINT')} placement="top">
            <IconButton color="inherit" onClick={() => setShowScheduleDialog(true)}>
              <OpenInFullIcon />
            </IconButton>
          </Tooltip>
        )}
        {canCreateTask && (
          <RoleCheck
            role={role}
            action="tasks:create"
            yes={() => (
              <Tooltip title={tooltipTitle} placement="top">
                <IconButton color="inherit" component={Link} to={link}>
                  <AddCircle />
                </IconButton>
              </Tooltip>
            )}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppBarSchedule;
