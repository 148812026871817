import React from 'react';
import 'moment/locale/fr';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';

import Stepper from './TaskFormStepper';
import TaskFormStep0 from './TaskFormStep0';
import TaskFormStep1 from './TaskFormStep1';
import TaskFormStep2 from './TaskFormStep2';
import TaskFormStep3 from './TaskFormStep3';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import { serializeDateFromDatePicker } from '../../../Services/FormHelpers';
import TasksActions from '../../../Redux/TasksRedux';
import HoldingActions from '../../../Redux/HoldingRedux';
import { getFamilyAddress, getFamilyAdminId } from '../../../Services/FamilyHelper';
import ConfirmationDialog from '../../../Components/Shared/ConfirmationDialog';
import { useTranslation } from 'react-i18next';

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFilePoster,
);

type Props = {
  task: Object,
  organisation: Object,
  family: Object,
};

const useStyles = makeStyles(theme => ({
  layoutContainer: {
    ...LayoutStyles.fullWidthLayout,
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
}));

const TaskStepForm = ({ task, organisation, family }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const location = useLocation();
  const {
    holding_slug: holdingSlug,
    organisation_slug: orgaSlug,
    family_slug: familySlug,
    task_slug: taskSlug,
  } = match.params;
  const [editTaskConfirmDialog, setEditTaskConfirmDialog] = React.useState(false);
  const expertises = useSelector(state => state.holding.allExpertises);
  const [activeStep, setActiveStep] = React.useState(0);
  const [newTask, setNewTask] = React.useState({
    id: task?.id || null,
    // Step 0
    expertiseId: task?.expertise.id || null,
    files: [],
    // Step 1
    excerpt: task?.excerpt || '',
    description: task?.description || '',
    relatedWords: task?.related_words || [],
    externalLink: task?.external_link || '',
    // Step 2
    formattedAddress:
      task?.formatted_address ||
      organisation?.holding?.formatted_address ||
      getFamilyAddress(family).address ||
      '',
    latitude:
      task?.coordinates?.latitude ||
      organisation?.holding?.coordinates?.latitude ||
      getFamilyAddress(family).latitude ||
      '',
    longitude:
      task?.coordinates?.longitude ||
      organisation?.holding?.coordinates?.longitude ||
      getFamilyAddress(family).longitude ||
      '',
    locality:
      task?.locality || organisation?.holding?.locality || getFamilyAddress(family).locality || '',
    startDate: serializeDateFromDatePicker(
      new Date(task?.start_date || location.state?.date || moment().add(7, 'days').format()),
    ),
    endDate: serializeDateFromDatePicker(
      new Date(
        task?.end_date ||
          moment(task?.start_date || location.state?.date || moment().add(7, 'days').format())
            .add(60, 'm')
            .format(),
      ),
    ),
    recurrence: (task?.recurrence_type && task?.recurrence_type !== 'NONE') || false,
    recurrenceType: task?.recurrence_type || 'NONE',
    recurrenceInterval: task?.recurrence_interval || 1,
    untilDate: task?.until_date ? serializeDateFromDatePicker(new Date(task?.until_date)) : null,
    // Step 3
    registration:
      task?.registration === null || task?.registration === undefined ? true : task?.registration,
    registrableRoles: family ? [] : task?.registrable_roles || ['admin'],
    maxParticipants: task?.max_participants || 10,
  });

  React.useEffect(() => {
    const activityExpertises = expertises?.filter(exp => exp.expertise_type === 'activity');
    const familyExpertises = expertises?.filter(exp => exp.expertise_type === 'family');
    if (
      !activityExpertises ||
      activityExpertises.length === 0 ||
      !familyExpertises ||
      familyExpertises.length === 0
    ) {
      dispatch(HoldingActions.getAllExpertisesRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNextClick = stepTask => {
    setNewTask({ ...newTask, ...stepTask });
    setActiveStep(activeStep + 1);
  };

  const onBackClick = stepTask => {
    setNewTask({ ...newTask, ...stepTask });
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = (event, stepTask) => {
    event.preventDefault();
    const data = { ...newTask, ...stepTask };
    const changedTask =
      task?.id &&
      (moment(data.startDate).format() !== moment(task?.start_date).format() ||
        data.formattedAddress !== task?.formatted_address);
    if (changedTask) {
      setNewTask({ ...newTask, ...stepTask });
      setEditTaskConfirmDialog(stepTask);
      return;
    }

    handleEditTask(stepTask);
  };

  const handleEditTask = stepTask => {
    const data = { ...newTask, ...stepTask };

    let taskToCreate = {
      expertise_id: data.expertiseId,
      excerpt: data.excerpt,
      description: data.description,
      related_words: data.relatedWords,
      external_link: data.externalLink,
      formatted_address: data.formattedAddress,
      latitude: data.latitude,
      longitude: data.longitude,
      locality: data.locality,
      start_date_fixed: true,
      start_date: data.startDate,
      end_date: data.endDate,
      recurrence_type: data.recurrenceType,
      recurrence_interval: data.recurrenceInterval,
      until_date: data.untilDate,
      registration: data.registration,
      registrable_roles: data.registrableRoles,
      max_participants: data.maxParticipants,
    };

    if (family) {
      taskToCreate = { ...taskToCreate, helped_id: getFamilyAdminId(family) };
    }
    if (task && task.id) {
      taskToCreate.id = task.id;
      taskToCreate.slug = task.slug;
      dispatch(
        TasksActions.updateTaskRequest(
          holdingSlug,
          orgaSlug,
          familySlug,
          taskToCreate,
          data.files[0] && data.files[0].size > 0 ? data.files[0] : null,
        ),
      );
    } else {
      dispatch(
        TasksActions.createTaskRequest(
          holdingSlug,
          orgaSlug,
          familySlug,
          taskToCreate,
          data.files[0] && data.files[0].size > 0 ? data.files[0] : null,
          taskSlug,
        ),
      );
    }
  };

  return (
    <>
      <Stepper isFamilyForm={family} activeStep={activeStep} />

      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Grid container className={classes.layoutContainer}>
          <Grid item md={2} />
          <Grid item md={8} xs={12}>
            <Paper className={classes.container}>
              {activeStep === 0 && (
                <TaskFormStep0
                  task={newTask}
                  setTask={setNewTask}
                  onNextClick={onNextClick}
                  coverUrl={task?.cover_url}
                  expertises={expertises}
                  family={family}
                />
              )}
              {activeStep === 1 && (
                <TaskFormStep1
                  task={newTask}
                  expertises={expertises}
                  setTask={setNewTask}
                  onBackClick={onBackClick}
                  onNextClick={onNextClick}
                  isFamily={!!family}
                />
              )}
              {activeStep === 2 && (
                <TaskFormStep2
                  task={newTask}
                  setTask={setNewTask}
                  onBackClick={onBackClick}
                  onNextClick={onNextClick}
                  isFamily={!!family}
                  onSubmitClick={handleSubmit}
                />
              )}
              {activeStep === 3 && !family && (
                <TaskFormStep3
                  task={newTask}
                  setTask={setNewTask}
                  onBackClick={onBackClick}
                  onSubmitClick={handleSubmit}
                  organisation={organisation}
                />
              )}
            </Paper>
          </Grid>
          <Grid item md={2} />
        </Grid>
      </form>
      <ConfirmationDialog
        openDialog={Boolean(editTaskConfirmDialog)}
        onCancel={() => setEditTaskConfirmDialog(null)}
        onClickConfirm={handleEditTask}
        dialogTitle={t('APP.ACTIVITY.CONFIRM.TITLE')}
        dialogContent={t('APP.ACTIVITY.CONFIRM.CONTENT')}
      />
    </>
  );
};

export default TaskStepForm;
