import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import isArray from 'lodash/isArray';

import Grid from '@material-ui/core/Grid';
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom';
import OrganisationElementsCard from './OrganisationElementsCard';
import { searchClient, INDEX_NAMES, FILTERING_ATTRIBUTES } from '../../../Services/AlgoliaHelper';
import images from '../../../Themes/Images';
import Routes from '../../../Routing/Routes';

type Props = {
  organisation: Object,
};

const useStyles = makeStyles(() => ({
  maxWidth: {
    width: '100%',
  },
}));

const ActivitiesBlock = ({ organisation }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid item className={classes.maxWidth}>
        <OrganisationElementsCard
          organisation={organisation}
          title="APP.ACTIVITIES.CARD.TITLE"
          subtitle="APP.ACTIVITIES.CARD.SUBTITLE"
          buttonCreateTitle="APP.ACTIVITIES.CARD.CREATE"
          buttonCreateRule="tasks:create"
          createLink={`/${organisation.holding_slug}/${organisation.slug}/activites/new`}
          isFetchingElements={false}
          emptylistMessage="APP.ACTIVITY.EMPTY"
          elements={organisation?.sampled_twigs?.map(twig => ({
            id: twig.event_date,
            title: twig.task.description,
            description: `${moment(twig.event_date).format('LL')} ${twig.event_start_time}`,
            link: `/${organisation.holding_slug}/${organisation.slug}/activites/${twig.task.slug}/date/${twig.event_date}`,
            cover_url: twig.task.cover_url || images.badge.ERROR,
          }))}
          seeMoreAction={() =>
            history.push(`/${organisation.holding_slug}/${organisation.slug}/activites`)
          }
        />
      </Grid>
      {organisation?.sampled_twigs && organisation.sampled_twigs.length === 0 && (
        <Grid item className={classes.maxWidth}>
          <InstantSearch searchClient={searchClient} indexName={INDEX_NAMES.TWIG}>
            <Configure
              filters={`${FILTERING_ATTRIBUTES.TWIG_DATE_ATTRIBUTE} > ${Math.floor(
                Date.now() / 1000,
              )}`}
            />
            <CustomAlgoliaTwigs organisation={organisation} />
          </InstantSearch>
        </Grid>
      )}
    </>
  );
};

type HitsProps = {
  hits: Array,
  organisation: Object,
};

const AlgoliaTwigs = ({ hits, organisation }: HitsProps) => {
  const randomHits = [];
  const hitsFromLeague = hits.filter(
    hit =>
      hit.a_league &&
      isArray(hit.a_league) &&
      hit.a_league.find(league => league.slug === Routes.currentLeague().slug),
  );
  if (hitsFromLeague && hitsFromLeague.length > 0) {
    while (randomHits.length < 3 && randomHits.length < hitsFromLeague.length) {
      const hit = hitsFromLeague[Math.floor(Math.random() * hitsFromLeague.length)];
      if (!randomHits.find(h => hit.objectID === h.objectID)) {
        randomHits.push(hit);
      }
    }
  }

  return (
    <>
      {randomHits && randomHits.length > 0 && (
        <OrganisationElementsCard
          organisation={organisation}
          title="APP.ALGOLIA_ACTIVITIES.CARD.TITLE"
          subtitle="APP.ACTIVITIES.CARD.SUBTITLE"
          elements={randomHits?.map(hit => ({
            id: hit.a_task_id,
            title: hit.a_description,
            description: `${moment(hit.a_event_date).format('LL')} ${hit.a_event_start_time}`,
            link: `/${hit.a_holding_slug}/${hit.a_organisation_slug}/activites/${hit.a_slug}/date/${hit.a_event_date}`,
            cover_url: hit.a_cover_url,
          }))}
        />
      )}
    </>
  );
};

const CustomAlgoliaTwigs = connectHits(AlgoliaTwigs);

export default ActivitiesBlock;
