import { CheckRoleRule, CheckOrgaRule } from '../../Acl/Rules';

type Props = {
  type: String,
  role: String,
  action: String,
  data: Object,
  yes: Function,
  no: Function,
};

export const RoleCheck = ({ role, action, data, yes, no }: Props) =>
  CheckRoleRule(role, action, data) ? yes() : no();

RoleCheck.defaultProps = {
  yes: () => null,
  no: () => null,
};

export const OrgaCheck = ({ type, action, data, yes, no }: Props) =>
  CheckOrgaRule(type, action, data) ? yes() : no();

OrgaCheck.defaultProps = {
  yes: () => null,
  no: () => null,
};
