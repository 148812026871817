import React from 'react';
import loadable from '@loadable/component';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const ReactMarkdown = loadable(() => import('react-markdown'), { ssr: false });
const gfm = loadable(() => import('remark-gfm'), { ssr: false });
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { grey } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';

import WelloAvatar from '../Shared/WelloAvatar';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  minePostDirection: {
    flexDirection: 'row-reverse',
    flexWrap: 'nowrap',
    padding: '4px',
  },
  othersPostDirection: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '4px',
  },
  avatar: {
    margin: theme.spacing(0, 0.5),
    height: 36,
    width: 36,
  },
  card: {
    maxWidth: '400px',
    borderRadius: theme.spacing(2),
  },
  minePostCard: {
    borderTopRightRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    overflow: 'auto',
    '&>*': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    whiteSpace: 'pre-wrap',
  },
  othersPostCard: {
    borderTopLeftRadius: theme.spacing(0.5),
    backgroundColor: grey[100],
    color: '#000',
    overflow: 'auto',
    '&>*': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    whiteSpace: 'pre-wrap',
  },
  cardContent: {
    '&:last-child': {
      padding: theme.spacing(0, 2),
    },
  },
  myName: {
    fontSize: '0.65rem',
    textAlign: 'right',
  },
  othersName: {
    fontSize: '0.65rem',
    textAlign: 'left',
  },
}));

type Props = {
  post: Object,
  currentUser: Object,
};

const ClubPost = ({ post, currentUser }: Props) => {
  const classes = useStyles();
  const isMine = post?.helper?.id === currentUser?.id;

  const renderAvatar = () => {
    return (
      <Tooltip title={`${post.helper_first_name} ${post.helper_last_name}`} placement="top">
        <div style={{ paddingTop: 18 }}>
          <WelloAvatar
            avatarUrl={post.helper?.avatar_url}
            firstName={post.helper?.first_name}
            lastName={post.helper?.last_name}
            backgroundColor={post.helper?.avatar_background_color}
            avatarClass={classes.avatar}
            size={36}
          />
        </div>
      </Tooltip>
    );
  };

  return (
    <Grid container className={isMine ? classes.minePostDirection : classes.othersPostDirection}>
      {post?.helper && renderAvatar()}
      <Grid>
        <Typography gutterBottom className={isMine ? classes.myName : classes.othersName}>
          {post.helper_first_name}
        </Typography>
        <Tooltip
          title={moment(post.created_at).format('LT')}
          placement={isMine ? 'left-start' : 'right-start'}
        >
          <Card
            className={classNames(
              classes.card,
              isMine ? classes.minePostCard : classes.othersPostCard,
            )}
            elevation={0}
          >
            <CardContent className={classes.cardContent}>
              <ReactMarkdown
                plugins={[gfm]}
                className={classNames(
                  classes.cardContentText,
                  isMine ? classes.minePostCard : classes.othersPostCard,
                )}
              >
                {post.raw}
              </ReactMarkdown>
            </CardContent>
          </Card>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ClubPost;
