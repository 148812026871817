import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';

type Props = {
  isFamilyForm: Object,
  activeStep: Object,
};

const useStyles = makeStyles(() => ({
  layoutContainer: {
    ...LayoutStyles.fullWidthLayout,
  },

  stepperContainer: {
    backgroundColor: 'white',
  },
}));

const TaskFormStepper = ({ isFamilyForm, activeStep }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const steps = [
    {
      id: 0,
      custom: `APP.ACTIVITY.STEP_0.STEPPER`,
      default: `APP.ACTIVITY.STEP_0.STEPPER`,
    },
    {
      id: 1,
      custom: `APP.ACTIVITY.STEP_1.STEPPER`,
      default: `APP.ACTIVITY.STEP_1.STEPPER`,
    },
    {
      id: 2,
      custom: `APP.ACTIVITY.STEP_2.STEPPER`,
      default: `APP.ACTIVITY.STEP_2.STEPPER`,
    },
  ];

  if (!isFamilyForm) {
    steps.push({
      id: 3,
      custom: `APP.ACTIVITY.STEP_3.STEPPER`,
      default: `APP.ACTIVITY.STEP_3.STEPPER`,
    });
  }

  return (
    <div className={classes.stepperContainer}>
      <Stepper activeStep={activeStep} alternativeLabel className={classes.layoutContainer}>
        {steps.map(label => (
          <Step key={label.id}>
            <StepLabel>{t(label.custom, t(label.default))}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default TaskFormStepper;
