import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MUIGrid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import InfoIcon from '@material-ui/icons/Info';

import LayoutStyles from '../../Layout/Styles/LayoutStyles';
import WelloAvatar from '../../Shared/WelloAvatar';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import Role, { getRolesForOrganisation } from '../../../Acl/Roles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    ...LayoutStyles.centered600Layout,
    marginTop: 0,
  },
  buttonContainer: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  form: {
    padding: theme.spacing(0, 2),
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    fontSize: '1.8rem',
  },
  info: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(1, 0),
    color: 'initial',
  },
  infoIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

type Props = {
  memberToUpdateRole: Object,
  organisation: Object,
  onCloseDialog: Function,
  currentUserId: Number,
};

const ResetMemberPin = ({
  memberToUpdateRole,
  organisation,
  currentUserId,
  onCloseDialog,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [role, setRole] = React.useState(memberToUpdateRole?.role);

  const handleUpdateRole = event => {
    event.preventDefault();
    const updateAttributes = {
      id: memberToUpdateRole.id,
      role,
    };
    dispatch(
      OrganisationMembershipActions.updateOrganisationMembershipRequest(
        organisation.holding_slug,
        organisation.slug,
        memberToUpdateRole.helper_id,
        updateAttributes,
        currentUserId,
      ),
    );
    onCloseDialog();
  };

  return (
    <Card itemScope className={classes.paper}>
      <CardHeader
        avatar={
          <WelloAvatar
            avatarUrl={memberToUpdateRole?.helper?.avatar_url}
            firstName={memberToUpdateRole?.helper?.first_name}
            lastName={memberToUpdateRole?.helper?.last_name}
            backgroundColor={memberToUpdateRole?.helper?.avatar_background_color}
            avatarClass={classes.bigAvatar}
            size={60}
          />
        }
        title={
          <>
            <Typography variant="h5">
              {memberToUpdateRole?.helper?.first_name} {memberToUpdateRole?.helper?.last_name}
            </Typography>
            <Typography variant="subtitle1">{memberToUpdateRole?.helper?.pseudo}</Typography>
          </>
        }
      />
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {t('APP.ORGANISATION.SETTINGS.MEMBERS.UPDATE_ROLE')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t('APP.ORGANISATION.SETTINGS.MEMBERS.UPDATE_ROLE.SUBTITLE')}
        </Typography>
      </CardContent>
      <form onSubmit={handleUpdateRole} className={classes.form}>
        <FormControl variant="outlined" component="fieldset" margin="none" fullWidth>
          <Select
            value={role}
            onChange={event => setRole(event.target.value)}
            input={<OutlinedInput labelWidth={0} name="role" id="role-simple" />}
            variant="outlined"
          >
            {getRolesForOrganisation(organisation, t, [Role.PRIMARY]).map(organisationRole => (
              <MenuItem
                key={`select-item-${organisationRole.value}`}
                value={organisationRole.value}
              >
                {organisationRole.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {role === Role.ADMIN && (
          <Typography variant="subtitle2" className={classes.info}>
            <InfoIcon className={classes.infoIcon} />
            {t('APP.ORGANISATION.SETTINGS.MEMBERS.UPDATE_ROLE.ADMIN')}
          </Typography>
        )}
        <MUIGrid className={classes.buttonContainer}>
          <Button color="primary" type="submit" variant="contained">
            {t('SEND')}
          </Button>
        </MUIGrid>
      </form>
    </Card>
  );
};

export default ResetMemberPin;
