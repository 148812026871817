import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';

import OrganisationActions from '../../Redux/OrganisationRedux';
import HoldingBanner from '../../Components/Organisations/Home/HoldingBanner';
import NoMatch404 from '../NoMatch404';
import NoMatch401 from '../NoMatch401';
import OrganisationLayoutContainer from '../../Components/Layout/LayoutContainer';
import CardCommunityHome from './CardCommunityHome';
import About from '../../Components/Organisations/Home/About';
import OrganisationTimeline from '../../Components/Organisations/Home/OrganisationTimeline';
import MembershipSettings from '../Organisations/Settings/MembershipSettings';
import MenuClub from '../../Components/Organisations/Shared/MenuClub';
import MenuItemClubs from '../../Components/Organisations/Shared/MenuItemClubs';
import ShowClub from '../Organisations/Clubs/ShowClub';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import { isCommunityMember, getRoleInCommunity } from '../../Acl/Controls';
import { CheckRoleRule } from '../../Acl/Rules';
import CommunityArticles from '../../Components/Communities//CommunityArticles';
import Seo from '../../Routing/Seo';
import Routes from '../../Routing/Routes';
import getUnreadCount from '../../Services/SubscriptionHelper';
import FirstClubCard from '../Organisations/Clubs/FirstClubCard';
import CommunityPost from '../../Components/Communities/CommunityPost';
import DealsActions from '../../Redux/DealsRedux';
import DealDetail from '../../Components/Communities/DealDetail';
import { urlifyText } from '../../Services/DataHelper';

const useStyles = makeStyles(() => ({
  sticky: {
    width: '100%',
    position: 'sticky',
    top: '8rem',
  },
  tabs: {
    position: 'sticky',
    top: '64px',
    backgroundColor: 'white',
    zIndex: '1010',
  },
  paper: {
    ...LayoutStyles.fullWidthLayout,
  },
  tab: {
    textTransform: 'none',
    color: 'black',
    minWidth: 120,
  },
  tabSelected: {
    color: 'black',
    fontWeight: 800,
    minWidth: 120,
  },
  indicator: {
    backgroundColor: 'black',
  },
}));

const HomeCommunity = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug, tab_value: tabValue, item_id: itemId } = match.params;
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.auth.currentUser);
  const community = useSelector(state => state.organisation.organisation);
  const isFetching = useSelector(state => state.organisation.isFetching.getOrganisation);
  const errors = useSelector(state => state.organisation.errors);
  const clubs = useSelector(state => state.clubs.clubs);
  const isCommunityReady = holdingSlug === community?.slug && !errors;
  const showDealTab =
    community?.helper_count > 0 &&
    (community?.visibility === 'public' ||
      (CheckRoleRule(community?.role, 'deals:read') && community?.validated));
  const isClubReady = clubs && clubs[0]?.organisation_slug === holdingSlug;
  const hasNoClub =
    community?.holding_slug && community?.holding_slug === holdingSlug && !community?.has_clubs;
  const showClubTab =
    Routes.currentLeague().namespace !== 'SLA' &&
    CheckRoleRule(getRoleInCommunity(currentUser, holdingSlug), 'clubs:read') &&
    (community?.has_clubs || CheckRoleRule(community?.role, 'clubs:create'));
  const showArticleTab = CheckRoleRule(
    getRoleInCommunity(currentUser, holdingSlug),
    'articles:read',
  );
  const hasRightMenu = tabValue === 'clubs' && showClubTab;
  const clubId = tabValue === 'clubs' ? itemId || (clubs?.length > 0 ? clubs[0].id : null) : null;
  const dealId = tabValue === 'timeline' ? itemId : null;

  React.useEffect(() => {
    dispatch(OrganisationActions.getOrganisationRequest(holdingSlug, holdingSlug, 'full'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingSlug]);

  const renderLabel = (title, unreadCount) => (
    <Grid style={{ flexDirection: 'row', display: 'flex' }}>
      {title}
      {unreadCount && ` (${unreadCount})`}
    </Grid>
  );

  const onEditDeal = (post, attachments) => {
    const deal = {
      id: post.id,
      title: post.title,
      description: urlifyText(post.raw),
      related_words: post.related_words,
    };
    dispatch(
      DealsActions.updateDealRequest(community.holding_slug, community.slug, deal, attachments),
    );
  };

  const onDeleteDeal = (holdingSlug, organisationSlug, dealId) => {
    dispatch(
      DealsActions.destroyDealRequest(holdingSlug, organisationSlug, dealId, community.type),
    );
  };

  const onCreateComment = (post, attachments, postId) => {
    dispatch(
      DealsActions.createDealPostRequest(
        post.organisation?.holding_slug || holdingSlug,
        post.organisation?.slug,
        postId,
        post,
      ),
    );
  };
  const onDeleteComment = (holdingSlug, organisationSlug, dealId, post) => {
    dispatch(DealsActions.destroyDealPostRequest(holdingSlug, organisationSlug, dealId, post.id));
  };

  const tabs = React.useMemo(() => {
    if (!isCommunityReady || !community) return [];
    const tabsToShow = [];
    if (showDealTab) {
      tabsToShow.push({
        label: renderLabel(
          t('APP.COMMUNITIES.HOME.TAB.TIMELINE'),
          getUnreadCount(community, 'deals'),
        ),
        value: '',
      });
    }
    if (showArticleTab) {
      tabsToShow.push({
        label: renderLabel(t('APP.COMMUNITIES.HOME.TAB.ARTICLES')),
        value: 'articles',
      });
    }
    tabsToShow.push({
      label: renderLabel(t('APP.COMMUNITIES.HOME.TAB.ABOUT'), false),
      value: showDealTab ? 'description' : '',
    });
    tabsToShow.push({
      label: renderLabel(t('APP.COMMUNITIES.HOME.TAB.MEMBERS')),
      value: 'members',
    });
    if (showClubTab) {
      tabsToShow.push({
        label: renderLabel(
          t('APP.ORGANISATION.HOME.TAB.CLUBS'),
          getUnreadCount(community, 'clubs'),
        ),
        value: 'clubs',
      });
    }

    return tabsToShow;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingSlug, isCommunityReady, currentUser, community, showDealTab, showClubTab]);
  return (
    <>
      {isFetching && <LinearProgress />}

      {!isFetching && errors?.status === 404 && <NoMatch404 />}
      {!isFetching && errors?.status === 401 && <NoMatch401 />}
      {!errors?.status && (
        <Grid style={{ minHeight: '80vh' }}>
          <Helmet>
            {Seo.title(
              community
                ? community.holding_name
                : t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.TITLE`),
            )}
            {Seo.description(
              community
                ? community.holding?.short_description
                : t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.DESCRIPTION`),
            )}
            {Seo.image(community?.holding?.cover_url)}
            {Seo.canonical(community?.holding?.league_id, match.url)}
          </Helmet>
          <HoldingBanner organisation={isCommunityReady && community} currentUser={currentUser} />
          <AppBar className={classes.tabs} elevation={1}>
            <Paper square className={classes.paper} elevation={0}>
              <Tabs
                value={tabValue || ''}
                onChange={(e, value) => history.push(`/communities/${holdingSlug}/${value}`)}
                classes={{ indicator: classes.indicator }}
                variant="scrollable"
                scrollButtons="on"
              >
                {tabs.map(tab => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    classes={{ root: classes.tab, selected: classes.tabSelected }}
                  />
                ))}
              </Tabs>
            </Paper>
          </AppBar>
          {isCommunityReady && (
            <OrganisationLayoutContainer
              menu={
                <Grid item className={classes.sticky}>
                  {dealId ? (
                    <DealDetail holdingSlug={holdingSlug} dealId={dealId} />
                  ) : (
                    <CardCommunityHome
                      community={community}
                      canJoin={
                        (tabValue || (!tabValue && !showDealTab)) &&
                        !isCommunityMember(community?.role)
                      }
                    />
                  )}
                </Grid>
              }
              rightMenu={
                hasRightMenu && (
                  <>
                    {isClubReady && <MenuClub organisation={community} clubId={clubId} />}
                    <MenuItemClubs organisation={community} />
                  </>
                )
              }
            >
              {(tabValue === 'description' || (!tabValue && !showDealTab)) && (
                <About
                  organisation={community}
                  userCanEditHolding={CheckRoleRule(community?.role, 'organisation:configure')}
                />
              )}
              {(!tabValue || tabValue === '') && showDealTab && (
                <OrganisationTimeline organisation={community} />
              )}
              {tabValue === 'timeline' && dealId && (
                <CommunityPost
                  dealId={dealId}
                  onEditPost={onEditDeal}
                  onDeletePost={onDeleteDeal}
                  onCreateComment={onCreateComment}
                  onDeleteComment={onDeleteComment}
                />
              )}
              {tabValue === 'articles' && showArticleTab && (
                <CommunityArticles community={community} />
              )}
              {tabValue === 'members' && <MembershipSettings organisation={community} />}
              {tabValue === 'clubs' && showClubTab && (
                <>
                  {isClubReady && <ShowClub organisation={community} clubId={clubId} />}
                  {hasNoClub && <FirstClubCard />}
                </>
              )}
            </OrganisationLayoutContainer>
          )}
        </Grid>
      )}
    </>
  );
};

export default HomeCommunity;
