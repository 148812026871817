import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import CardPost from '../Organisations/Shared/CardPost';
import PostForm from '../Posts/Form';
import TimelinePostForm from './TimelinePostForm';
import { getOldMessagesBarPosition } from '../../Services/SubscriptionHelper';

type Props = {
  posts: Array,
  lastReadDate: String,
  holdingSlug: String,
  organisationSlug: String,
  currentUser: Object,
  header: Object,
  onCreatePost: Function,
  isCreatingPost: Boolean,
  postPostableType: String,
  commentPostableType: String,
  isFetching: Boolean,
  noPostCard: Node,
  onEditPost: Function,
  totalPosts: Number,
  isFetchingNext: Boolean,
  onFetchNext: Function,
  creatingComment: Boolean,
  onCreateComment: Function,
  onDeletePost: Function,
  onDeleteComment: Function,
  canCreatePost: Boolean,
  canReadPost: Function,
  canEditDeletePost: Function,
  canDeleteComment: Function,
  notifs: Object,
  showPostType: Boolean,
  withExpertises: Boolean,
  family: Object,
  openCardPostDialog: Boolean,
  setOpenCardPostDialog: Function,
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 0,
    display: 'block',
  },
  progress: {
    marginTop: theme.spacing(-2),
    zIndex: '1000',
  },
  button: {
    padding: theme.spacing(1),
  },
  buttonContainer: {
    textAlign: 'center',
  },
  date: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.mormal.primary.main,
  },
  oldMessagesText: {
    color: theme.mormal.primary.main,
    padding: '8px',
  },
}));

const Timeline = ({
  posts,
  lastReadDate,
  holdingSlug,
  organisationSlug,
  currentUser,
  header,
  onCreatePost,
  isCreatingPost,
  postPostableType,
  commentPostableType,
  isFetching,
  noPostCard,
  onEditPost,
  totalPosts,
  isFetchingNext,
  onFetchNext,
  creatingComment,
  onCreateComment,
  onDeletePost,
  onDeleteComment,
  canCreatePost,
  canReadPost,
  canEditDeletePost,
  canDeleteComment,
  notifs,
  showPostType,
  withExpertises,
  family,
  openCardPostDialog,
  setOpenCardPostDialog,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editedPostId, setEditedPostId] = React.useState(null);
  const showSeeMoreButton = !isFetching && posts?.length > 0 && totalPosts > posts.length;

  const oldMessageBarPositionIndex = React.useMemo(() => {
    return getOldMessagesBarPosition(lastReadDate, posts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts, lastReadDate]);
  const renderOldMessagesBar = () => (
    <>
      <Divider light className={classes.divider} />
      <div className={classes.date}>
        <Typography variant="caption" className={classes.oldMessagesText}>
          {t('APP.TIMELINE.OLD_MESSAGES')}
        </Typography>
      </div>
    </>
  );

  return (
    <>
      <Grid container spacing={2} className={classes.container}>
        <Grid item style={{ paddingTop: 0 }}>
          <TimelinePostForm
            currentUser={currentUser}
            header={header}
            canCreatePost={canCreatePost}
            onCreatePost={onCreatePost}
            isCreatingPost={isCreatingPost}
            postableType={postPostableType}
            notifs={notifs}
            showPostType={showPostType}
            withExpertises={withExpertises}
            family={family}
          />
        </Grid>
        {isFetching && (
          <Grid item xs={12}>
            <LinearProgress className={classes.progress} />
          </Grid>
        )}
        {!isFetching && posts?.length === 0 && (
          <Grid item xs={12}>
            {noPostCard}
          </Grid>
        )}
        {posts?.length > 0 &&
          posts.map((post, i) => (
            <Grid item key={`post-${post.id}`}>
              {i > 0 && i === oldMessageBarPositionIndex && renderOldMessagesBar()}
              <CardPost
                holdingSlug={holdingSlug}
                organisationSlug={organisationSlug}
                post={post}
                onEditPost={() => setEditedPostId(post.id)}
                onDeletePost={() =>
                  onDeletePost(
                    post.organisation?.holding_slug || holdingSlug,
                    post.organisation?.slug || organisationSlug,
                    post.id,
                  )
                }
                currentUser={currentUser}
                creatingComment={creatingComment}
                onCreateComment={onCreateComment}
                onDeleteComment={onDeleteComment}
                postableType={commentPostableType}
                canReadPost={() =>
                  canReadPost(
                    post.organisation?.holding_slug || holdingSlug,
                    post.organisation?.slug || organisationSlug,
                  )
                }
                canEditDeletePost={() =>
                  canEditDeletePost(
                    post.organisation?.holding_slug || holdingSlug,
                    post.organisation?.slug || organisationSlug,
                    post.helper?.id,
                  )
                }
                canDeleteComment={canDeleteComment}
                family={family}
              />
              <Dialog
                open={editedPostId === post.id}
                onClose={() => setEditedPostId(null)}
                fullWidth
              >
                <DialogTitle>{t('APP.TIMELINE.EDIT')}</DialogTitle>
                <DialogContent>
                  <PostForm
                    post={post}
                    currentUser={currentUser}
                    postableType={postPostableType}
                    iconBar
                    isMessage
                    onClose={() => setEditedPostId(null)}
                    updatePost={onEditPost}
                    isDialog
                    withExpertises={withExpertises}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
          ))}
        {showSeeMoreButton && (
          <Grid item key="button" className={classes.buttonContainer}>
            <Button
              className={classes.button}
              onClick={onFetchNext}
              startIcon={isFetchingNext ? <HourglassEmptyIcon /> : <ArrowDownwardIcon />}
              endIcon={isFetchingNext ? <HourglassEmptyIcon /> : <ArrowDownwardIcon />}
              disabled={isFetchingNext}
            >
              <Typography variant="caption">
                {isFetchingNext ? t('LOADING') : t('APP.TIMELINE.SEE_MORE')}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={openCardPostDialog}
        onClose={() => setOpenCardPostDialog(null)}
        PaperProps={{ style: { flexGrow: 1 } }}
      >
        {openCardPostDialog && (
          <CardPost
            holdingSlug={holdingSlug}
            organisationSlug={organisationSlug}
            post={openCardPostDialog}
            onEditPost={() => setEditedPostId(openCardPostDialog?.id)}
            onDeletePost={() =>
              onDeletePost(
                openCardPostDialog?.organisation?.holding_slug || holdingSlug,
                openCardPostDialog?.organisation?.slug || organisationSlug,
                openCardPostDialog?.id,
              )
            }
            currentUser={currentUser}
            creatingComment={creatingComment}
            onCreateComment={onCreateComment}
            onDeleteComment={onDeleteComment}
            postableType={commentPostableType}
            canReadPost={() =>
              canReadPost(
                openCardPostDialog?.organisation?.holding_slug || holdingSlug,
                openCardPostDialog?.organisation?.slug || organisationSlug,
              )
            }
            canEditDeletePost={() =>
              canEditDeletePost(
                openCardPostDialog?.organisation?.holding_slug || holdingSlug,
                openCardPostDialog?.organisation?.slug || organisationSlug,
                openCardPostDialog?.helper?.id,
              )
            }
            canDeleteComment={canDeleteComment}
            family={family}
            isDialog
          />
        )}
      </Dialog>
    </>
  );
};

export default Timeline;
