import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import { IconButton } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

type Props = {
  items: Array,
  title: String,
  limit: number,
  onClickItem: Function,
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
    color: 'black',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  regular: {
    color: 'black',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  grid: {
    display: 'flex',
  },
  group: {
    marginLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
  },
  label: {
    justifyContent: 'flex-start',
    display: 'flex',
    flexWrap: 'nowrap',
  },
  icon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  errorIcon: {
    color: grey[400],
    marginTop: 7,
    marginLeft: 4,
    padding: 2,
  },
}));

const FilteringCard = ({ title, items, onClickItem, limit }: Props) => {
  const classes = useStyles();
  const [showAll, setShowAll] = React.useState(false);
  const itemsToShow = showAll ? items?.slice() : items?.slice(0, limit || 5);

  return (
    <>
      {itemsToShow && itemsToShow.length > 0 && (
        <Paper style={{ maxHeight: 370, overflow: 'auto' }}>
          <Typography variant="h6" component="div" className={classes.title}>
            {title}
          </Typography>
          <FormGroup className={classes.group} spacing={3}>
            {itemsToShow?.map(item => (
              <FormControlLabel
                key={item.label}
                control={
                  <Checkbox
                    checked={item.isRefined}
                    className={classes.checkbox}
                    onClick={event => {
                      event.preventDefault();
                      onClickItem(item);
                    }}
                    icon={<CheckBoxOutlineBlankOutlinedIcon style={{ color: '#E1E3E2' }} />}
                    checkedIcon={
                      <>
                        <CheckOutlinedIcon
                          style={{ position: 'absolute', color: 'black', left: '6', bottom: '5' }}
                        />
                        <CheckBoxOutlineBlankOutlinedIcon style={{ color: '#E1E3E2' }} />
                      </>
                    }
                  />
                }
                label={
                  <Grid container className={classes.label} spacing={1}>
                    {item.icon && <Grid className={classes.icon}>{item.icon}</Grid>}
                    <Typography
                      variant="subtitle2"
                      component="h4"
                      className={item.isRefined ? classes.bold : classes.regular}
                    >
                      {item.labelToShow || item.label} ({item.count})
                    </Typography>
                    {item.tooltip && (
                      <Tooltip title={item.tooltip} placement="bottom">
                        <ErrorIcon className={classes.errorIcon} />
                      </Tooltip>
                    )}
                  </Grid>
                }
                style={{ alignItems: 'flex-start' }}
              />
            ))}
          </FormGroup>
          {!showAll && items?.length > itemsToShow?.length && (
            <Grid style={{ width: '100%', textAlign: 'center' }}>
              <IconButton
                onClick={() => setShowAll(true)}
                aria-label="More"
                aria-haspopup="true"
                className={classes.iconButton}
                size="small"
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          )}
        </Paper>
      )}
    </>
  );
};

export default FilteringCard;
