import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { green, red } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Popover from '@material-ui/core/Popover';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@material-ui/core/Tooltip';

import WelloAvatar from '../Shared/WelloAvatar';
import ImagesMosaic from '../Shared/ImagesMosaic';
import RestrictionsPost from '../CareNotebook/RestrictionsPost';
import PostComments from '../Organisations/Shared/PostComments';
import { CARENOTEBOOK_TYPE, getRestrictions } from '../../Services/FamilyHelper';

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  chip: {
    height: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    color: 'white',
    backgroundColor: theme.palette.grey[400],
  },
  carenotebookChip: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    backgroundColor: 'white',
  },
  green: {
    color: green[600],
  },
  red: {
    color: red[600],
  },
  avatar: {
    margin: theme.spacing(1),
    marginRight: 0,
    fontSize: '1rem',
  },
  description: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  action: {
    margin: 0,
  },
  attachment: {
    '&:last-child': {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(2),
    },
  },
  link: {
    textDecoration: 'auto',
    color: 'black',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  restrictions: {
    paddingRight: theme.spacing(2),
  },
  raw: {
    marginBottom: 8,
  },
  healthcareChipContainer: {
    display: 'flex',
  },
  healthcareChip: {
    backgroundColor: '#4069DB',
    color: 'white',
    marginRight: theme.spacing(1),
  },
  warningAvatar: {
    backgroundColor: 'red',
    marginRight: theme.spacing(2),
    width: 24,
    height: 24,
  },
  warningIcon: {
    width: '16px !important',
  },
}));

type Props = {
  post: Object,
  currentUser: Object,
  holdingSlug: String,
  organisationSlug: String,
  creatingComment: Boolean,
  onCreateComment: Function,
  onDeleteComment: Function,
  canReadPost: Function,
  canEditDeletePost: Function,
  canDeleteComment: Function,
  postableType: String,
  family: Object,
  renderMenuAction: Function,
  isDialog: Boolean,
  simplifiedVersion: Boolean,
};

const MessageCardPost = ({
  holdingSlug,
  organisationSlug,
  post,
  currentUser,
  creatingComment,
  onCreateComment,
  onDeleteComment,
  canReadPost,
  canEditDeletePost,
  canDeleteComment,
  postableType,
  family,
  renderMenuAction,
  isDialog,
  simplifiedVersion,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isNotFromCurrentOrga =
    post.organisation?.slug && post.organisation?.slug !== organisationSlug;
  const [restrictionsMenuAnchor, setRestrictionsMenuAnchor] = React.useState(null);
  const { raw, related_words: keyWords, excluded_words: excludedWords } = post;
  const attachments = post?.attachments?.filter(a => !a.preview_url) || [];
  const images = post?.attachments?.filter(a => a.preview_url) || [];
  const isHealthcarePost = post.type === CARENOTEBOOK_TYPE['healthcare']?.postableType;
  const hasRestrictions = post.access_ids && Object.keys(post.access_ids)?.length > 0;
  const fullName = post.helper_first_name
    ? `${post.helper_first_name}  ${post.helper_last_name}`
    : `${post.helper?.first_name}  ${post.helper?.last_name}`;
  const shortName = post.helper_first_name
    ? `${post.helper_first_name}  ${post.helper_last_name ? post.helper_last_name[0] : ''}`
    : `${post.helper?.first_name}  ${post.helper?.last_name ? post.helper?.last_name[0] : ''}`;
  const restrictionsCircles = getRestrictions('dailylife', family, t);

  const getPadding = () => {
    if (isDialog) return '24px';
    else if (simplifiedVersion) return '8px';
    return 'auto';
  };

  return (
    <Card key={`cardpost-${post.id}`} style={{ overflow: 'visible', padding: getPadding() }}>
      <CardHeader
        classes={{
          root: classes.header,
          action: classes.action,
        }}
        title={
          <Grid container justifyContent="space-between">
            <Grid>
              <Grid container alignItems="center" wrap="wrap">
                {canReadPost() ? (
                  <Typography
                    variant="h6"
                    component="div"
                    style={{ textTransform: 'capitalize' }}
                  >{`${fullName}${!post.helper ? t('APP.POST.VISITOR') : ''}`}</Typography>
                ) : (
                  <Typography
                    variant="h6"
                    component="div"
                    style={{ textTransform: 'capitalize' }}
                  >{`${shortName}.`}</Typography>
                )}
                {isNotFromCurrentOrga && (
                  <>
                    <ArrowRightIcon />
                    <Link
                      to={`/${post.organisation?.holding_slug}/${post.organisation?.slug}`}
                      className={classes.link}
                    >
                      <Typography variant="h6" component="div">
                        {`${post.organisation?.holding_name} - ${post.organisation?.name}`}
                      </Typography>
                    </Link>
                  </>
                )}
              </Grid>
              <Typography variant="caption" component="div" color="textSecondary">
                {moment(post.created_at).format('dddd LL - LT')}
              </Typography>
            </Grid>
            <Grid className={classes.healthcareChipContainer}>
              {post.is_important && !simplifiedVersion && (
                <Tooltip title={t('APP.POST.IMPORTANT')}>
                  <Avatar className={classes.warningAvatar}>
                    <WarningIcon className={classes.warningIcon} />
                  </Avatar>
                </Tooltip>
              )}
              {isHealthcarePost && !simplifiedVersion && (
                <Chip
                  label={t('APP.POST.HEALTHCARE')}
                  size="small"
                  className={classes.healthcareChip}
                />
              )}
            </Grid>
          </Grid>
        }
        avatar={
          <WelloAvatar
            avatarUrl={post.helper?.avatar_url}
            firstName={post.helper?.first_name}
            lastName={post.helper?.last_name}
            backgroundColor={post.helper?.avatar_background_color}
            avatarClass={classes.avatar}
            size={36}
            helper={currentUser ? post.helper : null}
          />
        }
        action={
          <>
            {hasRestrictions && !simplifiedVersion && (
              <>
                <IconButton
                  onClick={event => setRestrictionsMenuAnchor(event.currentTarget)}
                  aria-label="restrictions"
                  aria-owns={restrictionsMenuAnchor ? 'actions-restrictions' : undefined}
                  aria-haspopup="true"
                  style={{ margin: 0 }}
                >
                  <VisibilityIcon />
                </IconButton>
                <Popover
                  open={Boolean(restrictionsMenuAnchor)}
                  anchorEl={restrictionsMenuAnchor}
                  onClose={() => setRestrictionsMenuAnchor(null)}
                >
                  <Grid item className={classes.restrictions}>
                    <RestrictionsPost
                      restrictions={post.access_ids}
                      circles={restrictionsCircles}
                    />
                  </Grid>
                </Popover>
              </>
            )}

            {canEditDeletePost() && !simplifiedVersion && renderMenuAction()}
          </>
        }
      />
      {(raw || keyWords || excludedWords) && (
        <CardContent className={classes.description}>
          {raw && (
            <Typography
              className={classes.raw}
              align="left"
              dangerouslySetInnerHTML={{ __html: raw }}
            />
          )}
          {keyWords &&
            keyWords.map(word => (
              <Chip
                className={post?.type ? classes.carenotebookChip : classes.chip}
                icon={post?.type && <CheckIcon className={classes.green} />}
                label={word}
                key={word}
              />
            ))}
          {excludedWords &&
            excludedWords.map(word => (
              <Chip
                className={classes.carenotebookChip}
                icon={post?.type && <CloseIcon className={classes.red} />}
                label={word}
                key={word}
              />
            ))}
        </CardContent>
      )}
      {attachments.map(attachment => (
        <a
          href={attachment.download_url}
          key={attachment.id}
          target="_blank"
          without="true"
          rel="noopener noreferrer"
        >
          <CardContent className={classes.attachment}>{attachment.filename}</CardContent>
        </a>
      ))}
      {images && <ImagesMosaic images={images} />}
      {canReadPost() && !simplifiedVersion && (
        <PostComments
          holdingSlug={holdingSlug}
          organisationSlug={organisationSlug}
          post={post}
          currentUser={currentUser}
          creatingComment={creatingComment}
          onCreateComment={onCreateComment}
          onDeleteComment={onDeleteComment}
          canDeleteComment={canDeleteComment}
          postableType={postableType}
          isDialog={isDialog}
        />
      )}
    </Card>
  );
};

export default MessageCardPost;
