import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { getFamilyAvatar, getFamilyName } from '../../Services/FamilyHelper';
import { getFormattedRole } from '../../Acl/Roles';
import { isMainHelper } from '../../Acl/Controls';
import UserForm from '../../Pages/UserSetup/UserForm';
import OrganisationActions from '../../Redux/OrganisationRedux';
import { serializeDateFromDatePicker } from '../../Services/FormHelpers';
import { standardizeWordCase } from '../../Services/DataHelper';
import Button from '@material-ui/core/Button';
import HelperDetails from '../Shared/HelperDetails';

const useStyles = makeStyles(theme => ({
  media: {
    objectFit: 'cover',
    boxShadow: 'none',
  },
  familyName: {
    fontWeight: 600,
    height: 45,
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helpers: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  procount: {
    alignSelf: 'center',
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: 0,
    position: 'relative',
  },
  avatarContent: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  count: {
    paddingTop: theme.spacing(3),
  },
  avatar: {
    marginRight: '-20px',
    width: 45,
    height: 45,
    fontSize: '14px',
    border: '3px solid #fff',
  },
  edit: {
    color: 'initial',
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(2, 3, 3),
    maxWidth: '800px',
  },
  buttonLink: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

type Props = {
  family: Object,
  showFamiliesComponent: Boolean,
  canEditHelped: Boolean,
  canSeeIdentifier: Boolean,
  showDetails: Boolean,
};

const CardFamilyHome = ({
  family,
  showFamiliesComponent,
  canEditHelped,
  canSeeIdentifier,
  showDetails,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isUpdating = useSelector(state => state.organisation.isFetching.updateFamilyHelped);
  const [showEditUserSettings, setShowEditUserSettings] = React.useState(false);
  const [showQrCodeDialog, setShowQrCodeDialog] = React.useState(false);
  const [openUserDiag, setOpenUserDiag] = React.useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [helped, setHelped] = React.useState({
    gender: family?.admin?.gender || 'female',
    firstName: family?.admin?.first_name || '',
    lastName: family?.admin?.last_name || '',
    phone: family?.admin?.phone || '',
    country_code: family?.admin?.country_code || 'fr',
    locality: family?.admin?.locality || '',
    birthday: new Date(family?.admin?.birthday || (1970, 1, 1)),
    formattedAddress: family?.admin?.formatted_address || '',
    addressSupplement: family?.admin?.address_supplement || '',
    description: family?.admin?.description || t('APP.USER.TEXT_FIELD.DEFAULT_VALUE.DESCRIPTION'),
    pathology: family?.admin?.pathology || '',
    latitude: family?.admin?.coordinates?.latitude || '',
    longitude: family?.admin?.coordinates?.longitude || '',
    avatarPreview: family?.admin?.avatar_url,
    avatar: null,
  });

  React.useEffect(() => {
    const avatar = family?.admin?.avatar_url;
    if (avatar) {
      setHelped({
        ...helped,
        avatar: {
          source: avatar,
          options: {
            type: 'local',
            file: { name: avatar, size: 0 },
            metadata: { poster: avatar },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [family?.admin]);

  const handleSubmit = event => {
    event.preventDefault();
    const attributes = {
      first_name: standardizeWordCase(helped.firstName),
      last_name: standardizeWordCase(helped.lastName),
      phone: helped.phone,
      country_code: helped.country_code,
      description: helped.description,
      pathology: helped.pathology,
      gender: helped.gender,
      birthday: serializeDateFromDatePicker(helped.birthday),
      locality: helped.locality,
      formatted_address: helped.formattedAddress,
      latitude: helped.latitude,
      longitude: helped.longitude,
      address_supplement: helped.addressSupplement,
    };

    dispatch(
      OrganisationActions.updateFamilyHelpedRequest(
        family?.admin.id,
        attributes,
        helped.avatar && helped.avatar.size > 0 ? helped.avatar : null,
      ),
      null,
    );

    setShowEditUserSettings(false);
  };

  const renderUserCard = () => (
    <Card className={classes.media}>
      <CardContent className={classes.content}>
        {showFamiliesComponent && canEditHelped && (
          <IconButton className={classes.edit} onClick={() => setShowEditUserSettings(true)}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        <Grid align="center" className={classes.avatarContent}>
          {getFamilyAvatar(family, {
            height: showFamiliesComponent ? 60 : 90,
            width: showFamiliesComponent ? 60 : 90,
            fontSize: showFamiliesComponent ? '1.25rem' : '2.5rem',
          })}
        </Grid>
        <Typography
          variant={showFamiliesComponent ? 'h5' : 'h4'}
          component={showFamiliesComponent ? 'h4' : 'h1'}
          className={classes.familyName}
          align="center"
        >
          {getFamilyName(family)}
        </Typography>
        {family?.admin?.has_carenotebook && !showFamiliesComponent && canSeeIdentifier && (
          <Grid align="center">
            <Typography variant="body2" className={classes.count}>
              {t('FAMILY_IDENTIFIER', { identifier: family?.identifier })}
            </Typography>
            <Button
              className={classes.buttonLink}
              color="primary"
              onClick={() => setShowQrCodeDialog(true)}
            >
              {t('QR_CODE')}
            </Button>
          </Grid>
        )}
        {showFamiliesComponent && (
          <Typography variant="subtitle2" component="h5" align="center">
            {isMainHelper(family.role)
              ? t('APP.FAMILY.HOME.BENEFICIARY_HELPEDS')
              : getFormattedRole(family.role, t)}
          </Typography>
        )}
      </CardContent>
    </Card>
  );

  return (
    <>
      {showDetails && !canEditHelped && (
        <CardActionArea
          onClick={() => setOpenUserDiag(true)}
          className={classes.cardActionArea}
          disabled={!showDetails || canEditHelped}
        >
          {renderUserCard()}
        </CardActionArea>
      )}
      {(!showDetails || canEditHelped) && renderUserCard()}
      <Dialog open={openUserDiag} onClose={() => setOpenUserDiag(false)}>
        <HelperDetails helper={family?.admin} />
      </Dialog>
      <Dialog open={showEditUserSettings} onClose={() => setShowEditUserSettings(false)} fullWidth>
        <Paper className={classes.paper}>
          <UserForm
            user={helped}
            setUser={setHelped}
            onSubmit={handleSubmit}
            isUpdating={isUpdating}
            buttonBack
            onCancel={() => setShowEditUserSettings(false)}
          />
        </Paper>
      </Dialog>
      <Dialog open={showQrCodeDialog} onClose={() => setShowQrCodeDialog(false)}>
        <Paper
          className={classes.paper}
          ref={componentRef}
          style={{ padding: 70, textAlign: 'center' }}
          elevation={0}
        >
          <svg
            width="50%"
            height="50%"
            viewBox="0 0 41 41"
            fill="#000000"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d={family.qrcode_svg} />
          </svg>
          <Typography
            variant={showFamiliesComponent ? 'h5' : 'h4'}
            component={showFamiliesComponent ? 'h4' : 'h1'}
            className={classes.familyName}
            align="center"
            style={{ alignSelf: 'center', marginTop: 32 }}
          >
            {getFamilyName(family)}
          </Typography>
        </Paper>
        <Button
          onClick={handlePrint}
          style={{ alignSelf: 'center', marginBottom: 32 }}
          variant="contained"
        >
          {t('PRINT')}
        </Button>
      </Dialog>
    </>
  );
};
export default CardFamilyHome;
