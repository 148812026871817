import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import 'moment/locale/fr';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import ChipInput from 'material-ui-chip-input';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import Editor from '../Posts/EditorWysiwyg';
import { handleAddChip, handleRemoveChip } from '../../Services/FormHelpers';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);

type Props = {
  dialogTitle: String,
  titleLabel: String,
  rawLabel: String,
  post: Object,
  handleSubmit: Function,
  onCreatePost: Function,
  onEditPost: Function,
  onClose: Function,
  withImage: Boolean,
  withFiles: Boolean,
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  container: {
    justifyContent: 'space-around',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  buttons: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  label: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
  facet: {
    paddingLeft: theme.spacing(2),
  },
  formLabel: {
    paddingLeft: '3px',
    paddingRight: '3px',
    zIndex: '1002',
    backgroundColor: 'white',
  },
  divider: {
    margin: theme.spacing(1, 3),
  },
}));

function CommunityPostForm({
  dialogTitle,
  titleLabel,
  rawLabel,
  post,
  handleSubmit,
  onClose,
  withImage,
  withFiles,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [title, setTitle] = React.useState(post?.title || '');
  const [raw, setRaw] = React.useState(post?.description || post?.content || '');
  const [images, setImages] = React.useState([]);
  const [imagesCompressed, setImagesCompressed] = React.useState([]);
  const [keyWords, setKeyWords] = React.useState(post?.related_words || []);
  const [files, setFiles] = React.useState([]);
  const isCreatingPost = useSelector(state => state.deals.isFetching.createDeal);
  const isCreatingArticle = useSelector(state => state.articles.isFetching.createHoldingArticle);
  const canSubmit = title && raw && ((withFiles && files.length > 0) || !withFiles);

  React.useEffect(() => {
    const attachments = post?.attachments?.filter(a => !a.preview_url);
    const imgs = post?.attachments?.filter(a => a.preview_url);
    if (post?.cover_image_url) imgs.push({ preview_url: post?.cover_image_url });
    if (imgs?.length > 0) {
      setImages(
        imgs.map(im => ({
          source: im.preview_url,
          options: {
            type: 'local',
            file: { id: im.id, name: im.preview_url, size: 0 },
            metadata: { poster: im.preview_url },
          },
        })),
      );
    }
    if (attachments) {
      setFiles(
        attachments.map(attachment => ({
          source: attachment.download_url,
          options: {
            type: 'local',
            file: {
              id: attachment.id,
              name: attachment.filename,
              size: 0,
              url: attachment.download_url,
            },
            metadata: { poster: attachment },
          },
        })),
      );
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = event => {
    event.preventDefault();
    if (!canSubmit || isCreatingPost || isCreatingArticle) return;
    const p = {
      title,
      raw,
      related_words: keyWords,
      image: [],
      slug: post?.slug,
    };
    const attachments = withImage
      ? images.map(i => imagesCompressed.find(ic => ic.name === i.name) || i)
      : files;

    handleSubmit({ ...p, id: post?.id }, attachments);

    if (onClose) onClose();
  };

  return (
    <Paper className={classes.paper}>
      <form onSubmit={onSubmit}>
        <Typography variant="h5" component="p" className={classes.title}>
          {t(dialogTitle)}
        </Typography>
        <Grid container className={classes.container}>
          <Grid item md={4} xs={12}>
            <FormControl component="fieldset" fullWidth required>
              <Typography className={classes.label}>
                {t('APP.COMMUNITIES.POST_FORM.LABEL.KEY_WORDS')}
              </Typography>
              <ChipInput
                fullWidth
                autoFocus
                rows={4}
                disableUnderline
                value={keyWords}
                onDelete={chip => setKeyWords(handleRemoveChip(chip, keyWords))}
                onAdd={chip => setKeyWords(handleAddChip(chip, keyWords))}
                placeholder={t('APP.COMMUNITIES.POST_FORM.PLACEHOLDER.KEY_WORDS')}
                variant="outlined"
              />
            </FormControl>
            {withImage && (
              <FormControl component="fieldset" fullWidth required>
                <Typography className={classes.label}>
                  {t('APP.COMMUNITIES.POST_FORM.LABEL.COVER')}
                </Typography>
                <FilePond
                  files={images}
                  allowMultiple={false}
                  maxFiles={1}
                  credits={('', '')}
                  instantUpload={false}
                  acceptedFileTypes={['image/*']}
                  labelIdle={t('APP.USER.IMAGE_FIELD.PLACEHOLDER')}
                  onupdatefiles={fileItems => {
                    setImages(fileItems.map(fileItem => fileItem.file));
                  }}
                  imageResizeTargetWidth="1200"
                  allowImageResize
                  imageResizeMode="contain"
                  onpreparefile={(file, output) => {
                    const newIm = imagesCompressed.slice();
                    newIm.push(output);
                    setImagesCompressed(newIm);
                  }}
                  imageResizeUpscale={false}
                />
              </FormControl>
            )}
            {withFiles && (
              <FormControl component="fieldset" fullWidth required>
                <Typography className={classes.label}>
                  {t('APP.COMMUNITIES.ARTICLE_FORM.LABEL.FILES')}
                </Typography>
                <FilePond
                  files={files}
                  allowMultiple
                  maxFiles={5}
                  credits={('', '')}
                  instantUpload={false}
                  acceptedFileTypes={['application/*']}
                  labelIdle={t('APP.USER.ATTACHMENT_FIELD.PLACEHOLDER')}
                  onupdatefiles={fileItems => {
                    setFiles(fileItems.map(fileItem => fileItem.file));
                  }}
                />
              </FormControl>
            )}
          </Grid>
          <Hidden only={['xs']}>
            <Divider md={1} orientation="vertical" flexItem className={classes.divider} />
          </Hidden>
          <Grid item md={7} xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Typography className={classes.label}>{t(titleLabel)}</Typography>
              <TextField
                required
                name="title"
                value={title || ''}
                type="text"
                onChange={event => setTitle(event.target.value)}
                variant="outlined"
                autoFocus
                fullWidth
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FormControl>
            <FormControl component="fieldset" fullWidth>
              <Typography className={classes.label}>{t(rawLabel)}</Typography>
              <Editor
                name="raw"
                value={post?.description || post?.content || ''}
                onChange={event => setRaw(event.target.value)}
                options={['inline', 'emoji', 'list', 'link']}
                editorStyle={{ height: '300px' }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button color="primary" onClick={onClose} variant="outlined">
            {t('CANCEL')}
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            style={{ marginLeft: 16 }}
            disabled={!canSubmit || isCreatingPost || isCreatingArticle}
          >
            {t('SEND')}
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default CommunityPostForm;
