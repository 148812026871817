import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';

import PostForm from '../../Posts/Form';
import CommentPost from '../../Posts/CommentPost';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  postForm: {
    '&:last-child': {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
  },
  buttonShowComments: {
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#fff',
      textDecorationLine: 'underline',
    },
  },
  divider: {
    flexGrow: 1,
  },
  commentDivider: {
    backgroundColor: grey[100],
    margin: theme.spacing(0, 2),
  },
}));

type Props = {
  post: Object,
  currentUser: Object,
  holdingSlug: String,
  organisationSlug: String,
  creatingComment: Boolean,
  onCreateComment: Function,
  onDeleteComment: Function,
  canDeleteComment: Function,
  postableType: String,
  isDialog: Boolean,
};

const PostComments = ({
  holdingSlug,
  organisationSlug,
  post,
  currentUser,
  creatingComment,
  onCreateComment,
  onDeleteComment,
  canDeleteComment,
  postableType,
  isDialog,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const commentsLength = post?.posts?.length;
  const [showAllComments, setShowAllComments] = React.useState(Boolean(commentsLength <= 3));
  const comments = showAllComments
    ? post.posts?.sort((a, b) => a.id - b.id) || []
    : (post.posts?.sort((a, b) => a.id - b.id) || []).slice(commentsLength - 3);

  return (
    <>
      <Grid container className={classes.container}>
        {expanded && <Divider light className={classes.divider} />}
        <Button
          size="small"
          onClick={() => setExpanded(!expanded)}
          endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          style={{ textTransform: 'none', margin: 8 }}
        >
          <Typography variant="body2" component="h4" align="left" style={{ paddingRight: '8px' }}>
            {t('APP.POST.COMMENT_COUNT', {
              count: commentsLength,
              context: `${commentsLength}`,
            })}
          </Typography>
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {commentsLength - 3 > 0 && !showAllComments && (
          <Button
            size="small"
            onClick={() => setShowAllComments(true)}
            className={classes.buttonShowComments}
          >
            <Typography
              variant="body2"
              component="h4"
              align="left"
              style={{ paddingRight: '8px', fontWeight: 700 }}
            >
              {t('APP.POST.OTHER_COMMENT_COUNT', { count: commentsLength - 3 })}
            </Typography>
          </Button>
        )}
        {comments.map(comment => (
          <Grid key={`comment-${comment.id}`}>
            <CommentPost
              post={comment}
              onDelete={
                canDeleteComment(
                  post.organisation?.holding_slug || holdingSlug,
                  post.organisation?.slug || organisationSlug,
                  comment.helper_id,
                )
                  ? () =>
                      onDeleteComment(
                        post.organisation?.holding_slug || holdingSlug,
                        post.organisation?.slug || organisationSlug,
                        post.id,
                        comment,
                      )
                  : null
              }
            />
            <Divider className={classes.commentDivider} />
          </Grid>
        ))}
        <CardContent className={classes.postForm}>
          <PostForm
            currentUser={currentUser}
            postableType={postableType}
            createPost={onCreateComment}
            creatingPost={creatingComment === post.id}
            postableId={post.id}
            post={{ organisation: post?.organisation }}
            sendOnEnter
            isDialog={isDialog}
          />
        </CardContent>
      </Collapse>
    </>
  );
};

export default PostComments;
