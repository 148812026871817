import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

// import getVideoId from 'get-video-id';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import OrganisationLayoutContainer from '../../Components/Layout/LayoutContainer';
import CardOrganisationHome from '../../Components/Organisations/Home/CardOrganisationHome';
import CardJoinUs from '../../Components/Organisations/Shared/CardJoinUs';
import OrganisationTimeline from '../../Components/Organisations/Home/OrganisationTimeline';
import HoldingBanner from '../../Components/Organisations/Home/HoldingBanner';
import OrganisationActions from '../../Redux/OrganisationRedux';
import NoMatch404 from '../NoMatch404';
import { isServerSide } from '../../Config/ServerConfig';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Twigs from '../../Components/Organisations/Schedule/Twigs';
import About from '../../Components/Organisations/Home/About';
import { CheckOrgaRule, CheckRoleRule } from '../../Acl/Rules';
import MembershipSettings from './Settings/MembershipSettings';
import ActivitiesBlock from '../../Components/Organisations/Home/ActivitiesBlock';
// import ClubsBlock from '../../Components/Organisations/Home/ClubsBlock';
import { RoleCheck } from '../../Components/Shared/Can';
import { isHoldingAdmin, isLeagueAdmin } from '../../Acl/Controls';
// import Organisations from '../../Components/Organisations/Home/Organisations';
import ShowClub from './Clubs/ShowClub';
import MenuClub from '../../Components/Organisations/Shared/MenuClub';
import MenuItemClubs from '../../Components/Organisations/Shared/MenuItemClubs';
import CardInviteAdmin from '../../Components/Holdings/CardInviteAdmin';
import Leads from './Leads/Leads';
import Courses from './Courses/Courses';
import Routes from '../../Routing/Routes';
import getUnreadCount from '../../Services/SubscriptionHelper';
import Footer from '../../Components/Layout/Footer';
import Seo from '../../Routing/Seo';
import GridPrimaries from './Settings/GridPrimaries';
import ShowTransmissions from './ShowTransmissions';
import MenuPrimary from '../../Components/Organisations/Membership/MenuPrimary';
import RightMenuPrimary from '../../Components/Organisations/Membership/RightMenuPrimary';
import Roles from '../../Acl/Roles';
import Articles from './Articles/Articles';
import HomeBreadcrumb from '../../Components/Shared/Breadcrumbs/HomeBreadcrumb';
import { canEditHolding } from '../../Acl/Controls';
import { getLeagueSearchResultTabs } from '../../Services/AlgoliaHelper';
import { isMobile } from '../../Components/Layout/Styles/LayoutStyles';

const useStyles = makeStyles(theme => ({
  sticky: {
    width: '100%',
    position: 'sticky',
    top: '8rem',
  },
  tabs: {
    position: 'sticky',
    top: '64px',
    backgroundColor: 'white',
    zIndex: '1010',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      top: '56px',
    },
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    ...LayoutStyles.fullWidthLayout,
  },
  tab: {
    textTransform: 'none',
    color: 'black',
    minWidth: 120,
  },
  tabSelected: {
    color: 'black',
    fontWeight: 800,
    minWidth: 120,
  },
  indicator: {
    backgroundColor: 'black',
  },
  badge: {
    color: 'red',
    right: -8,
    top: 12,
  },
}));

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    tab_value: tabValue,
    item_id: itemId,
  } = match.params;
  const { t } = useTranslation();
  const clubs = useSelector(state => state.clubs.clubs);
  const currentUser = useSelector(state => state.auth.currentUser);
  const organisation = useSelector(state => state.organisation.organisation);
  const isFetching = useSelector(state => state.organisation.isFetching.getOrganisation);
  const errors = useSelector(state => state.organisation.errors);
  const isOrganisationReady = organisationSlug === organisation?.slug && !errors;
  const hasHoldingAdmin = Boolean(organisation?.holding?.admin_id);
  const isLeagueOrHoldingAdmin =
    isLeagueAdmin(organisation?.holding?.league_slug, currentUser) ||
    isHoldingAdmin(organisation?.holding_id, currentUser);
  const showDealTab = organisation?.helper_count > 0;
  const showActivityTab =
    organisation?.helper_count > 0 && CheckOrgaRule(organisation?.type, 'tasks');
  const showClubTab =
    Routes.currentLeague().namespace === 'GLOBAL' &&
    CheckOrgaRule(organisation?.type, 'clubs') &&
    CheckRoleRule(organisation?.role, 'clubs:read') &&
    (clubs?.length > 0 || CheckRoleRule(organisation?.role, 'clubs:create')) &&
    organisation?.validated;
  const showLeadTab =
    CheckOrgaRule(organisation?.type, 'leads') &&
    CheckRoleRule(organisation?.role, 'leads:read') &&
    organisation?.has_leads &&
    organisation?.validated;
  const showCourseTab =
    Routes.currentLeague().namespace !== 'REPRISE' &&
    // organisation?.validated && // demande de claire, d'afficher les moocs même quand on n'est pas connecté
    // CheckRoleRule(organisation?.role, 'courses:read') &&
    CheckOrgaRule(organisation?.type, 'courses') &&
    (isLeagueOrHoldingAdmin ||
      organisation?.has_courses ||
      CheckRoleRule(organisation?.role, 'courses:create'));
  const showPrimariesTab =
    organisation?.validated && CheckRoleRule(organisation?.role, 'primaries:read');
  const showMembersTab =
    organisation?.validated && hasHoldingAdmin && CheckRoleRule(organisation?.role, 'members:read');
  const showArticleTab =
    CheckOrgaRule(organisation?.type, 'articles') &&
    (isLeagueOrHoldingAdmin ||
      organisation?.has_articles ||
      CheckRoleRule(organisation?.role, 'articles:create'));
  const clubId = tabValue === 'clubs' ? itemId || (clubs?.length > 0 ? clubs[0].id : null) : null;
  const primaryOrgaId = tabValue === 'primaries' && itemId ? itemId : null;
  const isTransmissionWindow = tabValue === 'primaries' && primaryOrgaId;
  const isPrimariesWindow = tabValue === 'primaries' && !primaryOrgaId;
  const hasRightMenu = ['clubs', 'timeline'].includes(tabValue) || isTransmissionWindow;
  const hideMenu = isPrimariesWindow || ['members', 'activites', 'articles'].includes(tabValue);
  const showBreadcrumb =
    isOrganisationReady &&
    getLeagueSearchResultTabs(Routes.currentNamespace()).includes('organisations');

  if (isServerSide() && !isOrganisationReady) {
    dispatch(OrganisationActions.getOrganisationRequest(holdingSlug, organisationSlug, 'full')); // SSR
  }

  React.useEffect(() => {
    if (organisation?.id)
      window.dataLayer.push({
        event: 'gtm_view_structure_home',
        orga_id: organisation?.id,
        role: organisation?.role,
      });
  }, [organisation?.id]);

  useEffect(() => {
    dispatch(OrganisationActions.getOrganisationRequest(holdingSlug, organisationSlug, 'full'));
  }, [dispatch, holdingSlug, organisationSlug, currentUser?.id]);

  const getDescription = org => {
    const short = org.holding?.short_description;

    const allServices = org?.all_services?.map(s => s.service?.label);
    const services = allServices?.reduce((acc, item) => {
      if (acc.length === 0) {
        return `Services proposés : ${item}`;
      }
      if (acc.length + `${item}`.length > 320) return `${acc}`;
      return `${acc}, ${item}`;
    }, '');

    if (services) return `${short}. ${services}`;

    return short;
  };
  const renderLabel = (title, unreadCount) => (
    <Grid style={{ flexDirection: 'row', display: 'flex' }}>
      {title}
      {unreadCount && ` (${unreadCount})`}
    </Grid>
  );

  const tabs = React.useMemo(() => {
    if (!isOrganisationReady || !organisation) return [];
    const tabsToShow = [
      { label: renderLabel(t('APP.ORGANISATION.HOME.TAB.ABOUT'), false), value: '' },
    ];
    if (showDealTab) {
      tabsToShow.push({
        label: renderLabel(
          t('APP.ORGANISATION.HOME.TAB.TIMELINE'),
          getUnreadCount(organisation, 'deals'),
        ),
        value: 'timeline',
      });
    }
    if (showActivityTab) {
      tabsToShow.push({
        label: renderLabel(
          t('APP.ORGANISATION.HOME.TAB.EVENTS'),
          getUnreadCount(organisation, 'tasks'),
        ),
        value: 'activites',
      });
    }
    if (showLeadTab) {
      tabsToShow.push({
        label: renderLabel(
          t(`APP.ORGANISATION.HOME.TAB.${organisation?.type}.LEADS`),
          getUnreadCount(organisation, 'leads'),
        ),
        value: 'leads',
      });
    }
    if (showClubTab) {
      tabsToShow.push({
        label: renderLabel(
          t('APP.ORGANISATION.HOME.TAB.CLUBS'),
          getUnreadCount(organisation, 'clubs'),
        ),
        value: 'clubs',
      });
    }
    if (showArticleTab) {
      tabsToShow.push({
        label: renderLabel(t('APP.ORGANISATION.HOME.TAB.ARTICLES')),
        value: 'articles',
      });
    }
    if (showCourseTab) {
      tabsToShow.push({
        label: renderLabel(t('APP.ORGANISATION.HOME.TAB.COURSES')),
        value: 'formations',
      });
    }
    if (showPrimariesTab) {
      tabsToShow.push({
        label: renderLabel(t('APP.ORGANISATION.HOME.TAB.PRIMARIES')),
        value: 'primaries',
      });
    }
    if (showMembersTab) {
      tabsToShow.push({
        label: renderLabel(t('APP.ORGANISATION.HOME.TAB.MEMBERS')),
        value: 'members',
      });
    }
    return tabsToShow;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    holdingSlug,
    organisationSlug,
    isOrganisationReady,
    currentUser,
    showClubTab,
    showLeadTab,
    showCourseTab,
    organisation,
  ]);

  return (
    <>
      {isFetching && <LinearProgress />}

      {!isFetching && errors?.status === 404 && <NoMatch404 />}
      {errors?.status !== 404 && (
        <>
          <Grid style={{ minHeight: '80vh' }}>
            <Helmet>
              {Seo.title(
                isOrganisationReady && organisation
                  ? `${organisation.holding_name} - ${organisation.name}`
                  : t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.TITLE`),
              )}
              {Seo.description(
                isOrganisationReady && organisation
                  ? getDescription(organisation)
                  : t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.DESCRIPTION`),
              )}
              {Seo.image(isOrganisationReady && organisation?.holding?.cover_url)}
              {Seo.canonical(organisation?.holding?.league_id, match.url)}
            </Helmet>
            <HoldingBanner
              organisation={isOrganisationReady && organisation}
              currentUser={currentUser}
            />
            <AppBar className={classes.tabs} elevation={1}>
              <Paper square className={classes.paper} elevation={0}>
                <Tabs
                  value={tabValue || ''}
                  onChange={(e, value) =>
                    history.push(`/${holdingSlug}/${organisationSlug}/${value}`)
                  }
                  classes={{ indicator: classes.indicator }}
                  variant="scrollable"
                  scrollButtons="on"
                >
                  {tabs.map(tab => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      classes={{ root: classes.tab, selected: classes.tabSelected }}
                    />
                  ))}
                </Tabs>
              </Paper>
            </AppBar>
            {isOrganisationReady && (
              <>
                <OrganisationLayoutContainer
                  menu={
                    !hideMenu && (
                      <>
                        {!['clubs', 'primaries', 'members', 'activites', 'articles'].includes(
                          tabValue,
                        ) &&
                          (!isMobile || !tabValue || tabValue === '') && (
                            <>
                              <Grid item className={classes.sticky}>
                                <CardOrganisationHome
                                  organisation={organisation}
                                  currentUser={currentUser}
                                  showMap
                                  showMembersAndCover
                                  // showSettings
                                />
                                {isLeagueAdmin(organisation?.holding?.league_slug, currentUser) &&
                                  !hasHoldingAdmin && (
                                    <Grid style={{ marginTop: 8 }}>
                                      <CardInviteAdmin holding={organisation?.holding} />{' '}
                                    </Grid>
                                  )}
                                {hasHoldingAdmin &&
                                  organisation?.holding?.opened_application_roles?.includes(
                                    Roles.VOLUNTEER,
                                  ) && (
                                    <CardJoinUs
                                      organisation={organisation}
                                      role={Roles.VOLUNTEER}
                                    />
                                  )}
                                {hasHoldingAdmin &&
                                  organisation?.holding?.opened_application_roles?.includes(
                                    Roles.EXTERIOR,
                                  ) && (
                                    <CardJoinUs organisation={organisation} role={Roles.EXTERIOR} />
                                  )}
                                {hasHoldingAdmin &&
                                  organisation?.holding?.opened_application_roles?.includes(
                                    Roles.PRIMARY,
                                  ) && (
                                    <CardJoinUs organisation={organisation} role={Roles.PRIMARY} />
                                  )}
                              </Grid>
                            </>
                          )}
                        {tabValue === 'clubs' && showClubTab && clubs?.length > 0 && (
                          <MenuItemClubs organisation={organisation} />
                        )}
                        {isTransmissionWindow && (
                          <RoleCheck
                            role={organisation.role}
                            action="primaries:read"
                            yes={() => <MenuPrimary organisation={organisation} />}
                          />
                        )}
                      </>
                    )
                  }
                  rightMenu={
                    hasRightMenu && (
                      <>
                        {['timeline'].includes(tabValue) && showDealTab && (
                          <Hidden only={['xs', 'sm']}>
                            <Grid container spacing={2}>
                              <ActivitiesBlock organisation={organisation} />
                              {/* <ClubsBlock organisation={organisation} clubs={clubs} /> */}
                            </Grid>
                          </Hidden>
                        )}
                        {tabValue === 'clubs' && showClubTab && (
                          <MenuClub organisation={organisation} clubId={clubId} />
                        )}
                        {isTransmissionWindow && (
                          <RoleCheck
                            role={organisation.role}
                            action="primaries:read"
                            yes={() => <RightMenuPrimary />}
                          />
                        )}
                      </>
                    )
                  }
                >
                  <>
                    {(!tabValue || tabValue === '') && (
                      <About
                        organisation={organisation}
                        userCanEditHolding={canEditHolding(organisation, currentUser)}
                      />
                    )}
                    {tabValue === 'timeline' && showDealTab && (
                      <OrganisationTimeline organisation={organisation} />
                    )}
                    {tabValue === 'activites' && showActivityTab && (
                      <Twigs organisation={organisation} />
                    )}
                    {tabValue === 'members' && (
                      <RoleCheck
                        role={organisation.role}
                        action="members:read"
                        yes={() => <MembershipSettings organisation={organisation} />}
                      />
                    )}
                    {tabValue === 'primaries' && (
                      <RoleCheck
                        role={organisation.role}
                        action="primaries:read"
                        yes={() =>
                          primaryOrgaId ? (
                            <ShowTransmissions organisation={organisation} />
                          ) : (
                            <GridPrimaries organisation={organisation} />
                          )
                        }
                      />
                    )}
                    {tabValue === 'clubs' && showClubTab && (
                      <ShowClub organisation={organisation} clubId={clubId} />
                    )}
                    {tabValue === 'leads' && showLeadTab && <Leads organisation={organisation} />}
                    {tabValue === 'formations' && showCourseTab && (
                      <Courses organisation={organisation} />
                    )}
                    {tabValue === 'articles' && showArticleTab && (
                      <Articles organisation={organisation} />
                    )}
                  </>
                </OrganisationLayoutContainer>
              </>
            )}
            <Divider style={{ margin: '2rem', background: 'none' }} />
          </Grid>
          {showBreadcrumb && <HomeBreadcrumb organisation={organisation} />}
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
