import React from 'react';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { useLocation } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import WelloAvatar from '../../Shared/WelloAvatar';
import Routes from '../../../Routing/Routes';
import { RoleCheck } from '../../Shared/Can';
import OrganisationEdit from '../../../Pages/Organisations/Settings/OrganisationEdit';
import images from '../../../Themes/Images';
import CardHoldingHome from '../../Holdings/CardHoldingHome';
import { getCdnUrl } from '../../../Services/ImageResizer';
import { CheckRoleRule } from '../../../Acl/Rules';
import { canEditHolding } from '../../../Acl/Controls';
import HoldingEdit from '../../../Pages/Holding/Settings/HoldingEdit';

const MapWithPolygon = loadable(() => import('../../Shared/MapWithPolygon'), { ssr: false });

const useStyles = makeStyles(theme => ({
  media: {
    objectFit: 'cover',
  },
  headerName: {
    fontWeight: 600,
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    maxHeight: '3.8em',
    whiteSpace: 'normal',
  },
  helpers: {
    display: 'flex',
    margin: theme.spacing(1, 0),
    // '&:first-child': {
    //   marginLeft: 0,
    // },
  },
  avatar: {
    marginRight: '-20px',
    width: 45,
    height: 45,
    fontSize: '14px',
    border: '2px solid #fff',
  },
  procount: {
    alignSelf: 'center',
    fontSize: '0.8rem',
  },
  map: {
    padding: 0,
    height: 600,
    width: 800,
  },
  content: {
    paddingBottom: 0,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  edit: {
    color: '#000000de',
    marginLeft: theme.spacing(2),
  },
  dialog: {
    minWidth: '60%',
  },
  more: {
    color: 'black',
    position: 'absolute',
    right: theme.spacing(2),
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline',
    margin: 0,
    padding: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  polygonBtn: {
    textTransform: 'none',
    width: '100%',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
  },
  mediaBtn: {
    textTransform: 'none',
    width: '100%',
    borderRadius: '4px 4px 0 0',
    height: 140,
    boxShadow: 'none',
  },
  editIcon: {
    color: '#000000de',
    marginLeft: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2),
  },
}));

type Props = {
  organisation: Object,
  showMap: Boolean,
  // showSettings: Boolean,
  currentUser: Object,
  showMembersAndCover: Boolean,
};

const CardOrganisationHome = ({
  organisation,
  showMap,
  currentUser,
  showMembersAndCover,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    dialog: queryParam.get('dialog') || '',
  };
  const userCanEditOrga = CheckRoleRule(organisation.role, 'organisation:configure', {
    organisation,
  });
  const [showEditOrga, setShowEditOrga] = React.useState(
    urlParams.dialog === 'organisation-form' && userCanEditOrga,
  );
  const [showEditHolding, setShowEditHolding] = React.useState(false);

  // const [showEditSubscription, setShowEditSubscription] = React.useState(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMapDialog, setShowMapDialog] = React.useState(false);

  const isBannerVisible =
    (organisation.banner_url || (organisation?.admin_id && !userCanEditOrga)) &&
    showMembersAndCover;

  let avatarMore = organisation.helper_count;
  avatarMore -=
    organisation.sampled_helpers?.length >= 5 ? 5 : organisation.sampled_helpers?.length;

  const helpersAvatars = React.useMemo(() => {
    const helps = organisation.sampled_helpers?.slice(0, 5).map(h => ({
      id: h.id,
      avatar: (
        <WelloAvatar
          key={`helper-${h.id}`}
          avatarUrl={h.avatar_url}
          firstName={h.first_name}
          lastName={h.last_name}
          backgroundColor={h.avatar_background_color}
          avatarClass={classes.avatar}
          size={45}
        />
      ),
    }));
    return helps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  React.useEffect(() => {
    setShowEditOrga(urlParams.dialog === 'organisation-form' && userCanEditOrga);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation?.role]);

  return (
    <>
      <Grid itemScope itemProp="organization" itemType="http://schema.org/Organization">
        <meta itemProp="name" content={`${organisation.holding?.name} ${organisation.name}`} />
        <meta
          itemProp="description"
          content={organisation.holding?.short_description?.replace(/<[^>]*>/g, '')}
        />
        <meta itemProp="telephone" content={organisation.holding?.phone} />
        <meta itemProp="url" content={Routes.organisationHomePath(organisation)} />
        <meta itemProp="address" content={organisation.holding?.formatted_address} />
        <meta
          itemProp="logo"
          content={organisation.holding?.cover_url || organisation.banner_url}
        />
        <meta itemProp="knowsAbout" content={organisation.holding?.external_link} />
        <Card className={classes.media}>
          {isBannerVisible && (
            <CardMedia
              component="img"
              className={classes.media}
              height="140"
              src={getCdnUrl(organisation.banner_url, 280, 140) || images.wello.orga_empty_banner}
              key={organisation.id}
            />
          )}
          {!organisation.banner_url && userCanEditOrga && showMembersAndCover && (
            <Button
              variant="contained"
              startIcon={<AddAPhotoIcon />}
              size="small"
              className={classes.mediaBtn}
              onClick={() => setShowEditOrga(true)}
            >
              {t('APP.ORGANISATION.SETTINGS.GENERAL.BANNER_LABEL')}
            </Button>
          )}
          <CardContent className={classes.content}>
            <Grid className={classes.flex}>
              <Grid className={classes.grow}>
                <Tooltip title={organisation.name} placement="bottom">
                  <Typography
                    variant="h4"
                    component="h3"
                    className={classes.headerName}
                    align="left"
                  >
                    {organisation.name}
                  </Typography>
                </Tooltip>
                {organisation.helper_count > 0 && showMembersAndCover && (
                  <Typography
                    variant="body2"
                    align="left"
                    className={classes.procount}
                    color="textSecondary"
                  >
                    {t('APP.ORGANISATION.HOME.LABEL.Organisations.PRO', {
                      context: organisation.helper_count > 1 && 'MANY',
                      pro_count: organisation.helper_count,
                    })}
                  </Typography>
                )}
              </Grid>
              <RoleCheck
                role={organisation?.role}
                action="organisation:configure"
                data={{ organisation }}
                yes={() => (
                  <Grid>
                    <IconButton
                      size="small"
                      className={classes.edit}
                      onClick={() => setShowEditOrga(true)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                )}
              />
              {/* {showSettings && (
                <Grid>
                  <IconButton
                    size="small"
                    className={classes.more}
                    onClick={event => setAnchorEl(event.currentTarget)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    open={anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        // setShowEditSubscription(true);
                      }}
                    >
                      <ListItemIcon>
                        <NotificationsActiveIcon />
                      </ListItemIcon>
                      <ListItemText>{t('APP.SUBSCRIPTIONS.HANDLE')}</ListItemText>
                    </MenuItem>
                  </Menu>
                </Grid>
              )} */}
            </Grid>
            {showMembersAndCover && helpersAvatars?.length > 0 && (
              <div className={classes.helpers}>
                {/* HELPERS */}
                {helpersAvatars.map(helperAv => (
                  <div key={helperAv.id}>{helperAv.avatar}</div>
                ))}
                {avatarMore > 0 && <Avatar className={classes.avatar}>+{avatarMore}</Avatar>}
              </div>
            )}
          </CardContent>
          <CardContent className={classes.content}>
            <Grid container alignItems="center" className={classes.paddingBottom}>
              <Typography variant="h6" component="p" align="left">
                {t('APP.ORGANISATION.HOME.ABOUT.HOLDING')}
              </Typography>
              {canEditHolding(organisation, currentUser) && (
                <IconButton
                  size="small"
                  className={classes.editIcon}
                  title={t('APP.ORGANISATION.HOME.EDIT_HOLDING')}
                  onClick={() => setShowEditHolding(true)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </Grid>
            <CardHoldingHome organisation={organisation} showCta />
          </CardContent>
          {showMap && (
            <CardContent className={classes.content}>
              <button
                type="button"
                className={classes.linkButton}
                onClick={() => setShowMapDialog(true)}
              >
                {t('SHOW_MAP')}
              </button>
            </CardContent>
          )}
        </Card>
      </Grid>
      {(!organisation?.intervention_polygon || organisation?.intervention_polygon.length < 1) &&
        userCanEditOrga && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<InfoIcon />}
            size="small"
            className={classes.polygonBtn}
            onClick={() => setShowEditOrga(true)}
          >
            {t('APP.ORGANISATION.SETTINGS.GENERAL.GEOGRAPHIC_LABEL')}
          </Button>
        )}
      <Dialog
        open={showEditOrga}
        onClose={() => setShowEditOrga(false)}
        classes={{ paperWidthSm: classes.dialog }}
      >
        <OrganisationEdit organisation={organisation} onClose={() => setShowEditOrga(false)} />
      </Dialog>
      {/* <Dialog
        open={showEditSubscription}
        onClose={() => setShowEditSubscription(false)}
        classes={{ paperWidthSm: classes.dialog }}
      >
        <SubscriptionEdit organisation={organisation} onClose={() => setShowEditSubscription(false)} />
      </Dialog> */}
      <Dialog open={showMapDialog} onClose={() => setShowMapDialog(false)} maxWidth="md">
        <CardContent className={classes.map}>
          {organisation.coordinates?.latitude && organisation.coordinates?.longitude && (
            <MapWithPolygon
              height={600}
              coordinates={{
                latitude: organisation.coordinates.latitude,
                longitude: organisation.coordinates.longitude,
              }}
              points={organisation.intervention_polygon}
            />
          )}
        </CardContent>
      </Dialog>
      <Dialog open={showEditHolding} onClose={() => setShowEditHolding(false)}>
        <HoldingEdit holding={organisation?.holding} onClose={() => setShowEditHolding(false)} />
      </Dialog>
    </>
  );
};
export default CardOrganisationHome;
