import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import automatch from 'autosuggest-highlight/match';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import HelpIcon from '@mui/icons-material/Help';
import CheckIcon from '@mui/icons-material/Check';
import EventIcon from '@mui/icons-material/Event';
import ArticleIcon from '@mui/icons-material/Article';
import { orange } from '@material-ui/core/colors';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import Popover from '@material-ui/core/Popover';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/fr';
import TransmissionsActions from '../../Redux/TransmissionsRedux';
import HoldingActions from '../../Redux/HoldingRedux';
import { getExpertiseStyle } from '../../Themes/Expertises';
import WelloAvatar from '../Shared/WelloAvatar';
import { serializeDateFromDatePicker } from '../../Services/FormHelpers';
import { isClientSide } from '../../Config/ServerConfig';
import { getRoundedTime } from '../../Services/DataHelper';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);
registerPlugin(FilePondPluginFilePoster);
registerPlugin(FilePondPluginImageTransform);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginFileValidateType);

moment.locale(i18n?.language);

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  input: {
    display: 'none',
  },
  primaryGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  primaryName: {
    marginLeft: theme.spacing(1),
  },
  datePicker: {
    display: 'flex',
    alignItems: 'center',
  },
  timePicker: {
    display: 'flex',
    alignItems: 'center',
  },
  picker: {
    '&>*': {
      marginRight: -8,
    },
  },
  orangeColor: {
    color: orange[600],
  },
  infoText: {
    margin: theme.spacing(2, 0),
    marginLeft: theme.spacing(4),
  },
  selected: {
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderRadius: '5px',
    border: `solid 1px ${theme.palette.grey[400]}`,
  },
  crossIcon: {
    flexGrow: 1,
    textAlign: 'end',
  },
  icon: {
    color: 'grey',
    marginRight: theme.spacing(2),
  },
  typos: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  chip: {
    '&>*': {
      '&>*': {
        fill: 'inherit',
      },
    },
  },
}));

type Props = {
  transmission: Object,
  primaryMembership: Object,
  onclose: Function,
};

const TransmissionPostForm = ({ transmission, primaryMembership, onclose }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    item_id: primaryOrgaId,
  } = match.params;
  const expertises = useSelector(state => state.holding.allExpertises);
  const [expertiseSlug, setExpertiseSlug] = React.useState(transmission?.expertise_slug || null);
  const [eventDate, setEventDate] = React.useState(transmission?.event_start_date || Date.now());
  const [startTime, setStartTime] = React.useState(
    transmission?.event_start_date || getRoundedTime(),
  );
  const [endTime, setEndTime] = React.useState(
    transmission?.event_end_date || moment(getRoundedTime()).add(30, 'm').toDate(),
  );
  const [message, setMessage] = React.useState(transmission?.message || '');
  const [images, setImages] = React.useState([]);
  const [imagesCompressed, setImagesCompressed] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(null);
  const eventStartDate = `${moment(eventDate).format('YYYY-MM-DD')} ${moment(startTime).format(
    'H:mm:ss',
  )}`;
  const eventEndDate = `${moment(eventDate).format('YYYY-MM-DD')} ${moment(endTime).format(
    'H:mm:ss',
  )}`;
  const filteredExpertises = expertises
    ? orderBy(expertises, 'level_id', 'desc').filter(e => e.expertise_type === 'service')
    : [];
  if (!isClientSide()) return <></>;
  const EmojiPicker = React.lazy(() => import('emoji-picker-react'));

  const isValidFormStep = () => {
    return (
      message &&
      message.trim() !== '' &&
      eventDate &&
      startTime &&
      new Date(endTime).toISOString() > new Date(startTime).toISOString()
    );
  };

  React.useEffect(() => {
    if (!expertises || expertises.length === 0) {
      dispatch(HoldingActions.getAllExpertisesRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (endTime < startTime) {
      setEndTime(moment(startTime).add(30, 'm').toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime, startTime]);

  React.useEffect(() => {
    const attachments = transmission?.attachments?.filter(a => !a.preview_url);
    const imgs = transmission?.attachments?.filter(a => a.preview_url);
    if (transmission?.cover_image_url) imgs.push({ preview_url: transmission?.cover_image_url });
    if (imgs?.length > 0) {
      setImages(
        imgs.map(im => ({
          source: im.preview_url,
          options: {
            type: 'local',
            file: { id: im.id, name: im.preview_url, size: 0 },
            metadata: { poster: im.preview_url },
          },
        })),
      );
    }
    if (attachments) {
      setFiles(
        attachments.map(attachment => ({
          source: attachment.download_url,
          options: {
            type: 'local',
            file: {
              id: attachment.id,
              name: attachment.filename,
              size: 0,
              url: attachment.download_url,
            },
            metadata: { poster: attachment },
          },
        })),
      );
    }
    // eslint-disable-next-line
  }, []);

  const handleEmojiClick = (event, emojiObject) => {
    setMessage(message + emojiObject.emoji);
  };

  const handleClickSubmit = event => {
    event.preventDefault();
    if (message.trim() === '') return;

    const im = images.map(i => imagesCompressed.find(ic => ic.name === i.name) || i);
    const allFiles = files.concat(im || []);

    if (transmission?.id && transmission?.type === 'Transmissions::Message') {
      const transmissionAttributes = {
        message,
        event_start_date: serializeDateFromDatePicker(eventStartDate),
        event_end_date: serializeDateFromDatePicker(eventEndDate),
        expertise_slug: expertiseSlug,
      };
      dispatch(
        TransmissionsActions.updateOrganisationTransmissionRequest(
          holdingSlug,
          organisationSlug,
          primaryOrgaId,
          transmission.id,
          transmissionAttributes,
          allFiles,
        ),
      );
    }

    if (!transmission?.id) {
      const transmissionAttributes = {
        message,
        event_start_date: serializeDateFromDatePicker(eventStartDate),
        event_end_date: serializeDateFromDatePicker(eventEndDate),
        is_important: false,
        expertise_slug: expertiseSlug,
        element_id: transmission?.element_id,
        // number (obligatoire quand compte rendu depuis un lead ou twig, =id du twig ou du lead)
        element_type: transmission?.element_type,
        // string (obligatoire quand compte rendu depuis un lead ou twig, =url du twig ou du lead)
      };
      dispatch(
        TransmissionsActions.createOrganisationTransmissionRequest(
          holdingSlug,
          organisationSlug,
          primaryOrgaId || primaryMembership?.id,
          transmissionAttributes,
          allFiles,
        ),
      );
    }
    onclose();
  };

  const handleClose = () => {
    setExpertiseSlug(null);
  };

  return (
    <form onSubmit={handleClickSubmit}>
      <Paper className={classes.paper}>
        <Typography variant="h4" align="center" className={classes.title}>
          {t(
            transmission?.id
              ? 'APP.TRANSMISSIONS.POST_FORM.EDIT'
              : 'APP.TRANSMISSIONS.POST_FORM.TITLE',
          )}
        </Typography>
        <Grid item xs={12} sm={8} className={classes.primaryGrid}>
          <AssignmentIndIcon className={classes.icon} />
          <WelloAvatar
            avatarUrl={primaryMembership?.helper?.avatar_url}
            firstName={primaryMembership?.helper?.first_name}
            lastName={primaryMembership?.helper?.last_name}
            backgroundColor={primaryMembership?.helper?.avatar_background_color}
            avatarStyle={{ height: 36, width: 36 }}
          />
          <Typography align="center" className={classes.primaryName}>
            {primaryMembership?.helper.fullname}
          </Typography>
        </Grid>
        {/* expertises */}
        <Grid item xs={12} sm={7} className={classes.primaryGrid}>
          <CheckIcon className={classes.icon} />
          {expertiseSlug && (
            <Grid container flexdirection="row" className={classes.selected}>
              <Grid item className={classes.chip}>
                <Chip
                  variant="outlined"
                  label={t(getExpertiseStyle({ slug: expertiseSlug }).label)}
                  icon={getExpertiseStyle({ slug: expertiseSlug }).icon}
                  style={{ fill: getExpertiseStyle({ slug: expertiseSlug }).color }}
                />
              </Grid>
              {!transmission?.element_type && (
                <Grid item className={classes.crossIcon}>
                  <IconButton size="small" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          )}
          {!expertiseSlug && (
            <FormControl margin="normal" variant="outlined" component="fieldset" fullWidth>
              <Autocomplete
                fullWidth
                id="expertise-select"
                options={filteredExpertises}
                autoHighlight
                defaultValue={
                  expertiseSlug
                    ? {
                        label: t(getExpertiseStyle({ slug: expertiseSlug }).label),
                        slug: expertiseSlug,
                      }
                    : null
                }
                getOptionLabel={exp => t(getExpertiseStyle({ slug: exp.slug }).label)}
                renderOption={(exp, { inputValue }) => {
                  const matches = automatch(
                    t(getExpertiseStyle({ slug: exp.slug }).label),
                    inputValue,
                  );
                  const parts = parse(t(getExpertiseStyle({ slug: exp.slug }).label), matches);
                  return (
                    <Grid item className={classes.chip}>
                      <Chip
                        variant="outlined"
                        label={parts.map((part, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <span key={`${exp.id}-${index}`}>{part.text}</span>
                        ))}
                        icon={getExpertiseStyle(exp).icon}
                        style={{ fill: getExpertiseStyle(exp).color }}
                      />
                    </Grid>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('APP.ACTIVITY.CATEGORY.TITLE')}
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => setExpertiseSlug(value?.slug)}
                disabled={transmission?.element_type}
              />
            </FormControl>
          )}
        </Grid>
        {/* date and time */}
        <Grid container style={{ alignItems: 'center', marginRight: 38 }}>
          <Grid item xs={12} sm={12} md={5} className={classes.datePicker}>
            <EventIcon className={classes.icon} />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={eventDate}
                onChange={date => setEventDate(date)}
                label={t('APP.ACTIVITY.DATE.LABEL')}
                cancelText={t('CANCEL')}
                renderInput={params => (
                  <TextField {...params} variant="outlined" required margin="normal" />
                )}
              />
            </LocalizationProvider>
            <Typography align="center" className={classes.typos}>
              {t('FROM')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.timePicker}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                // ampm={false}
                value={startTime}
                onChange={date => setStartTime(date)}
                label={t('START_TIME.LABEL')}
                cancelText={t('CANCEL')}
                minutesStep={5}
                renderInput={params => (
                  <TextField {...params} variant="outlined" required margin="normal" />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className={classes.timePicker}>
            <Typography align="center" className={classes.typos}>
              {t('TO')}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                // ampm={false}
                value={endTime}
                onChange={date => setEndTime(date)}
                label={t('END_TIME.LABEL')}
                cancelText={t('CANCEL')}
                minutesStep={5}
                renderInput={params => (
                  <TextField {...params} variant="outlined" required margin="normal" />
                )}
              />
            </LocalizationProvider>
            <Tooltip title={t('APP.TRANSMISSIONS.POST_FORM.TOOLTIP.DATE')} placement="top">
              <HelpIcon className={classes.orangeColor} style={{ marginLeft: 8 }} />
            </Tooltip>
          </Grid>
        </Grid>

        {/* message */}
        <Grid className={classes.primaryGrid}>
          <ArticleIcon className={classes.icon} />

          <FormControl component="fieldset" fullWidth>
            <TextField
              name="message"
              value={message}
              onChange={event => setMessage(event.target.value)}
              label={t('APP.POST.FIELD.MESSAGE')}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid style={{ marginLeft: 40 }}>
          {/* files */}
          <input
            accept="image/*"
            className={classes.input}
            id={transmission ? 'icon-button-image' : 'icon-button-image-edit'}
            type="file"
            onChange={event => {
              setImages([event.target.files[0]]);
            }}
          />
          {/* eslint-disable-next-line */}
          <label htmlFor={transmission ? 'icon-button-image' : 'icon-button-image-edit'}>
            <IconButton size="small" aria-label="upload picture" component="span">
              <ImageIcon />
            </IconButton>
          </label>
          <input
            accept="application/*"
            className={classes.input}
            id={transmission ? 'icon-button-file' : 'icon-button-file-edit'}
            type="file"
            onChange={event => {
              setFiles([event.target.files[0]]);
            }}
          />
          {/* eslint-disable-next-line */}
          <label htmlFor={transmission ? 'icon-button-file' : 'icon-button-file-edit'}>
            <IconButton size="small" aria-label="upload picture" component="span">
              <AttachFileIcon />
            </IconButton>
          </label>
          <IconButton onClick={event => setShowEmojiPicker(event.currentTarget)} size="small">
            <SentimentSatisfiedOutlinedIcon style={{ fontsize: '10px' }} />
          </IconButton>
          <Popover
            open={Boolean(showEmojiPicker)}
            anchorEl={showEmojiPicker}
            onClose={() => setShowEmojiPicker(null)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              style: { bottom: 10 },
            }}
          >
            <Suspense fallback={t('LOADING')}>
              <EmojiPicker
                onEmojiClick={handleEmojiClick}
                disableAutoFocus
                groupNames={{
                  smileys_people: t('APP.POST.EMOJIS.PEOPLE'),
                  animals_nature: t('APP.POST.EMOJIS.ANIMALS'),
                  food_drink: t('APP.POST.EMOJIS.FOOD'),
                  travel_places: t('APP.POST.EMOJIS.TRAVEL'),
                  activities: t('APP.POST.EMOJIS.ACTIVITIES'),
                  objects: t('APP.POST.EMOJIS.OBJECTS'),
                  symbols: t('APP.POST.EMOJIS.SYMBOLS'),
                  flags: t('APP.POST.EMOJIS.FLAGS'),
                  recently_used: t('APP.POST.EMOJIS.RECENT'),
                }}
              />
            </Suspense>
          </Popover>
        </Grid>
        <Grid className={classes.filepond}>
          {images && images.length > 0 && (
            <FilePond
              files={images}
              allowMultiple
              maxFiles={6}
              instantUpload={false}
              credits={('', '')}
              acceptedFileTypes={['image/*']}
              labelIdle={t('APP.USER.IMAGE_FIELD.PLACEHOLDER')}
              onupdatefiles={fileItems => {
                setImages(fileItems.map(fileItem => fileItem.file));
              }}
              imageResizeTargetWidth="1200"
              allowImageResize
              imageResizeMode="contain"
              onpreparefile={(file, output) => {
                const newIm = imagesCompressed.slice();
                newIm.push(output);
                setImagesCompressed(newIm);
              }}
              imageResizeUpscale={false}
            />
          )}
          {files && files.length > 0 && (
            <FilePond
              files={files}
              allowMultiple
              maxFiles={5}
              credits={('', '')}
              instantUpload={false}
              acceptedFileTypes={['application/*']}
              labelIdle={t('APP.USER.ATTACHMENT_FIELD.PLACEHOLDER')}
              onupdatefiles={fileItems => {
                setFiles(fileItems.map(fileItem => fileItem.file));
              }}
            />
          )}
        </Grid>
        <Typography align="center" className={classes.infoText}>
          {t('APP.TRANSMISSIONS.POST_FORM.INFO')}
        </Typography>
        <Grid align="center">
          <Button type="submit" variant="contained" color="primary" disabled={!isValidFormStep()}>
            {t('SEND')}
          </Button>
        </Grid>
      </Paper>
    </form>
  );
};

export default TransmissionPostForm;
