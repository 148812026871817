import React from 'react';
import { grey } from '@material-ui/core/colors';
import ForumIcon from '@material-ui/icons/Forum';
import CircleIcon from '@mui/icons-material/Circle';
import CallIcon from '@mui/icons-material/Call';
import ShareIcon from '@mui/icons-material/Share';
import { getExpertiseStyle } from '../Themes/Expertises';

export default function getAvatar(transmission) {
  let avatar = {};
  if (
    transmission?.type === 'Transmissions::Message' &&
    !transmission?.expertise_slug &&
    !transmission?.element_type
  ) {
    avatar = {
      color: '#fff',
      backgroundColor: grey[600],
      component: <ForumIcon style={{ height: 22, width: 22 }} />,
    };
  } else if (
    !transmission?.expertise_slug &&
    transmission?.type === 'Transmissions::Lead' &&
    transmission?.lead.type === 'Leads::NonQualified'
  ) {
    avatar = {
      color: '#fff',
      backgroundColor: grey[600],
      component: <CallIcon style={{ height: 22, width: 22 }} />,
    };
  } else if (
    !transmission?.expertise_slug &&
    transmission?.type === 'Transmissions::Lead' &&
    transmission?.lead.type === 'Leads::Share'
  ) {
    avatar = {
      color: '#fff',
      backgroundColor: grey[600],
      component: <ShareIcon style={{ height: 22, width: 22 }} />,
    };
  } else if (transmission?.expertise_slug) {
    avatar = {
      color: getExpertiseStyle({ slug: transmission?.expertise_slug }).color,
      backgroundColor: '#fff',
      component: getExpertiseStyle({ slug: transmission?.expertise_slug }).icon,
    };
  } else
    avatar = {
      color: grey[600],
      backgroundColor: '#fff',
      component: <CircleIcon fontSize="small" />,
    };

  return avatar;
}
