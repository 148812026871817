import React from 'react';
import Routes from '../../../Routing/Routes';
import Breadcrumb from './Breadcrumb';
import { getOrgaName } from '../../../Services/OrganisationHelper';
import { LEAGUES_WITH_RESULTS_ALGO } from '../../../Services/AlgoliaHelper';

type Props = {
  organisation: Object,
};

const HomeBreadcrumb = ({ organisation }: Props) => {
  const rootName = Routes.currentLeague().rootName;
  const searchUrl = '/recherche/structures';
  const ho = organisation.holding;
  let citySearchUrl = `${searchUrl}?city=${ho.locality}&address=${ho.formatted_address}&lat=${ho.coordinates?.latitude}&long=${ho.coordinates?.longitude}`;
  if (LEAGUES_WITH_RESULTS_ALGO.includes(Routes.currentNamespace()))
    citySearchUrl += `&bbox=${ho.coordinates?.longitude - 0.01};${
      ho.coordinates?.latitude - 0.01
    };${ho.coordinates?.longitude + 0.01};${ho.coordinates?.latitude + 0.01}`;

  const links = [
    { name: rootName, link: '/' },
    { name: 'Structures', link: searchUrl },
    { name: ho.locality, link: citySearchUrl },
  ];

  const current = { name: getOrgaName(organisation) };

  return <Breadcrumb previousLinks={links} currentLink={current} />;
};

export default HomeBreadcrumb;
