import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import OrganisationsSearchBar from './OrganisationsSearchBar';

type Props = {
  open: Boolean,
  dialogTitle: String,
  dialogButtonLabel: String,
  onConfirm: Function,
  onCancel: Function,
  organisations: Array,
  disabledOrganisationsIds: Array,
  textFieldLabel: String,
  needConsent: Boolean,
};
const useStyles = makeStyles(theme => ({
  dialogContent: {
    textAlign: '-webkit-center',
    paddingBottom: 16,
    paddingTop: 16,
  },
  checkbox: { marginTop: theme.spacing(-1) },
  formControl: {
    textAlign: 'left',
    alignItems: 'flex-start',
  },
}));

const SelectOrganisationDialog = ({
  open,
  dialogTitle,
  dialogButtonLabel,
  onConfirm,
  onCancel,
  organisations,
  disabledOrganisationsIds,
  textFieldLabel,
  needConsent,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedOrga, setSelectedOrga] = React.useState(null);
  const [description, setDescription] = React.useState('');
  const [consent, setConsent] = React.useState(false);

  const handleCancel = () => {
    onCancel();
    setSelectedOrga(null);
    setDescription('');
    setConsent(false);
  };

  const handleConfirm = () => {
    onConfirm(selectedOrga, description);
    setSelectedOrga(null);
    setDescription('');
    setConsent(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { paddingBottom: 16, paddingTop: 16 } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4" component="div" align="center">
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <OrganisationsSearchBar
          organisations={organisations}
          selectedOrga={selectedOrga}
          onClickOrga={setSelectedOrga}
          width="100%"
          disabledOrganisationsIds={disabledOrganisationsIds}
        />
        {textFieldLabel && (
          <TextField
            name="description"
            value={description}
            type="text"
            onChange={event => {
              setDescription(event.target.value);
            }}
            label={textFieldLabel}
            margin="normal"
            variant="outlined"
            multiline
            minRows={2}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {needConsent && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={event => setConsent(event.target.checked)}
                checked={consent}
                color="primary"
                className={classes.checkbox}
              />
            }
            label={t('APP.LEADS.SHARED.CONCENT')}
            className={classes.formControl}
          />
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={handleCancel}>{t('CANCEL')}</Button>
        <Button
          color="primary"
          onClick={handleConfirm}
          disabled={
            !selectedOrga || (Boolean(textFieldLabel) && !description) || (needConsent && !consent)
          }
        >
          {dialogButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectOrganisationDialog;
