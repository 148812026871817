import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MUIGrid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';
import { checkEmailValidity } from '../../../Services/DataHelper';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import WelloAvatar from '../../Shared/WelloAvatar';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    ...LayoutStyles.centered600Layout,
    marginTop: 0,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    fontSize: '1.8rem',
  },
  form: {
    padding: theme.spacing(0, 2),
  },
  buttonContainer: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

type Props = {
  memberToAddEmail: Object,
  onCloseDialog: Function,
};

const InviteMemberByEmail = ({ memberToAddEmail, onCloseDialog }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(null);

  const onInviteByEmail = event => {
    if (!memberToAddEmail) return;

    event.preventDefault();
    const updateAttributes = {
      id: memberToAddEmail.id,
      email: email?.toLowerCase()?.trim(),
    };
    dispatch(
      OrganisationMembershipActions.updateOrganisationMembershipRequest(
        memberToAddEmail.holding_slug,
        memberToAddEmail.organisation_slug,
        memberToAddEmail.helper_id,
        updateAttributes,
      ),
    );
    onCloseDialog();
  };

  return (
    <Card itemScope className={classes.paper}>
      <CardHeader
        avatar={
          <WelloAvatar
            avatarUrl={memberToAddEmail?.helper?.avatar_url}
            firstName={memberToAddEmail?.helper?.first_name}
            lastName={memberToAddEmail?.helper?.last_name}
            backgroundColor={memberToAddEmail?.helper?.avatar_background_color}
            avatarClass={classes.bigAvatar}
            size={60}
          />
        }
        title={
          <>
            <Typography variant="h5">
              {memberToAddEmail?.helper?.first_name} {memberToAddEmail?.helper?.last_name}
            </Typography>
            <Typography variant="subtitle1">{memberToAddEmail?.helper?.pseudo}</Typography>
          </>
        }
      />
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {t('APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.EMAIL.TITLE')}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {t('APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.EMAIL.SUBTITLE')}
        </Typography>
      </CardContent>
      <form onSubmit={onInviteByEmail} className={classes.form}>
        <TextField
          name="email"
          value={email}
          onChange={event => setEmail(event.target.value?.trim())}
          placeholder={t('APP.QUOTATION.EMAIL')}
          variant="outlined"
          fullWidth
          onBlur={() => setEmailError(!checkEmailValidity(email))}
          error={emailError}
          helperText={emailError && t('INCORRECT_EMAIL')}
        />
        <MUIGrid className={classes.buttonContainer}>
          <Button
            disabled={email === '' || emailError}
            color="primary"
            type="submit"
            variant="contained"
          >
            {t('SEND')}
          </Button>
        </MUIGrid>
      </form>
    </Card>
  );
};

export default InviteMemberByEmail;
