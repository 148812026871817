import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Hidden } from '@material-ui/core';

import OrganisationSignUp from '../Authentication/Organisation/SignUp';
import ContactSuccess from '../../Components/Organisations/Shared/ContactSuccess';
import { getCdnUrl } from '../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(1),
  },
  media: {
    width: '100%',
    height: 300,
  },
  joinBody: {
    marginBottom: theme.spacing(3),
  },
  btn: {
    backgroundColor: theme.current.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.current.primary.dark,
    },
  },
}));

type Props = {
  community: Object,
  canJoin: Boolean,
};

const CardCommunityHome = ({ community, canJoin }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openSignUp, setOpenSignUp] = React.useState(false);
  const [openSignUpPending, setOpenSignUpPending] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const handleSubmitClick = userEmail => {
    setEmail(userEmail);
    setOpenSignUp(false);
    setOpenSignUpPending(true);
  };

  return (
    <>
      <Card>
        <Hidden smDown>
          <CardMedia
            component="img"
            className={classes.media}
            height="140"
            src={getCdnUrl(community?.holding_cover_url, null, 400)}
          />
        </Hidden>
      </Card>
      {canJoin && (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h4" component="div" gutterBottom>
              {t('APP.COMMUNITIES.CARD.JOIN.TITLE')}
            </Typography>
            <Typography variant="body2" className={classes.joinBody}>
              {t('APP.COMMUNITIES.CARD.JOIN.BODY')}
            </Typography>
            <Button variant="contained" className={classes.btn} onClick={() => setOpenSignUp(true)}>
              {t('APP.COMMUNITIES.CARD.JOIN.LABEL')}
            </Button>
          </CardContent>
        </Card>
      )}
      <Dialog open={openSignUp} onClose={() => setOpenSignUp(false)}>
        <OrganisationSignUp
          organisation={community}
          isDialog
          onSubmitClick={handleSubmitClick}
          subtitle={t('APP.COMMUNITIES.SIGN_UP.SUBTITLE')}
          title={t('APP.COMMUNITIES.SIGN_UP.TITLE')}
        />
      </Dialog>
      <Dialog open={openSignUpPending} onClose={() => setOpenSignUpPending(false)}>
        <ContactSuccess
          title={t('APP.COMMUNITIES.SIGN_UP.SUCCESS.TITLE')}
          description={!isLoggedIn && t('APP.COMMUNITIES.SIGN_UP.SUCCESS.DESCRIPTION', { email })}
          subtitle={t('APP.COMMUNITIES.SIGN_UP.SUCCESS.SUBTITLE')}
        />
      </Dialog>
    </>
  );
};

export default CardCommunityHome;
