import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';

import CardService from './CardService';
import HoldingActions from '../../../Redux/HoldingRedux';
import RubricForm from '../../Holdings/RubricForm';
import { orderExpertisesByLabelRank } from '../../../Themes/Expertises';
import ServiceForm from '../../../Pages/Holding/Settings/ServiceForm';
import Slider from 'react-slick';
import { getSettings } from '../../../Services/DataHelper';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: '800',
    textTransform: 'none',
  },
  description: { margin: theme.spacing(2) },
  slider: {
    margin: theme.spacing(1.5),
    '& .slick-track': {
      margin: 0, // align left
    },
  },
  button: { textTransform: 'none' },
  paper: {
    padding: theme.spacing(4),
  },
  cardAction: {
    objectFit: 'cover',
    boxShadow: 'none',
    height: 312,
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    border: 'dashed',
    borderColor: theme.wello.primary.main,
    margin: theme.spacing(0.5),
  },
  dialogWidth: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 800,
    },
  },
}));

type Props = {
  organisation: Object,
  rubric: Object,
  userCanEditHolding: Boolean,
};

const CardRubric = ({ organisation, rubric, userCanEditHolding }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEditRubric, setShowEditRubric] = React.useState(false);
  const [showRemoveServices, setShowRemoveServices] = React.useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = React.useState(false);
  const [showServiceForm, setShowServiceForm] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const settings = getSettings([3, 3, 2, 1]);

  const rubricOrgaServices = organisation.services?.filter(
    orgaService => orgaService?.service?.level?.id === rubric?.level_id,
  );
  const rubricServices = rubricOrgaServices?.map(orgaService => ({ ...orgaService.service }));

  const handleClickAddService = () => {
    setShowServiceForm(true);
    setAnchorEl(null);
  };

  const handleClickEditRubric = () => {
    setShowEditRubric(true);
    setAnchorEl(null);
  };

  const handleClickDeleteRubric = () => {
    setAnchorEl(null);
    if (rubricOrgaServices.length > 0) {
      setShowRemoveServices(true);
    } else {
      setShowConfirmRemove(true);
    }
  };

  const handleRemove = () => {
    dispatch(HoldingActions.removeHoldingRubricRequest(organisation.holding_slug, rubric.id));
  };

  return (
    <>
      <Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h3" className={classes.title}>
            {rubric?.title}
          </Typography>
          {userCanEditHolding && (
            <>
              <IconButton
                onClick={event => setAnchorEl(event.currentTarget)}
                aria-label="More"
                aria-owns={anchorEl ? 'actions-menu' : undefined}
                aria-haspopup="true"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="actions-menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                {rubric?.level_id && (
                  <MenuItem onClick={() => handleClickAddService()}>
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText>{t('APP.ORGANISATION.SETTINGS.SERVICES.NEW')}</ListItemText>
                  </MenuItem>
                )}
                <MenuItem onClick={() => handleClickEditRubric()}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText>{t('APP.ORGANISATION.HOME.RUBRIC.UPDATE')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClickDeleteRubric()}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>{t('APP.ORGANISATION.HOME.RUBRIC.REMOVE')}</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )}
        </Grid>
        {rubric?.description && (
          <Typography
            variant="body1"
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: rubric?.description }}
          />
        )}
        {rubric?.level_id !== null && (
          <Slider {...settings} className={classes.slider}>
            {orderExpertisesByLabelRank(rubricServices)?.map(service => (
              <CardService
                key={service.slug}
                service={service}
                organisation={organisation}
                canEditHolding={userCanEditHolding}
              />
            ))}
            {userCanEditHolding && (
              <Grid>
                <Card className={classes.cardAction}>
                  <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => setShowServiceForm(true)}
                  >
                    <AddIcon />
                    {t('APP.ORGANISATION.SETTINGS.SERVICES.NEW')}
                  </Button>
                </Card>
              </Grid>
            )}
          </Slider>
        )}
      </Grid>
      <Dialog
        open={showEditRubric}
        onClose={() => setShowEditRubric(false)}
        className={classes.dialogWidth}
      >
        <RubricForm
          rubric={rubric}
          organisation={organisation}
          onClose={() => setShowEditRubric(false)}
        />
      </Dialog>
      <Dialog open={showRemoveServices} onClose={() => setShowRemoveServices(false)}>
        <Paper className={classes.paper}>
          <Typography variant="body1" align="center" gutterBottom>
            {t('APP.ORGANISATION.HOME.RUBRIC.NOT_REMOVE')}
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            {t('APP.ORGANISATION.HOME.RUBRIC.REMOVE_SERVICES')}
          </Typography>
        </Paper>
      </Dialog>
      <Dialog open={showConfirmRemove} onClose={() => setShowConfirmRemove(false)}>
        <DialogTitle>{t('APP.ORGANISATION.HOME.RUBRIC.CONFIRM_REMOVE')}</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button onClick={() => setShowConfirmRemove(false)} color="primary">
            {t('CANCEL')}
          </Button>
          <Button onClick={handleRemove}>{t('OK')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showServiceForm}
        onClose={() => setShowServiceForm(false)}
        className={classes.dialogWidth}
      >
        <ServiceForm
          organisation={organisation}
          onClose={() => setShowServiceForm(false)}
          levelId={rubric?.level_id}
        />
      </Dialog>
    </>
  );
};

export default CardRubric;
