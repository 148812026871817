import moment from 'moment';
import { muiTheme } from '../Themes';
import getExpertiseLevelStyle, { getExpertiseStyle } from '../Themes/Expertises';
import { getTwigEndDate, hex2rgba } from './DataHelper';

export const sameDate = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getYear() === date2.getYear()
  );
};

export const sameMonth = (date1, date2) => {
  return date1.getMonth() === date2.getMonth() && date1.getYear() === date2.getYear();
};

export const formatTwigData = twig => {
  return {
    title: twig.task?.description,
    startDate: new Date(twig.event_date),
    endDate: getTwigEndDate(twig),
    time: moment(twig.event_date).format('LT'),
    event_start_time: twig?.event_start_time,
    event_end_time: twig?.event_end_time,
    id: `${twig.task?.id}-${twig.event_date}`,
    location: twig.task?.formatted_address,
    lightBackgroundColor:
      twig?.task?.holding_slug || (!twig?.task?.holding_slug && twig?.helpers?.length > 0)
        ? getExpertiseLevelStyle(twig.task?.expertise?.level?.slug).lightColor
        : hex2rgba(muiTheme.palette.grey[500], 0.2),
    backgroundColor:
      twig?.task?.holding_slug || (!twig?.task?.holding_slug && twig?.helpers?.length > 0)
        ? getExpertiseStyle(twig.task?.expertise).color
        : muiTheme.palette.grey[500], // facet color unless family task without registered
    icon: getExpertiseStyle(twig.task?.expertise).icon,
    twig,
    hasRecurrence: Boolean(twig.task?.recurrence_type !== 'NONE'), // 'FREQ=DAILY;COUNT=10',
    colorIcon: twig?.task?.holding_slug || (!twig?.task?.holding_slug && twig?.helpers?.length > 0),
  };
};

export const getOffsetDates = (oldTwigDate, newTwigDate, task) => {
  // keep same time
  newTwigDate.setHours(oldTwigDate.getHours());
  newTwigDate.setMinutes(oldTwigDate.getMinutes());

  // compute offset
  const offset = Math.round(moment(newTwigDate).diff(moment(oldTwigDate), 'days', true));

  // apply offset
  const newStartDate = new Date(moment(task?.start_date).add(offset, 'days').format());
  let newUntilDate = new Date(task.until_date);
  if (task.recurrence_type !== 'NONE') {
    newUntilDate = new Date(moment(task?.until_date).add(offset, 'days').format());
  }

  return [newStartDate, newUntilDate];
};
