import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import HelpIcon from '@mui/icons-material/Help';
import Card from '@material-ui/core/Card';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { orange } from '@material-ui/core/colors';
import ConfirmationDialog from '../../Shared/ConfirmationDialog';
import { isAdmin, isMainHelper, getRole, isCommunity, isLeagueAdmin } from '../../../Acl/Controls';
import OrganisationMembershipActions from '../../../Redux/OrganisationMembershipRedux';
import { CheckRoleRule } from '../../../Acl/Rules';
import Roles from '../../../Acl/Roles';
import HelperDetails from '../../Shared/HelperDetails';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 0,
    ...LayoutStyles.centered600Layout,
    marginTop: 0,
  },
  list: {
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '0px',
  },
  listItem: {
    marginRight: '3px',
    paddingLeft: '2px',
  },
  flex: {
    '&>*': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  orangeColor: {
    color: orange[600],
    marginLeft: 8,
  },
}));

type Props = {
  membership: Object,
  organisation: Object,
  canConfigureFamily: Boolean,
  onValidateMember: Function,
  onRemoveMember: Function,
  // onResetPassword: Function,
  onResetPin: Function,
  onClickInviteWithEmail: Function,
  onUpdateRole: Function,
};

const MembershipDetail = ({
  membership,
  organisation,
  canConfigureFamily,
  onValidateMember,
  onRemoveMember,
  // onResetPassword,
  onResetPin,
  onClickInviteWithEmail,
  onUpdateRole,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: orgaSlug,
    family_slug: familySlug,
  } = match.params;
  const currentUser = useSelector(state => state.auth.currentUser);
  const [openRemoveMemberDialog, setOpenRemoveMemberDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { helper, role, validated } = membership;
  const open = Boolean(anchorEl);
  const orgaRole = getRole(currentUser, holdingSlug, orgaSlug, familySlug);
  const canUpdateMember =
    CheckRoleRule(orgaRole, 'members:update') ||
    isLeagueAdmin(organisation?.holding?.league_slug, currentUser);
  const canValidateMember = CheckRoleRule(orgaRole, 'members:validate');

  const onClickValidateMember = () => {
    onValidateMember(membership);
  };

  const onConfirmRemoveMember = () => {
    onRemoveMember(membership);
    setOpenRemoveMemberDialog(false);
    setAnchorEl(null);
  };

  const onCancelRemoveMember = () => {
    setOpenRemoveMemberDialog(false);
    setAnchorEl(null);
  };

  // const onClickResetPassword = () => {
  //   onResetPassword(membership);
  //   setAnchorEl(null);
  // };

  const onClickResetPin = () => {
    onResetPin(membership);
    setAnchorEl(null);
  };

  const onClickUpdateMemberRole = () => {
    onUpdateRole(membership);
    setAnchorEl(null);
  };

  const onClickInviteByEmail = () => {
    onClickInviteWithEmail(membership);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    dispatch(OrganisationMembershipActions.resetNewMembership());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card itemScope className={classes.paper}>
        <HelperDetails
          helper={helper}
          actions={
            <>
              {(canConfigureFamily ||
                CheckRoleRule(organisation?.role, 'organisation:configure', { organisation })) && (
                <>
                  <IconButton
                    onClick={event => setAnchorEl(event.currentTarget)}
                    aria-label="More"
                    aria-owns={open ? 'actions-menu' : undefined}
                    aria-haspopup="true"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                  >
                    {canValidateMember && onValidateMember && (
                      <MenuItem disabled={validated} onClick={() => onClickValidateMember()}>
                        <ListItemText className={classes.flex}>
                          {t('APP.ORGANISATION.SETTINGS.MEMBERS.VALIDATE')}
                          {!validated && (
                            <Tooltip
                              title={t('APP.ORGANISATION.SETTINGS.MEMBERS.VALIDATE.TOOLTIP')}
                              placement="top"
                            >
                              <HelpIcon className={classes.orangeColor} />
                            </Tooltip>
                          )}
                        </ListItemText>
                      </MenuItem>
                    )}
                    {/* <MenuItem disabled={helper?.email} onClick={() => onClickResetPassword()}>
                      <ListItemText>
                        {t('APP.ORGANISATION.SETTINGS.MEMBERS.RESET_PASSWORD')}
                      </ListItemText>
                    </MenuItem> */}
                    {CheckRoleRule(role, 'pin:be-updated') &&
                      !isCommunity(organisation) &&
                      CheckRoleRule(orgaRole, 'pin:update') && (
                        <MenuItem onClick={() => onClickResetPin()}>
                          <ListItemText>
                            {t('APP.ORGANISATION.SETTINGS.MEMBERS.RESET_PIN')}
                          </ListItemText>
                        </MenuItem>
                      )}
                    {canUpdateMember && !familySlug && (
                      <MenuItem
                        disabled={role === Roles.ADMIN}
                        onClick={() => onClickUpdateMemberRole()}
                      >
                        <ListItemText>
                          {t('APP.ORGANISATION.SETTINGS.MEMBERS.UPDATE_ROLE')}
                        </ListItemText>
                      </MenuItem>
                    )}
                    <MenuItem
                      disabled={helper?.email && helper.validation_status === 'email_validated'}
                      onClick={onClickInviteByEmail}
                    >
                      <ListItemText>
                        {t(
                          `APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.${
                            helper.validation_status === 'not_invited' ? 'EMAIL' : 'EMAIL_AGAIN'
                          }`,
                        )}
                      </ListItemText>
                    </MenuItem>
                    {onRemoveMember && (
                      <MenuItem
                        onClick={() => setOpenRemoveMemberDialog(true)}
                        disabled={isAdmin(role) || isMainHelper(role)}
                      >
                        <ListItemText>
                          {t('APP.ORGANISATION.SETTINGS.MEMBERS.REMOVE.TITLE')}
                        </ListItemText>
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </>
          }
          validated={validated}
        />
      </Card>
      <ConfirmationDialog
        openDialog={openRemoveMemberDialog}
        onCancel={() => onCancelRemoveMember()}
        onClickConfirm={onConfirmRemoveMember}
        dialogTitle={t('APP.ORGANISATION.SETTINGS.MEMBERS.REMOVE.TITLE')}
        dialogContent={t('APP.ORGANISATION.SETTINGS.MEMBERS.REMOVE.CONTENT')}
      />
    </>
  );
};

export default MembershipDetail;
