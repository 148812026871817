/* eslint-disable camelcase */
const getFirstNames = (club, t) => {
  if (!club.firstnames || club.firstnames.length === 0) {
    return t('APP.ORGANISATION.MENU.CLUBS_NO_MEMBERS');
  }
  return club.firstnames.reduce((acc, item) => {
    if (acc.length === 0) {
      return item?.first_name;
    }
    return `${acc}, ${item?.first_name}`;
  }, '');
};

const getPostFormatedCount = club => (club.unread_posts_count > 9 ? '9+' : club.unread_posts_count);
const getOrgaFormatedCount = orga => (orga.unread_count > 9 ? '9+' : orga.unread_count);

export { getFirstNames, getPostFormatedCount, getOrgaFormatedCount };
