import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import TasksActions from '../../Redux/TasksRedux';
import OrganisationLayoutContainer from '../../Components/Layout/LayoutContainer';
import AuthMenu from '../../Components/Organisations/Shared/Menu';
import { getFutureDate, getPastDate, getCurrentDate } from '../../Services/DataHelper';

import { formatTwigData } from '../../Services/SchedulerHelper';
import { getRole } from '../../Acl/Controls';
import OrganisationScheduler from '../../Components/Organisations/Schedule/OrganisationScheduler';
import GridTwigs from '../../Components/Organisations/Schedule/GridTwigs';
import EmptyList from '../../Components/Shared/EmptyList';
import AppBarSchedule from '../../Components/Organisations/Schedule/AppBarSchedule';

type Props = {
  family: Object,
  organisation: Object,
};

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  progress: {
    transform: 'translateY(-8px)',
    marginBottom: '-4px',
  },
}));

const Schedule = ({ family, organisation }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.auth.currentUser);
  const twigs = useSelector(state => state.tasks.twigs);
  const errors = useSelector(state => state.tasks.errors.getTwigs);
  const isFetching = useSelector(state => state.tasks.isFetching.getTwigs);
  const [viewMode, setViewMode] = React.useState('schedule');
  const [filterArchived, setFilterArchived] = React.useState('toCome');
  const role = getRole(currentUser, organisation?.holding_slug, organisation?.slug, family?.slug);
  const [currentDate, setCurrentDate] = React.useState(Date.now());

  const twigsFound =
    twigs &&
    twigs.length !== 0 &&
    twigs.every(
      tw =>
        tw.task?.organisation_slug === family?.slug ||
        !!tw.helpers?.find(h => h.id === family?.admin?.id),
    );

  const noTwigs = !isFetching && twigs && twigs.length === 0;

  const gridData = React.useMemo(() => {
    const filteredTwigs = twigs.filter(
      twig =>
        !twig.task?.holding_slug &&
        twig.task?.organisation_slug === family.slug &&
        ((filterArchived === 'over' && twig.event_date < getCurrentDate()) ||
          (filterArchived === 'toCome' && twig.event_date >= getCurrentDate())),
    );
    return orderBy(filteredTwigs, 'event_date', filterArchived === 'over' ? 'desc' : 'asc');
  }, [twigs, filterArchived]);

  const scheduleData = React.useMemo(() => {
    if (twigsFound) return twigs.map(twig => formatTwigData(twig));
    return [];
  }, [twigs, twigsFound]);

  React.useEffect(() => {
    dispatch(
      TasksActions.getTwigsRequest(null, null, family?.slug, getFutureDate(), getPastDate(), true),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, organisation, family]);

  return (
    <>
      <OrganisationLayoutContainer menu={<AuthMenu organisation={organisation} family={family} />}>
        <div className={classes.appBarContainer}>
          <AppBarSchedule
            typography={
              <Typography className={classes.grow} variant="h5" component="h2">
                {t('APP.FAMILY.SCHEDULE.APP_BAR.TITLE')}
              </Typography>
            }
            viewMode={viewMode}
            setViewMode={setViewMode}
            filterArchived={filterArchived}
            setFilterArchived={setFilterArchived}
            role={role}
            canCreateTask={family?.admin?.has_carenotebook}
            tooltipTitle={t('APP.FAMILY.SCHEDULE.APP_BAR.CREATE')}
            link={`/families/${family.slug}/schedule/new`}
          />
        </div>

        {isFetching && <LinearProgress className={classes.progress} />}
        {viewMode === 'grid' && twigsFound && (
          <GridTwigs
            twigs={gridData}
            currentUser={currentUser}
            organisation={family || organisation}
            archived={filterArchived === 'over'}
          />
        )}
        {viewMode === 'grid' && noTwigs && (
          <EmptyList id="APP.ACTIVITY.FAMILY.EMPTY" level={errors ? 'error' : 'info'} />
        )}
        {viewMode === 'schedule' && (
          <OrganisationScheduler
            data={scheduleData}
            currentUser={currentUser}
            role={role}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
          />
        )}
      </OrganisationLayoutContainer>
    </>
  );
};

export default Schedule;
