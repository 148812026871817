import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { grey } from '@material-ui/core/colors';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/Download';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';

import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  namesText: {
    fontWeight: '700',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    overflow: 'hidden',
  },
  iconContainer: {
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  folderIcon: {
    marginRight: theme.spacing(3),
    color: theme.current.primary.main,
  },
  arrowWidthCell: { width: 64 },
  widthCell: { width: 160 },
  downloadBtn: { backgroundColor: grey[100] },
  box: {
    padding: theme.spacing(0, 9),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0),
    },
  },
  detail: {
    justifyContent: 'space-between',
    padding: theme.spacing(4, 0),
    flexWrap: 'nowrap',
  },
  articleContent: {
    '&>*': { margin: 0 },
  },
  actionIcon: { height: 'fit-content' },
  file: {
    alignItems: 'flex-end',
    padding: theme.spacing(2, 0),
  },
  linkColor: { color: 'initial' },
  downloadIcon: { marginRight: theme.spacing(1) },
}));

type Props = {
  article: object,
  onDeleteArticle: Function,
  handleEditArticle: Function,
  canEditDelete: Boolean,
};

const RowCommunityArticle = ({
  article,
  onDeleteArticle,
  handleEditArticle,
  canEditDelete,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [actionMenuAnchor, setActionMenuAnchor] = React.useState(null);

  const downloadAllFiles = attachments => {
    attachments.map(file => window.open(file.download_url, '_blank', 'noopener noreferrer'));
  };

  return (
    <React.Fragment>
      <TableRow
        key={article?.id}
        onClick={() => setOpen(!open)}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell className={classes.arrowWidthCell}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Grid container className={classes.iconContainer}>
            {article?.attachments.length > 1 ? (
              <FolderIcon className={classes.folderIcon} />
            ) : (
              <InsertDriveFileIcon className={classes.folderIcon} />
            )}
            <Typography className={classes.namesText}>{article?.title}</Typography>
          </Grid>
        </TableCell>
        <TableCell variant="subtitle1" className={classes.widthCell}>
          {moment(article?.created_at).format('LL')}
        </TableCell>
        <TableCell className={classes.widthCell} align="center">
          <IconButton
            className={classes.downloadBtn}
            onClick={() => downloadAllFiles(article?.attachments)}
          >
            <DownloadIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            <Box sx={{ margin: 1 }} className={classes.box}>
              <Grid container className={classes.detail}>
                <Grid>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: t('APP.COMMUNITIES.ARTICLES.CREATED_BY', {
                        helper: `${article?.helper?.first_name} ${article?.helper?.last_name}`,
                      }),
                    }}
                  />
                  <Typography
                    className={classes.articleContent}
                    dangerouslySetInnerHTML={{ __html: article?.content }}
                  />
                </Grid>
                {canEditDelete && (
                  <>
                    <IconButton
                      className={classes.actionIcon}
                      onClick={event => setActionMenuAnchor(event.currentTarget)}
                      aria-label="More"
                      aria-owns={actionMenuAnchor ? 'actions-menu' : undefined}
                      aria-haspopup="true"
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="actions-menu"
                      open={Boolean(actionMenuAnchor)}
                      anchorEl={actionMenuAnchor}
                      onClose={() => setActionMenuAnchor(null)}
                    >
                      <MenuItem
                        onClick={() => {
                          handleEditArticle(article);
                          setActionMenuAnchor(null);
                        }}
                      >
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText>{t('EDIT')}</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => onDeleteArticle(article?.slug)}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText>{t('DELETE')}</ListItemText>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Grid>
              {article?.attachments?.length > 0 &&
                article?.attachments?.map(file => (
                  <>
                    <Divider />
                    <a
                      href={file.download_url}
                      key={file.id}
                      target="_blank"
                      without="true"
                      rel="noopener noreferrer"
                      className={classes.linkColor}
                    >
                      <Grid container className={classes.file}>
                        <DownloadIcon className={classes.downloadIcon} />
                        <Typography>{file.filename}</Typography>
                      </Grid>
                    </a>
                  </>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default RowCommunityArticle;
