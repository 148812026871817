import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/fr';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { grey, orange } from '@material-ui/core/colors';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import WelloAvatar from '../../Components/Shared/WelloAvatar';
import AutoCompletePlaces from '../../Components/Shared/AutoCompletePlaces';
import { checkEmailValidity, checkNoSpecialCharsInName } from '../../Services/DataHelper';
import PhoneControl from '../../Components/Shared/PhoneControl';
import { getHelpersStatus } from '../../Services/LeagueHelper';
import { useSelector } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

moment.locale(i18n?.language);

const useStyles = makeStyles(theme => ({
  layout: LayoutStyles.centered600Layout,
  gender: {
    flexDirection: 'row',
  },
  submit: {
    margin: theme.spacing(2, 1, 0),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  placeholderLike: {
    color: grey[300],
  },
  avatar: {
    width: 80,
    height: 80,
    fontSize: '2.5rem',
  },
  cameraButton: {
    backgroundColor: theme.palette.grey[300],
    padding: '5px',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.primary.main,
    },
  },
  badge: {
    width: 'fit-content',
    paddingTop: theme.spacing(1),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  autocomplete: {
    paddingBottom: theme.spacing(2),
  },
  helpIcon: {
    color: orange[600],
    marginLeft: theme.spacing(1),
  },
  checkBox: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(-2),
  },
}));

type Props = {
  user: Object,
  setUser: Function,
  hideAdvancedParams: Boolean,
  hideProfilParams: Boolean,
  onSubmit: Function,
  isUpdating: Boolean,
  buttonBack: Boolean,
  onCancel: Function,
  showLeagueStatus: Boolean,
  emailRequired: Boolean,
  tooltipLabel: String,
  organisation: Object,
  hideEmailParams: Boolean,
};

const UserForm = ({
  user,
  setUser,
  onSubmit,
  hideAdvancedParams,
  hideProfilParams,
  isUpdating,
  buttonBack,
  onCancel,
  showLeagueStatus,
  emailRequired,
  tooltipLabel,
  hideEmailParams,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const leagueHelpers = useSelector(state => state.leagues.helpers);
  const [address, setAddress] = React.useState();
  const [phoneError, setPhoneError] = React.useState(null);
  const [firstNameError, setFirstNameError] = React.useState(null);
  const [lastNameError, setLastNameError] = React.useState(null);
  const existingHelpersStatus = leagueHelpers ? getHelpersStatus(leagueHelpers) : [];
  const [emailError, setEmailError] = React.useState(null);
  const [initialEmail] = React.useState(user?.email);
  const currentUser = useSelector(state => state.auth.currentUser);

  React.useEffect(() => {
    if (address) {
      setUser({
        ...user,
        formattedAddress: address.formattedAddress,
        latitude: address.latitude,
        longitude: address.longitude,
        locality: address.locality,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const handleOnBlurFirstName = (event, trim) => {
    const {
      target: { value },
    } = event;

    if (!value || checkNoSpecialCharsInName(value)) setFirstNameError(null);
    if (value && !checkNoSpecialCharsInName(value)) {
      setFirstNameError(t('WELLO.ALL_FIRST_NAME.ERRORS'));
    }

    if (trim) setUser({ ...user, firstName: value?.trim() });
  };

  const handleOnBlurLastName = (event, trim) => {
    const {
      target: { value },
    } = event;

    if (!value || checkNoSpecialCharsInName(value)) setLastNameError(null);
    if (value && !checkNoSpecialCharsInName(value)) {
      setLastNameError(t('WELLO.ALL_LAST_NAME.ERRORS'));
    }

    if (trim) setUser({ ...user, lastName: value?.trim() });
  };

  const handleLoadAvatar = ev => {
    const file = ev.target.files[0];
    const reader = new FileReader();
    reader.onload = e => {
      const img = document.createElement('img');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const MAX_WIDTH = 100;
        const MAX_HEIGHT = 100;
        let { width, height } = img;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const dataurl = canvas.toDataURL('image/png');
        setUser({ ...user, avatarPreview: dataurl, avatar: file });
      };
      img.src = e.target.result;
    };
    if (file) reader.readAsDataURL(file);
  };

  const renderBasicForm = () => (
    <Grid container>
      <Grid item xs={12} sm={6} className={classes.paddingRight}>
        <FormControl component="fieldset" fullWidth>
          <TextField
            name="firstName"
            value={user.firstName}
            onChange={event => {
              setUser({ ...user, firstName: event.target.value });
              handleOnBlurFirstName(event, false);
            }}
            onBlur={event => handleOnBlurFirstName(event, true)}
            label={t('APP.USER.TEXT_FIELD.LABEL.FIRST_NAME')}
            margin="normal"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
            helperText={firstNameError}
            error={firstNameError !== null}
          />{' '}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset" fullWidth>
          <TextField
            name="lastName"
            value={user.lastName}
            onChange={event => {
              setUser({ ...user, lastName: event.target.value });
              handleOnBlurLastName(event, false);
            }}
            onBlur={event => handleOnBlurLastName(event, true)}
            label={t('APP.USER.TEXT_FIELD.LABEL.LAST_NAME')}
            margin="normal"
            variant="outlined"
            fullWidth
            required
            InputLabelProps={{
              shrink: true,
            }}
            helperText={lastNameError}
            error={lastNameError !== null}
          />
        </FormControl>
      </Grid>
    </Grid>
  );

  const renderProfilForm = () => (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.paddingRight}>
          <PhoneControl
            phone={user.phone}
            phoneError={phoneError}
            countryCode={user.country_code}
            setPhone={p => setUser({ ...user, phone: p })}
            setPhoneError={setPhoneError}
            setCountryCode={c => setUser({ ...user, country_code: c })}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.paddingRight}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={user.birthday}
              onChange={date => setUser({ ...user, birthday: date })}
              label={t('APP.USER.TEXT_FIELD.LABEL.BIRTHDAY')}
              cancelText={t('CANCEL')}
              renderInput={params => (
                <TextField {...params} variant="outlined" required margin="normal" />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <FormControl margin="normal" component="fieldset" fullWidth style={{ border: 1 }}>
        <InputLabel
          variant="outlined"
          shrink
          className={classes.selectLabel}
          style={{
            zIndex: '1011',
          }}
        >
          {t('APP.USER.TEXT_FIELD.LABEL.ADDRESS')}
        </InputLabel>
        <AutoCompletePlaces
          onSelect={value => {
            setAddress({
              formattedAddress: value.formatted_address,
              latitude: value.coordinates?.lat,
              longitude: value.coordinates?.lng,
              locality: value.locality,
            });
          }}
          onClear={() => {
            setAddress({
              formattedAddress: '',
              latitude: null,
              longitude: null,
              locality: '',
            });
          }}
          placeHolder={t('APP.ACTIVITY.ADDRESS.PLACE_HOLDER')}
          defaultValue={user.formattedAddress}
          id="helperAddress"
        />
      </FormControl>
      <TextField
        name="addressSupplement"
        value={user.addressSupplement}
        onChange={event => {
          setUser({ ...user, addressSupplement: event.target.value });
        }}
        label={t('APP.USER.TEXT_FIELD.ADDRESS_SUPPLEMENT')}
        placeholder={t('APP.USER.TEXT_FIELD.ADDRESS_SUPPLEMENT.PLACE_HOLDER')}
        margin="normal"
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        name="description"
        value={user.description}
        multiline
        maxRows={20}
        onChange={event => {
          setUser({ ...user, description: event.target.value });
        }}
        placeholder={t('APP.USER.TEXT_FIELD.PLACEHOLDER.DESCRIPTION')}
        label={t('APP.USER.TEXT_FIELD.LABEL.DESCRIPTION')}
        margin="normal"
        variant="outlined"
        fullWidth
        required
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        name="pathology"
        value={user.pathology}
        multiline
        minRows={2}
        onChange={event => {
          setUser({ ...user, pathology: event.target.value });
        }}
        placeholder={t('APP.USER.TEXT_FIELD.PLACEHOLDER.PATHOLOGY')}
        label={t('APP.USER.TEXT_FIELD.LABEL.PATHOLOGY')}
        margin="normal"
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );

  const renderAdvancedParams = () => (
    <FormControl component="fieldset" className={classes.formControl} fullWidth>
      <FormLabel component="legend">
        {t('APP.USER.TEXT_FIELD.LABEL.AVATAR')}
        <Typography variant="caption" component="div">
          {t('APP.USER.TEXT_FIELD.PLACEHOLDER.AVATAR')}
        </Typography>
      </FormLabel>
      <Badge
        overlap="circular"
        className={classes.badge}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <label htmlFor="avatar-file" style={{ paddingTop: '8px' }}>
            <input
              accept="image/*"
              className={classes.input}
              style={{ display: 'none' }}
              id="avatar-file"
              multiple
              type="file"
              onChange={e => handleLoadAvatar(e)}
            />
            <IconButton
              className={classes.cameraButton}
              aria-label="upload picture"
              component="span"
              size="medium"
            >
              <PhotoCamera />
            </IconButton>
          </label>
        }
      >
        <WelloAvatar
          avatarUrl={user.avatarPreview}
          firstName={user?.first_name}
          lastName={user?.last_name}
          backgroundColor={user?.avatar_background_color}
          avatarClass={classes.avatar}
        />
      </Badge>
    </FormControl>
  );

  const renderEmailParams = () => (
    <>
      <Grid container style={{ alignItems: 'center' }}>
        <Grid style={{ flexGrow: 1 }}>
          <TextField
            id="email"
            name="email"
            value={user?.email}
            label={t('APP.ORGANISATION.SETTINGS.MEMBERS.EMAIL.LABEL')}
            onChange={event => {
              setUser({ ...user, email: event.target.value?.trim() });
            }}
            className={classes.formControl}
            fullWidth
            margin="none"
            variant="outlined"
            onBlur={() => setEmailError(user.email && !checkEmailValidity(user.email))}
            InputLabelProps={{
              shrink: true,
            }}
            error={emailError}
            helperText={emailError && t('INCORRECT_EMAIL')}
            required={emailRequired}
            disabled={initialEmail}
          />
        </Grid>
        {tooltipLabel && (
          <Tooltip title={t(tooltipLabel)} placement="bottom">
            <HelpIcon className={classes.helpIcon} />
          </Tooltip>
        )}
      </Grid>

      {!initialEmail && user?.id != currentUser?.id && (
        <FormControl component="div" className={classes.checkBox}>
          <FormControlLabel
            key="inviteLabel"
            label={t('APP.ORGANISATION.SETTINGS.MEMBERS.INVITE.SEND_EMAIL')}
            control={
              <Checkbox
                name="invite"
                onChange={() => {
                  setUser({ ...user, sendEmail: !user.sendEmail });
                }}
                checked={user.sendEmail}
                color="primary"
                disabled={!user.email}
              />
            }
          />
        </FormControl>
      )}
    </>
  );

  const renderLeagueStatus = () => (
    <Autocomplete
      value={user?.leagueStatus}
      onChange={(event, newValue) => {
        if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setUser({ ...user, leagueStatus: newValue.inputValue });
        } else {
          setUser({ ...user, leagueStatus: newValue });
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(option => inputValue === option);
        if (inputValue.trim() !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: t('ADD') + ` "${inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="league-helper-satus"
      options={existingHelpersStatus}
      getOptionLabel={option => {
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option;
      }}
      renderOption={(props, status) => <li {...props}>{status?.title || status}</li>}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          label={t('APP.LEAGUES.SETTINGS.HELPERS.STATUS')}
          margin="normal"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t('APP.LEAGUES.SETTINGS.HELPERS.STATUS.PLACEHOLDER')}
        />
      )}
      className={classes.autocomplete}
    />
  );

  return (
    <>
      <form onSubmit={onSubmit}>
        {showLeagueStatus && renderLeagueStatus()}
        {!hideProfilParams && (
          <FormControl>
            <RadioGroup
              className={classes.gender}
              name="gender"
              value={user.gender}
              onChange={event => {
                setUser({ ...user, gender: event.target.value });
              }}
            >
              <FormControlLabel
                value="male"
                control={<Radio color="primary" required />}
                label={t('APP.USER.TEXT_FIELD.LABEL.GENDER.MALE')}
              />
              <FormControlLabel
                value="female"
                control={<Radio color="primary" required />}
                label={t('APP.USER.TEXT_FIELD.LABEL.GENDER.FEMALE')}
              />
            </RadioGroup>
          </FormControl>
        )}
        {renderBasicForm()}
        {!hideEmailParams && renderEmailParams()}
        {!hideProfilParams && renderProfilForm()}
        {!hideAdvancedParams && renderAdvancedParams()}
        {onSubmit && (
          <Grid item xs align="center">
            {buttonBack && (
              <Button onClick={onCancel} className={classes.submit}>
                {t('BACK')}
              </Button>
            )}
            <Button
              disabled={
                isUpdating ||
                phoneError !== null ||
                firstNameError !== null ||
                lastNameError !== null
              }
              color="primary"
              type="submit"
              variant="contained"
              className={classes.submit}
            >
              {t('SEND')}
            </Button>
          </Grid>
        )}
      </form>
    </>
  );
};

export default UserForm;
