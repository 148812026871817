import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  title: {
    textTransform: 'initial',
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

const RightMenuPrimary = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Card className={classes.card}>
        <Grid className={classes.padding}>
          <Typography variant="h5" className={classes.title}>
            {t('APP.ORGANISATION.SETTINGS.PRIMARY.RIGHT_MENU')}
          </Typography>
        </Grid>
        <Divider light />
        <Typography variant="body2" className={classes.padding}>
          {t('APP.ORGANISATION.SETTINGS.PRIMARY.RIGHT_MENU.SUBTITLE')}
        </Typography>
      </Card>
      <Card className={classes.card}>
        <Grid className={classes.padding}>
          <Typography variant="h5" className={classes.title}>
            {t('APP.ORGANISATION.SETTINGS.PRIMARY.RIGHT_MENU.EXPORT')}
          </Typography>
          <Typography variant="body2">{t('COMMING_SOON')}</Typography>
        </Grid>
      </Card>
    </>
  );
};

export default RightMenuPrimary;
