import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import HoldingActions from '../../Redux/HoldingRedux';
import { checkEmailValidity } from '../../Services/DataHelper';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(3),
  },
  buttonContainer: {
    justifyContent: 'center',
    marginTop: theme.spacing(-1),
  },
}));

type Props = {
  holding: Object,
};

const CardInviteAdmin = ({ holding }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.auth.currentUser);

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(null);

  const logo = holding?.cover_url
    ? {
        source: holding?.cover_url,
        options: {
          type: 'local',
          file: { name: holding?.cover_url, size: 0 },
          metadata: { poster: holding?.cover_url },
        },
      }
    : null;

  const charters =
    holding?.charters?.length > 0
      ? [
          {
            source: holding.charters[0].download_url,
            options: {
              type: 'local',
              file: {
                name: holding.charters[0].filename,
                size: 0,
                url: holding.charters[0].download_url,
              },
              metadata: { poster: holding.charters[0] },
            },
          },
        ]
      : null;

  const onSubmit = () => {
    if (!checkEmailValidity(email)) return;

    const updateAttributes = {};
    if (holding) {
      updateAttributes.id = holding.id;
      updateAttributes.slug = holding.slug;
      updateAttributes.league_id = holding.league_id;
      dispatch(
        HoldingActions.updateHoldingRequest(
          holding,
          updateAttributes,
          logo,
          charters ? charters[0] : null,
          currentUser.id,
          email.toLowerCase()?.trim(),
        ),
      );
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" className={classes.title}>
          {t('APP.ORGANISATION.HOME.INVITE_ADMIN')}
        </Typography>
        <TextField
          name="email"
          value={email}
          type="text"
          placeholder="email@yahoo.com"
          onChange={event => setEmail(event.target.value?.trim())}
          label={t('APP.ORGANISATION.SETTINGS.GENERAL.EMAIL')}
          variant="outlined"
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={() => setEmailError(!checkEmailValidity(email))}
          error={emailError}
          helperText={emailError && t('INCORRECT_EMAIL')}
        />
      </CardContent>
      <CardActions className={classes.buttonContainer}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={!holding || !email.includes('@') || email[email.length - 1] === '@'}
          onClick={onSubmit}
        >
          {t('INVITE')}
        </Button>
      </CardActions>
    </Card>
  );
};
export default CardInviteAdmin;
