import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { MonthView, WeekView } from '@devexpress/dx-react-scheduler-material-ui';

type Props = {
  view: String,
  startDate: String,
  isFullScreen: Boolean,
  canCreateTask: Boolean,
};

const TwigTimeTableCell = ({
  view,
  startDate,
  isFullScreen,
  canCreateTask,
  ...restProps
}: Props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    family_slug: familySlug,
  } = match.params;
  const date = new Date(startDate);
  if (view === 'Month') date.setHours(8);

  const onDoubleClick = () => {
    if (isFullScreen) return;
    if (canCreateTask) {
      if (!familySlug) {
        history.push(`/${holdingSlug}/${organisationSlug}/activites/new`, {
          date,
        });
      } else {
        history.push(`/families/${familySlug}/schedule/new`, {
          date,
        });
      }
    }
  };

  if (view === 'Month') {
    return (
      <MonthView.TimeTableCell startDate={startDate} {...restProps} onDoubleClick={onDoubleClick} />
    );
  }

  return (
    <WeekView.TimeTableCell startDate={startDate} {...restProps} onDoubleClick={onDoubleClick} />
  );
};

export default TwigTimeTableCell;
