import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getPostFormatedCount } from '../../Services/ClubHelpers';
import { grey } from '@material-ui/core/colors';
import MuiLink from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  accordionTitle: {
    padding: 0,
    width: '100%',
    fontWeight: '700',
  },
  accordionMenuItem: {
    padding: theme.spacing(0, 2),
    width: '100%',
    alignSelf: 'center',
  },
  accordion: {
    width: '100%',
    borderRadius: 0,
    margin: 0,
    padding: 0,
    '&:first-child': {
      width: '100%',
      borderRadius: 0,
      margin: 0,
    },
  },
  icon: {
    marginRight: '-15px',
    color: 'black',
  },
  accordionCreateMenuItem: {
    paddingLeft: theme.spacing(2),
    color: 'grey',
    width: '100%',
  },
  subtitle: {
    color: grey[500],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    whiteSpace: 'initial',
  },
  title: {
    '&>*': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: '1',
      whiteSpace: 'initial',
    },
  },
  box: {
    backgroundColor: 'red',
    textAlign: 'center',
    width: '22px',
    height: '22px',
  },
  number: {
    paddingTop: '3px',
    height: '100%',
    fontSize: '0.7em',
    fontWeight: '700',
    color: 'white',
  },
  text: {
    fontSize: '0.85em',
    padding: theme.spacing(2),
    paddingTop: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  colorPrimary: {
    backgroundColor: '#DDDDDE',
  },
  barColorPrimary: {
    backgroundColor: '#707070',
  },
  notifIcon: {
    marginLeft: theme.spacing(1),
    color: grey[600],
  },
}));

type Props = {
  items: Array,
  isFetching: Boolean,
  menuTitle: String,
  canCreate: Boolean,
  onCreateClick: Function,
  createTitle: String,
  noItemTitle: String,
  hideAccordion: Boolean,
};

const MenuItems = ({
  items,
  isFetching,
  menuTitle,
  canCreate,
  onCreateClick,
  createTitle,
  noItemTitle,
  hideAccordion,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderMenu = () => {
    return (
      <Grid container>
        {isFetching && (
          <Grid item xs={12}>
            <LinearProgress
              classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary,
              }}
            />
          </Grid>
        )}
        {items &&
          items.length > 0 &&
          items.map(item => {
            return (
              <MenuItem
                component={Link}
                to={item.link}
                className={classes.accordionMenuItem}
                key={item.id}
              >
                <Grid container style={{ flexFlow: 'nowrap' }} alignItems="center">
                  <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
                  <ListItemText
                    className={classes.title}
                    primary={t(item.title)}
                    secondary={
                      item.subTitle && (
                        <Typography variant="caption" className={classes.subtitle}>
                          {item.subTitle}
                        </Typography>
                      )
                    }
                  />
                  {!item.showNotifOffIcon && item.unread_posts_count > 0 && (
                    <Box className={classes.box} borderRadius="50%">
                      <Typography className={classes.number}>
                        {getPostFormatedCount(item)}
                      </Typography>
                    </Box>
                  )}
                  {item.showNotifOffIcon && (
                    <NotificationsOffIcon fontSize="small" className={classes.notifIcon} />
                  )}
                </Grid>
              </MenuItem>
            );
          })}
        {canCreate && onCreateClick && (
          <MenuItem
            component={MuiLink}
            onClick={onCreateClick}
            className={classes.accordionCreateMenuItem}
          >
            <ListItemIcon className={classes.icon}>
              <AddIcon />
            </ListItemIcon>
            {createTitle}
          </MenuItem>
        )}
        {!canCreate && !isFetching && items && items.length === 0 && (
          <Typography className={classes.text}>{noItemTitle}</Typography>
        )}
      </Grid>
    );
  };

  return hideAccordion ? (
    renderMenu()
  ) : (
    <MenuItem className={classes.accordionTitle}>
      <Accordion
        classes={{
          root: classes.accordion,
          rounded: classes.accordion,
          expanded: classes.accordion,
        }}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{menuTitle}</AccordionSummary>
        <AccordionDetails className={classes.accordion}>{renderMenu()}</AccordionDetails>
      </Accordion>
    </MenuItem>
  );
};
export default MenuItems;
