import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Button from '@material-ui/core/Button';
import debounce from 'lodash/debounce';
import ClubsActions from '../../Redux/ClubsRedux';
import ClubPost from './ClubPost';
import PostForm from './Form';
import 'moment/locale/fr';
import { subscribeChannel, unsubscribeChannel, CHANNEL_CLUB } from '../../Services/WebSocket';
import {
  getLastReadDate,
  getNewMessagesBarPosition,
  getSubscription,
  SubscribleTypes,
} from '../../Services/SubscriptionHelper';
import AuthActions from '../../Redux/AuthRedux';

type Props = {
  organisation: Object,
  clubId: String,
  club: Object,
  currentUser: Object,
};

const useStyles = makeStyles(theme => ({
  sticky: {
    position: 'sticky',
    top: '60px',
    paddingTop: '10px',
  },
  posts: {
    height: 'calc(100vh - 425px)',
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  postTextField: {
    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  date: {
    textAlign: 'center',
  },
  dateText: {
    color: theme.palette.grey[500],
    padding: '8px',
  },
  divider: {
    marginTop: '8px',
    backgroundColor: theme.mormal.primary.main,
  },
  newMessagesText: {
    color: theme.mormal.primary.main,
    padding: '8px',
  },
  button: {
    borderRadius: '10px',
    padding: '8px',
    marginTop: '5px',
    marginBottom: '5px',
  },
}));

const ClubChat = ({ organisation, club, currentUser, clubId }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isCreatingPost = useSelector(state => state.clubs.isFetching.createClubPost);
  const isFetchingPosts = useSelector(state => state.clubs.isFetching.getClubPosts);
  const posts = useSelector(state => state.clubs.posts);
  const totalPosts = useSelector(state => state.clubs.totalPosts);
  const postContainer = React.useRef();
  const isScrolledDown = React.useRef(true);
  const containerHeight = React.useRef(0);
  const [page, setPage] = React.useState(1);
  const [lastPostDate, setLastPostDate] = React.useState(null);
  const token = currentUser?.token;

  // eslint-disable-next-line
  const updateLastReadDate = React.useCallback(
    debounce(
      (sub, date) =>
        dispatch(
          AuthActions.updateHelperSubscriptionRequest(sub.helper_id, {
            ...sub,
            last_read_date: date.toJSON(),
          }),
        ),
      2000,
    ),
    [dispatch, organisation],
  );

  React.useEffect(() => {
    const channelSubscription = token
      ? subscribeChannel(
          token,
          { channel: CHANNEL_CLUB, club_id: clubId },
          {
            received(data) {
              // data { club_id: 9999, helper_id: 9999, post: {...} }
              // console.log('WS Received', CHANNEL_CLUB, data);
              if (data && data.helper_id !== currentUser.id && data.post) {
                dispatch(ClubsActions.createClubPostSuccess(clubId, data.post));
                const sub = getSubscription(organisation, SubscribleTypes.CLUB, null, clubId);
                const date = new Date();
                if (sub)
                  dispatch(
                    AuthActions.updateHelperSubscriptionRequest(sub.helper_id, {
                      ...sub,
                      last_read_date: date.toJSON(),
                    }),
                  );
              }
            },
          },
        )
      : null;
    return () => {
      if (channelSubscription) unsubscribeChannel(channelSubscription);
    };
    // eslint-disable-next-line
  }, [dispatch, token, clubId]);

  React.useEffect(() => {
    const sub = getSubscription(organisation, SubscribleTypes.CLUB, null, clubId);
    const date = new Date();
    if (club?.id && sub) updateLastReadDate(sub, date);
    // eslint-disable-next-line
  }, [organisation?.slug, club?.id]);

  React.useEffect(() => {
    if (organisation && club && `${clubId}` === `${club.id}` && page > 1) {
      dispatch(
        ClubsActions.getClubPostsRequest(
          organisation.holding_slug,
          organisation.slug,
          clubId,
          page,
          lastPostDate,
        ),
      );
    }
    // eslint-disable-next-line
  }, [dispatch, organisation, clubId, page]);

  React.useEffect(() => {
    if (postContainer.current && page !== 1 && containerHeight.current !== 0) {
      postContainer.current.scrollTop =
        postContainer.current.scrollHeight - containerHeight.current;
      containerHeight.current = 0;
    } else if (postContainer.current) {
      postContainer.current.scrollTop = postContainer.current.scrollHeight;
    }

    if (!isFetchingPosts && posts?.length > 0 && `${clubId}` === `${club.id}`) {
      setLastPostDate(posts[posts.length - 1].created_at);
    }
    // eslint-disable-next-line
  }, [posts]);

  const handleCreatePost = post => {
    dispatch(
      ClubsActions.createClubPostRequest(
        organisation.holding_slug,
        organisation.slug,
        club.id,
        post,
      ),
    );
  };

  const handleSeeMoreMessages = () => {
    containerHeight.current = postContainer.current.scrollHeight;
    setPage(page + 1);
  };

  const handleScroll = () => {
    isScrolledDown.current =
      postContainer.current.scrollHeight - postContainer.current.scrollTop ===
      postContainer.current.clientHeight;
  };

  const today = moment(new Date()).format('DD/MM/YY');

  const lastReadDate = React.useMemo(() => {
    return getLastReadDate(organisation, SubscribleTypes.CLUB, null, club.id);
  }, [club?.id]);

  const newMessagesBarPosition = getNewMessagesBarPosition(lastReadDate, posts);

  const renderNewMessagesBar = () => (
    <>
      <Divider light className={classes.divider} />
      <div className={classes.date}>
        <Typography variant="caption" className={classes.newMessagesText}>
          {t('APP.CLUBS.SHOW_CLUB.NEW_MESSAGES')}
        </Typography>
      </div>
    </>
  );

  return (
    <>
      <Grid className={classes.sticky}>
        <Paper>
          <Grid className={classes.posts} ref={postContainer} onScroll={handleScroll}>
            {(isFetchingPosts || totalPosts - posts?.length > 0) && (
              <div className={classes.date}>
                <Button
                  className={classes.button}
                  onClick={handleSeeMoreMessages}
                  startIcon={isFetchingPosts ? <HourglassEmptyIcon /> : <ArrowUpwardIcon />}
                  endIcon={isFetchingPosts ? <HourglassEmptyIcon /> : <ArrowUpwardIcon />}
                  disabled={isFetchingPosts}
                >
                  <Typography variant="caption">
                    {t(isFetchingPosts ? 'LOADING' : 'APP.CLUBS.SHOW_CLUB.SEE_MORE_MESSAGES')}
                  </Typography>
                </Button>
              </div>
            )}
            {!isFetchingPosts && page === 1 && totalPosts === 0 && (
              <div className={classes.date} style={{ paddingTop: '8px' }}>
                <Typography variant="caption" className={classes.dateText}>
                  {t('APP.CLUBS.SHOW_CLUB.NO_MESSAGE')}
                </Typography>
              </div>
            )}
            {(!isFetchingPosts || page > 1) &&
              posts?.length > 0 &&
              `${clubId}` === `${club.id}` &&
              posts.map((post, i) => {
                const postDay = moment(post.created_at).format('DD/MM/YY');

                if (i > 0 && moment(posts[i - 1].created_at).format('DD/MM/YY') === postDay) {
                  return (
                    <div key={post.id}>
                      {i === newMessagesBarPosition && renderNewMessagesBar()}
                      <ClubPost post={post} key={post.id} currentUser={currentUser} />
                    </div>
                  );
                }

                return (
                  <div key={post.id}>
                    {i > 0 && <Divider light style={{ marginTop: '8px' }} />}
                    <div className={classes.date}>
                      <Typography variant="caption" className={classes.dateText}>
                        {postDay !== today && moment(post.created_at).format('LL')}
                        {postDay === today && t('TODAY')}
                      </Typography>
                    </div>
                    {i === newMessagesBarPosition && renderNewMessagesBar()}
                    <ClubPost post={post} key={post.id} currentUser={currentUser} />
                  </div>
                );
              })}
          </Grid>

          <Grid container className={classes.postTextField}>
            <PostForm
              currentUser={currentUser}
              postableType="Club"
              createPost={handleCreatePost}
              creatingPost={Boolean(isCreatingPost)}
              isMessage
              sendOnEnter
            />
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default ClubChat;
