import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import classNames from 'classnames';

import { Chip, Collapse, Select, Tooltip, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { green, grey, orange, red } from '@material-ui/core/colors';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ImagesMosaic from '../../Shared/ImagesMosaic';
import TransmissionPostForm from '../../Posts/TransmissionPostForm';
import TransmissionsActions from '../../../Redux/TransmissionsRedux';
import PostForm from '../../Posts/Form';
import { CheckRoleRule } from '../../../Acl/Rules';
import { getRoleInOrga } from '../../../Acl/Controls';
import getAvatar from '../../../Services/TransmissionHelper';
import { getOrgaName } from '../../../Services/OrganisationHelper';
import { hex2rgba } from '../../../Services/DataHelper';
import LeadsActions from '../../../Redux/LeadsRedux';
import SelectOrganisationDialog from '../../Shared/SelectOrganisationDialog';
import getLeagueOrganisationsWithoutNoStruct from '../../../Services/LeagueHelper';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(2),
  },
  cardHeader: { padding: theme.spacing(0, 0, 0.5) },
  avatar: {
    height: 35,
    width: 35,
    '&>*': {
      height: 35,
      width: 35,
    },
  },
  title: {
    marginRight: theme.spacing(0.5),
    textTransform: 'none',
  },
  textContainer: {
    borderLeft: '#ddd 1px solid',
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1, 1, 2, 4),
  },
  commentCard: {
    padding: theme.spacing(1),
  },
  commentCardDialog: {
    padding: theme.spacing(3, 3, 5),
  },
  greyIcon: {
    color: grey[600],
    marginRight: theme.spacing(1),
  },
  select: {
    borderRadius: 30,
    maxHeight: 24,
    backgroundColor: hex2rgba(grey[500], 0.2),
    border: `solid ${grey[500]}`,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  validated: {
    backgroundColor: hex2rgba(green[500], 0.2),
    border: `solid ${green[500]}`,
  },
  pending: {
    backgroundColor: hex2rgba(orange[500], 0.2),
    border: `solid ${orange[500]}`,
  },
  declined: {
    backgroundColor: hex2rgba(red[500], 0.2),
    border: `solid ${red[500]}`,
  },
  statusSelected: { paddingLeft: 8, fontSize: '0.8125rem' },
}));

type Props = {
  transmission: Object,
  primaryMembership: Object,
  handleClickEditLeagueTransmission: Function,
  league: Object,
};

const CardTransmission = ({
  transmission,
  primaryMembership,
  handleClickEditLeagueTransmission,
  league,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const {
    league_id: leagueId,
    holding_slug: holdingSlug,
    organisation_slug: organisationSlug,
    item_id: primaryOrgaId,
    helper_id: helperId,
  } = match.params;
  const currentUser = useSelector(state => state.auth.currentUser);
  const isCreatingPost = useSelector(
    state => state.transmissions.isFetching.createOrganisationTransmissionPost,
  );
  const [showTransmissionPostForm, setShowTransmissionPostForm] = React.useState(false);
  const [editComment, setEditComment] = React.useState(false);
  const [post, setPost] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorComment, setAnchorComment] = React.useState(null);
  const [openSelectOrgaDialog, setOpenSelectOrgaDialog] = React.useState('');
  const hasPosts = transmission?.posts?.length > 0;
  const [expanded, setExpanded] = React.useState(hasPosts);
  const open = Boolean(anchorEl);
  const openComment = Boolean(anchorComment);
  const isMessageTransmission = transmission?.type === 'Transmissions::Message';
  const isOtherTransmission = transmission?.type === 'Transmissions::Other';
  const attachments = transmission?.attachments?.filter(a => !a.preview_url);
  const images = transmission?.attachments?.filter(a => a.preview_url) || [];
  const eventStartDate = new Date(transmission?.event_start_date);
  eventStartDate?.setSeconds(0);
  eventStartDate?.setMilliseconds(0);
  const createdAt = new Date(transmission?.created_at);
  createdAt?.setSeconds(0);
  createdAt?.setMilliseconds(0);
  const lead = transmission?.lead;
  const isTransmissionOnLeague = transmission?.helpers_league_id && leagueId;
  const leadOrgaName = getOrgaName(lead?.organisation);
  const isTransmissionLead = transmission?.type === 'Transmissions::Lead';
  const isLeadOnOrga = lead?.organisation;
  const leagueOrganisationsWithoutNoStruct = getLeagueOrganisationsWithoutNoStruct(league);
  const leagueStatus = [
    {
      value: 'validated',
      label: t(`APP.LEADS.LEAGUE.STATUS.validated`),
      classes: classes.validated,
    },
    {
      value: 'pending',
      label: t(`APP.LEADS.LEAGUE.STATUS.pending`),
      classes: classes.pending,
    },
    {
      value: 'declined',
      label: t(`APP.LEADS.LEAGUE.STATUS.declined`),
      classes: classes.declined,
    },
  ];

  const onClickEditTransmission = () => {
    if (isTransmissionOnLeague) {
      handleClickEditLeagueTransmission(transmission);
    } else {
      setShowTransmissionPostForm(true);
    }
    setAnchorEl(null);
  };

  const onClickDeleteTransmission = () => {
    if (isTransmissionOnLeague) {
      dispatch(
        TransmissionsActions.destroyLeagueTransmissionRequest(leagueId, helperId, transmission?.id),
      );
    } else {
      dispatch(
        TransmissionsActions.destroyOrganisationTransmissionRequest(
          holdingSlug,
          organisationSlug,
          primaryOrgaId,
          transmission?.id,
        ),
      );
    }
  };

  const handleClickTransferLead = () => {
    setOpenSelectOrgaDialog(true);
    setAnchorEl(null);
  };

  const onCreateComment = comment => {
    if (leagueId) {
      dispatch(
        TransmissionsActions.createLeagueTransmissionPostRequest(
          leagueId,
          transmission?.id,
          comment,
        ),
      );
    } else {
      dispatch(
        TransmissionsActions.createOrganisationTransmissionPostRequest(
          holdingSlug,
          organisationSlug,
          transmission?.id,
          comment,
        ),
      );
    }
  };

  const handleOpenMenuComment = (event, p) => {
    setAnchorComment(event.currentTarget);
    setPost(p);
  };

  const onClickEditComment = () => {
    setEditComment(true);
    setAnchorComment(null);
  };

  const onEditComment = comment => {
    if (leagueId) {
      dispatch(
        TransmissionsActions.updateLeagueTransmissionPostRequest(
          leagueId,
          transmission?.id,
          comment,
          [],
        ),
      );
    } else {
      dispatch(
        TransmissionsActions.updateOrganisationTransmissionPostRequest(
          holdingSlug,
          organisationSlug,
          transmission?.id,
          comment,
          [],
        ),
      );
    }
  };

  const onClickDeleteComment = () => {
    if (leagueId) {
      dispatch(
        TransmissionsActions.destroyLeagueTransmissionPostRequest(
          leagueId,
          transmission?.id,
          post.id,
        ),
      );
    } else {
      dispatch(
        TransmissionsActions.destroyOrganisationTransmissionPostRequest(
          holdingSlug,
          organisationSlug,
          transmission?.id,
          post.id,
        ),
      );
    }
    setAnchorComment(null);
  };

  const handleStatusChange = status => {
    dispatch(
      LeadsActions.updateLeagueLeadRequest(
        leagueId,
        lead?.id,
        {
          league_status: status,
        },
        helperId,
      ),
    );
  };

  const handleConfirmTransfer = selectedOrga => {
    if (
      !selectedOrga ||
      !lead ||
      isLeadOnOrga ||
      !leagueOrganisationsWithoutNoStruct.find(o => o.id === selectedOrga.id)
    )
      return;

    dispatch(
      LeadsActions.transferLeagueLeadRequest(league?.id, lead?.id, selectedOrga.id, helperId),
    );
    setOpenSelectOrgaDialog(false);
  };

  const renderLeadStatus = (color, label) => (
    <Chip
      size="small"
      label={label}
      style={{
        backgroundColor: hex2rgba(color, 0.2),
        border: `solid ${color}`,
      }}
    />
  );

  return (
    <>
      <Grid className={classes.mainContainer}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar
              className={classes.avatar}
              alt={transmission?.title}
              style={{
                color: getAvatar(transmission).color,
                backgroundColor: getAvatar(transmission).backgroundColor,
              }}
            >
              {getAvatar(transmission).component}
            </Avatar>
          }
          action={
            <Grid container alignItems="center">
              {isTransmissionOnLeague && isLeadOnOrga && (
                <>
                  <Tooltip
                    title={t(`APP.LEADS.LEAGUE.STATUS.ORGA.${lead?.league_status}`, {
                      orga: leadOrgaName,
                    })}
                  >
                    {lead?.league_status === 'in_organisation' ? (
                      <ArrowRightAltIcon className={classes.greyIcon} />
                    ) : (
                      <AirlineStopsIcon className={classes.greyIcon} />
                    )}
                  </Tooltip>
                  {lead?.responsible_id
                    ? renderLeadStatus(green[500], t('APP.LEADS.LEAGUE.STATUS.validated'))
                    : renderLeadStatus(grey[500], t('APP.LEADS.LEAGUE.STATUS.new'))}
                </>
              )}
              {isTransmissionLead && !isLeadOnOrga && (
                <Select
                  labelId="league-status-select"
                  id="league-status-select"
                  value={lead?.league_status}
                  label="Status"
                  onChange={event => handleStatusChange(event.target.value)}
                  disableUnderline
                  className={classNames(classes.select, classes[lead?.league_status])}
                  size="small"
                  renderValue={selected => (
                    <Typography className={classes.statusSelected}>
                      {t(`APP.LEADS.LEAGUE.STATUS.${selected}`)}
                    </Typography>
                  )}
                >
                  {leagueStatus.map(status => (
                    <MenuItem key={status.value} value={status.value}>
                      <Typography>{status.label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
              {!isOtherTransmission && (
                <>
                  <IconButton
                    onClick={event => setAnchorEl(event.currentTarget)}
                    aria-label="More"
                    aria-owns={open ? 'actions-menu' : undefined}
                    aria-haspopup="true"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="actions-menu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                  >
                    {isTransmissionLead && !isLeadOnOrga && (
                      <MenuItem onClick={handleClickTransferLead}>
                        <ListItemIcon>
                          <AirlineStopsIcon />
                        </ListItemIcon>
                        <ListItemText>{t('APP.LEADS.ACTION.TRANSFER')}</ListItemText>
                      </MenuItem>
                    )}
                    {transmission?.path && (
                      <MenuItem onClick={() => history.push(transmission?.path)}>
                        <ListItemIcon>
                          <VisibilityIcon />
                        </ListItemIcon>
                        <ListItemText>{t('CONSULT')}</ListItemText>
                      </MenuItem>
                    )}
                    {isMessageTransmission && (
                      <MenuItem onClick={() => onClickEditTransmission()}>
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText className={classes.flex}>{t('EDIT')}</ListItemText>
                      </MenuItem>
                    )}
                    {isMessageTransmission && (
                      <MenuItem onClick={() => onClickDeleteTransmission()}>
                        <ListItemIcon>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText>{t('DELETE')}</ListItemText>
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </Grid>
          }
          title={
            <Typography variant="h5" className={classes.title}>
              {transmission?.title}
            </Typography>
          }
          subheader={
            <Typography variant="overline" component="div">
              {moment(createdAt).format('DD MMMM YYYY - LT')}
            </Typography>
          }
        />

        {transmission?.message && (
          <Grid className={classes.textContainer}>
            <Typography>{transmission?.message}</Typography>
            {eventStartDate.getTime() !== createdAt.getTime() && (
              <Typography variant="subtitle2" component="div" gutterBottom>
                {`${t('APP.ACTIVITY.DATE.LABEL')} : ${moment(eventStartDate).format('DD/MM LT')} ${
                  transmission?.event_end_date
                    ? ` - ${moment(transmission?.event_end_date).format('LT')}`
                    : ''
                }`}
              </Typography>
            )}
            {attachments.map(attachment => (
              <a
                href={attachment.download_url}
                key={attachment.id}
                target="_blank"
                without="true"
                rel="noopener noreferrer"
              >
                <CardContent className={classes.attachment}>{attachment.filename}</CardContent>
              </a>
            ))}
            {images && <ImagesMosaic images={images} />}

            {/* Comment */}
            <Collapse in={expanded} timeout="auto" unmountOnExit style={{ paddingTop: 24 }}>
              {hasPosts &&
                orderBy(transmission?.posts, 'created_at', 'asc').map(p => (
                  <CardHeader
                    key={p.id}
                    title={
                      <Typography variant="body2" component="div">
                        <b>
                          {p.helper_first_name} {p?.helper_last_name} -{' '}
                        </b>
                        {t('DATE', {
                          date: moment(p.created_at).format('DD/MM'),
                          time: moment(p.created_at).format('LT'),
                        })}
                      </Typography>
                    }
                    action={
                      (isTransmissionOnLeague ||
                        CheckRoleRule(
                          getRoleInOrga(currentUser, holdingSlug, organisationSlug),
                          'transmissions-comments:editdelete',
                          {
                            userId: currentUser.id,
                            commentOwnerId: p?.helper_id,
                          },
                        )) && (
                        <>
                          <IconButton
                            onClick={event => handleOpenMenuComment(event, p)}
                            aria-label="More"
                            aria-owns={openComment ? 'actions-menu-comment' : undefined}
                            aria-haspopup="true"
                          >
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu
                            id="actions-menu-comment"
                            open={openComment}
                            anchorEl={anchorComment}
                            onClose={() => setAnchorComment(null)}
                          >
                            <MenuItem onClick={() => onClickEditComment()}>
                              <ListItemIcon>
                                <EditIcon />
                              </ListItemIcon>
                              <ListItemText className={classes.flex}>{t('EDIT')}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => onClickDeleteComment()}>
                              <ListItemIcon>
                                <DeleteIcon />
                              </ListItemIcon>
                              <ListItemText>{t('DELETE')}</ListItemText>
                            </MenuItem>
                          </Menu>
                        </>
                      )
                    }
                    subheader={
                      <Typography variant="body2" component="div">
                        {p.raw}
                      </Typography>
                    }
                    className={classes.commentCard}
                  />
                ))}
              <Grid className={classes.commentCard}>
                <PostForm
                  currentUser={currentUser}
                  postableType="Transmission"
                  createPost={onCreateComment}
                  creatingPost={Boolean(isCreatingPost)}
                />
              </Grid>
            </Collapse>
          </Grid>
        )}
        {!isOtherTransmission && (
          <Grid container justifyContent="center">
            <IconButton size="small" onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={showTransmissionPostForm}
        onClose={() => setShowTransmissionPostForm(false)}
        fullWidth
      >
        <TransmissionPostForm
          transmission={transmission}
          primaryMembership={primaryMembership}
          onclose={() => setShowTransmissionPostForm(false)}
        />
      </Dialog>
      <Dialog open={editComment} onClose={() => setEditComment(false)} fullWidth>
        <DialogTitle>{t('APP.TIMELINE.EDIT')}</DialogTitle>
        <DialogContent>
          <Grid className={classes.commentCardDialog}>
            <PostForm
              post={post}
              currentUser={currentUser}
              postableType="Transmission"
              updatePost={onEditComment}
              onClose={() => setEditComment(false)}
              isDialog
            />
          </Grid>
        </DialogContent>
      </Dialog>
      <SelectOrganisationDialog
        open={openSelectOrgaDialog}
        dialogTitle={t(
          `APP.${league?.slug?.toUpperCase()}.LEAGUES.SETTINGS.SELECT_ORGANISATION`,
          t(`APP.LEAGUES.SETTINGS.SELECT_ORGANISATION`),
        )}
        dialogButtonLabel={t('APP.LEAGUES.SETTINGS.HELPERS.ASSIGN')}
        onConfirm={handleConfirmTransfer}
        onCancel={() => setOpenSelectOrgaDialog(false)}
        organisations={leagueOrganisationsWithoutNoStruct}
        disabledOrganisationsIds={[]}
      />
    </>
  );
};

export default CardTransmission;
