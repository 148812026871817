import React from 'react';
import { useTranslation } from 'react-i18next';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';

import WishActivityCardPost from '../../Posts/WishActivityCardPost';
import HomeVisitCardPost from '../../Posts/HomeVisitCardPost';
import MessageCardPost from '../../Posts/MessageCardPost';

type Props = {
  post: Object,
  currentUser: Object,
  onEditPost: Function,
  onDeletePost: Function,
  holdingSlug: String,
  organisationSlug: String,
  creatingComment: Boolean,
  onCreateComment: Function,
  onDeleteComment: Function,
  canReadPost: Function,
  canEditDeletePost: Function,
  canDeleteComment: Function,
  postableType: String,
  family: Object,
  isDialog: Boolean,
};

const CardPost = ({
  holdingSlug,
  organisationSlug,
  post,
  currentUser,
  onEditPost,
  onDeletePost,
  creatingComment,
  onCreateComment,
  onDeleteComment,
  canReadPost,
  canEditDeletePost,
  canDeleteComment,
  postableType,
  family,
  isDialog,
}: Props) => {
  const { t } = useTranslation();
  const [actionMenuAnchor, setActionMenuAnchor] = React.useState(null);
  const wishActivityPost = post?.meta?.subtype === 'activity-wish';
  const homeVisitPost = post?.meta?.subtype === 'home-visit' && post?.posts.length === 0;

  const renderMenuAction = () => {
    return (
      <>
        <IconButton
          onClick={event => setActionMenuAnchor(event.currentTarget)}
          aria-label="More"
          aria-owns={actionMenuAnchor ? 'actions-menu' : undefined}
          aria-haspopup="true"
          style={{ margin: 0 }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="actions-menu"
          open={Boolean(actionMenuAnchor)}
          anchorEl={actionMenuAnchor}
          onClose={() => setActionMenuAnchor(null)}
        >
          {!wishActivityPost && (
            <MenuItem
              onClick={() => {
                onEditPost();
                setActionMenuAnchor(null);
              }}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>{t('EDIT')}</ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={onDeletePost}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>{t('DELETE')}</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <>
      {wishActivityPost && (
        <WishActivityCardPost
          post={post}
          currentUser={currentUser}
          holdingSlug={holdingSlug}
          organisationSlug={organisationSlug}
          creatingComment={creatingComment}
          onCreateComment={onCreateComment}
          onDeleteComment={onDeleteComment}
          canReadPost={canReadPost}
          canEditDeletePost={canEditDeletePost}
          canDeleteComment={canDeleteComment}
          postableType={postableType}
          renderMenuAction={renderMenuAction}
          isDialog={isDialog}
        />
      )}
      {homeVisitPost && <HomeVisitCardPost post={post} />}
      {!post?.meta && (
        <MessageCardPost
          post={post}
          currentUser={currentUser}
          holdingSlug={holdingSlug}
          organisationSlug={organisationSlug}
          creatingComment={creatingComment}
          onCreateComment={onCreateComment}
          onDeleteComment={onDeleteComment}
          canReadPost={canReadPost}
          canEditDeletePost={canEditDeletePost}
          canDeleteComment={canDeleteComment}
          postableType={postableType}
          family={family}
          renderMenuAction={renderMenuAction}
          isDialog={isDialog}
        />
      )}
    </>
  );
};

export default CardPost;
